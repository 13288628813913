// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  CREATE_LANGUAGE_REQUEST,
  CREATE_LANGUAGE_REQUEST_SUCCESS,
  CREATE_LANGUAGE_REQUEST_ERROR,
} from "../constants";
import axios from "services/api";

import { error as err } from "../../../main.js";
import { isNil } from "lodash";

function* createLanguage(data) {
  try {
    const values = data;
    const params = new URLSearchParams();
    params.append("code", values.code);
    params.append("name", values.name);
    params.append("data", values.data);

    const response = yield call(axios.post, "/language/", params);

    if (response?.data?.error) {
      yield put({
        type: CREATE_LANGUAGE_REQUEST_ERROR,
        data: response.data.error,
      });
      const newItem = {
        ...data.item,
        error: response.data.error,
        success: false,
        uploading: false,
      };
      data.callback({ success: false, item: newItem });
    } else {
      yield put({
        type: CREATE_LANGUAGE_REQUEST_SUCCESS,
        data: response.data,
      });
      const newItem = {
        name: response.data.name,
        code: response.data.code,
        data: values.data,
        error: false,
        success: true,
        uploading: false,
      };
      data.callback({ success: true, item: newItem });
    }
  } catch (error) {
    const newItem = {
      ...data.item,
      error: !isNil(error?.response?.data?.error)
        ? error.response.data.error
        : "Something went wrong",
      success: false,
      uploading: false,
    };

    yield call(() => data.callback({ success: false, item: newItem }));

    yield call(() =>
      err(
        !isNil(error?.response?.data?.error)
          ? error.response.data.error
          : "Something went wrong"
      )
    );
    yield put({
      type: CREATE_LANGUAGE_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* createLanguageSaga() {
  yield takeLatest(CREATE_LANGUAGE_REQUEST, createLanguage);
}
