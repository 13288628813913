// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_ME_REQUEST,
  GET_ME_REQUEST_SUCCESS,
  GET_ME_REQUEST_ERROR,
} from "../constants";
import axios from "../../../services/api";

function* getMe() {
  try {
    const response = yield call(axios.get, "/me/");

    if (response.data.error) {
      yield put({
        type: GET_ME_REQUEST_ERROR,
        data: response.data.error,
      });
    } else {
      yield put({
        type: GET_ME_REQUEST_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_ME_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* getMeSaga() {
  yield takeLatest(GET_ME_REQUEST, getMe);
}
