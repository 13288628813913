import classes from "./test.module.scss";
import React from "react";
import Button from "components/UI/button";
import Footer from "components/UI/footer";
import Heading from "components/UI/heading";

import { Link } from "react-router-dom";
import { getProgressRequest, setProgressRequest } from "store/progress/actions";
import { setCurrentSection, getSectionTime } from "store/time/actions";
import { makeSelectProgress } from "store/progress/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { mockConfig } from "./mocks";
import Carousel from "components/UI/carousel";
const Test = (props) => {
  return (
    <div className={classes.Container}>
      <Heading
        title={"Introductory multi-media content"}
        className={classes.header}
      />
      <Carousel config={mockConfig} />

      {/* Logic for enabling and Disabling buttons will be triggered from children */}

      <Footer>
        <Link className={classes.Link}>
          <Button
            subText={"Welcome"}
            type={"back"}
            theme={"blue"}
            id="apply-button"
            text="Back"
          />
        </Link>

        <Link className={classes.Link}>
          <Button
            subText={"Take a look around"}
            type={"next"}
            id="apply-button"
            text="Continue"
          />
        </Link>
      </Footer>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  progressData: makeSelectProgress(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getProgress: (val) => dispatch(getProgressRequest(val)),
    setProgress: (val) => dispatch(setProgressRequest(val)),
    setSection: (val) => dispatch(setCurrentSection(val)),
    getSectionTimeReq: (val) => dispatch(getSectionTime(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Test);
