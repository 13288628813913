import React from "react";
import { Route, withRouter } from "react-router-dom";
import { Suspense } from "react";
import Loading from "components/UI/loading";
import { connect } from "react-redux";
import LandScapeModal from "components/UI/landscapeModal";

const NonAuthmiddleware = ({
  component: Component,
  path,
  layout: Layout,
  landscapeWarningVisible,
}) => (
  <Route
    path={path}
    render={(props) => {
      return (
        <Suspense fallback={Loading}>
          <Layout menu={false}>
            {landscapeWarningVisible ? (
              <>
                <LandScapeModal
                  isVisible={landscapeWarningVisible}
                  name={"Landscape Mode"}
                  content={
                    "This chapter does not support landscape mode. Please rotate your device to enjoy the exercise."
                  }
                />
              </>
            ) : (
              <Component {...props} />
            )}
          </Layout>
        </Suspense>
      );
    }}
  />
);

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    hasCards: state.auth.hasCards,
  };
};

export default withRouter(connect(mapStateToProps)(NonAuthmiddleware));
