// @flow
import { put, takeLatest } from "redux-saga/effects";
import { SET_CURRENT_RULE, SET_CURRENT_RULE_SUCCESS } from "../constants";

function* setCurrentRule(data) {
  yield put({
    type: SET_CURRENT_RULE_SUCCESS,
    data: { rule: data.rule, step: data.step, section: data.section },
  });
}

export default function* setCurrentRuleSaga() {
  yield takeLatest(SET_CURRENT_RULE, setCurrentRule);
}
