import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectTime = (state) => state.time || initialState;

const makeSelectLoading = () =>
  createSelector(selectTime, (state) => state.loading);

const makeSelectError = () =>
  createSelector(selectTime, (state) => state.error);

const makeSelectSectionTime = () =>
  createSelector(selectTime, (state) => state.sectionTime);

const makeSelectCountTime = () =>
  createSelector(selectTime, (state) => state.counter);

const makeSelectCurrentSection = () =>
  createSelector(selectTime, (state) => state.currentSection);

export {
  selectTime,
  makeSelectLoading,
  makeSelectError,
  makeSelectSectionTime,
  makeSelectCountTime,
  makeSelectCurrentSection,
};
