// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  SET_PROGRESS_REQUEST,
  SET_PROGRESS_REQUEST_SUCCESS,
  SET_PROGRESS_REQUEST_ERROR,
  GET_PROGRESS_REQUEST_SUCCESS,
} from "../constants";
import axios from "../../../services/api";

function* setProgressRequest(data) {
  const params = new URLSearchParams();
  params.append("progress", data.progress);
  params.append("section", data.section);
  params.append("induction_id", data.induction_id);

  try {
    const response = yield call(axios.post, "/progress/", params);
    if (response.data.error) {
      yield put({
        type: SET_PROGRESS_REQUEST_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: SET_PROGRESS_REQUEST_SUCCESS,
        data: response.data,
      });
      yield put({
        type: GET_PROGRESS_REQUEST_SUCCESS,
        data: response.data,
      });
      data.setNext();
    }
  } catch (error) {
    yield put({
      type: SET_PROGRESS_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* setProgressRequestSaga() {
  yield takeLatest(SET_PROGRESS_REQUEST, setProgressRequest);
}
