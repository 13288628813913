import React from "react";
import classes from "../reporting.module.scss";
import FilterSelector from "components/Supervisor/filterSelector";

//redux
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  getCompaniesRequest,
  getAllAttendaceReportsRequest,
  getAttendanceReportsRequest,
  getRegionRequest,
  getSitesRequest,
} from "../../../../store/supervisor/actions";
import {
  makeSelectCompanies,
  makeSelectLoading,
  makeSelectAllReportsLoading,
  makeSelectAttendanceReports,
  makeSelectAllAttendancesReports,
  makeSelectRegions,
  makeSelectSites,
} from "../../../../store/supervisor/selector";

const Filters = ({
  branchOptions,
  setBranchOptions,
  setSiteOptions,
  siteOptions,
  companyOptions,
  setCompanyOptions,
  personaOptions,
  setPersonaOptions,
  runFilters,
  getCompanies,
  filterLoading,
}) => {
  return (
    <div className={classes.filterWrapper}>
      <FilterSelector
        title={"Branch"}
        options={branchOptions}
        onChange={(val) => {
          const opts = branchOptions.map((item) => {
            return {
              ...item,
              checked: item.value === val ? !item.checked : item.checked,
            };
          });
          setBranchOptions(opts);
          runFilters(opts, siteOptions, companyOptions, personaOptions);
        }}
      />
      <FilterSelector
        title={"Site"}
        options={siteOptions}
        onChange={(val) => {
          const opts = siteOptions.map((item) => {
            return {
              ...item,
              checked: item.value === val ? !item.checked : item.checked,
            };
          });
          setSiteOptions(opts);
          runFilters(branchOptions, opts, companyOptions, personaOptions);
        }}
      />
      {/*
      <FilterSelector
        loading={filterLoading}
        title={"Company"}
        options={companyOptions}
        searchable={true}
        onSearch={(val) => getCompanies({ search: val, filter: true })}
        onChange={(val) => {
          const opts = companyOptions.map((item) => {
            return {
              ...item,
              checked: item.value === val ? !item.checked : item.checked,
            };
          });
          setCompanyOptions(opts);
          runFilters(branchOptions, siteOptions, opts, personaOptions);
        }}
      />
      */}
      <FilterSelector
        title={"Persona"}
        options={personaOptions}
        onChange={(val) => {
          const opts = personaOptions.map((item) => {
            return {
              ...item,
              checked: item.value === val ? !item.checked : false,
            };
          });
          setPersonaOptions(opts);
          runFilters(branchOptions, siteOptions, companyOptions, opts);
        }}
      />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  reports: makeSelectAttendanceReports(),
  companies: makeSelectCompanies(),
  branches: makeSelectRegions(),
  allReports: makeSelectAllAttendancesReports(),
  aloading: makeSelectAllReportsLoading(),
  sites: makeSelectSites(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getCompanies: (val) => dispatch(getCompaniesRequest(val)),
    getReports: (val) => dispatch(getAttendanceReportsRequest(val)),
    getAllReports: (val) => dispatch(getAllAttendaceReportsRequest(val)),
    getBranches: (val) => dispatch(getRegionRequest(val)),
    getSites: (val) => dispatch(getSitesRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
