import React, { useState, useEffect } from "react";
import classes from "./PersonaSelection.module.scss";
import Footer from "components/UI/footer";
import Button from "components/UI/button";
import IconComponent from "components/UI/IconComponent";
import RedTick from "./red-tick.svg";
import { isNil } from "lodash";
import queryString from "query-string";
import { FormattedMessage } from "react-intl";

const PersonaSelection = ({ history }) => {
  const [selectedPersona, setSelectedPersona] = useState(false);
  const [selectedSite, setSite] = useState();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [redirectRoute, setRedirectRoute] = useState();
  const [siteManager, setSiteManager] = useState(null);

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    const { site, redirect, sitemanager } = parsed;
    setSite(site);
    setRedirectRoute(redirect);
    setSiteManager(sitemanager);

    setTimeout(() => {
      setPageLoaded(true);
    }, 100);
  }, []);

  const PersonaList = [
    {
      id: 1,
      title: "Contractors",
      titleFormatID: "persona.title.1",
      desc:
        "I am contracted to, or employed by an external Company to work on this site.",
      descFormatID: "persona.description.1",
    },
    {
      id: 3,
      title: "Staff",
      titleFormatID: "persona.title.3",
      desc: "I am a KeepSafe employee.",
      descFormatID: "persona.description.3",
      icon: "SSO",
    },
    {
      id: 2,
      title: "Visitor",
      titleFormatID: "persona.title.2",
      desc: "I am a visitor to this site.",
      descFormatID: "persona.description.2",
    },
  ];

  return (
    <>
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <h1 className={classes.title}>
            {" "}
            <FormattedMessage
              defaultMessage={"Select appropriate persona"}
              id={"persona.main.title"}
            />
          </h1>
          <div
            className={
              pageLoaded
                ? classes.personaItemWrapperLoaded
                : classes.personaItemWrapper
            }
          >
            {PersonaList.map((item, index) => (
              <div
                key={index}
                className={
                  item.id === selectedPersona || selectedPersona === false
                    ? classes.personaItem
                    : classes.personaItemDisabled
                }
              >
                <div>
                  <div
                    className={classes.checkbox}
                    onClick={() => {
                      setSelectedPersona(item.id);
                    }}
                  >
                    {item.id === selectedPersona && (
                      <img
                        alt={"tick"}
                        src={RedTick}
                        className={classes.tick}
                      />
                    )}
                  </div>
                </div>
                <div className={classes.textWrapper}>
                  <span className={classes.personaTitle}>
                    <FormattedMessage
                      defaultMessage={item.title}
                      id={item.titleFormatID}
                    />
                    {!isNil(item.icon) && (
                      <IconComponent
                        className={classes.icon}
                        icon={item.icon}
                      />
                    )}
                  </span>
                  <span className={classes.personaDesc}>
                    <FormattedMessage
                      defaultMessage={item.desc}
                      id={item.descFormatID}
                    />
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer transparent isHidden={selectedPersona === false}>
        <Button
          formatID={"continue.button"}
          id="apply-button"
          text="Continue"
          type="submit"
          disabled={isNil(selectedPersona)}
          onClick={() => {
            const params = new URLSearchParams();
            if (!isNil(selectedPersona)) {
              params.append("userType", selectedPersona);
            }
            if (!isNil(selectedSite)) {
              params.append("site", selectedSite);
            }
            if (!isNil(redirectRoute)) {
              params.append("redirect", redirectRoute);
            }
            if (!isNil(siteManager)) {
              params.append("sitemanager", siteManager);
            }

            if (selectedPersona === 3) {
              history.push(`/supervisor-login?${params.toString()}`);
            } else {
              history.push(`/login?${params.toString()}`);
            }
          }}
        />
      </Footer>
    </>
  );
};

export default PersonaSelection;
