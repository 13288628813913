// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_SITE_STATS_REQUEST_SUCCESS,
  GET_SITE_STATS_REQUEST_ERROR,
  GET_SITE_STATS_REQUEST,
} from "../../constants";
import axios from "../../../../services/api";
import { error as err } from "../../../../main";

function* getSiteStatsRequest(data) {
  try {
    const response = yield call(axios.get, "/site-stats/");
    if (response.data.error) {
      yield put({
        type: GET_SITE_STATS_REQUEST_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: GET_SITE_STATS_REQUEST_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error?.response?.status === 403) {
      yield call(err, error?.response?.data?.detail);
    }

    yield put({
      type: GET_SITE_STATS_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* getSiteStatsRequestSaga() {
  yield takeLatest(GET_SITE_STATS_REQUEST, getSiteStatsRequest);
}
