import React from "react";
import classes from "./sectionStats.module.scss";
import { intervalToDuration } from "date-fns";
import { isNil, isNaN } from "lodash";
import Icon from "components/UI/IconComponent";

function minutesToHMS(d) {
  const duration = Number(d);
  if (!isNil(duration) && !isNaN(duration)) {
    const seconds = duration / 0.016667;
    const calculatedTime = intervalToDuration({
      start: 0,
      end: seconds * 1000,
    });

    const hour =
      calculatedTime?.hours < 10
        ? `0${calculatedTime.hours}`
        : calculatedTime?.hours;
    const minute =
      calculatedTime.minutes < 10
        ? `0${calculatedTime.minutes}`
        : calculatedTime?.minutes;
    const second =
      calculatedTime.seconds < 10
        ? `0${calculatedTime?.seconds}`
        : calculatedTime?.seconds;

    return `${hour}:${minute}:${second}`;
  }
}

const SectionStats = ({ dashboardData }) => {
  return (
    <>
      <div className={classes.dashboardSection1}>
        <div className={classes.progressColumn}>
          {dashboardData?.map((item) => (
            <div className={classes.progressItem}>
              <div className={classes.progressWrapper}>
                <div className={classes.progressItemSection1}>
                  <div
                    className={classes.iconWrapper}
                    style={{
                      background: item.iconBackground,
                    }}
                  >
                    <Icon
                      icon={item.iconType}
                      color={item.iconColor}
                      className={classes.icon}
                    />
                    {/* <img
                      alt={"Progress Icon"}
                      style={{ borderColor: item.color }}
                      className={classes.icon}
                      src={item.icon}
                    /> */}
                  </div>
                </div>
                <div className={classes.progressItemSection2}>
                  <p>{item.title}</p>
                  <span>
                    Avg time: <b>{minutesToHMS(item.average_time)}</b>
                  </span>
                </div>

                <div className={classes.progressItemSection3}>
                  <span className={classes.passRateText}>Pass Rate:</span>
                  <span className={classes.percentageText}>
                    {item.percentage.toFixed(0)}%
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SectionStats;
