import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectInduction = (state) => state.induction || initialState;

const makeSelectInduction = () =>
  createSelector(selectInduction, (state) => state.induction);

const makeSelectSelectedInduction = () =>
  createSelector(selectInduction, (state) => state.selectedInduction);

const makeSelectLoading = () =>
  createSelector(selectInduction, (state) => state.loading);

const makeSelectError = () =>
  createSelector(selectInduction, (state) => state.error);

const makeSelectSelectedSites = () =>
  createSelector(selectInduction, (state) => state.selectedSites);

const makeSelectCurrentRule = () =>
  createSelector(selectInduction, (state) => state.currentRule);

const makeSelectIsFooterVisible = () =>
  createSelector(selectInduction, (state) => state.isFooterVisible);

const makeSelectSection = () =>
  createSelector(selectInduction, (state) => state.section);

const makeSelectSectionStep = () =>
  createSelector(selectInduction, (state) => state.sectionStep);

const makeSelectChapterCompletion = () =>
  createSelector(selectInduction, (state) => state.chapterCompletion);

export {
  selectInduction,
  makeSelectInduction,
  makeSelectSelectedInduction,
  makeSelectLoading,
  makeSelectError,
  makeSelectSelectedSites,
  makeSelectIsFooterVisible,
  makeSelectCurrentRule,
  makeSelectSection,
  makeSelectSectionStep,
  makeSelectChapterCompletion,
};
