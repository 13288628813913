import React, { useEffect, useState } from "react";
import Card from "components/Supervisor/card";
import Input from "components/Supervisor/input";
import classes from "./user.module.scss";
import { isEmpty, isNil } from "lodash";

import {
  getRolesRequest,
  getPermissionsRequest,
  deleteUser,
  updateUser,
  createUser,
  getUserByIdRequest,
} from "store/supervisor/actions";
import {
  makeSelectRoles,
  makeSelectLoading,
  makeSelectPermissions,
  makeSelectUsers,
  makeSelectCurrentUser,
} from "store/supervisor/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Formik, Form, Field } from "formik";
import ReactLoading from "react-loading";
import queryString from "query-string";

const BackIcon = window.location.origin + "/backIcon/backIconn.svg";

const UserForm = ({
  updateUserRequest,
  deleteUserRequest,

  history,
  roles,
  getRoles,
  users,
  createUserRequest,
  loading,
  currentUser,
  getUserById,
}) => {
  const id = queryString.parse(window.location.search).id;

  const [roleOptions, setRoleOptions] = useState([]);

  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    if (!isNil(id)) {
      getUserById({ id: id });
    }
  }, [id]);

  useEffect(() => {
    if (!isEmpty(roles)) {
      const options = roles.results.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setRoleOptions(options);
    }
  }, [roles, users]);

  useEffect(() => {
    if (isNil(id)) {
      setSelectedUser(true);
    }
  }, [roles]);

  useEffect(() => {
    if (!isNil(currentUser)) {
      setSelectedUser(currentUser);
    }
  }, [roles, currentUser]);

  const initialValues = {
    firstName: !isNil(id)
      ? !isNil(selectedUser?.first_name)
        ? selectedUser?.first_name
        : ""
      : "",
    lastName:
      !isNil(id) && !isNil(selectedUser?.last_name)
        ? selectedUser?.last_name
        : "",
    email: !isNil(id) && !isNil(selectedUser?.email) ? selectedUser?.email : "",
    tel:
      !isNil(id) && selectedUser && !isEmpty(selectedUser.userextra_set)
        ? selectedUser.userextra_set[0].phone
        : "",
    company:
      !isNil(id) && selectedUser && !isEmpty(selectedUser.userextra_set)
        ? selectedUser?.userextra_set[0].company
        : "",
    branch: "",
    group:
      !isNil(id) && selectedUser && !isEmpty(selectedUser.groups)
        ? selectedUser?.groups[0].id
        : "",
  };

  return (
    <>
      {loading ? (
        <div className={classes.loadingContainer}>
          <ReactLoading
            type={"spin"}
            color={"#20a8d8"}
            height={300}
            width={300}
          />
        </div>
      ) : (
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <img
            alt={"back icon"}
            onClick={() => history.push("/supervisor-settings-users")}
            src={BackIcon}
            style={{
              width: "50px",
              height: "50px",
              cursor: "pointer",
              margin: "10px 10px 10px 0px",
            }}
          />
          <div className={classes.form}>
            {selectedUser && (
              <Formik
                initialValues={isNil(id) ? initialValues : initialValues}
                onSubmit={(values) => {
                  if (!isNil(id)) {
                    updateUserRequest({
                      id: id,
                      values: values,
                    });
                  }
                  if (isNil(id)) {
                    createUserRequest({
                      values: values,
                    });
                  }
                }}
              >
                {({ values, setFieldValue, handleSubmit }) => (
                  <Form>
                    <Card title={"Personal details"}>
                      <Field
                        name={"firstName"}
                        value={values.firstName}
                        onChange={(e) => {
                          setFieldValue("firstName", e.target.value);
                        }}
                        label={"First name"}
                        placeholder={"First name"}
                        component={Input}
                      />
                      <Field
                        name={"lastname"}
                        value={values.lastName}
                        onChange={(e) => {
                          setFieldValue("lastName", e.target.value);
                        }}
                        label={"Last name"}
                        placeholder={"Last name"}
                        component={Input}
                      />
                    </Card>
                    <Card title={"Contact details"}>
                      <Field
                        value={values.email}
                        onChange={(e) => {
                          setFieldValue("email", e.target.value);
                        }}
                        label={"Email"}
                        placeholder={"email@adddress.com"}
                        component={Input}
                      />
                      <Field
                        value={values.tel}
                        onChange={(e) => {
                          setFieldValue("tel", e.target.value);
                        }}
                        label={"Tel. num"}
                        placeholder={"012 345 678 900"}
                        component={Input}
                      />
                    </Card>
                    <Card title={"Corporate details"}>
                      <Field
                        value={values.company}
                        onChange={(e) => {
                          setFieldValue("company", e.target.value);
                        }}
                        label={"Company"}
                        placeholder={"Company name"}
                        component={Input}
                      />
                      <Field
                        value={values.branch}
                        onChange={(e) => {
                          setFieldValue("branch", e.target.value);
                        }}
                        label={"Branch"}
                        placeholder={"Branch ID"}
                        component={Input}
                      />
                    </Card>
                    <Card title={"Role"} style={{ marginBottom: "200px" }}>
                      <Field
                        tabSelectsValue={values.group}
                        value={roleOptions.filter(
                          (option) => option.value === values.group
                        )}
                        type={"select"}
                        onChange={(val) => {
                          setFieldValue("group", val.value);
                        }}
                        label={"Role"}
                        placeholder={"Select Role"}
                        options={roleOptions}
                        component={Input}
                      />
                    </Card>
                    <div className={classes.buttonContainer}>
                      <div
                        style={{
                          width: "75%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{ justifyContent: "flex-start" }}
                          className={classes.flex}
                        >
                          {!isNil(id) && (
                            <button
                              className={classes.lightButton}
                              onClick={() => deleteUserRequest({ id: id })}
                            >
                              Delete
                            </button>
                          )}
                        </div>
                        <div
                          style={{ justifyContent: "flex-end" }}
                          className={classes.flex}
                        >
                          <button
                            onClick={() =>
                              history.push("/supervisor-settings/users")
                            }
                            className={classes.lightButton}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={loading}
                            className={classes.darkButton}
                            onClick={() => {
                              handleSubmit();
                            }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  roles: makeSelectRoles(),
  loading: makeSelectLoading(),
  permissions: makeSelectPermissions(),
  users: makeSelectUsers(),
  currentUser: makeSelectCurrentUser(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getRoles: (val) => dispatch(getRolesRequest(val)),
    getPermissions: (val) => dispatch(getPermissionsRequest(val)),
    deleteUserRequest: (val) => dispatch(deleteUser(val)),
    updateUserRequest: (val) => dispatch(updateUser(val)),
    createUserRequest: (val) => dispatch(createUser(val)),
    getUserById: (val) => dispatch(getUserByIdRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
