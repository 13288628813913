// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_ROLES_REQUEST_SUCCESS,
  GET_ROLES_REQUEST_ERROR,
  GET_ROLES_REQUEST,
} from "../../constants";
import axios from "../../../../services/api";

function* getRolesRequest(data) {
  try {
    const params = new URLSearchParams();
    params.append("id", data.id);
    const response = yield call(
      axios.get,
      data.search !== undefined && data.page !== undefined
        ? `/roles/?search=${data.search}?page=${data.page}`
        : data.search !== undefined
        ? `/roles/?search=${data.search}`
        : data.page !== undefined
        ? `/roles/?page=${data.page}`
        : data.id !== undefined
        ? `/roles/?id=${data.id}`
        : "/roles/"
    );
    if (response.data.error) {
      yield put({
        type: GET_ROLES_REQUEST_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: GET_ROLES_REQUEST_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_ROLES_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* getRolesRequestSaga() {
  yield takeLatest(GET_ROLES_REQUEST, getRolesRequest);
}
