import React from "react";
import classes from "./importance.module.scss";
import { ReactComponent as CheckIcon } from "./check.svg";
import { Tooltip } from "react-tooltip";

const ImportanceSelect = ({ value, onChange }) => {
  return (
    <div className={classes.importanceWrapper}>
      <div
        onClick={() => {
          onChange(0);
        }}
        className={classes.yellowDot}
        data-tooltip-id="yellowDot"
      >
        {value === 0 && <CheckIcon />}
      </div>
      <div
        onClick={() => {
          onChange(1);
        }}
        className={classes.redDot}
        data-tooltip-id="redDot"
      >
        {value === 1 && <CheckIcon />}
      </div>
      <Tooltip
        className="react-tooltip-keepsafe"
        id="yellowDot"
        place="top"
        type="dark"
        effect="solid"
      >
        <span>Medium</span>
      </Tooltip>
      <Tooltip
        className="react-tooltip-keepsafe"
        id="redDot"
        place="top"
        type="dark"
        effect="solid"
      >
        <span>Critical</span>
      </Tooltip>
    </div>
  );
};

export default ImportanceSelect;
