import React, { useEffect, useState } from "react";
import Form from "../Form";
import Menu from "components/Supervisor/menu";
import { pages } from "../utils";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  updateSite,
  getSiteById,
  deleteSite,
  getUsersRequest,
  getRegionRequest,
} from "store/supervisor/actions";
import { getInductionRequest } from "store/induction/actions";
import {
  makeSelectLoading,
  makeSelectCurrentSite,
  makeSelectUsers,
  makeSelectRegions,
} from "store/supervisor/selector";
import { makeSelectInduction } from "store/induction/selector";
import { isNil } from "lodash";
import ReactLoading from "react-loading";
import classes from "./detail.module.scss";
import queryString from "query-string";

const Detail = ({
  history,
  updateSiteRequest,
  getSiteByIdRequest,
  currentSite,
  loading,
  deleteSiteRequest,
  getSiteManagers,
  siteManagers,
  getRegions,
  regions,
  getInductions,
  inductions,
}) => {
  const id = queryString.parse(window.location.search).id;

  const [editData, setEditData] = useState(false);

  useEffect(() => {
    getSiteByIdRequest({ id });
    getSiteManagers();
    getRegions();
    getInductions();
  }, []);

  useEffect(() => {
    if (!isNil(currentSite) && !isNil(id)) {
      setEditData(currentSite.find((item) => item?.id?.toString() === id));
    }
  }, [currentSite]);

  return (
    <>
      <Menu pages={pages} id={id} />
      {loading ? (
        <div className={classes.loadingContainer}>
          <ReactLoading
            type={"spin"}
            color={"#20a8d8"}
            height={300}
            width={300}
          />
        </div>
      ) : (
        <Form
          siteManagers={siteManagers}
          deleteSiteRequest={deleteSiteRequest}
          editData={editData}
          id={id}
          updateAction={updateSiteRequest}
          history={history}
          regions={regions}
          inductions={inductions}
        />
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  currentSite: makeSelectCurrentSite(),
  siteManagers: makeSelectUsers(),
  regions: makeSelectRegions(),
  inductions: makeSelectInduction(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    updateSiteRequest: (val) => dispatch(updateSite(val)),
    getSiteByIdRequest: (val) => dispatch(getSiteById(val)),
    deleteSiteRequest: (val) => dispatch(deleteSite(val)),
    getSiteManagers: (val) => dispatch(getUsersRequest(val)),
    getRegions: (val) => dispatch(getRegionRequest(val)),
    getInductions: (val) => dispatch(getInductionRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
