// @flow
import { all, fork } from "redux-saga/effects";
import setProgressRequestSaga from "./sagas/setProgressRequest";
import getProgressRequestSaga from "./sagas/getProgressRequest";

function* progressSaga() {
  yield all([fork(setProgressRequestSaga), fork(getProgressRequestSaga)]);
}

export default progressSaga;
