import React, { useEffect, useState } from "react";

//redux utils//
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

//actions
import {
  getContractorStats,
  updateContractorRequest,
  getCompaniesRequest,
  getContractorCardsRequest,
  createContractorCard,
  updateContractorCard,
  getSitesRequest,
} from "store/supervisor/actions";

//selectors
import {
  makeSelectLoading,
  makeSelectContractorStats,
  makeSelectCompanies,
  makeSelectContractorCards,
} from "store/supervisor/selector";

//components
import Card from "components/Supervisor/card";
import Switch from "components/Supervisor/switch/custom";
import TextArea from "components/Supervisor/textArea";
import Input from "components/Supervisor/input";
import ReactLoading from "react-loading";
import Table from "components/Supervisor/table"; //halilk: for the cards table
import CardModal from "components/Supervisor/modal/card";
import PhoneInput from "components/UI/phoneInput";
import IconComponent from "components/UI/IconComponent";

//styles
import classes from "./detail.module.scss";

//assets
import UserIcon from "./user.svg";
import PreviewIcon from "./preview.svg";
import TickIcon from "./tick.svg";
import CrossIcon from "./cross.svg";
import cardType1 from "./card_type_1.svg";
import cardType2 from "./card_type_2.svg";

//utils
import { format, parseISO } from "date-fns";
import { isNil, isEmpty } from "lodash";
import queryString from "query-string";
import { completeStatusEnums } from "utils/enums";

const ContractorDetails = ({
  getContractorStats,
  getContractorCards,
  contractorDetail,
  loading,
  updateContractor,
  history,
  getCompanies,
  companies,
  cards,
  createCard,
  updateCard,
  getSites,
}) => {
  const id = queryString.parse(window.location.search).id;

  const returnUrl = queryString.parse(window.location.search).returnUrl;

  const formatDate = (val) =>
    !isNil(val) ? format(parseISO(val), `MM/dd/yyyy`) : "-";

  const daysLeft = (val) => {
    const date = new Date();
    var difference = Math.round(new Date(val).getTime() - date.getTime());
    return Math.floor(difference / 1000 / 60 / 60 / 24);
  };

  useEffect(() => {
    getContractorStats({ id: id });
    getCompanies();
    getContractorCards({ id: id });
    getSites();
  }, []);

  const [contractor, setContractor] = useState(null);
  const [notes, setNotes] = useState();
  const [isActive, setIsActive] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState();
  const [jdeNumber, setJDENumber] = useState();
  const [companyOptions, setCompanyOptions] = useState();
  const [selectedCompany, setSelectedCompany] = useState();
  const [cardList, setCardList] = useState([]);
  const [greenCard, setGreenCard] = useState([]);
  const [redCard, setRedCard] = useState([]);
  const [cardModal, setCardModal] = useState({
    open: false,
    type: null,
    action: null,
    cancel: null,
    data: null,
  });

  const cardTableData = (item) => {
    const data = item.map((a) => {
      return {
        id: a?.id,
        values: [
          {
            value: (
              <img
                alt={"card"}
                className={classes.cardIcon}
                src={a?.type === 1 ? cardType1 : cardType2}
              />
            ),
            id: a?.id,
            site_id: a?.site?.id,
            type: a?.type,
            raw: item,
          },
          {
            value: formatDate(a?.created),
          },
          {
            value: a?.site?.name,
          },
          {
            value: a?.issuer?.first_name + " " + a?.issuer?.last_name,
          },
          {
            value: a?.summary,
          },
          {
            value: a?.action,
          },
        ],
      };
    });

    const labels = [
      { label: "" },
      { label: "Date Issued" },
      { label: "Site ID" },
      { label: "By Whom" },
      { label: "Summary" },
      { label: "Action" },
    ];

    return {
      columns: data,
      labels: labels,
    };
  };

  useEffect(() => {
    if (!isNil(contractorDetail)) {
      setContractor(contractorDetail);
      setNotes(contractorDetail?.notes === null ? "" : contractorDetail?.notes);
      setIsActive(contractorDetail?.is_active);
      setJDENumber(contractorDetail.jdenumber);
      setPhoneNumber(contractorDetail.phone);
    }
  }, [contractorDetail]);

  useEffect(() => {
    if (!isNil(companies) && !isNil(contractorDetail)) {
      setCompanyOptions(
        companies?.results?.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        })
      );
      setSelectedCompany({
        name: contractorDetail?.company,
        id: companies?.results?.find(
          (item) => item.name === contractorDetail.company
        )?.id,
      });
    }
  }, [contractorDetail, companies]);

  useEffect(() => {
    if (!isNil(cards)) {
      setCardList(cardTableData(cards.results));

      var greenCardCount = 0;
      var redCardCount = 0;

      //count the numbers just like it's 90s :)
      //If there is a pagination, this will fail for sure but it's improbable

      for (var index = 0; index < cards.results.length; index++) {
        if (cards.results[index].type == 1) greenCardCount += 1;
        else redCardCount += 1;
      }

      setGreenCard(greenCardCount);
      setRedCard(redCardCount);
    }
  }, [cards]);

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <ReactLoading
          type={"spin"}
          color={"#20a8d8"}
          height={300}
          width={300}
        />
      </div>
    );
  }

  /*
  const HSEChecks = [
    { title: "Safe Card", visible: true, complete: true, link: contractor?.profile },
    { title: "Company task analysis", visible: true, complete: true },
    { title: "Task training", visible: true, complete: true },
    { title: "Task Licence", visible: true, complete: contractor?.certificate != "" ? true: false, link: contractor?.certificate },
  ];
  */

  const HSEChecks = contractorDetail?.qualifications?.map((item) => {
    return {
      title: item.name,
      visible: true,
      complete: true,
      link: item.document,
      date: item.date,
      induction: item.induction
    };
  })

  const handleAddCard = () => {
    //alert('test');
    setCardModal({
      open: true,
      data: null,
      cancel: () =>
        setCardModal({
          open: false,
          type: null,
          save: null,
          cancel: null,
        }),
      save: (val) => {
        createCard({
          user_id: id,
          cardType: val.cardType,
          action: val.action,
          site_id: val.site_id,
          summary: val.summary,
          callBack: () => {
            getContractorCards({ id: id });
            setCardModal({
              open: false,
              type: null,
              save: null,
              cancel: null,
            });
          },
        });
      },
    });

    //TODO: Card modal
  };

  //halilk: editing card on cards table
  const rowActions = [
    {
      name: "Edit",
      action: (val) => {
        //TODO: Define the action here
        setCardModal({
          open: true,
          data: val,
          cancel: () =>
            setCardModal({
              open: false,
              type: null,
              save: null,
              cancel: null,
            }),
          save: (val) => {
            updateCard({
              user_id: id,
              id: val.id,
              cardType: val.cardType,
              action: val.action,
              site_id: val.site_id,
              summary: val.summary,
              callBack: () => {
                getContractorCards({ id: id });
                setCardModal({
                  open: false,
                  type: null,
                  save: null,
                  cancel: null,
                });
              },
            });
          },
        });
      },
    },
  ];

  return (
    <>
      {contractor && (
        <div className={classes.mainContainer}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Card>
              <div style={{ display: "flex", width: "100%" }}>
                <img
                  alt={"user icon"}
                  style={{ width: "48px", height: "48px", margin: "15px" }}
                  src={UserIcon}
                />
                <div className={classes.nameWrapper}>
                  <span className={classes.text}>{contractor.first_name}</span>
                  <span className={classes.lastName}>
                    {contractor.last_name}
                  </span>
                </div>
              </div>
              {/*
              <div>
                <span className={classes.text}>Company:</span>{" "}
                <span className={classes.desc}>{contractor.company}</span>
              </div>
             */}
              <div>
                <span className={classes.text}>Email:</span>{" "}
                <span className={classes.desc}>{contractor.email}</span>
              </div>
              <div style={{ marginTop: "16px" }}>
                <span className={classes.text}>Joined:</span>{" "}
                <span className={classes.desc}>
                  {formatDate(contractor.date_joined)}
                </span>
              </div>
            </Card>
            <Card
              style={{ marginLeft: "10px", marginRight: "10px", display: "block","overflow-y":"auto","max-height":"250px"}}
              title={"EHS Checks"}
            >
              {HSEChecks.map((item) => (
                <div
                  className={classes.hseChecks}
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "45px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", width: "70%" }}>
                  <span><a href={item?.link !="" ? item?.link : "#"} target="_blank" style={{ color: "rgb(60, 75, 100"}}>{item.title}</a></span>
                  </div>
                  <div
                    style={{
                      width: "15%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {item.visible && (
                      item.link ? (
                      <a href={item.link} target="_blank">
                          <img
                            alt={"preview"}
                            style={{ width: "15px", height: "10px" }}
                            src={PreviewIcon}
                          />
                      </a>) :
                        (
                          <img
                            alt={"preview"}
                            style={{ width: "15px", height: "10px" }}
                            src={PreviewIcon}
                          />
                        )
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "15%",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt={"status"}
                      style={
                        item.complete
                          ? { width: "11px", height: "8px" }
                          : { width: "9px", height: "9px" }
                      }
                      src={item.complete ? TickIcon : CrossIcon}
                    />
                  </div>
                </div>
              ))}
            </Card>
            <Card
              title={"EHS Progress"}
              bodyStyle={{ justifyContent: "flex-start" }}
            >
              {contractor?.inductions
                .filter((item) => item.type === "main")
                .map((item) => (
                  <div
                    className={classes.hseChecks}
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "45px",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <span>{item.name}:</span>
                    </div>
                    <div style={{ width: "30%", display: "flex" }}>
                      <span>
                        {!isEmpty(item.status)
                          ? completeStatusEnums[item.status]
                          : "-"}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "20%",
                      }}
                    >
                      {item.status === "permitted" && (
                        <img
                          alt={"tick"}
                          style={{ width: "9px", height: "9px" }}
                          src={TickIcon}
                        />
                      )}
                    </div>
                  </div>
                ))}
              <div
                className={classes.hseChecks}
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <span>Site induction(s):</span>
                </div>
                <div style={{ width: "50%", display: "flex" }}>
                  <span>
                    {!isEmpty(
                      contractor?.inductions.filter(
                        (item) =>
                          item.type === "site_specific" &&
                          item.status === "permitted"
                      )
                    )
                      ? contractor?.inductions
                          .filter(
                            (item) =>
                              item.type === "site_specific" &&
                              item.status === "permitted"
                          )
                          .map((item) => item.name)
                          .toString()
                      : "-"}
                  </span>
                </div>
              </div>
            </Card>
          </div>
          <Card title={"Details"}>
            <PhoneInput
              placeholder={"login.phoneNumber.placeholder"}
              label={"login.phoneNumber"}
              defaultLabel={"Phone number"}
              defaultPlaceholder={"Phone number"}
              name="phoneNumber"
              value={phoneNumber}
              onChange={setPhoneNumber}
            />

            {/*
            <Input
              label={"JDE Number"}
              placeholder={"JDE Number"}
              order={"column"}
              style={{ flexDirection: "column",display: "none !important" }}
              onChange={(e) => {
                setJDENumber(e.target.value);
              }}
              value={jdeNumber}
              type={"number"}
            />
            */}
            
            {/*
            <Input
              label={"Company"}
              placeholder={"Company"}
              order={"column"}
              style={{ flexDirection: "column",display: "none" }}
              options={companyOptions}
              type={"select"}
              onChange={(val) =>
                setSelectedCompany({ name: val.label, id: val.value })
              }
              // tabsSelectedValue={""}
              value={companyOptions?.find(
                (item) => item.label === selectedCompany.name
              )}
              marginTop={"0px"}
            />
            */}
          </Card>
          {contractor.inductions.map((item) => (
            <Card title={item.name}>
              <div className={classes.genericWrapper}>
                {item?.rules?.map((rule) => (
                  <div className={classes.genericBox}>
                    <div
                      className={classes.iconWrapper}
                      style={{
                        background: rule.iconBackground,
                      }}
                    >
                      {!isNil(rule?.iconType) ? (
                        <IconComponent
                          size={"80%"}
                          icon={rule?.iconType}
                          color={rule?.iconColor}
                        />
                      ) : (
                        <img
                          className={classes.icon}
                          src={rule.icon}
                          alt={`${rule?.title} icon`}
                        />
                      )}
                    </div>
                    {rule.passed ? (
                      <img
                        className={classes.icon}
                        alt={"tick 2"}
                        style={{
                          width: "17px",
                          height: "13px",
                          display: "flex",
                        }}
                        src={TickIcon}
                      />
                    ) : null}
                    <span
                      className={classes.sectionName}
                      style={{ display: "flex", textAlign: "center" }}
                    >
                      {rule.title}
                    </span>
                    <span
                      className={classes.attempts}
                      style={{ display: "flex", textAlign: "center" }}
                    >
                      {rule?.trycount}{" "}
                      {rule?.trycount === 1 ? "Attempt" : "Attempts"}
                    </span>
                  </div>
                ))}
              </div>
              {!isEmpty(item.assesment_tries) && (
                <div className={classes.divider} />
              )}
              <div
                style={{
                  display: "flex",
                  marginTop: "37px",
                  marginBottom: "37px",
                }}
              >
                <div>
                  <span className={classes.date}>Completed:</span>{" "}
                  <span className={classes.date}>
                    {isNil(item?.complete_log?.created)
                      ? "-"
                      : formatDate(item?.complete_log?.created)}
                  </span>
                </div>
                <div style={{ marginLeft: "49px" }}>
                  <span className={classes.date}>Renewal:</span>{" "}
                  {isNil(item?.complete_log?.renewal) ? (
                    <>
                      {" "}
                      <span className={classes.date}>-</span>{" "}
                    </>
                  ) : (
                    <>
                      <span className={classes.date}>
                        {daysLeft(item?.complete_log?.renewal) > 0 ? (
                          <>
                            {daysLeft(item?.complete_log?.renewal)} days left{" "}
                          </>
                        ) : (
                          <>
                            {daysLeft(item?.complete_log?.renewal) * -1} days
                            passed due{" "}
                          </>
                        )}
                      </span>
                      <span className={classes.date}>
                        {" "}
                        •{" "}
                        {item?.complete_log?.renewal &&
                          formatDate(item?.complete_log?.renewal)}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </Card>
          ))}

          <Card title={"Notes"}>
            <TextArea
              onChange={(e) => {
                setNotes(e.target.value);
              }}
              value={notes}
            />
          </Card>

          <div className={classes.contractorCardWrapper}>
            <Card
              title={"Cards"}
              action={"Add card"}
              onClick={handleAddCard}
              style={{ padding: "0" }}
              bodyStyle={{ display: "block", padding: "0" }}
              //halilk: Pills that appear next to title
              pills={[
                { name: greenCard, style: { backgroundColor: "#6FCF97" } },
                { name: redCard, style: { backgroundColor: "#E26C82" } },
              ]}
            >
              <Table
                width={"100%"}
                isSearchable={false}
                loading={loading}
                data={cardList}
                rowActions={rowActions}
                clickable={false}
                style={{ border: "none", boxShadow: "none" }}
              />
            </Card>
          </div>

          <Card title={"Danger Zone"}>
            <span className={classes.dangerZoneText}>
              In case this contractor is showing repeated misconducts and
              putting in danger other colleagues or site coworkers, you can
              change the status to not permitted to enter the site.
            </span>
            <Switch
              option2={"Permitted to enter"}
              option1={"Not permitted to enter"}
              onChange={() => setIsActive(!isActive)}
              checked={isActive}
            />
          </Card>
          <div className={classes.buttonContainer}>
            <div
              style={{
                width: "75%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{ justifyContent: "flex-start" }}
                className={classes.flex}
              >
                {
                  <button className={classes.lightButton} onClick={() => {}}>
                    Delete
                  </button>
                }
              </div>
              <div
                style={{ justifyContent: "flex-end" }}
                className={classes.flex}
              >
                <button
                  onClick={() => {
                    history.push(`/${returnUrl}`);
                  }}
                  className={classes.lightButton}
                >
                  Cancel
                </button>

                <button
                  className={classes.darkButton}
                  onClick={() => {
                    updateContractor({
                      id: contractor.id,
                      notes: notes,
                      isActive: isActive,
                      phoneNumber: phoneNumber,
                      jdeNumber: jdeNumber,
                      companyId: selectedCompany.id ? selectedCompany.id : "",
                      callBack: () => getContractorStats({ id: id }),
                    });
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          <CardModal
            open={cardModal.open}
            cancel={cardModal.cancel}
            save={cardModal.save}
            type={cardModal.type}
            data={cardModal.data}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  contractorDetail: makeSelectContractorStats(),
  companies: makeSelectCompanies(),
  cards: makeSelectContractorCards(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getContractorStats: (val) => dispatch(getContractorStats(val)),
    updateContractor: (val) => dispatch(updateContractorRequest(val)),
    getCompanies: (val) => dispatch(getCompaniesRequest(val)),
    getContractorCards: (val) => dispatch(getContractorCardsRequest(val)),
    createCard: (val) => dispatch(createContractorCard(val)),
    updateCard: (val) => dispatch(updateContractorCard(val)),
    getSites: (val) => dispatch(getSitesRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractorDetails);
