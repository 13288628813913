import React, { useState, useEffect, useRef } from "react";
import { getEmptyImage } from "react-dnd-html5-backend";
import cn from "classnames";

import classes from "./cardSort.module.scss";
import TickIcon from "./tick.svg";

import { FormattedMessage } from "react-intl";
const { useDrop, useDrag } = require("react-dnd");

export const MovableItem = ({
  name,
  index,
  currentColumnName,
  moveCardHandler,
  setItems,
  description,
  description_id,
  feedback,
  onDrag,
  items,
}) => {
  const changeItemColumn = async (currentItem, columnName) => {
    const selectedItem = items.find((item) => item.name === currentItem.name);
    const newItem = {
      ...selectedItem,
      column: columnName,
    };
    const otherItems = items.filter((item) => currentItem.name !== item.name);
    setItems([...otherItems, newItem]);
  };

  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: "Our first type",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Time to actually perform the action
      moveCardHandler(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      index,
      name,
      currentColumnName,
      description,
      description_id,
      feedback,
    },
    type: "Our first type",
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      if (dropResult) {
        const { name } = dropResult;
        changeItemColumn(item, name);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);
  drag(drop(ref));

  return (
    <div
      ref={ref}
      className={cn(
        `${classes.Question}`,
        currentColumnName === "START" && "movable-item",
        onDrag && classes.hidden,
        currentColumnName !== "START" && classes.fadeOut
      )}
      style={{
        zIndex: index,
        position: "absolute",
        top: "6px",
        left: "6px",
        opacity: currentColumnName !== "START" && 0,
      }}
    >
      <div className={classes.descriptionWrapper}>
        <span className={classes.counter}>
          {currentColumnName === "START"
            ? items.length -
              items.filter((item) => item.column === "START").length +
              1
            : items.length -
              items.filter((item) => item.column === "START").length}
          /{items.length}
        </span>
        <img
          className={cn(
            currentColumnName !== "START"
              ? classes.successIcon
              : classes.hidden,
            classes.successIcon
          )}
          src={TickIcon}
          alt={"Tick Icon"}
        />

        <div className={classes.QuestionDescription}>
          {" "}
          <FormattedMessage defaultMessage={description} id={description_id} />
        </div>
        <div className={classes.DotsContainer}>
          <span className={classes.dots}>...</span>
          <span className={classes.dots}>...</span>
        </div>
      </div>
    </div>
  );
};

export const Column = ({ children, className, title, dataId }) => {
  const [{ isOver }, drop] = useDrop({
    accept: "Our first type",
    drop: () => ({ name: title }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    // Override monitor.canDrop() function
    canDrop: (item) => {
      return true;
    },
  });

  return (
    <div
      className={classes.cardWrapper}
      style={{ border: isOver && "4px solid transparent !important" }}
    >
      <div
        ref={drop}
        className={`${classes.Card} ${className}`}
        style={{
          background: title !== "START" && isOver && "#56CCF2",
          border: title !== "START" && isOver && "4px solid #DDDDDD",
        }}
        contentEditable={false}
        data-text={title}
        data-id={dataId}
      >
        {children}
      </div>
    </div>
  );
};

export const calcResults = (items, categories) => {
  return items.reduce(
    (acc, item) =>
      item.answer === categories.find((c) => c.name === item.column)?.id
        ? (acc += 1)
        : acc,
    0
  );
};
