// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  CREATE_SITE_SUCCESS,
  CREATE_SITE_ERROR,
  CREATE_SITE,
} from "../../constants";
import axios from "../../../../services/api";
import { push } from "connected-react-router";
import { error as err, success } from "../../../../main";
import { isEmpty, isNil } from "lodash";

function* createSiteRequest(data) {
  try {
    const values = data;
    const params = new URLSearchParams();
    params.append("name", values.values.name);
    params.append("address1", values.values.address1);
    params.append("address2", values.values.address2);
    params.append("induction_id", values.values.induction_id);
    if (!isNil(values.values.branch.value)) {
      params.append("region", values.values.branch.value);
    }
    // if (
    //   !isEmpty(values.values.siteManager) &&
    //   !isNil(values.values.siteManager)
    // ) {
    //   params.append(
    //     "staff",
    //     values.values.siteManager.map((item) => item.value).toString()
    //   );
    // }

    const response = yield call(axios.post, `/sites/`, params);
    if (response.data.error) {
      yield put({
        type: CREATE_SITE_ERROR,
        data: response.error,
      });
      yield call(err, `${response.data.error}`);
    } else if (response.data.success === 1) {
      yield put({
        type: CREATE_SITE_SUCCESS,
        data: response.data,
      });
      yield call(success, `New Site Created Succesfully`);
      yield put(push("/supervisor-sites"));
    }
  } catch (error) {
    yield call(err, error?.response?.status?.message?.detail);
    yield put({
      type: CREATE_SITE_ERROR,
      data: error,
    });
  }
}

export default function* createSiteRequestSaga() {
  yield takeLatest(CREATE_SITE, createSiteRequest);
}
