import React from "react";
import { memo, useEffect, useState } from "react";
import classes from "./cardSort.module.scss";
import { FormattedMessage } from "react-intl";

export const BoxDragPreview = memo(function BoxDragPreview({ item }) {
  const [tickTock, setTickTock] = useState(false);
  const { name, description, description_id } = item;
  useEffect(
    function subscribeToIntervalTick() {
      const interval = setInterval(() => setTickTock(!tickTock), 500);
      return () => clearInterval(interval);
    },
    [tickTock]
  );
  return (
    <div
      className={classes.Question}
      style={{ background: "#F6F7FA", transform: "rotate(-10deg)" }}
    >
      <div className={classes.QuestionDescription} style={{ color: "#28353C" }}>
        <FormattedMessage defaultMessage={description} id={description_id} />
        {/* {description} */}
      </div>
      <div className={classes.DotsContainerPreview}>
        <span className={classes.dotsPreview}>...</span>
        <span className={classes.dotsPreview}>...</span>
      </div>
    </div>
  );
});
