import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Timer from "utils/timer";
import Progress from "utils/progress";
import { isNil } from "lodash";
import LandScapeModal from "components/UI/landscapeModal";

const Authmiddleware = ({
  component: Component,
  user,
  path,
  layout: Layout,
  type,
  landscapeWarningVisible,
  userType,
}) => (
  <Route
    path={path}
    render={(props) => {
      if (isNil(user?.token)) {
        return (
          <Redirect
            to={{
              pathname: "/language-selection",
              state: { from: props.location },
            }}
          />
        );
      }

      return (
        <Layout menu={true}>
          <Progress type={type} />
          {type === "induction" && <Timer />}
          {landscapeWarningVisible ? (
            <>
              <LandScapeModal
                isVisible={landscapeWarningVisible}
                name={"Landscape Mode"}
                content={
                  "This chapter does not support landscape mode. Please rotate your device to enjoy the exercise."
                }
              />
            </>
          ) : (
            <Component {...props} />
          )}
        </Layout>
      );
    }}
  />
);

const mapStateToProps = (state) => {
  return {
    supervisor: state.auth.supervisor,
    user: state.auth.user,
    hasCards: state.auth.hasCards,
    userType: state.auth.userType,
  };
};

export default withRouter(connect(mapStateToProps)(Authmiddleware));
