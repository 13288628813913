import React from "react";
import classes from "./cardSort.module.scss";
import Success from "./success1.svg";
import Error from "./error1.svg";
import cn from "classnames";
import { FormattedMessage } from "react-intl";

const EndCard = ({ correctAnswers, inCorrectAnswers, type }) => (
  <div
    className={cn(
      classes.EndCard,
      type === "success" ? classes.SuccessBackground : classes.ErrorBackground,
      classes.fadeIn
    )}
  >
    <div className={classes.Results}>
      {type === "error" && <img src={Error} alt="error-icon" />}
      {type === "success" && <img src={Success} alt="success-icon" />}
      {type === "success" && (
        <span>
          <b>{correctAnswers}</b> &nbsp;
          <FormattedMessage
            id={"cardsort.correct"}
            defaultMessage={"correct"}
          />
        </span>
      )}
      {type === "error" && (
        <span>
          <b>{inCorrectAnswers}</b> &nbsp;{" "}
          <FormattedMessage
            id={"cardsort.incorrect"}
            defaultMessage={"incorrect"}
          />
        </span>
      )}
    </div>
  </div>
);
export default EndCard;
