import React from "react";
import Select from "react-select";

const SelectInput = ({
  onChange,
  tabSelectsValue,
  placeholder,
  options,
  value,
  error,
  components,
  width,
  isSearchable,
}) => {
  return (
    <Select
      styles={{
        control: () => ({
          width: width ? width : 176,
          // height: 34,
          display: "flex",
          background: "#FFFFFF",
          color: "#40507E",
          border: error ? "1px solid #E26C82" : `1px solid #40507E`,
          boxSizing: "border-box",
          borderRadius: "17px",
        }),
        container: () => ({
          width: width ? width : 176,
          // height: 34,
          position: "relative",
          boxSizing: "border-box",
          marginBottom: 32,
          marginRight: 18,
          marginTop: 32,
        }),
        placeholder: () => ({
          color: "#40507E",
          fontFamily: "Open Sans",
          marginLeft: 5,
        }),
      }}
      components={components}
      onChange={onChange}
      tabSelectsValue={tabSelectsValue}
      value={value}
      placeholder={placeholder}
      options={options}
      isSearchable={isSearchable}
    />
  );
};
export default SelectInput;
