// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_ERROR,
} from "../../constants";
import axios from "../../../../services/api";
import { push } from "connected-react-router";
import { success } from "../../../../main";

function deleteReq(data) {
  axios.delete("/roles/", { data: { id: data.id } });
}

function* deleteLanguage(data) {
  try {
    const response = yield call(deleteReq, data);
    yield put({
      type: DELETE_ROLE_SUCCESS,
      data: response,
    });

    yield put(push("/supervisor-settings-roles"));
    yield call(success, `Role Deleted Succesfully`);
    if (data.callback) {
      data.callback();
    }
    if (data.end != null) {
      data.end.callback();
    }
  } catch (error) {
    console.log({ error });
    yield put({
      type: DELETE_ROLE_ERROR,
      data: error,
    });
  }
}

export default function* deleteLanguageSaga() {
  yield takeLatest(DELETE_ROLE, deleteLanguage);
}
