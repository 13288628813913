import React, { useState, useEffect } from "react";
import classes from "./settings.module.scss";
import Menu from "components/Supervisor/menu";
import Switch from "../components/switch";
import { pages } from "../lib";

import {
  getHazardSettingsReq,
  postHazardSettingsReq,
} from "store/supervisor/actions";
import {
  makeSelectHazardSettings,
  makeSelectLoading,
} from "store/supervisor/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import IconComponent from "components/UI/IconComponent";

const HazardSettings = ({
  loading,
  hazardSettings,
  getHazardSettings,
  postHazardSettings,
}) => {
  const [isActive, setActive] = useState(true);

  useEffect(() => {
    getHazardSettings();
  }, []);

  useEffect(() => {
    if (hazardSettings) {
      const hazardValue = hazardSettings.results[0].value;
      setActive(hazardValue === "1" ? true : false);
    }
  }, [hazardSettings]);

  return (
    <div className={classes.hazardsContainer}>
      <div className={classes.headingWrapper}>
        <h1 className={classes.titleHead}>Settings</h1>
      </div>
      <Menu pages={pages} width="100%" />

      <div className={classes.settingsCard}>
        <div className={classes.settingsCardHeader}>
          <span className={classes.cardTitle}>
            Hazard acknowledgment required
          </span>
          <a data-tooltip-id={"help"}>
            <IconComponent
              icon="help_outline"
              size="25px"
              color="#6FCF97"
              className={classes.tooltipIcon}
            />
          </a>

          <Tooltip
            className="react-tooltip-keepsafe"
            id="help"
            place="left"
            type="dark"
            effect="solid"
          >
            <span>
              <b> ON:</b> Hazard will be presented before the checked-in card is
              presented, and a user will have to acknowledge it.
              <br />
              <br /> <b> OFF:</b> Hazard will be displayed as part of the
              check-in process. No acknowledgement will be required. 
            </span>
          </Tooltip>
        </div>
        <div className={classes.settingsCardBody}>
          <Switch
            checked={isActive}
            onChange={(val) =>
              postHazardSettings({
                hazards: JSON.stringify([
                  {
                    key: "hazard_acknowledgement",
                    value: !isActive === true ? "1" : "0",
                  },
                ]),
              })
            }
            activeTitle="ON"
            inActiveTitle="OFF"
            width={"80px"}
          />
          <span className={classes.cardContent}>
            Each staff member, visitor, or contractor is required to knowledge
            the hazard upon site check-in.
          </span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  hazardSettings: makeSelectHazardSettings(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    postHazardSettings: (val) => dispatch(postHazardSettingsReq(val)),
    getHazardSettings: (val) => dispatch(getHazardSettingsReq(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HazardSettings);
