import * as Yup from "yup";

const LoginSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  // company: Yup.object({
  //   value: Yup.string().required("Required"),
  // }),
  email: Yup.string().email("Invalid email").required("Required"),
  // phoneNumber: Yup.string().required("Required"),
  checkbox: Yup.boolean().oneOf([true], "Required"),
});

export default LoginSchema;
