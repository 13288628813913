import styled from "styled-components";
import { device } from "utils/helper/breakpoints";
const CardContainer = styled.div`
  border-radius: 20px;
  margin: 10px 10px 50px 10px;
  // display: flex;
  // flex-direction: column;
  width: 90%;
  min-height: ${(props) => (props.isLocked ? "192px" : "110px")};
  height: ${(props) => (props.isLocked ? "192px" : "110px")};
  position: relative;
  background: ${(props) =>
    props.color
      ? props.color
      : "linear-gradient(180deg, #3c4d56 0%, #28353c 100%)"};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 40px 6px 6px 6px;
  border: 3px solid transparent;
  background-clip: padding-box;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -3px;
    border-radius: 40px 6px 6px 6px;
    background: ${(props) =>
      props?.borderColor ? props?.borderColor : props?.color};
  }
  @media ${device.desktopL} {
    display: grid;
  }
  @media ${device.desktop} {
    display: grid;
  }
  @media ${device.laptopL} {
    display: grid;
  }
  @media ${device.laptop} {
    display: grid;
  }
  @media ${device.tablet} {
    display: flex;
    flex: 0 40%;
    flex-direction: column;
  }
  @media ${device.mobileL} {
    display: grid;
    width: 90%;
  }
`;
const TitleWrapper = styled.div`
  margin: 50px 20px 0px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;
const Title = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 138%;
  text-align: center;
  letter-spacing: 0.0075em;
  color: ${(props) => (props?.textColor ? props?.textColor : "#ffffff")};
`;
const MiddleArea = styled.div`
  display: flex;
  position: relative;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;
const ImageBox = styled.div`
  width: 66px;
  height: 66px;
  min-height: 66px;
  position: relative;
  display: flex;
  justify-content: center;
  align-item: center;
  background-color: #28353c;
  z-index: 3;
  border-radius: 66px;
  overflow: hidden;
  margin-top: -40px;
  margin-bottom: 20px;
  border: 5px solid #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
`;
const IconImage = styled.img`
  width: 80%;
`;
const Thumbnail = styled.img`
  display: flex;
  align-self: center;
  height: ${(props) => (props.complete ? "12px" : "66px")};
  width: ${(props) => (props.complete ? "16px" : "66px")};
`;
const CorrectBox = styled.div`
  display: flex;
  position: absolute;
  top: -10px;
  right: -10px;
  justify-content: flex-end;
`;
const ProgressBar = styled.div`
  display: flex;
  position: relative;
  width: 90%;
  height: 1px;
  border-radius: 3px;
  background-color: ${(props) => (props.color ? props.color : "#ffffff")};
  margin: 0px auto;
`;
const Progress = styled.div`
  display: flex;
  position: relative;
  height: 1px;
  border-radius: 3px;
  background-color: ${(props) => props.color};
  width: ${(props) => props.progress};
`;

const IconWrapper = styled.div`
  width: 66px;
  height: 66px;
  position: absolute;
  top: -33px;
  left: calc(50% - 33px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.color
      ? props.color
      : "linear-gradient(180deg, #3c4d56 0%, #28353c 100%)"};
  padding: 5px;
  border-radius: 66px;
  border: 6px solid white;
`;

const IconContainer = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin-left: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LockIcon = styled.div`
  width: 16px;
  height: 21px;
  position: absolute;
  top: 10px;
  right: 12px;
`;

const Button = styled.button`
  max-width: 140px;
  width: auto;
  height: 40px;
  min-height: 40px;
  border: 1px solid
    ${(props) => (props?.borderColor ? props?.borderColor : "#ffffff")};
  border-radius: 20px;
  padding: 12px 20px;
  background: ${(props) =>
    props?.background ? props?.background : "transparent"};
  font-family: Open Sans;
  color: ${(props) => (props?.textColor ? props?.textColor : "#ffffff")};
  margin: 20px auto;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  line-height: 138%;
  @media ${device.tablet} {
    font-size: 14px;
  }
  @media ${device.mobileL} {
    font-size: 14px;
  }
  @media ${device.mobileM} {
    font-size: 14px;
  }
  @media ${device.mobileS} {
    font-size: 14px;
  }
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProgressWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
`;
const ProgressDescriptionWrapper = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin: 0 auto;
`;
const ProgressText = styled.span`
  font-size: 12px;
  font-family: Open Sans;
  color: ${(props) => (props?.textColor ? props?.textColor : "#ffffff")};
`;

export {
  CardContainer,
  TitleWrapper,
  Title,
  MiddleArea,
  ImageBox,
  Thumbnail,
  CorrectBox,
  ProgressBar,
  Progress,
  Button,
  IconContainer,
  LockIcon,
  ProgressWrapper,
  ProgressDescriptionWrapper,
  ProgressText,
  IconWrapper,
  IconImage,
};
