import React, { useEffect, useState } from "react";
import classes from "./checkin.module.scss";
import { types } from "./types";
import queryString from "query-string";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
//action start
import {
  checkInRequest,
  getSiteHazardsRequest,
  resetSiteHazardsRequest,
} from "store/auth/actions";
//action end

//selectors start
import {
  makeSelectLoading,
  makeSelectError,
  makeSelectUser,
  makeSelectCheckInStatus,
  makeSelectSupervisor,
  makeSelectSiteHazards,
} from "store/auth/selector";
//selectors end
import { FormattedMessage } from "react-intl";
import FormattedHTMLMessage from "components/UI/FormattedHTMLMessage";

//components
import Loading from "components/Supervisor/loading";
import Layout from "layouts/contractor";
import RightIcon from "./right-icon.svg";
import Button from "components/UI/button";
import SiteHazards from "pages/SiteHazards";

//utils
import { delay } from "utils/helper";
import { isNil } from "lodash";
import { withRouter } from "react-router";
import { msToTime } from "utils/helper";

const CheckIn = ({
  loading,
  user,
  checkIn,
  status,
  history,
  error,
  getSiteHazards,
  hazards,
  resetSiteHazards,
}) => {
  const [site, setSite] = useState(null);
  const [time, setTime] = useState(null);
  const [timeDiff, setTimeDiff] = useState(null);
  const [data, setData] = useState(null);

  const [variable, setVariable] = useState(null);
  const [hasSiteHazards, setHasSiteHazards] = useState(false);
  const [isHazardCollapsed, setHazardCollapsed] = useState(false);
  const [isCollapsedIndicator, setIsCollapsedIndicator] = useState(false);

  // State for showing or hiding top popup menu for Site-specific induction and checkin
  const [isPopUpVisible, setIsPopUpVisible] = useState(true);

  // State for showing or hiding bottom popup menu for Site-specific induction
  const [showSitePopUp, setShowSitePopUp] = useState(false);

  useEffect(() => {
    const counter = () => {
      setTimeDiff(timeDiff + 1000);
      setTime(msToTime(timeDiff + 1000));
    };
    const id = setInterval(counter, 1000);
    return () => clearInterval(id);
  }, [time]);

  useEffect(() => {
    if (
      !loading &&
      !isNil(hazards) &&
      hazards?.results?.length > 0 &&
      !hasSiteHazards
    ) {
      const hazardCollapsed =
        hazards?.settings?.find((item) => item.key === "hazard_acknowledgement")
          ?.value === "1"
          ? true
          : false;

      setIsCollapsedIndicator(hazardCollapsed);
      setHazardCollapsed(!hazardCollapsed);
      setHasSiteHazards(true);
    }
  }, [hazards]);

  useEffect(() => {
    resetSiteHazards();
    const parsed = queryString.parse(window.location.search);
    checkIn({ site: parsed?.site, token: user?.token });
    setSite(parsed?.site);
    const access_token = window.localStorage.getItem("access_token");

    if (isNil(user?.token) && isNil(access_token)) {
      history.push(
        `/language-selection?site=${parsed?.site}&redirect=check-in`
      );
    }
  }, []);
  useEffect(() => {
    if (!isNil(status)) {
      var date = new Date(status?.check_in);
      var now = new Date();

      const diffTime = Math.abs(date - now);
      setTimeDiff(diffTime);
      setTime(msToTime(diffTime));

      const selectedVariable = types.find(
        (item) => item.id === status.messageId
      );
      setVariable(selectedVariable);
      setData(status);

      if (status.site.id) {
        getSiteHazards({ siteId: status?.site?.id });
      }
    }

    if (isNil(status)) {
      setHasSiteHazards(false);
    }
  }, [status]);

  useEffect(() => {
    if (!isNil(error)) {
      const selectedVariable = types.find(
        (item) => item.id === error.messageId
      );
      setVariable(selectedVariable);
      setData(error);
      setHasSiteHazards(false);
    }
  }, [error]);

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading />
      </div>
    );
  }
  const hasMenu =
    error?.message === "Renewal required soon" ||
    status?.message === "User checked in" ||
    status?.message === "User has already checked in";

  return (
    <>
      <Layout header={hasSiteHazards ? isHazardCollapsed : true} menu={hasMenu}>
        <>
          {!isNil(variable) && !isNil(data) && (
            <div
              className={classes.container}
              style={{ background: variable?.color }}
            >
              {variable?.timeCount && (
                <div className={classes.timeCountContainer}>
                  <span>{time}</span>
                </div>
              )}
              {variable?.popUp?.title && isPopUpVisible && (
                <div
                  className={classes.PopUpWrapper}
                  style={{ top: variable?.timeCount ? "130px" : "" }}
                >
                  <div className={classes.popUp}>
                    <img
                      alt={"icon"}
                      className={classes.popUpIcon}
                      style={{
                        width: variable?.popUp?.icon?.iconWidth,
                        height: variable?.popUp?.icon?.iconHeight,
                      }}
                      src={variable.popUp.icon.icon}
                      onClick={() => setIsPopUpVisible(false)}
                    />
                    <div className={classes.popUpTitle}>
                      {" "}
                      <FormattedMessage
                        defaultMessage={variable?.popUp.title}
                        id={variable.popUp.titleId}
                      />
                    </div>

                    <div className={classes.popUpDesc}>
                      <FormattedMessage
                        defaultMessage={variable?.popUp.description}
                        id={variable.popUp.descriptionId}
                      />
                    </div>
                  </div>
                </div>
              )}
              <span className={classes.name}>
                {user && user?.first_name} {user && user?.last_name}
              </span>

              <img
                alt={"icon"}
                className={classes.icon}
                style={{
                  width: variable?.icon?.iconWidth,
                  height: variable.icon.iconHeight,
                }}
                src={variable.icon.icon}
              />

              {variable?.title && (
                <span className={classes.title}>
                  <FormattedMessage
                    defaultMessage={variable?.title}
                    id={variable.titleId}
                  />
                </span>
              )}
              {!isNil(variable?.description) && variable.id === 11 && (
                <span className={classes.description}>
                  <span className={classes.description}>
                    <FormattedMessage
                      defaultMessage={variable.description}
                      id={variable.descriptionId}
                    />
                  </span>
                </span>
              )}

              {variable?.htmlDescription && (
                <span className={classes.htmlDescription}>
                  <FormattedHTMLMessage
                    defaultMessage={variable?.htmlDescription}
                    id={variable.htmlDescription}
                  />
                </span>
              )}

              {!isNil(variable?.description) && variable.id !== 11 && (
                <span className={classes.description}>
                  {variable?.title && (
                    <span className={classes.description}>
                      <FormattedMessage
                        defaultMessage={variable.description}
                        id={variable.descriptionId}
                      />
                    </span>
                  )}
                </span>
              )}

              <div className={classes.infoContainer}>
                {variable?.showLocationPin == 1 && (
                  <div className={classes.locationBox}>
                    <span className={classes.locationTitle}>
                      <img src={variable?.locationPin} />
                    </span>
                    <span className={classes.locationText}>
                      {data?.site?.address1}
                    </span>
                  </div>
                )}

                <div className={classes.boxWrapper}>
                  {variable?.companyIcon && (
                    <div className={classes.box}>
                      <img src={variable?.companyIcon} />
                      <span className={classes.boxText}>
                        {data?.site?.company?.name}
                      </span>
                    </div>
                  )}

                  {variable?.showLocation == 1 && (
                    <div className={classes.box}>
                      <img src={variable?.locationPin} />
                      <span className={classes.boxText}>
                        {data?.site?.address1}
                      </span>
                    </div>
                  )}

                  {variable?.showOnlineCompleted == 1 && (
                    <div className={classes.box}>
                      <img src={variable?.onlineCompletedIcon} />
                      <span className={classes.boxText}>
                        <FormattedMessage
                          defaultMessage={"Online induction completed"}
                          id={"checkin.inductioncompleted"}
                        />
                      </span>
                    </div>
                  )}

                  {variable?.showTimeRemaining == 1 && (
                    <div className={classes.box}>
                      <img
                        src={
                          data.remaining_days > 30
                            ? variable?.TimelapseIconSet.green
                            : variable?.TimelapseIconSet.yellow
                        }
                        alt={"Time Icon"}
                      />
                      <span className={classes.boxDays}>
                        {data.remaining_days}
                      </span>
                      <span className={classes.boxText}>
                        <FormattedMessage
                          defaultMessage={variable?.dateText}
                          id={variable.dateTextId}
                          values={{ day: data.remaining_days }}
                        />
                      </span>
                    </div>
                  )}
                </div>
              </div>
              {!variable?.bottomPopUp && variable?.footer && (
                <div
                  className={classes.footer}
                  style={{ background: variable.footer?.background }}
                  onClick={() => {
                    if (variable.key === "pending_induction" && isNil(user)) {
                      history.push(`/login?site=${site}`);
                    } else {
                      history.push(`${variable?.pushPath}?site=${site}`);
                    }
                  }}
                >
                  <div
                    className={classes.CustomFooterLabelWrapper}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <span
                      className={
                        variable.footer.background ? classes.footerLabel : null
                      }
                    >
                      <FormattedMessage
                        defaultMessage={variable?.footer?.text}
                        id={variable?.footer?.textID}
                      />
                    </span>

                    {variable?.footer?.subText && (
                      <span
                        className={`${classes.customFooterSubDesc} ${
                          variable?.footer?.background
                            ? classes.footerLabel
                            : null
                        }`}
                      >
                        <FormattedMessage
                          defaultMessage={variable.footer.subText}
                          id={variable.footer.subText}
                        />
                      </span>
                    )}
                  </div>
                  {variable?.footer?.hasRightIcon && (
                    <img
                      src={RightIcon}
                      className={classes.RightIcon}
                      alt="right-icon"
                    />
                  )}
                </div>
              )}
            </div>
          )}
          {variable?.bottomPopUp ? (
            showSitePopUp ? (
              <div
                className={classes.bottomPopUp}
                onClick={() => setShowSitePopUp(false)}
              >
                <span className={`${classes.popUpHeader} `}>
                  <FormattedMessage
                    defaultMessage={variable?.bottomPopUp.title}
                    id={variable?.bottomPopUp.titleId}
                  />
                </span>

                <span className={`${classes.popUpContent}`}>
                  <FormattedMessage
                    defaultMessage={variable?.bottomPopUp?.description}
                    id={variable?.bottomPopUp?.descriptionId}
                  />
                </span>
                <span className={`${classes.popUpContent}`}>
                  <FormattedMessage
                    defaultMessage={variable?.bottomPopUp?.subText}
                    id={variable?.bottomPopUp?.subText}
                  />
                </span>
                <Button formatID={variable?.bottomPopUp?.buttonLabel} />
              </div>
            ) : (
              <div className={classes.bottomPopUpDrawer}>
                <img
                  alt="icon"
                  src={variable?.bottomPopUp?.popUpIcon}
                  onClick={() => setShowSitePopUp(true)}
                />

                <span>
                  <FormattedMessage
                    defaultMessage={variable?.bottomPopUp?.title}
                    id={variable?.bottomPopUp?.titleId}
                  />
                </span>
              </div>
            )
          ) : null}
        </>
        {hasSiteHazards && !isNil(status) && (
          <SiteHazards
            isCollapsedIndicator={isCollapsedIndicator}
            setHasSiteHazards={setHasSiteHazards}
            hasSiteHazards={hasSiteHazards}
            isHazardCollapsed={isHazardCollapsed}
            setHazardCollapsed={setHazardCollapsed}
          />
        )}
      </Layout>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  error: makeSelectError(),
  user: makeSelectUser(),
  status: makeSelectCheckInStatus(),
  supervisor: makeSelectSupervisor(),
  hazards: makeSelectSiteHazards(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    checkIn: (val) => dispatch(checkInRequest(val)),
    getSiteHazards: (val) => dispatch(getSiteHazardsRequest(val)),
    resetSiteHazards: (val) => dispatch(resetSiteHazardsRequest(val)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CheckIn)
);
