import * as Yup from "yup";

const StaffVerify = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be min 8 characters")
    .max(32, "Password must be max 32 characters"),
  rePassword: Yup.string()
    .required("Confirm Pasword is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export default StaffVerify;
