import produce from "immer";
import {
  LAYOUT_REQUEST,
  LAYOUT_REQUEST_ERROR,
  LAYOUT_REQUEST_SUCCESS,
} from "./constants";

export const initialState = {
  error: null,
  loading: false,
  layout: null,
};

const LayoutReducer = (state = initialState, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case LAYOUT_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case LAYOUT_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.error = null;
        draftState.layout = action.data;
        break;
      case LAYOUT_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      default:
        break;
    }
  });
};

export default LayoutReducer;
