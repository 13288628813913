import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";

import classes from "./textWithImage.module.scss";
const TextWithBackgroundImage = (props) => {
  const { title, description, src, title_id, description_id } = props;

  useEffect(() => {
    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "hidden";
    }
  }, []);

  useEffect(() => {
    return () => {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "unset";
      }
    };
  }, []);

  return (
    <div
      className={classes.Container}
      style={{
        background: `url(${src}) no-repeat center`,
        backgroundSize: "cover",
      }}
    >
      {/* <div className={classes.TextContent}>
        <span className={classes.title}>
          <FormattedMessage defaultMessage={title} id={title_id} />
        </span>
        <span className={classes.description}>
          <FormattedMessage defaultMessage={description} id={description_id} />
        </span>
      </div> */}
    </div>
  );
};

export default TextWithBackgroundImage;
