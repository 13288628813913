// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  CREATE_USER,
} from "../../constants";
import axios from "../../../../services/api";
import { push } from "connected-react-router";
import { error as err, success } from "../../../../main";

const errorHandler = (data) => {
  var result = [];
  for (var i in data) {
    result.push([i, data[i]]);
  }
  result.forEach((item) => err(`${item[0]}: ${item[1]}`));
};

function* createUserRequest(data) {
  try {
    const values = data;
    const params = new URLSearchParams();
    params.append("group", values.values.group);
    params.append("company", values.values.company);
    params.append("first_name", values.values.firstName);
    params.append("last_name", values.values.lastName);
    params.append("phone", values.values.tel);
    params.append("email", values.values.email);

    const response = yield call(axios.post, `/staff/`, params);
    if (response.data.error) {
      yield put({
        type: CREATE_USER_ERROR,
        data: response.error,
      });
      yield call(errorHandler, response.data.details);
    } else {
      yield put({
        type: CREATE_USER_SUCCESS,
        data: response.data,
      });
      yield call(success, `User created succesfully`);
      yield put(push("/supervisor-settings-users"));
    }
  } catch (error) {
    yield put({
      type: CREATE_USER_ERROR,
      data: error,
    });
  }
}

export default function* createUserRequestSaga() {
  yield takeLatest(CREATE_USER, createUserRequest);
}
