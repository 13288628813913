// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_NOTIFICATION_TEMPLATES_SUCCESS,
  GET_NOTIFICATION_TEMPLATES_ERROR,
  GET_NOTIFICATION_TEMPLATES,
} from "../../constants";
import axios from "../../../../services/api";

function* getNotificationTemplate(data) {
  try {
    const response = yield call(
      axios.get,
      `/emergency-notification-templates/`
    );
    if (response.data.error) {
      yield put({
        type: GET_NOTIFICATION_TEMPLATES_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: GET_NOTIFICATION_TEMPLATES_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_NOTIFICATION_TEMPLATES_ERROR,
      data: error,
    });
  }
}

export default function* getNotificationTemplateSaga() {
  yield takeLatest(GET_NOTIFICATION_TEMPLATES, getNotificationTemplate);
}
