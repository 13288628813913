import React from "react";
import classes from "./loading.module.scss";
const LoadingIndicator = process.env.PUBLIC_URL + "/loading-indicator.svg";

const Loading = () => {
  return (
    <img
      alt={"loading indicator"}
      src={LoadingIndicator}
      className={classes.loading}
    />
  );
};

export default Loading;
