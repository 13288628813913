import { delay } from "utils/helper";

export const progressHandler = async (
  ruleSet,
  index,
  maxStep,
  setStep,
  setSection,
  setProgressReq,
  data,
  currentProgress,
  setCurrentProgress,
  induction,
  history,
  step,
  progressRatePerStep,
  next,
  setChapterCompletion,
  chapterCompletion
) => {
  if (ruleSet?.length === index + 1 && step === maxStep) {
    //if user is on the last rule's last section step
    if (chapterCompletion) {
      setChapterCompletion(false);
      history.push(next.url);
      setCurrentProgress(0);
    } else {
      setChapterCompletion(true);
      setProgressReq({
        section: data.id,
        induction_id: induction?.id,
        progress: 100,
      });
    }
  } else if (step === maxStep) {
    // if user is on the last section step
    if (chapterCompletion) {
      setStep(1);
      setSection(ruleSet[index + 1]);
      setCurrentProgress(0);
      setChapterCompletion(false);
      await delay(500);
      setProgressReq({
        section: ruleSet[index + 1].id,
        induction_id: induction?.id,
        progress: 0,
      });
    } else {
      setChapterCompletion(true);
      setProgressReq({
        section: data.id,
        induction_id: induction?.id,
        progress: 100,
      });
    }
  } else {
    setStep(step + 1);
    setCurrentProgress(currentProgress + progressRatePerStep);
    setProgressReq({
      section: data.id,
      induction_id: induction?.id,
      progress: Number((currentProgress + progressRatePerStep).toFixed(0)),
    });
  }
};
