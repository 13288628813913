// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_ALL_CONTRACTORS_BY_SITE_SUCCESS,
  GET_ALL_CONTRACTORS_BY_SITE_ERROR,
  GET_ALL_CONTRACTORS_BY_SITE,
} from "../../constants";
import axios from "../../../../services/api";
import { isNil } from "lodash";

function* getAllContractorsBySite(data) {
  try {
    const params = new URLSearchParams();
    params.append("site", data.id);
    params.append("report", 1);

    const response = yield call(axios.get, `/site-contractors/`, { params });
    if (response.data.error) {
      yield put({
        type: GET_ALL_CONTRACTORS_BY_SITE_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: GET_ALL_CONTRACTORS_BY_SITE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_ALL_CONTRACTORS_BY_SITE_ERROR,
      data: error,
    });
  }
}

export default function* getAllContractorsBySiteSaga() {
  yield takeLatest(GET_ALL_CONTRACTORS_BY_SITE, getAllContractorsBySite);
}
