// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
    GET_INDUCTION_DETAILS_SUCCESS,
    GET_INDUCTION_DETAILS_ERROR,
    GET_INDUCTION_DETAILS,
    SET_PERMISSION_ERROR,
} from "../../constants";
import axios from "../../../../services/api";
import { isNil } from "lodash";

function* getInductionDetailRequest(data) {
  const params = new URLSearchParams();
  if (!isNil(data?.id)) {
    params.append("id", data.id);
  }

  try {
    const response = yield call(axios.get, `/induction-details/`, { params });
    if (response.data.error) {
      yield put({
        type: GET_INDUCTION_DETAILS_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: GET_INDUCTION_DETAILS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    if (error?.response?.status === 403) {
      yield put({
        type: SET_PERMISSION_ERROR,
        data: true,
      });
    }

    yield put({
      type: GET_INDUCTION_DETAILS_ERROR,
      data: error,
    });
  }
}

export default function* getInductionDetailsRequestSaga() {
  yield takeLatest(GET_INDUCTION_DETAILS, getInductionDetailRequest);
}
