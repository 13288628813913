/*  SET SELECTED_STEP RESULT */
export const SET_SELECTED_STEP_REQUEST =
  "SELECTED_STEP/SET_SELECTED_STEP_REQUEST";
export const SET_SELECTED_STEP_REQUEST_SUCCESS =
  "SELECTED_STEP/SET_SELECTED_STEP_REQUEST_SUCCESS";
export const SET_SELECTED_STEP_REQUEST_ERROR =
  "SELECTED_STEP/SET_SELECTED_STEP_REQUEST_ERROR";

export const RESET_SELECTED_STEP_REQUEST =
  "SELECTED_STEP/RESET_SELECTED_STEP_REQUEST";
