// @flow
import { isNil } from "lodash";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  SET_CURRENT_SECTION_REQUEST,
  SET_CURRENT_SECTION_REQUEST_SUCCESS,
} from "../constants";

function* setCurrentSection(data) {
  const value = data;
  try {
    yield put({
      type: SET_CURRENT_SECTION_REQUEST_SUCCESS,
      data: value.section,
    });

    if (!isNil(value.callBack)) {
      yield call(() => value.callBack());
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* setCurrentSectionSaga() {
  yield takeLatest(SET_CURRENT_SECTION_REQUEST, setCurrentSection);
}
