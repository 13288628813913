import { isNil } from "lodash";
import React from "react";
import ReactPlayer from "react-player/vimeo";
import classes from "./videoPlayer.module.scss";
import { usePrevious } from "utils/hooks";

const VideoPlayer = ({ orientation, videoUrl, subtitle }) => {
  if (isNil(videoUrl)) {
    return null;
  }

  const id = videoUrl?.split("/")[3];

  return (
    <>
      <div className={classes.mobile}>
        <div style={{ width: "100vw", height: "56.5vw" }}>
          <iframe
            src={`https://player.vimeo.com/video/${id}?amp;title=0&amp;byline=0&amp;portrait=0&amp;autopause=0&amp;app_id=122963&amp;texttrack=${subtitle?.value}&amp;fullscreen=true`}
            width={"56.5vw"}
            height={"100vw"}
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen=""
            title="blabla"
            data-ready="true"
            style={{ width: "100%", height: "100%" }}
          ></iframe>
        </div>
      </div>
      <div className={classes.laptop}>
        <div style={{ width: "650px", height: "350px", marginTop: "20px" }}>
          <iframe
            src={`https://player.vimeo.com/video/${id}?amp;title=0&amp;byline=0&amp;portrait=0&amp;autopause=0&amp;app_id=122963&amp;texttrack=${subtitle?.value}`}
            width={"350px"}
            height={"650px"}
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen=""
            title="blabla"
            data-ready="true"
            style={{ width: "100%", height: "100%" }}
          ></iframe>
        </div>
      </div>
    </>
  );
};

export default VideoPlayer;
