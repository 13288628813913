// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_COVID_QUESTION_REQUEST,
  GET_COVID_QUESTION_REQUEST_SUCCESS,
  GET_COVID_QUESTION_REQUEST_ERROR,
} from "../constants";
import axios from "../../../services/api";

function* getCovidQuestion(data) {
  try {
    const response = yield call(axios.get, "/covid-questionnaire/");

    yield put({
      type: GET_COVID_QUESTION_REQUEST_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: GET_COVID_QUESTION_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* getCovidQuestionSaga() {
  yield takeLatest(GET_COVID_QUESTION_REQUEST, getCovidQuestion);
}
