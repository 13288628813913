// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_SITE_HAZARDS,
  GET_SITE_HAZARDS_SUCCESS,
  GET_SITE_HAZARDS_ERROR,
} from "../constants";
import axios from "../../../services/api";
import { isNil } from "lodash";

function* getSiteHazards(data) {
  try {
    const params = new URLSearchParams();
    if (!isNil(data?.payload?.siteId)) {
      params.append("site_id", data.payload.siteId);
    }
    const response = yield call(axios.get, "/site-hazards/", { params });
    if (response.data.error) {
      yield put({
        type: GET_SITE_HAZARDS_ERROR,
        data: response.data.error,
      });
    } else {
      yield put({
        type: GET_SITE_HAZARDS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_SITE_HAZARDS_ERROR,
      data: error,
    });
  }
}

export default function* getSiteHazardsSaga() {
  yield takeLatest(GET_SITE_HAZARDS, getSiteHazards);
}
