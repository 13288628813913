// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_CARD_REPORTS,
  GET_CARD_REPORTS_ERROR,
  GET_CARD_REPORTS_SUCCESS,
} from "../../constants";
import axios from "../../../../services/api";

function* getCardReports(data) {
  try {
    const params = new URLSearchParams();
    if (data.startdate) {
      params.append("startdate", data.startdate);
    }
    if (data.enddate) {
      params.append("enddate", data.enddate);
    }
    if (data.companyIds) {
      params.append("companyIds", data.companyIds);
    }
    if (data.contractorIds) {
      params.append("contractorIds", data.contractorIds);
    }
    if (data.issuerIds) {
      params.append("issuerIds", data.issuerIds);
    }
    if (data.statusType) {
      params.append("type", data.statusType);
    }
    if (data.sort) {
      params.append("sort", data.sort);
    }
    if (data.page) {
      params.append("page", data.page);
    }
    if (data.search) {
      params.append("search", data.search);
    }

    const response = yield call(axios.get, "/reports-card/", { params });
    if (response.data.error) {
      yield put({
        type: GET_CARD_REPORTS_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: GET_CARD_REPORTS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_CARD_REPORTS_ERROR,
      data: error,
    });
  }
}

export default function* getCardReportsSaga() {
  yield takeLatest(GET_CARD_REPORTS, getCardReports);
}
