// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_INDUCTION_REQUEST,
  GET_INDUCTION_REQUEST_SUCCESS,
  GET_INDUCTION_REQUEST_ERROR,
} from "../constants";
import axios from "../../../services/api";

function* getInductionRequest(data) {
  try {
    const response = yield call(axios.get, "/inductions/");
    if (response.data.error) {
      yield put({
        type: GET_INDUCTION_REQUEST_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: GET_INDUCTION_REQUEST_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_INDUCTION_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* getInductionRequestSaga() {
  yield takeLatest(GET_INDUCTION_REQUEST, getInductionRequest);
}
