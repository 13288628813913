/*  SET SELECTED_STEP RESULT */
export const GET_CONTRACTOR_LIST_REQUEST =
  "SUPERVISOR/GET_CONTRACTOR_LIST_REQUEST";
export const GET_CONTRACTOR_LIST_REQUEST_SUCCESS =
  "SUPERVISOR/GET_CONTRACTOR_LIST_REQUEST_SUCCESS";
export const GET_CONTRACTOR_LIST_REQUEST_ERROR =
  "SUPERVISOR/GET_CONTRACTOR_LIST_REQUEST_ERROR";

export const GET_GENERIC_STAFF_LIST_REQUEST =
  "SUPERVISOR/GET_GENERIC_STAFF_LIST_REQUEST";
export const GET_GENERIC_STAFF_LIST_REQUEST_SUCCESS =
  "SUPERVISOR/GET_GENERIC_STAFF_LIST_REQUEST_SUCCESS";
export const GET_GENERIC_STAFF_LIST_REQUEST_ERROR =
  "SUPERVISOR/GET_GENERIC_STAFF_LIST_REQUEST_ERROR";

export const GET_VISITOR_LIST_REQUEST = "SUPERVISOR/GET_VISITOR_LIST_REQUEST";
export const GET_VISITOR_LIST_REQUEST_SUCCESS =
  "SUPERVISOR/GET_VISITOR_LIST_REQUEST_SUCCESS";
export const GET_VISITOR_LIST_REQUEST_ERROR =
  "SUPERVISOR/GET_VISITOR_LIST_REQUEST_ERROR";

export const GET_CONTRACTOR_STATS_REQUEST =
  "SUPERVISOR/GET_CONTRACTOR_STATS_REQUEST";
export const GET_CONTRACTOR_STATS_REQUEST_SUCCESS =
  "SUPERVISOR/GET_CONTRACTOR_STATS_REQUEST_SUCCESS";
export const GET_CONTRACTOR_STATS_REQUEST_ERROR =
  "SUPERVISOR/GET_CONTRACTOR_STATS_REQUEST_ERROR";

export const GET_DASHBOARD_REQUEST = "SUPERVISOR/GET_DASHBOARD_REQUEST";
export const GET_DASHBOARD_REQUEST_SUCCESS =
  "SUPERVISOR/GET_DASHBOARD_REQUEST_SUCCESS";
export const GET_DASHBOARD_REQUEST_ERROR =
  "SUPERVISOR/GET_DASHBOARD_REQUEST_ERROR";

export const GET_PERMISSIONS_REQUEST = "SUPERVISOR/GET_PERMISSIONS_REQUEST";
export const GET_PERMISSIONS_REQUEST_SUCCESS =
  "SUPERVISOR/GET_PERMISSIONS_REQUEST_SUCCESS";
export const GET_PERMISSIONS_REQUEST_ERROR =
  "SUPERVISOR/GET_PERMISSIONS_REQUEST_ERROR";

export const CREATE_ROLE = "SUPERVISOR/CREATE_ROLE";
export const CREATE_ROLE_SUCCESS = "SUPERVISOR/CREATE_ROLE_SUCCESS";
export const CREATE_ROLE_ERROR = "SUPERVISOR/CREATE_ROLE_ERROR";

export const UPDATE_ROLE = "SUPERVISOR/UPDATE_ROLE";
export const UPDATE_ROLE_SUCCESS = "SUPERVISOR/UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_ERROR = "SUPERVISOR/UPDATE_ROLE_ERROR";

export const DELETE_ROLE = "SUPERVISOR/DELETE_ROLE";
export const DELETE_ROLE_SUCCESS = "SUPERVISOR/DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_ERROR = "SUPERVISOR/DELETE_ROLE_ERROR";

export const GET_ROLES_REQUEST = "SUPERVISOR/GET_ROLES_REQUEST";
export const GET_ROLES_REQUEST_SUCCESS = "SUPERVISOR/GET_ROLES_REQUEST_SUCCESS";
export const GET_ROLES_REQUEST_ERROR = "SUPERVISOR/GET_ROLES_REQUEST_ERROR";

export const GET_ROLE_BY_ID_REQUEST = "SUPERVISOR/GET_ROLE_BY_ID_REQUEST";
export const GET_ROLE_BY_ID_REQUEST_SUCCESS =
  "SUPERVISOR/GET_ROLE_BY_ID_REQUEST_SUCCESS";
export const GET_ROLE_BY_ID_REQUEST_ERROR =
  "SUPERVISOR/GET_ROLE_BY_ID_REQUEST_ERROR";

export const GET_USERS_REQUEST = "SUPERVISOR/GET_USERS_REQUEST";
export const GET_USERS_REQUEST_SUCCESS = "SUPERVISOR/GET_USERS_REQUEST_SUCCESS";
export const GET_USERS_REQUEST_ERROR = "SUPERVISOR/GET_USERS_REQUEST_ERROR";

export const GET_USER_BY_ID_REQUEST = "SUPERVISOR/GET_USER_BY_ID_REQUEST";
export const GET_USER_BY_ID_REQUEST_SUCCESS =
  "SUPERVISOR/GET_USER_BY_ID_REQUEST_SUCCESS";
export const GET_USER_BY_ID_REQUEST_ERROR =
  "SUPERVISOR/GET_USER_BY_ID_REQUEST_ERROR";

export const UPDATE_USER = "SUPERVISOR/UPDATE_USER";
export const UPDATE_USER_SUCCESS = "SUPERVISOR/UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "SUPERVISOR/UPDATE_USER_ERROR";

export const CREATE_USER = "SUPERVISOR/CREATE_USER";
export const CREATE_USER_SUCCESS = "SUPERVISOR/CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "SUPERVISOR/CREATE_USER_ERROR";

export const DELETE_USER = "SUPERVISOR/DELETE_USER";
export const DELETE_USER_SUCCESS = "SUPERVISOR/DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "SUPERVISOR/DELETE_USER_ERROR";

export const IMPORT_STAFF = "SUPERVISOR/IMPORT_STAFF";
export const IMPORT_STAFF_SUCCESS = "SUPERVISOR/IMPORT_STAFF_SUCCESS";
export const IMPORT_STAFF_ERROR = "SUPERVISOR/IMPORT_STAFF_ERROR";

export const GET_COMPANIES = "SUPERVISOR/GET_COMPANIES";
export const GET_COMPANIES_SUCCESS = "SUPERVISOR/GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_ERROR = "SUPERVISOR/GET_COMPANIES_ERROR";

export const CREATE_COMPANY = "SUPERVISOR/CREATE_COMPANY";
export const CREATE_COMPANY_SUCCESS = "SUPERVISOR/CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_ERROR = "SUPERVISOR/CREATE_COMPANY_ERROR";

export const MERGE_COMPANY = "SUPERVISOR/MERGE_COMPANY";
export const MERGE_COMPANY_SUCCESS = "SUPERVISOR/MERGE_COMPANY_SUCCESS";
export const MERGE_COMPANY_ERROR = "SUPERVISOR/MERGE_COMPANY_ERROR";

export const DELETE_COMPANY = "SUPERVISOR/DELETE_COMPANY";
export const DELETE_COMPANY_SUCCESS = "SUPERVISOR/DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_ERROR = "SUPERVISOR/DELETE_COMPANY_ERROR";

export const UPDATE_COMPANY = "SUPERVISOR/UPDATE_COMPANY";
export const UPDATE_COMPANY_SUCCESS = "SUPERVISOR/UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_ERROR = "SUPERVISOR/UPDATE_COMPANY_ERROR";

export const CREATE_SITE = "SUPERVISOR/CREATE_SITE";
export const CREATE_SITE_SUCCESS = "SUPERVISOR/CREATE_SITE_SUCCESS";
export const CREATE_SITE_ERROR = "SUPERVISOR/CREATE_SITE_ERROR";

export const UPDATE_SITE = "SUPERVISOR/UPDATE_SITE";
export const UPDATE_SITE_SUCCESS = "SUPERVISOR/UPDATE_SITE_SUCCESS";
export const UPDATE_SITE_ERROR = "SUPERVISOR/UPDATE_SITE_ERROR";

export const DELETE_SITE = "SUPERVISOR/DELETE_SITE";
export const DELETE_SITE_SUCCESS = "SUPERVISOR/DELETE_SITE_SUCCESS";
export const DELETE_SITE_ERROR = "SUPERVISOR/DELETE_SITE_ERROR";

export const GET_SITE_REQUEST = "SUPERVISOR/GET_SITES_REQUEST";
export const GET_SITE_REQUEST_SUCCESS = "SUPERVISOR/GET_SITES_REQUEST_SUCCESS";
export const GET_SITE_REQUEST_ERROR = "SUPERVISOR/GET_SITES_REQUEST_ERROR";

export const GET_SITE_STATS_REQUEST = "SUPERVISOR/GET_SITE_STATS_REQUEST";
export const GET_SITE_STATS_REQUEST_SUCCESS =
  "SUPERVISOR/GET_SITE_STATS_REQUEST_SUCCESS";
export const GET_SITE_STATS_REQUEST_ERROR =
  "SUPERVISOR/GET_SITE_STATS_REQUEST_ERROR";

export const GET_WEEKLY_ACTIVITY = "SUPERVISOR/GET_WEEKLY_ACTIVITY";
export const GET_WEEKLY_ACTIVITY_SUCCESS =
  "SUPERVISOR/GET_WEEKLY_ACTIVITY_SUCCESS";
export const GET_WEEKLY_ACTIVITY_ERROR = "SUPERVISOR/GET_WEEKLY_ACTIVITY_ERROR";

export const GET_SITE_BY_ID_REQUEST = "SUPERVISOR/GET_SITE_BY_ID_REQUEST";
export const GET_SITE_BY_ID_REQUEST_SUCCESS =
  "SUPERVISOR/GET_SITE_BY_ID_REQUEST_SUCCESS";
export const GET_SITE_BY_ID_REQUEST_ERROR =
  "SUPERVISOR/GET_SITE_BY_ID_REQUEST_ERROR";

export const GET_CONTRACTORS_BY_SITE = "SUPERVISOR/GET_CONTRACTOR_BY_SITE";
export const GET_CONTRACTORS_BY_SITE_SUCCESS =
  "SUPERVISOR/GET_CONTRACTOR_BY_SITE_SUCCESS";
export const GET_CONTRACTORS_BY_SITE_ERROR =
  "SUPERVISOR/GET_CONTRACTOR_BY_SITE_ERROR";

export const GET_ALL_CONTRACTORS_BY_SITE =
  "SUPERVISOR/GET_ALL_CONTRACTOR_BY_SITE";
export const GET_ALL_CONTRACTORS_BY_SITE_SUCCESS =
  "SUPERVISOR/GET_ALL_CONTRACTOR_BY_SITE_SUCCESS";
export const GET_ALL_CONTRACTORS_BY_SITE_ERROR =
  "SUPERVISOR/GET_ALL_CONTRACTOR_BY_SITE_ERROR";

export const UPDATE_CONTRACTOR_REQUEST = "SUPERVISOR/UPDATE_CONTRACTOR_REQUEST";
export const UPDATE_CONTRACTOR_REQUEST_SUCCESS =
  "SUPERVISOR/UPDATE_CONTRACTOR_REQUEST_SUCCESS";
export const UPDATE_CONTRACTOR_REQUEST_ERROR =
  "SUPERVISOR/UPDATE_CONTRACTOR_REQUEST_ERROR";

export const GET_REPORTS = "SUPERVISOR/GET_REPORTS";
export const GET_REPORTS_SUCCESS = "SUPERVISOR/GET_REPORTS_SUCCESS";
export const GET_REPORTS_ERROR = "SUPERVISOR/GET_REPORTS_ERROR";

export const GET_ALL_REPORTS = "SUPERVISOR/GET_ALL_REPORTS";
export const GET_ALL_REPORTS_SUCCESS = "SUPERVISOR/GET_ALL_REPORTS_SUCCESS";
export const GET_ALL_REPORTS_ERROR = "SUPERVISOR/GET_ALL_REPORTS_ERROR";

export const GET_CARD_REPORTS = "SUPERVISOR/GET_CARD_REPORTS";
export const GET_CARD_REPORTS_SUCCESS = "SUPERVISOR/GET_CARD_REPORTS_SUCCESS";
export const GET_CARD_REPORTS_ERROR = "SUPERVISOR/GET_CARD_REPORTS_ERROR";

export const GET_ALL_CARD_REPORTS = "SUPERVISOR/GET_ALL_CARD_REPORTS";
export const GET_ALL_CARD_REPORTS_SUCCESS =
  "SUPERVISOR/GET_ALL_CARD_REPORTS_SUCCESS";
export const GET_ALL_CARD_REPORTS_ERROR =
  "SUPERVISOR/GET_ALL_CARD_REPORTS_ERROR";

export const GET_ATTENDANCE_REPORTS = "SUPERVISOR/GET_ATTENDANCE_REPORTS";
export const GET_ATTENDANCE_REPORTS_SUCCESS =
  "SUPERVISOR/GET_ATTENDANCE_REPORTS_SUCCESS";
export const GET_ATTENDANCE_REPORTS_ERROR =
  "SUPERVISOR/GET_ATTENDANCE_REPORTS_ERROR";

export const GET_ALL_ATTENDANCE_REPORTS =
  "SUPERVISOR/GET_ALL_ATTENDANCE_REPORTS";
export const GET_ALL_ATTENDANCE_REPORTS_SUCCESS =
  "SUPERVISOR/GET_ALL_ATTENDANCE_REPORTS_SUCCESS";
export const GET_ALL_ATTENDANCE_REPORTS_ERROR =
  "SUPERVISOR/GET_ALL_ATTENDANCE_REPORTS_ERROR";

export const GET_ATTENDANCE_DETAIL = "SUPERVISOR/GET_ATTENDANCE_DETAIL";
export const GET_ATTENDANCE_DETAIL_SUCCESS =
  "SUPERVISOR/GET_ATTENDANCE_DETAIL_SUCCESS";
export const GET_ATTENDANCE_DETAIL_ERROR =
  "SUPERVISOR/GET_ATTENDANCE_DETAIL_ERROR";

export const SET = "SUPERVISOR/GET_DASHBOARD_REQUEST_ERROR";

export const SET_PERMISSION_ERROR = "SUPERVISOR/SET_PERMISSION_ERROR";

export const GET_CONTRACTOR_CARDS = "SUPERVISOR/GET_CONTRACTOR_CARDS";
export const GET_CONTRACTOR_CARDS_SUCCESS =
  "SUPERVISOR/GET_CONTRACTOR_CARDS_SUCCESS";
export const GET_CONTRACTOR_CARDS_ERROR =
  "SUPERVISOR/GET_CONTRACTOR_CARDS_ERROR";

export const CREATE_CONTRACTOR_CARD = "SUPERVISOR/CREATE_CONTRACTOR_CARD";
export const CREATE_CONTRACTOR_CARD_SUCCESS =
  "SUPERVISOR/CREATE_CONTRACTOR_CARD_SUCCESS";
export const CREATE_CONTRACTOR_CARD_ERROR =
  "SUPERVISOR/CREATE_CONTRACTOR_CARD_ERROR";

export const UPDATE_CONTRACTOR_CARD = "SUPERVISOR/UPDATE_CONTRACTOR_CARD";
export const UPDATE_CONTRACTOR_CARD_SUCCESS =
  "SUPERVISOR/UPDATE_CONTRACTOR_CARD_SUCCESS";
export const UPDATE_CONTRACTOR_CARD_ERROR =
  "SUPERVISOR/UPDATE_CONTRACTOR_CARD_ERROR";

export const GET_REGION_REQUEST = "SUPERVISOR/GET_REGION_REQUEST";
export const GET_REGION_REQUEST_SUCCESS =
  "SUPERVISOR/GET_REGION_REQUEST_SUCCESS";
export const GET_REGION_REQUEST_ERROR = "SUPERVISOR/GET_REGION_REQUEST_ERROR";

export const CREATE_REGION_REQUEST = "SUPERVISOR/CREATE_REGION_REQUEST";
export const CREATE_REGION_REQUEST_SUCCESS =
  "SUPERVISOR/CREATE_REGION_REQUEST_SUCCESS";
export const CREATE_REGION_REQUEST_ERROR =
  "SUPERVISOR/CREATE_REGION_REQUEST_ERROR";

export const EDIT_REGION_REQUEST = "SUPERVISOR/EDIT_REGION_REQUEST";
export const EDIT_REGION_REQUEST_SUCCESS =
  "SUPERVISOR/EDIT_REGION_REQUEST_SUCCESS";
export const EDIT_REGION_REQUEST_ERROR = "SUPERVISOR/EDIT_REGION_REQUEST_ERROR";

export const DELETE_REGION_REQUEST = "SUPERVISOR/DELETE_REGION_REQUEST";
export const DELETE_REGION_REQUEST_SUCCESS =
  "SUPERVISOR/DELETE_REGION_REQUEST_SUCCESS";
export const DELETE_REGION_REQUEST_ERROR =
  "SUPERVISOR/DELETE_REGION_REQUEST_ERROR";

export const GET_ALL_ATTENDANCE_DETAIL_REPORT =
  "SUPERVISOR/GET_ALL_ATTENDANCE_DETAIL_REPORT";
export const GET_ALL_ATTENDANCE_DETAIL_REPORT_SUCCESS =
  "SUPERVISOR/GET_ALL_ATTENDANCE_DETAIL_REPORT_SUCCESS";
export const GET_ALL_ATTENDANCE_DETAIL_REPORT_ERROR =
  "SUPERVISOR/GET_ALL_ATTENDANCE_DETAIL_REPORT_ERROR";

export const SET_DATE_FILTER = "SUPERVISOR/SET_DATE_FILTER";

export const GET_EMERGENCY_NOTIFICATIONS =
  "SUPERVISOR/GET_EMERGENCY_NOTIFICATIONS";
export const GET_EMERGENCY_NOTIFICATIONS_SUCCESS =
  "SUPERVISOR/GET_EMERGENCY_NOTIFICATIONS_SUCCESS";
export const GET_EMERGENCY_NOTIFICATIONS_ERROR =
  "SUPERVISOR/GET_EMERGENCY_NOTIFICATIONS_ERROR";

export const CREATE_EMERGENCY_NOTIFICATIONS =
  "SUPERVISOR/CREATE_EMERGENCY_NOTIFICATIONS";
export const CREATE_EMERGENCY_NOTIFICATIONS_SUCCESS =
  "SUPERVISOR/CREATE_EMERGENCY_NOTIFICATIONS_SUCCESS";
export const CREATE_EMERGENCY_NOTIFICATIONS_ERROR =
  "SUPERVISOR/CREATE_EMERGENCY_NOTIFICATIONS_ERROR";

export const GET_NOTIFICATION_TEMPLATES =
  "SUPERVISOR/GET_NOTIFICATION_TEMPLATES";
export const GET_NOTIFICATION_TEMPLATES_SUCCESS =
  "SUPERVISOR/GET_NOTIFICATION_TEMPLATES_SUCCESS";
export const GET_NOTIFICATION_TEMPLATES_ERROR =
  "SUPERVISOR/GET_NOTIFICATION_TEMPLATES_ERROR";

export const GET_HAZARDS = "SUPERVISOR/GET_HAZARDS";
export const GET_HAZARDS_SUCCESS = "SUPERVISOR/GET_HAZARDS_SUCCESS";
export const GET_HAZARDS_ERROR = "SUPERVISOR/GET_HAZARDS_ERROR";

export const POST_HAZARDS = "SUPERVISOR/POST_HAZARDS";
export const POST_HAZARDS_SUCCESS = "SUPERVISOR/POST_HAZARDS_SUCCESS";
export const POST_HAZARDS_ERROR = "SUPERVISOR/POST_HAZARDS_ERROR";

export const UPLOAD_HAZARD_IMAGE = "SUPERVISOR/UPLOAD_HAZARD_IMAGE";
export const UPLOAD_HAZARD_IMAGE_SUCCESS =
  "SUPERVISOR/UPLOAD_HAZARD_IMAGE_SUCCESS";
export const UPLOAD_HAZARD_IMAGE_ERROR = "SUPERVISOR/UPLOAD_HAZARD_IMAGE_ERROR";

export const GET_HAZARD_SETTINGS = "SUPERVISOR/GET_HAZARD_SETTINGS";
export const GET_HAZARD_SETTINGS_SUCCESS =
  "SUPERVISOR/GET_HAZARD_SETTINGS_SUCCESS";
export const GET_HAZARD_SETTINGS_ERROR = "SUPERVISOR/GET_HAZARD_SETTINGS_ERROR";

export const RESET_HAZARDS = "SUPERVISOR/RESET_HAZARDDS";

export const POST_HAZARD_SETTINGS = "SUPERVISOR/POST_HAZARD_SETTINGS";
export const POST_HAZARD_SETTINGS_SUCCESS =
  "SUPERVISOR/POST_HAZARD_SETTINGS_SUCCESS";
export const POST_HAZARD_SETTINGS_ERROR =
  "SUPERVISOR/POST_HAZARD_SETTINGS_ERROR";

export const DELETE_HAZARD = "SUPERVISOR/DELETE_HAZARD";
export const DELETE_HAZARD_SUCCESS = "SUPERVISOR/DELETE_HAZARD_SUCCESS";
export const DELETE_HAZARD_ERROR = "SUPERVISOR/DELETE_HAZARD_ERROR";

export const RESET_HAZARD_IMAGE = "SUPERVISOR/RESET_HAZARD_IMAGE";

export const GET_PEOPLE_LIST = "SUPERVISOR/GET_PEOPLE_LIST";
export const GET_PEOPLE_LIST_SUCCESS = "SUPERVISOR/GET_PEOPLE_LIST_SUCCESS";
export const GET_PEOPLE_LIST_ERROR = "SUPERVISOR/GET_PEOPLE_LIST_ERROR";


export const GET_INDUCTION_DETAILS = "SUPERVISOR/GET_INDUCTION_DETAILS";
export const GET_INDUCTION_DETAILS_SUCCESS =
  "SUPERVISOR/GET_INDUCTION_DETAILS_SUCCESS";
export const GET_INDUCTION_DETAILS_ERROR = "SUPERVISOR/GET_INDUCTION_DETAILS_ERROR";

export const UPDATE_INDUCTION = "SUPERVISOR/UPDATE_INDUCTION";
export const UPDATE_INDUCTION_SUCCESS = "SUPERVISOR/UPDATE_INDUCTION_SUCCESS";
export const UPDATE_INDUCTION_ERROR = "SUPERVISOR/UPDATE_INDUCTION_ERROR";

export const UPLOAD_INDUCTION_IMAGE = "SUPERVISOR/UPLOAD_INDUCTION_IMAGE";
export const UPLOAD_INDUCTION_IMAGE_SUCCESS =
  "SUPERVISOR/UPLOAD_INDUCTION_IMAGE_SUCCESS";
export const UPLOAD_INDUCTION_IMAGE_ERROR = "SUPERVISOR/UPLOAD_INDUCTION_IMAGE_ERROR";