// @flow
import { put, takeEvery } from "redux-saga/effects";
import {
  SET_FOOTER_VISIBILITY,
  SET_FOOTER_VISIBILITY_SUCCESS,
} from "../constants";

function* setFooterVisibility(data) {
  yield put({
    type: SET_FOOTER_VISIBILITY_SUCCESS,
    data: data.isFooterVisible,
  });
}

export default function* setFooterVisibilitySaga() {
  yield takeEvery(SET_FOOTER_VISIBILITY, setFooterVisibility);
}
