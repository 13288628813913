import React from "react";
import TickIcon from "assets/supervisorIcons/tick.png";
import CloseIcon from "assets/supervisorIcons/close.svg";
const RoleCell = ({ data }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "9px",
      }}
    >
      <span style={{ display: "flex", width: "65px" }}>{data.role}</span>
      {data.valid ? (
        <img
          alt={"tick"}
          src={TickIcon}
          style={{
            marginLeft: "50px",
            width: "11px",
            height: "8px",
            justifyContent: "center",
          }}
        />
      ) : (
        <img
          alt={"close"}
          src={CloseIcon}
          style={{
            marginLeft: "50px",
            width: "11px",
            height: "8px",
            justifyContent: "center",
          }}
        />
      )}
    </div>
  );
};

export default RoleCell;
