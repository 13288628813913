import React from "react";
import Input from "../../../../../components/Supervisor/input";
import classes from "../editor.module.scss";

const InductionCompleteForm = ({page,updatePageData}) => {


  return <div>
        
        <br />
        <h4 className={classes.formHeading}>Motivational Text</h4>
        <Input
            hasTooltip={true}
            order="column"
            value={page?.data?.content}
            name={"Content"}
            characterLimit={120}
            onChange={(e) => {
                updatePageData({key:'content',value:e.target.value});
            }}
            label={"Content"}
            placeholder={"Content here"}
            error={""}
            tooltipText={
            "This content appears after the heading."
            }
        />


  </div>;
};

export default InductionCompleteForm;