import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LandScapeModal from "components/UI/landscapeModal";

const Authmiddleware = ({
  component: Component,
  path,
  layout: Layout,
  supervisor,
  type,
  landscapeWarningVisible,
  userType,
}) => (
  <Route
    path={path}
    render={(props) => {
      if (supervisor === null) {
        return (
          <Redirect
            to={{
              pathname: "/supervisor-login",
              state: { from: props.location },
            }}
          />
        );
      }

      return (
        <Layout menu={true}>
          {landscapeWarningVisible ? (
            <>
              <LandScapeModal
                isVisible={landscapeWarningVisible}
                name={"Landscape Mode"}
                content={
                  "This chapter does not support landscape mode. Please rotate your device to enjoy the exercise."
                }
              />
            </>
          ) : (
            <Component {...props} />
          )}
        </Layout>
      );
    }}
  />
);

const mapStateToProps = (state) => {
  return {
    supervisor: state.auth.supervisor,
    userType: state.auth.userType,
  };
};

export default withRouter(connect(mapStateToProps)(Authmiddleware));
