import React from "react";
import classes from "./pagination.module.scss";

const Pagination = ({ totalCount, pageCount, currentPage, onChange }) => {
  return (
    <div className={classes.paginationContainer}>
      {Array.from(
        Array.from(
          {
            length:
              totalCount < pageCount ? 1 : Math.ceil(totalCount / pageCount),
          },
          (_, i) => i
        )
      ).map((item, i) => (
        <span
          onClick={() => {
            onChange(i);
          }}
          style={{
            background: i + 1 === currentPage && " rgba(165, 180, 201, 0.2)",
          }}
        >
          {i + 1}
        </span>
      ))}
    </div>
  );
};

export default Pagination;
