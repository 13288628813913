// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
} from "../../constants";
import axios from "../../../../services/api";
import { error as err, success } from "../../../../main";

function deleteReq(data) {
  return axios.delete("/staff/", { data: { id: data.id } });
}

function* deleteUser(data) {
  try {
    const response = yield call(deleteReq, data);
    yield put({
      type: DELETE_USER_SUCCESS,
      data: response,
    });

    // yield put(push("/supervisor-settings/users"));
    if (response?.status === 200) {
      yield call(success, `User Deleted Succesfully`);
    }
    if (data.callback) {
      data.callback();
    }
    if (data.end != null) {
      data.end.callback();
    }
  } catch (error) {
    yield call(err, error.response.data.error);
    yield put({
      type: DELETE_USER_ERROR,
      data: error,
    });
  }
}

export default function* deleteUserSaga() {
  yield takeLatest(DELETE_USER, deleteUser);
}
