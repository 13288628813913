import React from "react";
import classes from "./landscape.module.scss";

const LandscapeModal = ({ name, content, isVisible }) => {
  return (
    <div className={isVisible ? classes.ModalVisible : classes.ModalHidden}>
      <div className={classes.ModalWrapper}>
        <div className={classes.Modal}>
          <div className={classes.TitleWrapper}>{name}</div>

          <div
            className={classes.ContentWrapper}
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </div>
      </div>
    </div>
  );
};
export default LandscapeModal;
