import * as Yup from "yup";

const LoginSchema = Yup.object().shape({
  message: Yup.string().required("Required"),
  template_id: Yup.string().required("Required"),
  site_list: Yup.array().test("site_test", "Required", function (value) {
    return this.parent.type !== 0 ? value.length > 0 : true;
  }),
});

export default LoginSchema;
