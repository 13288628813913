import React, { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, eventId: null, error: "" };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service

    this.setState({
      hasError: true,
      error: error.toString(),
    });

    console.log("error", error);

    console.log("errorInfo", errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <p>Something went wrong</p>
          <p>{this.state.error}</p>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
