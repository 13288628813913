import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import classes from "./assessment.module.scss";

import Lottie from "react-lottie";

//animations
import CheckAnimation from "assets/animations/Check-BIG-v2.json";

import {
  getAssesmentRequest,
  setAssesmentRequest,
  createAssesmentRequest,
  setAssesmentState,
} from "store/assesment/actions";

import { createStructuredSelector } from "reselect";
import {
  makeSelectAssesmentResult,
  makeSelectAssesmentTryId,
  makeSelectAssesment,
  makeSelectAssesmentState,
  makeSelectLoading,
} from "store/assesment/selector";

import { makeSelectSelectedInduction } from "store/induction/selector";
import { makeSelectUser } from "store/auth/selector";
import { withRouter } from "react-router-dom";
import RouteLeavingGuard from "../routeGuard";
import { FormattedMessage } from "react-intl";

const Assessment = ({
  assessmentId,
  getAssesment,
  assesmentData,
  assesmentTryId,
  createNewAssesment,
  setAssesmentQuestion,
  assessmentResult,
  induction,
  user,
  assesmentState,
  setAssesmentStateReq,
  history,
}) => {
  useEffect(() => {
    setAssesmentStateReq({ completed: null });
    resetState(0);
    setAssessmentCreated(false);
    setLoaded(false);
    getAssesment({ id: assessmentId });
  }, []);

  const [currentQuestionData, setCurrentQuestionData] = useState({ name: "" });
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOptions, setSelectedOption] = useState([]);
  const [result, setResult] = useState(false);
  const [options, setOptions] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [assessmentCreated, setAssessmentCreated] = useState(false);
  // const [buttonText, setButtonText] = useState("Submit your answer");
  const [buttonState, setButtonState] = useState(0); //0: Submit 1: retry
  const [summary, setSummary] = useState(false);

  const setCheckBoxHandler = (val) => {
    const newOptions = options.map((item) => {
      if (item.id === val) {
        if (currentQuestionData.type == "single-select") {
          setSelectedOption([item.id]);
          return {
            ...item,
            checked: true,
            result: "",
          };
        } else {
          if (selectedOptions.includes(item.id)) {
            //deselect it
            const index = selectedOptions.indexOf(item.id);
            var newOptions = [];

            if (index > -1) {
              newOptions = selectedOptions.splice(index, 1);
            }

            setSelectedOption(newOptions);

            return {
              ...item,
              checked: false,
              result: "",
            };
          } else {
            const newOptions = selectedOptions;
            newOptions.push(item.id);
            setSelectedOption(newOptions);

            return {
              ...item,
              checked: true,
              result: "",
            };
          }
        }
      } else if (currentQuestionData.type == "single-select") {
        //only make others not checked when it's single select
        return {
          ...item,
          checked: false,
          result: "",
        };
      } else {
        return {
          ...item,
          result: "",
        };
      }
    });
    setAssesmentStateReq({
      ...assesmentState,
      visible: true,
    });
    setOptions(newOptions);
  };

  const resetState = (nextQuestion) => {
    const newOptions = assesmentData?.data?.questions[
      nextQuestion
    ]?.options?.map((item) => {
      return {
        ...item,
        checked: false,
        correct: false,
      };
    });

    setOptions(newOptions);

    setButtonState(0);
    setAssesmentStateReq({
      buttonText: "Submit your answer",
      buttonTextId: "assesment.button.submit.answer",
    });
    // setButtonText(");

    setSelectedOption([]);

    setResult(false);
    setSummary(false);
  };

  useEffect(() => {
    if (assesmentState?.trigger === true) {
      handleSubmission();
    }
  }, [assesmentState]);

  const handleSubmission = (val) => {
    if (buttonState == 0) {
      if (currentQuestionData.type == "single-select")
        setAssesmentQuestion({
          assesmentTryId: assesmentTryId,
          questionId: currentQuestionData.id,
          optionId: selectedOptions[0] ? selectedOptions[0] : "",
        });
      else
        setAssesmentQuestion({
          assesmentTryId: assesmentTryId,
          questionId: currentQuestionData.id,
          optionIds: selectedOptions.length > 0 ? selectedOptions : "",
        });
    } else if (buttonState == 1) {
      //Retry
      const resettedQuestionIndex = 0;
      setCurrentQuestionIndex(resettedQuestionIndex);
      setCurrentQuestionData(
        assesmentData.data.questions[resettedQuestionIndex]
      );

      createNewAssesment({ id: assesmentData.id });
      setAssessmentCreated(true);

      resetState(resettedQuestionIndex);
    } else if (buttonState == 2) {
      const nextQuestion = currentQuestionIndex + 1;
      setCurrentQuestionIndex(nextQuestion);
      setCurrentQuestionData(assesmentData.data.questions[nextQuestion]);

      resetState(nextQuestion);
    }
  };

  useEffect(() => {
    if (!assesmentData) return;

    if (assessmentId != assesmentData.id) {
      return;
    }

    //halilk: Create a new assessment try
    if (assessmentCreated == false) {
      setAssessmentCreated(true);
      createNewAssesment({ id: assesmentData.id });
    }

    setCurrentQuestionData(assesmentData.data.questions[currentQuestionIndex]);

    const newOptions = assesmentData.data.questions[
      currentQuestionIndex
    ].options.map((item) => {
      return {
        ...item,
        checked: false,
        correct: false,
      };
    });

    setOptions(newOptions);
  }, [assesmentData]);

  useEffect(() => {
    setLoaded(true);
  }, [assesmentTryId]);

  useEffect(() => {
    if (!assessmentResult) return;

    //check if it's old data
    if (assesmentTryId != assessmentResult.assesmentTryId) return;

    if (assessmentCreated == false) return;

    const newOptions = options.map((item) => {
      if (selectedOptions.includes(item.id)) {
        return {
          ...item,
          checked: true,
          result:
            assessmentResult.is_correct == 1
              ? classes.questionItemCorrect
              : classes.questionItemWrong,
        };
      } else if (currentQuestionData.type == "single-select") {
        return {
          ...item,
          checked: false,
          result: "",
        };
      } else {
        return {
          ...item,
          result: "",
        };
      }
    });

    setOptions(newOptions);

    //Show result on top
    var newResult = null;
    if (assessmentResult.is_correct == 1)
      newResult = (
        <span className={classes.correctAnswer}>
          <FormattedMessage
            defaultMessage={"Correct"}
            id={"assesment.correct.answer"}
          />
        </span>
      );
    else
      newResult = (
        <span className={classes.wrongAnswer}>
          <FormattedMessage
            defaultMessage={" That's not what we're looking for."}
            id={"assesment.wrong.answer"}
          />
        </span>
      );

    setResult(newResult);

    //Check the next steps
    if (assessmentResult.is_finished == 1 && assessmentResult.passed == 1) {
      setSummary(1);
      setAssesmentStateReq({
        completed: true,
      });
    } else if (assessmentResult.is_finished == 1) {
      setAssesmentStateReq({
        buttonText: "Retry",
        buttonTextId: "assesment.button.retry",
        visible: true,
      });

      // setButtonText("Retry");
      setButtonState(1);
    } else if (
      currentQuestionIndex + 1 <=
      assesmentData.data.questions.length
    ) {
      setAssesmentStateReq({
        buttonText: "Next Question",
        buttonTextId: "assesment.button.next.question",
        visible: true,
      });
      setButtonState(2);
    }
  }, [assessmentResult]);

  const getStyle = (item) => {
    const style = {};

    if (item.image) {
      style.background = `url(${item.image})  no-repeat  center/cover`;
    }

    return style;
  };

  return (
    <>
      <div>
        <div
          className={
            summary ? classes.SummaryWrapper : classes.SummaryWrapperHidden
          }
        >
          <div className={classes.SummaryTitle}>
            <FormattedMessage
              defaultMessage={"Knowledge Check Results"}
              id={"assesment.summary.title"}
            />
          </div>

          <div className={classes.SummaryAnimationWrapper}>
            <div className={classes.SummaryImageWrapper}>
              &nbsp;
              <Lottie
                options={{
                  loop: false,
                  autoplay: true,
                  animationData: CheckAnimation,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                    progressiveLoad: true,
                  },
                }}
                width={"40%"}
                isClickToPauseDisabled={true}
                isPaused={!summary}
                title={"animation"}
                ariaLabel={"animation"}
              />
            </div>
          </div>

          <div className={classes.SummaryText}>
            <FormattedMessage
              defaultMessage={"Great Work"}
              values={{ name: user && user?.first_name }}
              id={"induction.assesment.completion.congrats"}
            />
          </div>
          <div className={classes.SummarySubText}>
            <FormattedMessage
              defaultMessage={
                "You've passed! Select the next button to continue."
              }
              id={"assesment.summary.subtext"}
            />
          </div>
        </div>

        {assessmentCreated && loaded ? (
          <div
            className={
              summary
                ? classes.assessmentWrapperHidden
                : classes.assessmentWrapper
            }
          >
            <h3>
              {" "}
              <FormattedMessage
                defaultMessage={" Knowledge Check"}
                id={"assesment.title"}
              />
            </h3>
            <p>{currentQuestionData.name}</p>

            <div className={classes.resultWrapper}>{result}</div>

            <div className={classes.questionWrapper}>
              {options &&
                options?.map((item) => (
                  <div
                    className={
                      (item.checked
                        ? classes.questionItemChecked
                        : classes.questionItem) +
                      " " +
                      (item.result ? item.result : "") +
                      " " +
                      (item.image ? classes.questionItemImage : "")
                    }
                    key={item.id}
                    onClick={() => setCheckBoxHandler(item.id)}
                    style={{
                      ...getStyle(item),
                    }}
                  >
                    {item.name}
                  </div>
                ))}
            </div>
            {/* <div className={classes.assessmentButton}>
            <Button
              subText={""}
              type={""}
              theme={"blue"}
              id={"sendButton"}
              className={classes.sendButton}
              text={buttonText}
              onClick={() => handleSubmission()}
            />
          </div> */}
          </div>
        ) : (
          <h1>Loading..</h1>
        )}
      </div>
      <RouteLeavingGuard
        // When should shouldBlockNavigation be invoked,
        // simply passing a boolean
        // (same as "when" prop of Prompt of React-Router)
        when={!summary}
        // Navigate function
        navigate={(path) => history.push(path)}
        // Use as "message" prop of Prompt of React-Router
        shouldBlockNavigation={(location) => {
          return true;
        }}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  selectedInduction: makeSelectSelectedInduction(),
  assesmentTryId: makeSelectAssesmentTryId(),
  assesmentData: makeSelectAssesment(),
  assessmentResult: makeSelectAssesmentResult(),
  induction: makeSelectSelectedInduction(),
  user: makeSelectUser(),
  assesmentState: makeSelectAssesmentState(),
  loading: makeSelectLoading(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getAssesment: (val) => dispatch(getAssesmentRequest(val)),
    setAssesmentQuestion: (val) => dispatch(setAssesmentRequest(val)),
    createNewAssesment: (val) => dispatch(createAssesmentRequest(val)),
    setAssesmentStateReq: (val) => dispatch(setAssesmentState(val)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Assessment)
);
