import React, { useEffect, useState, useRef } from "react";
//styling
import classes from "../../reporting.module.scss";

//components
import Table from "components/Supervisor/table";
import DateRangeFilter from "components/Supervisor/dateRangeFilter";
import { CSVLink } from "react-csv";
import ReactLoading from "react-loading";
import Pagination from "components/Supervisor/pagination";

//redux
import {
  getCompaniesRequest,
  getAllAttendaceReportsRequest,
  getRegionRequest,
  getSitesRequest,
  getAttendanceDetailRequest,
  setDateFilterReq,
} from "store/supervisor/actions";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  makeSelectCompanies,
  makeSelectLoading,
  makeSelectAllReportsLoading,
  makeSelectAllAttendancesReports,
  makeSelectRegions,
  makeSelectSites,
  makeSelectAttendanceDetail,
  makeSelectDateFilter,
} from "store/supervisor/selector";

//utils
import { isNil, flatten, isEmpty, first } from "lodash";
import { format, getUnixTime } from "date-fns";
import { useSortableData } from "../../../../../utils/hooks";
import queryString from "query-string";
import { reportingTableData } from "./data";

//assets
const BackIcon = window.location.origin + "/backIcon/backIconn.svg";

const AttendanceReportingDetail = ({
  history,
  getReports,
  reports,
  loading,
  getAllReports,
  aloading,
  menu,
  branches,
  getBranches,
  filterDate,
  setDateFilter,
}) => {
  const id = queryString.parse(window.location.search).id;
  const firstName = queryString.parse(window.location.search).firstName;
  const lastName = queryString.parse(window.location.search).lastName;

  const [reportingList, setReportingList] = useState([]);

  const [selectedPage, setSelectedPage] = useState(1);

  const [csvData, setcsvData] = useState([]);

  const csvLink = useRef();

  const [searchState, setSearchState] = useState("");

  const [rawReports, setRawReports] = useState([]);

  const [PAGE_COUNT, setPageCount] = useState(null);

  const onSearch = (e) => {
    setSearchState(e.target.value);
    if (e.target.value.length === 0) {
      getReports({
        id: id,
        search: e.target.value,
        enddate: !isNil(filterDate?.enddate) && filterDate.enddate,
        startdate: !isNil(filterDate?.startdate) && filterDate.startdate,
        sort:
          !isNil(sortConfig) &&
          sortConfig?.direction?.toString() === "descending"
            ? `-${sortConfig?.key}`
            : sortConfig?.key,
      });
      setSearchState("");
      setSelectedPage(1);
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      setSelectedPage(1);
      getReports({
        id: id,
        search: event.target.value,
        enddate: !isNil(filterDate?.enddate) && filterDate.enddate,
        startdate: !isNil(filterDate?.startdate) && filterDate.startdate,
      });
    }
  };

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const printReports = async (data) => {
    const { labels, columns } = reportingTableData(data, branches);
    const labelData = labels?.map((item) => item.label);
    const columnData = columns?.map((item) => item.values);
    const mergedData = [labelData, ...columnData];

    const handledCsvData = mergedData?.map((item, index) => {
      if (index === 0) {
        return item;
      } else return item.map((item) => item.value);
    });

    setcsvData(handledCsvData);
    await delay(1000);

    csvLink.current.link.click();
  };

  useEffect(() => {
    if (!isNil(reports)) {
      let data = isEmpty(reports?.results)
        ? []
        : flatten(
            reports?.results?.map((item) =>
              item?.checkin_data.map((a, index) => {
                return {
                  ...a,
                  title: index === 0 ? true : false,
                };
              })
            )
          );

      setReportingList(reportingTableData(data, branches));

      setRawReports(data);

      setPageCount(reports?.items_per_page);
    }
  }, [reports]);

  useEffect(() => {
    //api call
    getBranches();
  }, []);

  const { requestSort, sortConfig } = useSortableData(rawReports);
  const sortBy = (key) => {
    requestSort(key);
    if (!isNil(sortConfig)) {
      getReports({
        id: id,
        search: searchState,
        enddate: !isNil(filterDate?.enddate) && filterDate.enddate,
        startdate: !isNil(filterDate?.startdate) && filterDate.startdate,
        sort:
          sortConfig.direction.toString() === "descending" ? `-${key}` : key,
      });
    }
  };

  useEffect(() => {
    if (filterDate?.enddate && filterDate?.startdate) {
      getReports({
        id: id,
        enddate: filterDate.enddate,
        startdate: filterDate.startdate,
        search: searchState,
      });
    }
  }, [filterDate]);

  return (
    <div className={classes.container}>
      <div className={classes.headContainer}>
        <DateRangeFilter
          value={filterDate}
          onChange={(val) => {
            setDateFilter({
              startdate: getUnixTime(val[0].startDate),
              enddate: getUnixTime(val[0].endDate),
            });
          }}
        />
      </div>
      {menu}
      <div className={classes.filterContainer}>
        <img
          alt={"back icon"}
          onClick={() => history.push("/supervisor-reporting-attendance")}
          src={BackIcon}
          className={classes.backIcon}
        />
        <span className={classes.attendanceDetailTitle}>
          <b>{lastName ? lastName : reports[0]?.user?.last_name}</b>&nbsp;
          {firstName ? firstName : reports[0]?.user?.first_name}
        </span>
        <div />
      </div>
      <div className={classes.tableWrapper}>
        <Table
          handleKeyDown={handleKeyDown}
          isSearchable={true}
          searchState={searchState}
          loading={loading}
          onSearch={(e) => onSearch(e)}
          data={reportingList}
          sortByDesc={(val) => sortBy(val)}
          sortConfig={sortConfig}
        />
      </div>
      {PAGE_COUNT && reports && reports?.count > PAGE_COUNT && (
        <Pagination
          totalCount={reports?.count}
          pageCount={PAGE_COUNT}
          currentPage={selectedPage}
          onChange={(val) => {
            getReports({
              id: id,
              page: val + 1,
              search: searchState,
              enddate: !isNil(filterDate?.enddate) && filterDate.enddate,
              startdate: !isNil(filterDate?.startdate) && filterDate.startdate,
              sort:
                !isNil(sortConfig) &&
                sortConfig?.direction?.toString() === "descending"
                  ? `-${sortConfig?.key}`
                  : sortConfig?.key,
            });
            setSelectedPage(val + 1);
          }}
        />
      )}

      <div className={classes.buttonContainer}>
        <div className={classes.buttonWrapper}>
          <div style={{ justifyContent: "flex-end" }} className={classes.flex}>
            <button
              disabled={aloading}
              className={classes.darkButton}
              onClick={() => {
                printReports(rawReports);
              }}
            >
              {aloading ? (
                <ReactLoading
                  type={"spin"}
                  color={"#40507e"}
                  height={30}
                  width={30}
                />
              ) : (
                "Export"
              )}
            </button>
            <CSVLink
              filename={`${
                !isNil(filterDate) &&
                !isNil(filterDate?.startdate) &&
                !isNil(filterDate?.enddate)
                  ? `${format(
                      new Date(filterDate?.startdate * 1000),
                      "yyyyMMdd"
                    )}-${format(
                      new Date(filterDate?.enddate * 1000),
                      "yyyyMMdd"
                    )}`
                  : "reports"
              }.csv`}
              className="hidden"
              data={csvData}
              ref={csvLink}
              target="_blank"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  reports: makeSelectAttendanceDetail(),
  companies: makeSelectCompanies(),
  branches: makeSelectRegions(),
  allReports: makeSelectAllAttendancesReports(),
  aloading: makeSelectAllReportsLoading(),
  sites: makeSelectSites(),
  filterDate: makeSelectDateFilter(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getCompanies: (val) => dispatch(getCompaniesRequest(val)),
    getReports: (val) => dispatch(getAttendanceDetailRequest(val)),
    getAllReports: (val) => dispatch(getAllAttendaceReportsRequest(val)),
    getBranches: (val) => dispatch(getRegionRequest(val)),
    getSites: (val) => dispatch(getSitesRequest(val)),
    setDateFilter: (val) => dispatch(setDateFilterReq(val)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AttendanceReportingDetail);
