// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import { SET_SELECTED_SITE, SET_SELECTED_SITE_SUCCESS } from "../constants";
import axios from "../../../services/api";

function* setSelectedSite(data) {
  yield put({
    type: SET_SELECTED_SITE_SUCCESS,
    data: data.sites,
  });
}

export default function* setSelectedSiteSaga() {
  yield takeLatest(SET_SELECTED_SITE, setSelectedSite);
}
