import produce from "immer";
import {
  SET_SELECTED_STEP_REQUEST,
  SET_SELECTED_STEP_REQUEST_ERROR,
  SET_SELECTED_STEP_REQUEST_SUCCESS,
  RESET_SELECTED_STEP_REQUEST,
} from "./constants";

export const initialState = {
  error: null,
  loading: false,
  selectedStep: null,
};

const progressReducer = (state = initialState, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case SET_SELECTED_STEP_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case SET_SELECTED_STEP_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.selectedStep = action.data;
        break;
      case SET_SELECTED_STEP_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case RESET_SELECTED_STEP_REQUEST:
        draftState.loading = false;
        draftState.error = null;
        draftState.selectedStep = null;
        break;
      default:
        break;
    }
  });
};

export default progressReducer;
