// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  CREATE_ASSESMENT_REQUEST,
  CREATE_ASSESMENT_REQUEST_SUCCESS,
  CREATE_ASSESMENT_REQUEST_ERROR,
} from "../constants";
import axios from "../../../services/api";

function* createNewAssesment(data) {
  try {
    const params = new URLSearchParams();
    params.append("assesmentId", data.id);
    const response = yield call(axios.post, "/assesment-try/", params);
    if (response.data.error) {
      yield put({
        type: CREATE_ASSESMENT_REQUEST_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: CREATE_ASSESMENT_REQUEST_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: CREATE_ASSESMENT_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* setAssesmentRequestSaga() {
  yield takeLatest(CREATE_ASSESMENT_REQUEST, createNewAssesment);
}
