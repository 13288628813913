import React, { useState, useEffect } from "react";
import cn from "classnames";
import { ClickOutside } from "utils/helper";

import classes from "./filterSelector.module.scss";
import { isEmpty, isNil } from "lodash";
import ArrowIcon from "./arrow.svg";
import Checkbox from "../checkbox";
import Icon from "components/UI/IconComponent";

const FilterSelector = ({
  title,
  options,
  onChange,
  disabled,
  selectedPeriod,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeOption, setActiveOption] = useState(null);

  useEffect(() => {
    if (!isEmpty(options) && !isNil(options)) {
      setActiveOption(options[0]);
    }
  }, []);

  useEffect(() => {
    if (!isNil(activeOption)) {
      onChange(activeOption.label);
    }
  }, [activeOption]);

  const checkedOptions = options?.filter((item) => item.checked);

  return (
    <ClickOutside onClick={() => setIsDropdownOpen(false)}>
      <div className={cn(classes.selectbox)}>
        <button
          className={cn(classes.selectbox_head)}
          onClick={() => !disabled && setIsDropdownOpen(!isDropdownOpen)}
        >
          <span className={classes.title}>
            {" "}
            {checkedOptions != undefined ? (
              <>
                {title}
                {checkedOptions.length > 0 && (
                  <div className={classes.counterLabel}>
                    <span>{checkedOptions.length}</span>
                  </div>
                )}
              </>
            ) : (
              title
            )}
          </span>
          <img
            alt={"Arrow Icon"}
            src={ArrowIcon}
            className={cn(classes.icon, isDropdownOpen && classes.rotate)}
          />
        </button>
        <div className={cn(classes.dropdown, isDropdownOpen && classes.open)}>
          <ul>
            {options &&
              !!options.length &&
              options.map((option, index) => {
                return (
                  <li
                    key={index}
                    onClick={() => {
                      setActiveOption(option);
                    }}
                  >
                    <Checkbox checked={option.checked ? true : false} />
                    <div className={classes.indicatorItem}>
                      <div style={{ background: option.color }} />
                      <span style={{ color: option.color && option.color }}>
                        {option.label}
                      </span>
                      {option.icon && (
                        <Icon
                          className={classes.optionIcon}
                          icon={option.icon}
                          color={option.iconColor}
                          size={option.iconSize ? option.iconSize : "11px"}
                        />
                      )}
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </ClickOutside>
  );
};

export default FilterSelector;
