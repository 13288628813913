import {
  SET_PROGRESS_REQUEST,
  GET_PROGRESS_REQUEST,
  SET_CURRENT_SECTION_REQUEST,
} from "./constants";

export const setProgressRequest = (payload) => {
  return {
    type: SET_PROGRESS_REQUEST,
    ...payload,
  };
};

export const getProgressRequest = (payload) => {
  return {
    type: GET_PROGRESS_REQUEST,
    ...payload,
  };
};
