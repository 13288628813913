// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  POST_HAZARDS,
  POST_HAZARDS_ERROR,
  POST_HAZARDS_SUCCESS,
} from "../../constants";
import axios from "../../../../services/api";
import { error as err, success } from "../../../../main";
function* postHazards(data) {
  const params = new URLSearchParams();

  if (data.hazards) {
    params.append("data", data.hazards);
  }

  try {
    const response = yield call(axios.post, `/hazards/`, params);
    if (response.data.error) {
      yield put({
        type: POST_HAZARDS_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: POST_HAZARDS_SUCCESS,
        data: response?.data,
      });
      yield call(success, `Hazards succeffully updated. `);
      data.callBack();
    }
  } catch (error) {
    yield put({
      type: POST_HAZARDS_ERROR,
      data: error,
    });
  }
}

export default function* postHazardsSaga() {
  yield takeLatest(POST_HAZARDS, postHazards);
}
