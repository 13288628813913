import React from "react";
import Translations from "./translations";
import Roles from "./roles";
import RolesForm from "./roles/form";
import Users from "./user";
import UsersForm from "./user/form";
import Branch from "./branch";
import BranchForm from "./branch/form";
import CSVImport from "./user/csvimport";

import Menu from "components/Supervisor/menu";
import classes from "./settings.module.scss";

import { Route, Switch, Redirect } from "react-router-dom";

const Settings = () => {
  const pages = [
    {
      title: "Translations",
      active: true,
      component: <Translations />,
      pathName: "/supervisor-settings-translations",
      path: "translations",
    },
    {
      title: "Roles",
      active: false,
      component: <Roles />,
      pathName: "/supervisor-settings-roles",
      path: "roles",
    },
    {
      title: "Users",
      active: false,
      component: <Users />,
      pathName: "/supervisor-settings-users",
      path: "users",
    },
    {
      title: "Branch codes",
      active: false,
      component: <Branch />,
      pathName: "/supervisor-settings-branch",
      path: "branch",
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <Menu pages={pages} />
        <div className={classes.container}>
          <Switch>
            {
              /* Redirect from supervisor root URL to /supervisor translations */
              <Redirect
                exact={true}
                from="/supervisor-settings"
                to="/supervisor-settings-translations"
              />
            }
            <Route path="/supervisor-settings-branch">
              {({ history, match }) => (
                <Branch history={history} exact={true} />
              )}
            </Route>
            <Route path="/supervisor-settings-branch-create">
              {({ history, match }) => (
                <BranchForm
                  id={match && match.params.id}
                  history={history}
                  exact={true}
                />
              )}
            </Route>
            <Route path="/supervisor-settings-branch-edit">
              {({ history, match }) => (
                <BranchForm
                  id={match && match.params.id}
                  history={history}
                  exact={true}
                />
              )}
            </Route>
            <Route path="/supervisor-settings-translations" exact={true}>
              {({ history, match }) => <Translations history={history} />}
            </Route>
            <Route path="/supervisor-settings-roles-create" exact={true}>
              {({ history, match }) => (
                <RolesForm
                  key={"create"}
                  id={match && match.params.id}
                  history={history}
                />
              )}
            </Route>
            <Route path="/supervisor-settings-roles-edit" exact={true}>
              {({ history, match }) => (
                <RolesForm
                  key={"edit"}
                  id={match && match.params.id}
                  history={history}
                />
              )}
            </Route>
            <Route path="/supervisor-settings-roles">
              {({ history, match }) => <Roles history={history} exact={true} />}
            </Route>
            <Route path="/supervisor-settings-users-create" exact={true}>
              {({ history, match }) => (
                <UsersForm
                  key={"create"}
                  id={match && match.params.id}
                  history={history}
                />
              )}
            </Route>
            <Route path="/supervisor-settings-users-edit" exact={true}>
              {({ history, match }) => (
                <UsersForm
                  key={"edit"}
                  id={match && match.params.id}
                  history={history}
                />
              )}
            </Route>
            <Route path="/supervisor-settings-users-import-csv" exact={true}>
              {({ history, match }) => <CSVImport history={history} />}
            </Route>
            <Route path="/supervisor-settings-users">
              {({ history, match }) => <Users history={history} exact={true} />}
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
};

export default Settings;
