// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_USER,
} from "../../constants";
import axios from "../../../../services/api";
import { push } from "connected-react-router";
import { error as err, success } from "../../../../main";

function* updateUserRequest(data) {
  try {
    const values = data;
    const params = new URLSearchParams();
    params.append("group", values.values.group);
    params.append("company", values.values.company);
    params.append("first_name", values.values.firstName);
    params.append("last_name", values.values.lastName);
    params.append("phone", values.values.tel);
    params.append("email", values.values.email);
    params.append("id", values.id);

    const response = yield call(axios.put, `/staff/`, params);
    if (response.data.error) {
      yield put({
        type: UPDATE_USER_ERROR,
        data: response.error,
      });
      yield call(err, response.data.error);
    } else {
      yield put({
        type: UPDATE_USER_SUCCESS,
        data: response.data,
      });
      yield call(success, `User Updated Succesfully`);
      yield put(push("/supervisor-settings-users"));
    }
  } catch (error) {
    yield put({
      type: UPDATE_USER_ERROR,
      data: error,
    });
  }
}

export default function* updateUserRequestSaga() {
  yield takeLatest(UPDATE_USER, updateUserRequest);
}
