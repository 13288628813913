import React from "react";
import classes from "./counter.module.scss";

const Counter = ({ title, count }) => {
  return (
    <>
      <div className={classes.counter}>
        <span className={classes.count}>
          {count < 10 ? `0${count}` : count}
        </span>
        <span className={classes.title}>{title}</span>
      </div>
    </>
  );
};

export default Counter;
