import React from "react";

const PlusIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66683 5.66668H5.66683V9.66668H4.3335V5.66668H0.333496V4.33334H4.3335V0.333344H5.66683V4.33334H9.66683V5.66668Z"
        fill="#7185BE"
      />
    </svg>
  );
};

export default PlusIcon;
