import React, { useEffect, useState } from "react";
import classes from "./notifications.module.scss";
import Input from "components/Supervisor/input";
import Card from "components/Supervisor/card";
import TextArea from "components/Supervisor/textArea";

import { create, isNaN, template } from "lodash";

import {
  createEmergencyNotification,
  getNotificationTemplatesReq,
  getSitesRequest,
} from "store/supervisor/actions";
import {
  makeSelectLoading,
  makeSelectNotificationsTemplates,
  makeSelectSites,
} from "store/supervisor/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Formik, Form, Field } from "formik";
import ReactLoading from "react-loading";
import queryString from "query-string";
import MultiCheckbox from "components/Supervisor/multiCheckbox";
import SendIcon from "assets/supervisorIcons/send.js";
import validationSchema from "./validationSchema";

const BackIcon = window.location.origin + "/backIcon/back.png";

const CreateNotification = ({
  templates,
  history,
  createNotification,
  loading,
  getTemplates,
  getSites,
  sites,
}) => {
  const [siteOptions, setSiteOptions] = useState([]);
  const [templateOptions, setTemplateOptions] = useState([]);

  useEffect(() => {
    getTemplates();
    getSites({
      page: 1,
      search: "",
      ordering: "-id",
    });
  }, []);

  const items = [
    {
      label: "Everyone",
      value: 0,
      checked: false,
    },
    {
      label: "Everyone currently checked in",
      value: 1,
      checked: true,
    },
    {
      label: "By site",
      value: 2,
      checked: false,
    },
  ];

  useEffect(() => {
    if (templates) {
      const opts = templates?.results?.map((item) => {
        return {
          value: item.id,
          label: item.name,
          message: item.message,
        };
      });
      setTemplateOptions(opts);
    }
  }, [templates]);

  useEffect(() => {
    if (sites) {
      const opts = sites?.results?.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      setSiteOptions(opts);
    }
  }, [sites]);

  const initialValues = {
    message: "",
    type: 1,
    template_id: "",
    site_list: [],
  };

  return (
    <>
      <div className={classes.notificationContainer}>
        <div className={classes.headingWrapper}>
          <img
            alt={"back icon"}
            onClick={() => history.push("/supervisor-notifications")}
            src={BackIcon}
            className={classes.backIcon}
          />
          <h1 className={classes.titleHead} style={{ paddingLeft: "30px" }}>
            {" "}
            Notifications
          </h1>
        </div>
        {loading ? (
          <div className={classes.loadingContainer}>
            <ReactLoading
              type={"spin"}
              color={"#20a8d8"}
              height={300}
              width={300}
            />
          </div>
        ) : (
          <div className={classes.wrapper}>
            <div className={classes.form}>
              <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                validateOnChange={true}
                validateOnBlur={true}
                onSubmit={(values) => {
                  createNotification({
                    values: values,
                  });
                }}
              >
                {({ values, setFieldValue, handleSubmit, errors }) => (
                  <Form>
                    <Card title={"Message"}>
                      <Field
                        tabSelectsValue={values.template_id}
                        value={templateOptions.filter(
                          (option) => option.value === values.template_id
                        )}
                        type={"select"}
                        name={"template_id"}
                        onChange={(val) => {
                          setFieldValue("message", val.message);
                          setFieldValue("template_id", val.value);
                        }}
                        label={"Type*"}
                        placeholder={"Select Notification Type"}
                        options={templateOptions}
                        component={Input}
                        error={errors && errors["template_id"]}
                      />
                      <Field
                        name={"message"}
                        value={values.message}
                        onChange={(e) => {
                          setFieldValue("message", e.target.value);
                        }}
                        characterLimit={250}
                        label={"Message*"}
                        placeholder={"Message"}
                        component={TextArea}
                        error={
                          errors &&
                          errors["template_id"] &&
                          errors &&
                          errors["message"]
                        }
                      />
                    </Card>
                    <Card
                      title={"Recipient"}
                      style={{ marginBottom: "200px" }}
                      bodyStyle={{ minHeight: "unset !important" }}
                    >
                      <div className={classes.checkboxWrapper}>
                        <Field
                          component={MultiCheckbox}
                          items={items}
                          onChange={(val) => setFieldValue("type", val)}
                          value={values.type}
                          name="type"
                        />
                      </div>
                      {values.type !== 0 && (
                        <Field
                          onChange={(val, type) => {
                            setFieldValue("site_list", val);
                          }}
                          value={values?.site_list?.map((item) => {
                            return {
                              label: item.label,
                              value: item.value,
                            };
                          })}
                          options={siteOptions}
                          type={"select"}
                          label={"Site"}
                          placeholder={"Select a site from the list"}
                          isMulti
                          component={Input}
                          name="site_list"
                          error={errors && errors["site_list"]}
                        />
                      )}
                    </Card>
                    <div className={classes.buttonContainer}>
                      <div
                        style={{
                          width: "75%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{ justifyContent: "flex-end" }}
                          className={classes.flex}
                        >
                          <button
                            onClick={() =>
                              history.push("/supervisor-notifications")
                            }
                            className={classes.lightButton}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={loading}
                            className={classes.darkButton}
                            type={"submit"}
                            onClick={() => {
                              handleSubmit();
                            }}
                          >
                            <div className={classes.buttonWrapper}>
                              Send
                              <div className={classes.icon}>
                                <SendIcon />
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  templates: makeSelectNotificationsTemplates(),
  sites: makeSelectSites(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    createNotification: (val) => dispatch(createEmergencyNotification(val)),
    getTemplates: (val) => dispatch(getNotificationTemplatesReq(val)),
    getSites: (val) => dispatch(getSitesRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNotification);
