import React, { useEffect, useState } from "react";
import classes from "./dashboard.module.scss";
import {
  getDashboardRequest,
  getWeeklyActivity,
  getRegionRequest,
} from "store/supervisor/actions";
import { getLanguagesRequest } from "store/language/actions";
import { getInductionRequest } from "store/induction/actions";
import {
  makeSelectInduction,
  makeSelectLoading as makeSelectInductionLoading,
} from "store/induction/selector";

import SiteSelector from "components/Supervisor/siteSelector";

import {
  makeSelectDashboard,
  makeSelectLoading,
  makeSelectWeeklyActivity,
  makeSelectWeeklyLoading,
  makeSelectRegions,
} from "store/supervisor/selector";
import Select from "react-select";

import { makeSelectLanguages } from "store/language/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import LanguageStats from "./LanguageStats";
import SectionStats from "./SectionStats";
import OverviewStats from "./OverviewStats";
import WeeklyActivity from "./WeeklyActivity";

import ReactLoading from "react-loading";
import { isNil, isEmpty } from "lodash";
import cn from "classnames";

const Dashboard = ({
  dashboardData,
  getDashboard,
  getLanguages,
  loading,
  languages,
  regions,
  getRegions,
  weeklyActivityData,
  getWeekly,
  weeklyLoading,
  getInduction,
  inductions,
  inductionLoading,
}) => {
  const [inductionOptions, setInductionOptions] = useState([]);

  const [selectedInduction, setSelectedInduction] = useState();
  const [chapterStats, setSelectChapterStats] = useState();

  useEffect(() => {
    if (!isNil(dashboardData)) {
      setSelectChapterStats(dashboardData?.chapter_stats);
    }
  }, [dashboardData]);

  useEffect(() => {
    if (!isNil(inductions)) {
      getDashboard({ induction: inductions?.results[0].id });
      getLanguages();
      getRegions({ induction: inductions?.results[0].id });
      getWeekly({ induction: inductions?.results[0].id });
      const list = inductions?.results?.map((item, index) => {
        return {
          value: item.id,
          label: item.name,
          index: index,
        };
      });
      setInductionOptions(list);
      setSelectedInduction(list[0]);
    }
  }, [inductions, setInductionOptions]);

  useEffect(() => {
    getInduction();
  }, []);

  return (
    <>
      <div className={classes.dashboardContainer}>
        <div className={classes.topSection}>
          <h1 className={classes.title}>Dashboard</h1>
          <div className={classes.inductionSelector}>
            <Select
              styles={{
                control: () => ({
                  width: "100%",
                  height: "54px",
                  display: "flex",
                  background: "#FFFFFF",
                  boxSizing: "border-box",
                  color: "#28353C",
                  fontFamily: "Open Sans",
                  fontSize: 14,
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid #E0E0E0",
                  borderRadius: "6px",
                }),
                container: () => ({
                  width: "100%",
                  height: "54px",
                  position: "relative",
                  boxSizing: "border-box",
                }),

                placeholder: () => ({
                  color: "#28353C",
                  fontFamily: "Open Sans",
                  fontSize: 14,
                }),
                valueContainer: () => ({
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "180px",
                  height: "54px",
                }),
                singleValue: () => ({
                  fontFamily: "Open Sans",
                  fontSize: 14,
                  color: "#28353C !important",
                  width: "180px",
                  padding: "10px",
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  svg: {
                    fill: "#28A745",
                  },
                }),

                indicatorSeparator: () => ({
                  display: "none",
                }),
                option: (styles, state) =>
                  console.log(state.isFocused) || {
                    ...styles,
                    backgroundColor: state.isSelected ? "#6FCF97" : null,
                    "&:hover": {
                      backgroundColor: state.isFocused ? "#6FCF97" : null,
                      color: state.isFocused ? "#ffffff" : "#28353C",
                    },
                  },
              }}
              onChange={(val) => {
                getDashboard({ induction: val.value });
                getWeekly({ induction: val.value });
                getRegions({ induction: val.value });

                const select = inductionOptions.find(
                  (item) => item.value === val.value
                );
                setSelectedInduction(select);
              }}
              tabsSelectedValue={selectedInduction}
              value={selectedInduction}
              placeholder={"Select Induction"}
              options={inductionOptions}
              isSearchable={false}
            />
          </div>
        </div>
        {loading || inductionLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <ReactLoading
              type={"spin"}
              color={"#20a8d8"}
              height={300}
              width={300}
            />
          </div>
        ) : (
          <>
            <div className={classes.flexCol}>
              <h4 className={classes.midTitle}>Overview</h4>
              <OverviewStats dashboardData={dashboardData} />
            </div>
            <div className={classes.dashboardWrapper}>
              {!isNil(chapterStats) && !isEmpty(chapterStats) && (
                <div
                  className={classes.flexCol}
                  style={{
                    padding: "10px",
                  }}
                >
                  <div className={classes.headWrapper}>
                    <h4 className={classes.midTitle}>Induction</h4>
                  </div>
                  <SectionStats dashboardData={chapterStats} />
                </div>
              )}

              <div
                className={classes.flexCol}
                style={{
                  flexDirection:
                    !isNil(chapterStats) && !isEmpty(chapterStats)
                      ? "column"
                      : "row-reverse",
                  alignItems:
                    !isNil(chapterStats) && !isEmpty(chapterStats)
                      ? "flex-end"
                      : "flex-start",
                }}
              >
                <div
                  className={cn(
                    classes.flex,
                    classes.col,
                    isEmpty(chapterStats) && classes.ml10
                  )}
                >
                  <div className={cn(classes.headWrapper)}>
                    <h4 className={classes.midTitleRight}>Weekly activity</h4>
                    <div className={cn(classes.filterButton)}>
                      <SiteSelector
                        title={"Status"}
                        onChange={(val) => {
                          getWeekly({
                            regionIds: val,
                            induction: selectedInduction.value,
                          });
                        }}
                        regions={regions}
                      />
                    </div>
                  </div>
                  <WeeklyActivity
                    dashboardData={weeklyActivityData}
                    loading={weeklyLoading}
                  />
                </div>
                <div className={cn(classes.flex, classes.col)}>
                  <div className={cn(classes.headWrapper)}>
                    <h4 className={classes.midTitleRight}>Languages</h4>
                  </div>
                  <LanguageStats
                    languages={languages}
                    dashboardData={dashboardData}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  dashboardData: makeSelectDashboard(),
  loading: makeSelectLoading(),
  inductionLoading: makeSelectInductionLoading(),
  languages: makeSelectLanguages(),
  regions: makeSelectRegions(),
  weeklyActivityData: makeSelectWeeklyActivity(),
  weeklyLoading: makeSelectWeeklyLoading(),
  inductions: makeSelectInduction(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getDashboard: (val) => dispatch(getDashboardRequest(val)),
    getLanguages: (val) => dispatch(getLanguagesRequest(val)),
    getRegions: (val) => dispatch(getRegionRequest(val)),
    getWeekly: (val) => dispatch(getWeeklyActivity(val)),
    getInduction: (val) => dispatch(getInductionRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
