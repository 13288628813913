import React from "react";
import classes from "./itemCheckbox.module.scss";
import CorrectIcon from "assets/icons/correct.svg";
import { FormattedMessage } from "react-intl";

import Lottie from "react-lottie";

import CameraIcon from "../../../pages/Camera/camera.png"
import CameraIconGreen from "../../../pages/Camera/camera_green.png"

//animations
import CheckAnimation from "assets/animations/Check-small-v3.json";

const ItemCheckBox = (props) => {
  return (
    <div className={classes.ItemCheckboxWrapper}>
      <div onClick={props.onClick} className={classes.ItemCard}>
        <span className={classes.ItemText}>
          <FormattedMessage id={props.formatID} defaultMessage={props.title} />
        </span>
        {props?.type =="document" && (
          <div>
          <div className={classes.ItemIcon}>
            <img src={props.checked? CameraIconGreen : CameraIcon} />
          </div>
          {props?.checked && (
            <div className={classes.ItemDocumentUploadedText}>
                Document Uploaded
            </div>
          )}
          </div>
        )}
        <div className={classes.Checkbox} onClick={props.onClick}>
          {props.checked && (
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: CheckAnimation,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                  progressiveLoad: true,
                },
              }}
              width={"100%"}
              isClickToPauseDisabled={true}
              isPaused={false}
              title={"animation"}
              ariaLabel={"animation"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemCheckBox;
