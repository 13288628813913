/* INDUCTION GET */
export const GET_INDUCTION_REQUEST = "INDUCTION/GET_INDUCTION_REQUEST";
export const GET_INDUCTION_REQUEST_SUCCESS =
  "INDUCTION/GET_INDUCTION_REQUEST_SUCCESS";
export const GET_INDUCTION_REQUEST_ERROR =
  "INDUCTION/GET_INDUCTION_REQUEST_ERROR";

/* INDUCTION DETAIL GET */
export const GET_INDUCTION_DETAIL_REQUEST =
  "INDUCTION/GET_INDUCTION_DETAIL_REQUEST";
export const GET_INDUCTION_DETAIL_REQUEST_SUCCESS =
  "INDUCTION/GET_INDUCTION_DETAIL_REQUEST_SUCCESS";
export const GET_INDUCTION_DETAIL_REQUEST_ERROR =
  "INDUCTION/GET_INDUCTION_DETAIL_REQUEST_ERROR";

/* SET_SELECTED_SITE */
export const SET_SELECTED_SITE = "INDUCTION/SET_SELECTED_SITE";
export const SET_SELECTED_SITE_SUCCESS = "INDUCTION/SET_SELECTED_SITE_SUCCESS";
export const SET_SELECTED_SITE_ERROR = "INDUCTION/SET_SELECTED_SITE_ERROR";

/* SET_CURRENT_SECTION */
export const SET_CURRENT_RULE = "INDUCTION/SET_CURRENT_RULE";
export const SET_CURRENT_RULE_SUCCESS = "INDUCTION/SET_CURRENT_RULE_SUCCESS";
export const SET_CURRENT_RULE_ERROR = "INDUCTION/SET_CURRENT_RULE_ERROR";

/* SET_FOOTER_VISIBILITY */
export const SET_FOOTER_VISIBILITY = "INDUCTION/SET_FOOTER_VISIBILITY";
export const SET_FOOTER_VISIBILITY_SUCCESS =
  "INDUCTION/SET_FOOTER_VISIBILITY_SUCCESS";
export const SET_FOOTER_VISIBILITY_ERROR =
  "INDUCTION/SET_FOOTER_VISIBILITY_ERROR";

//SET_SECTION_STEP
export const SET_SECTION = "INDUCTION/SET_SECTION";
export const SET_SECTION_SUCCESS = "INDUCTION/SET_SECTION_SUCCESS";

//SET SECTION
export const SET_SECTION_STEP = "INDUCTION/SET_SECTION_STEP";
export const SET_SECTION_STEP_SUCCESS = "INDUCTION/SET_SECTION_STEP_SUCCESS";

//SET CHAPTER COMPLETION
export const SET_CHAPTER_COMPLETION = "INDUCTION/SET_CHAPTER_COMPLETION";
export const SET_CHAPTER_COMPLETION_SUCCESS =
  "INDUCTION/SET_CHAPTER_COMPLETION_SUCCESS";


/* DOCUMENT CAPTURE */
export const DOCUMENT_CAPTURE = "INDUCTION/DOCUMENT_CAPTURE";
export const DOCUMENT_CAPTURE_SUCCESS = "INDUCTION/DOCUMENT_CAPTURE_SUCCESS";
export const DOCUMENT_CAPTURE_ERROR = "INDUCTION/DOCUMENT_CAPTURE_ERROR";  
