import React,{ useState } from "react";
import Input from "../../../../../components/Supervisor/input";
import classes from "../editor.module.scss";

const Prequalification = ({page,updatePageData}) => {


  const [questions, setQuestions] = useState(page?.data.questions);

  const updateQuestions = (item, key,value) => {
    var updatedQuestions = [...questions];
    updatedQuestions[item] = {...questions[item]};
    updatedQuestions[item][key] = value;
    setQuestions(updatedQuestions);

    updatePageData({key:'questions',value:questions});
}


  return <div>

         <br />
         <h4 className={classes.formHeading}>Compliance Cards</h4>

         {questions.map((item,index) =>
          
          <div className={classes.inputWrapper}>

            <div className={classes.qualificationCard}>Card {index+1}</div>
            
            <div className={classes.qualificationCardRadio}>
                 <div className={classes.qualificationCardRadioInput}><input type="radio" name={"ack_"+index} disabled checked={item?.type == 'acknowledgement' ? true : false} /></div>
                <div className={classes.qualificationCardRadioLabel}>Acknowledgement</div>
            </div>
            <div className={classes.qualificationCardRadio}>
            <div className={classes.qualificationCardRadioInput}><input type="radio" name={"ack_"+index} disabled checked={item?.type == 'document' ? true : false} /></div>
                <div className={classes.qualificationCardRadioLabel}>Document Capture Required</div>
            </div>
            

            <Input
              hasTooltip={true}
              order="column"
              value={questions[index].name}
              name={"Question_" + (index + 1)}
              characterLimit={50}
              onChange={(e) => {
                updateQuestions(index,'name',e.target.value);
              }}
              label={"Card Text"}
              placeholder={"Question " + (index + 1)}
              error={""}
              tooltipText={
              "Question " + (index + 1)
            }
            />

            {item?.type == 'document' && (
              <div>
                <hr />
                <h5>Document Capture Screen</h5>
                <p>This instructional screen appears when a user taps on the card</p>

                <Input
                  hasTooltip={true}
                  order="column"
                  value={item?.capture_title}
                  name={"Heading Text"}
                  characterLimit={50}
                  onChange={(e) => {
                    updateQuestions(index,'capture_title',e.target.value);
                  }}
                  label={"Heading Text"}
                  placeholder={"Heading Text"}
                  error={""}
                  tooltipText={
                    "Heading text, also known as the page name"
                  }
                />
                
                <Input
                  hasTooltip={true}
                  order="column"
                  value={item?.capture_description}
                  name={"Supporting Text"}
                  characterLimit={150}
                  onChange={(e) => {
                    updateQuestions(index,'capture_description',e.target.value);
                  }}
                  label={"Supporting Text"}
                  placeholder={"Supporting Text"}
                  error={""}
                  tooltipText={
                    "Supporting text, appears underneath the heading"
                  }
                />

                <Input
                  hasTooltip={true}
                  order="column"
                  value={item?.capture_subtitle}
                  name={"Document saving period"}
                  characterLimit={150}
                  onChange={(e) => {
                    updateQuestions(index,'capture_subtitle',e.target.value);
                  }}
                  label={"Document saving period"}
                  placeholder={"Document saving period"}
                  error={""}
                  tooltipText={
                    "Info about document data retention period"
                  }
                />

                <Input
                  hasTooltip={true}
                  order="column"
                  value={item?.capture_punchline}
                  name={"Punchline"}
                  characterLimit={150}
                  onChange={(e) => {
                    updateQuestions(index,'capture_punchline',e.target.value);
                  }}
                  label={"Punchline"}
                  placeholder={"Punchline"}
                  error={""}
                  tooltipText={
                    "Punchline"
                  }
                />

                <hr />
                <h5>Document Capture Fallback Screen</h5>
                <p>This occurs when user denies access to the device camera or when the camera is unavailable.</p>

                <Input
                  hasTooltip={true}
                  order="column"
                  value={item?.capture_failed_title}
                  name={"Heading Text"}
                  characterLimit={50}
                  onChange={(e) => {
                    updateQuestions(index,'capture_failed_title',e.target.value);
                  }}
                  label={"Heading Text"}
                  placeholder={"Heading Text"}
                  error={""}
                  tooltipText={
                    "Heading text, also known as the page name"
                  }
                />
                
                <Input
                  hasTooltip={true}
                  order="column"
                  value={item?.capture_failed_description}
                  name={"Supporting Text"}
                  characterLimit={150}
                  onChange={(e) => {
                    updateQuestions(index,'capture_failed_description',e.target.value);
                  }}
                  label={"Supporting Text"}
                  placeholder={"Supporting Text"}
                  error={""}
                  tooltipText={
                    "Supporting text, appears underneath the heading"
                  }
                />

                <Input
                  hasTooltip={true}
                  order="column"
                  value={item?.capture_failed_subtitle}
                  name={"Suggestion text"}
                  characterLimit={150}
                  onChange={(e) => {
                    updateQuestions(index,'capture_failed_subtitle',e.target.value);
                  }}
                  label={"Suggestion text"}
                  placeholder={"Suggestion text"}
                  error={""}
                  tooltipText={
                    "Suggestion on what should be done next"
                  }
                />



              </div>
            )}

          </div>


         )}


  </div>;
};

export default Prequalification;