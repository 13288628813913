import React, { useEffect, useMemo, useRef, useState } from "react";
import { useFormik } from "formik";
import classes from "./editArea.module.scss";

import {
  uploadHazardImageReq,
  resetHazardImageReq,
} from "store/supervisor/actions";
import {
  makeSelectHazards,
  makeSelectHazardImage,
  makeSelectLoading,
  makeSelectHazardImageLoading,
  makeSelectHazardImageError,
  makeSelectHazardImageSuccess,
} from "store/supervisor/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

//components
import Input from "components/Supervisor/input";
import TextArea from "components/Supervisor/textArea";
import validationSchema from "./validationSchema";
import MultiCheckbox from "components/Supervisor/multiCheckbox";
import ImportanceSelect from "../components/importanceSelect";
import Switch from "../components/switch";
import DateRangePicker from "../components/datePicker";
import IconSelector from "../components/iconSelector";

//icons
import CloseIcon from "../icons/close.svg";

import cn from "classnames";
import { size, includes } from "lodash";
import IconComponent from "components/UI/IconComponent";
import ImageUpload from "../components/imageUpload";
import DragIcon from "../icons/drag.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getListStyle } from "../utils";
import Checkbox from "components/Supervisor/checkbox";
import { ClickOutside } from "utils/helper";
import { isNil, isEmpty } from "lodash";

import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import Icon from "components/UI/IconComponent";

const siteCheckList = [
  {
    label: "All sites",
    value: 1,
    checked: false,
  },
  {
    label: "Specific sites",
    value: 0,
    checked: true,
  },
];

const HazardEdit = ({
  selectedHazard,
  setSelectedHazard,
  uploadHazardImage,
  hazardImage,
  resetHazardImage,
  siteOptions,
  totalSites,
  setGlobalChangeCount,
  hazardImageLoading,
  hazardImageError,
  hazardImageSuccess,
  isEditBoxOpen,
  setEditBoxOpen,
}) => {
  const formik = useFormik({
    initialValues: {
      dragId: selectedHazard?.dragId,
      name: selectedHazard?.name ? selectedHazard.name : "",
      message: selectedHazard?.message ? selectedHazard.message : "",
      template_id: "",
      site_list: selectedHazard?.site_list ? selectedHazard.site_list : "",
      icon: selectedHazard?.icon ? selectedHazard.icon : "",
      is_active: selectedHazard?.is_active ? selectedHazard.is_active : 0,
      level: selectedHazard?.level ? selectedHazard.level : 0,
      image_url: hazardImage?.url
        ? hazardImage?.url
        : selectedHazard?.image_url
        ? selectedHazard?.image_url
        : "",
      start_date: selectedHazard?.start_date ? selectedHazard?.start_date : "",
      end_date: selectedHazard?.end_date ? selectedHazard?.end_date : "",
      is_for_all_sites: selectedHazard?.is_for_all_sites
        ? selectedHazard.is_for_all_sites
        : selectedHazard.is_for_all_sites === 0
        ? 0
        : 1,
    },
    validationSchema: validationSchema,

    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {},
    enableReinitialize: true,
  });

  const { values, setFieldValue, errors } = formik;

  useEffect(() => {
    resetHazardImage();
  }, []);

  const [changeCount, setChangeCount] = useState(0);

  const [checkedSites, setCheckedSites] = useState([]);

  const [isSiteActionOpen, setSiteActionOpen] = useState(false);

  const [siteSearch, setSiteSearch] = useState("");
  const [specificSiteSearch, setSpecificSiteSearch] = useState("");

  const siteRef = useRef(null);
  const descRef = useRef(null);

  useMemo(() => {
    if (changeCount > 0) {
      setSelectedHazard({ ...selectedHazard, ...values });
      setGlobalChangeCount((val) => val + 1);
    }
  }, [changeCount]);

  useEffect(() => {
    if (hazardImage?.url) {
      setFieldValue("image_id", hazardImage?.id);

      setChangeCount((val) => val + 1);
    }
  }, [hazardImage]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (
      result.source.droppableId === "droppable-1" &&
      result.destination.droppableId === "droppable-2"
    ) {
      let newArray = [...values.site_list];

      const item = siteOptions?.filter(
        (item) => item.value?.toString() === result.draggableId
      );

      newArray.push(item[0]);

      setFieldValue("site_list", newArray);
      setChangeCount((val) => val + 1);
    }

    if (
      result.source.droppableId === "droppable-2" &&
      result.destination.droppableId === "droppable-1"
    ) {
      let newArray = [...values.site_list];
      let newCheckedArray = [
        ...checkedSites?.filter(
          (item) => item?.value?.toString() !== result.draggableId
        ),
      ];

      setFieldValue(
        "site_list",
        newArray.filter(
          (item) => item?.value?.toString() !== result.draggableId
        )
      );
      setCheckedSites(newCheckedArray);
    }
  };

  return (
    <div
      className={cn(
        classes.container,
        !selectedHazard && classes.hidden,
        isNil(isEditBoxOpen) && classes.hide
      )}
    >
      {/* main title */}
      <div className={classes.mainTitleWrapper}>
        <div />
        <span className={classes.mainTitle}>{values.name}</span>
        <img
          alt="close"
          onClick={() => {
            setSelectedHazard(false);
            setEditBoxOpen(false);
          }}
          src={CloseIcon}
        />
      </div>
      {/* form information */}
      <div className={classes.infoWrapper}>
        <div
          className={classes.infoElement}
          tabIndex={"0"}
          onClick={() => {
            window.scrollTo({ top: 50, behavior: "smooth" });
          }}
        >
          <div className={classes.iconWrapper}>
            <Icon
              className={classes.icon}
              icon={
                values?.name?.length > 0 && values?.message?.length > 0
                  ? "tick"
                  : "close"
              }
              color={
                values?.name?.length > 0 && values?.message?.length > 0
                  ? "#44C032"
                  : "#E26C82"
              }
              size="15px"
            />
          </div>
          {/* <img src={TickIcon} alt="tick icon" /> */}
          <span>Hazard detail</span>
        </div>

        <div className={classes.miniDivider} />
        <div
          className={classes.infoElement}
          tabIndex={"1"}
          onClick={() => {
            const siteInit = siteRef?.current;
            if (siteInit) {
              siteInit?.scrollIntoView({ behavior: "smooth" });
            }
          }}
        >
          <div className={classes.iconWrapper}>
            <Icon
              className={classes.icon}
              icon={
                values?.is_for_all_sites === 0 &&
                values?.site_list?.length === 0
                  ? "close"
                  : "tick"
              }
              color={
                values?.is_for_all_sites === 0 &&
                values?.site_list?.length === 0
                  ? "#E26C82"
                  : "#44C032"
              }
              size="15px"
            />
          </div>
          <span>Sites</span>
          <div className={classes.siteCount}>
            <span>
              {values.is_for_all_sites === 1
                ? totalSites
                : size(values?.site_list)}
            </span>
          </div>
        </div>
      </div>

      {/* form area */}

      <form>
        <div className={classes.formAreaContainer}>
          {/* title */}
          <div className={classes.titleWrapper}>
            <span ref={descRef}>Hazard description</span>
          </div>
          <div
            className={cn(
              classes.characterizationFormArea,
              values.level === 1 && classes.borderRed
            )}
          >
            <ImportanceSelect
              value={values.level}
              onChange={(val) => {
                setFieldValue("level", val);
                setChangeCount((val) => val + 1);
              }}
            />
            <ImageUpload
              isSuccess={hazardImageSuccess}
              isUploading={hazardImageLoading}
              isError={hazardImageError}
              onDelete={() => {
                setFieldValue("image_url", null);
                setChangeCount((val) => val + 1);
              }}
              imageUrl={values.image_url}
              onLoad={(event) => {
                if (event.target.files[0]) {
                  uploadHazardImage({
                    image: event.target.files[0],
                  });
                }
              }}
            />
            <div className="flex-row">
              <IconSelector
                tooltipText="Select one of the beautifully designed icons to better represent hazard context."
                value={values.icon}
                onChange={(val) => {
                  setFieldValue("icon", val);
                  setChangeCount((val) => val + 1);
                }}
              />
              <div className="flex-col">
                <Input
                  hasTooltip={true}
                  order="column"
                  value={values.name}
                  name={"name"}
                  characterLimit={50}
                  onChange={(e) => {
                    setFieldValue("name", e.target.value);
                    setChangeCount((val) => val + 1);
                  }}
                  label={"Name"}
                  placeholder={"Name"}
                  error={errors && errors["name"]}
                  tooltipText={
                    "Simple and short terminology is the preferred approach before more complex and harder to understand keywords. Also, be mindful about your company naming conventions."
                  }
                />
                <TextArea
                  hasTooltip={true}
                  order="column"
                  name={"message"}
                  value={values.message}
                  onChange={(e) => {
                    setFieldValue("message", e.target.value);
                    setChangeCount((val) => val + 1);
                  }}
                  characterLimit={250}
                  label={"Characterization"}
                  placeholder={"Characterization"}
                  error={
                    errors &&
                    errors["template_id"] &&
                    errors &&
                    errors["message"]
                  }
                />
              </div>
            </div>
            <div className={classes.informationWrapper}>
              <div className={classes.siteCountWrapper}>
                <span>Sites:</span>
                <div className={classes.siteCount2}>
                  {values.is_for_all_sites === 1
                    ? totalSites
                    : size(values?.site_list)}
                </div>
              </div>
              <DateRangePicker
                tooltipText="Optionally, you can limit hazard visibility by selecting exact date or date range."
                startDateValue={
                  values.start_date ? new Date(values.start_date) : null
                }
                endDateValue={
                  values.end_date ? new Date(values.end_date) : null
                }
                onChange={(val) => {
                  if (val) {
                    if (val?.startDate) {
                      setFieldValue("start_date", val.startDate);
                      setChangeCount((val) => val + 1);
                    }
                    if (val?.endDate) {
                      setFieldValue("end_date", val.endDate);
                      setChangeCount((val) => val + 1);
                    }
                  }
                }}
              />
              <Switch
                tooltipText="An enabled hazard is always shown within your listing and its content is available to the end user. The disabled hazard is is not visible to the end user."
                onChange={(val) => {
                  setFieldValue("is_active", values.is_active === 1 ? 0 : 1);
                  setChangeCount((val) => val + 1);
                }}
                checked={values.is_active === 1 ? true : false}
              />
            </div>
          </div>
          <div className={classes.titleWrapper} ref={siteRef}>
            <span>Sites</span>
            <div className={classes.siteCountTitle}>
              <span>
                {values.is_for_all_sites === 1
                  ? totalSites
                  : size(values?.site_list)}
              </span>
            </div>
            <a className={classes.siteTooltip} data-tooltip-id={"help"}>
              <IconComponent
                icon="help_outline"
                size="25px"
                color="#6FCF97"
                className={classes.tooltipIcon}
              />
            </a>

            <Tooltip
              className="react-tooltip-keepsafe"
              id="help"
              place="left"
              type="dark"
              effect="solid"
            >
              Select at least one or multiple sites from the list in the left
              column. Selected sites will receive a notification upon check-in.
              You can drag and drop the desired site to the right column.
            </Tooltip>
          </div>
          <div className={classes.checkboxWrapper}>
            <MultiCheckbox
              items={siteCheckList}
              onChange={(val) => {
                setFieldValue("is_for_all_sites", val);
                setChangeCount((val) => val + 1);
              }}
              value={values.is_for_all_sites}
              name="is_for_all_sites"
            />
          </div>
          {values.is_for_all_sites === 0 && (
            <DragDropContext onDragEnd={(results) => onDragEnd(results)}>
              <div className={classes.siteDragWrapper}>
                <div className={classes.siteSelectionWrapper}>
                  <div className={classes.infoElement}>
                    <span>Sites</span>
                    <div className={classes.siteCount}>
                      <span>{size(siteOptions)}</span>
                    </div>
                  </div>

                  <Droppable droppableId={"droppable-1"} isDroppable={false}>
                    {(provided, snapshot) => (
                      <div
                        className={classes.siteDragBox}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        <div className={classes.siteSearchInputWrapper}>
                          <div className={classes.searchIcon}>
                            <IconComponent icon="search" color="white" />{" "}
                          </div>
                          <input
                            placeholder="Search"
                            value={siteSearch}
                            onChange={(e) => setSiteSearch(e.target.value)}
                          />
                        </div>
                        {siteOptions
                          .filter((i) =>
                            i.label
                              .toLowerCase()
                              .includes(siteSearch.toLowerCase())
                          )
                          .filter(
                            (item) =>
                              !includes(
                                values?.site_list?.map((x) => x.value),
                                item.value
                              )
                          )
                          .map((item, index) => (
                            <Draggable
                              key={item?.value}
                              draggableId={item?.value?.toString()}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  className={classes.siteDragItem}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className={classes.dragBox}>
                                    <img
                                      alt="drag"
                                      src={DragIcon}
                                      className={classes.DragIcon}
                                    />
                                  </div>
                                  <div>
                                    <span>{item.label}</span>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                      </div>
                    )}
                  </Droppable>
                </div>
                <div className={classes.siteSelectionWrapper}>
                  <div className={classes.infoElement}>
                    <span>Specific Sites</span>
                    <div className={classes.siteCount}>
                      <span>{size(values?.site_list)}</span>
                    </div>
                  </div>
                  <Droppable droppableId={"droppable-2"}>
                    {(provided, snapshot) => (
                      <div
                        className={classes.siteDragBox}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {isEmpty(values.site_list) ? (
                          <div className={classes.emptyDragDropContainer}>
                            <IconComponent
                              icon="drag"
                              color="#BB6BD9"
                              size={"64px"}
                            />
                            <span>
                              Use <b>drag</b>-and-<b>drop</b> method to add your
                              first site that will be informed about the
                              required hazard. 
                            </span>
                          </div>
                        ) : (
                          <React.Fragment>
                            <div className={classes.siteSearchInputWrapper}>
                              <div className={classes.searchIcon}>
                                <IconComponent icon="search" color="white" />{" "}
                              </div>
                              <input
                                placeholder="Search"
                                value={specificSiteSearch}
                                onChange={(e) =>
                                  setSpecificSiteSearch(e.target.value)
                                }
                              />
                            </div>
                            <div className={classes.controlSection}>
                              <div className={classes.dragBox}>
                                <Checkbox
                                  bigger={true}
                                  onClick={() => {
                                    if (
                                      checkedSites?.length !== 0 &&
                                      checkedSites?.length ===
                                        values?.site_list?.length
                                    ) {
                                      setCheckedSites([]);
                                    } else {
                                      setCheckedSites(values?.site_list);
                                    }
                                  }}
                                  checked={
                                    checkedSites?.length ===
                                    values?.site_list?.length
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                              <div className={classes.actionBox}>
                                <div
                                  className={classes.actionButton}
                                  onClick={() =>
                                    setSiteActionOpen(!isSiteActionOpen)
                                  }
                                >
                                  <IconComponent
                                    icon="action"
                                    size={25}
                                    color="#6FCF97"
                                  />
                                </div>
                                {isSiteActionOpen && (
                                  <ClickOutside
                                    onClick={() => {
                                      if (isSiteActionOpen) {
                                        setSiteActionOpen(false);
                                      }
                                    }}
                                  >
                                    <div
                                      className={classes.popOver}
                                      onClick={() => {
                                        if (checkedSites?.length > 0) {
                                          const specificList = values.site_list?.filter(
                                            (liItem) =>
                                              !includes(
                                                checkedSites.map(
                                                  (x) => x.value
                                                ),
                                                liItem.value
                                              )
                                          );
                                          setCheckedSites([]);
                                          setFieldValue(
                                            "site_list",
                                            specificList
                                          );
                                          setChangeCount((val) => val + 1);
                                          setSiteActionOpen(false);
                                        }
                                      }}
                                    >
                                      <IconComponent
                                        icon="close"
                                        size={20}
                                        color="#e26c82"
                                      />
                                      <span> Remove all sites</span>
                                    </div>
                                  </ClickOutside>
                                )}
                              </div>
                            </div>

                            {values?.site_list
                              .filter((i) =>
                                i.label
                                  .toLowerCase()
                                  .includes(specificSiteSearch.toLowerCase())
                              )
                              .map((item, index) => (
                                <Draggable
                                  key={item.value}
                                  draggableId={item.value.toString()}
                                  index={index}
                                  isDraggable={false}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      className={classes.siteDragItem}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <div className={classes.dragBox}>
                                        <Checkbox
                                          bigger={true}
                                          onClick={() => {
                                            const isChecked = checkedSites.findIndex(
                                              (a) => a.value === item.value
                                            );

                                            if (isChecked >= 0) {
                                              let list = [...checkedSites];
                                              list.splice(isChecked, 1);
                                              setCheckedSites(list);
                                            } else {
                                              let list = [...checkedSites];

                                              list.push(item);
                                              setCheckedSites(list);
                                            }
                                          }}
                                          checked={
                                            checkedSites.find(
                                              (a) => a.value === item.value
                                            )
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                      <div>
                                        <span>{item.label}</span>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                          </React.Fragment>
                        )}
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>
            </DragDropContext>
          )}
        </div>
      </form>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  hazards: makeSelectHazards(),
  loading: makeSelectLoading(),
  hazardImage: makeSelectHazardImage(),
  hazardImageLoading: makeSelectHazardImageLoading(),
  hazardImageSuccess: makeSelectHazardImageSuccess(),
  hazardImageError: makeSelectHazardImageError(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    uploadHazardImage: (val) => dispatch(uploadHazardImageReq(val)),
    resetHazardImage: (val) => dispatch(resetHazardImageReq(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HazardEdit);
