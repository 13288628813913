// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  DELETE_REGION_REQUEST,
  DELETE_REGION_REQUEST_ERROR,
  DELETE_REGION_REQUEST_SUCCESS,
} from "../../constants";
import axios from "../../../../services/api";
import { push } from "connected-react-router";
import { success } from "../../../../main";

function deleteReq(data) {
  return axios.delete("/regions/", { data: { id: data.id } });
}

function* deleteRegion(data) {
  try {
    const response = yield call(deleteReq, data);
    yield put({
      type: DELETE_REGION_REQUEST_SUCCESS,
      data: response,
    });

    yield put(push("/supervisor-settings-branch"));
    yield call(success, `Site Deleted Succesfully`);
    if (data.callback) {
      data.callback();
    }
    if (data.end != null) {
      data.end.callback();
    }
  } catch (error) {
    console.log({ error });
    yield put({
      type: DELETE_REGION_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* deleteRegionSaga() {
  yield takeLatest(DELETE_REGION_REQUEST, deleteRegion);
}
