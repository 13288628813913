import React from "react";
import classes from "./people.module.scss";

import Icon from "components/UI/IconComponent";
import StatusIndicator from "./statusIndicator";
import RecentActivity from "./recentActivity";
import { isNil, isEmpty } from "lodash";

export const peopleListTableData = (item) => {
  const data = item?.map((a) => {
    return {
      id: a.id,
      email: a.email,
      values: [
        {
          id: a.id,
          raw: item,
          value: `${a.first_name} `,

          component: (
            <div className={classes.imageWrapper}>
              <Icon
                icon={
                  a?.persona?.persona === "Contractor" ? "contractor" : "user"
                }
                size={a?.persona?.persona === "Contractor" ? "24px" : "16px"}
                color={"#999999"}
              />
              <div
                className={classes.statusIndicator}
                style={{
                  background:
                    !isEmpty(a.checkins) &&
                    isEmpty(a.checkins[0].checkout) &&
                    !isEmpty(a.checkins[0].checkin) &&
                    "#5ECA8B",
                }}
              />
            </div>
          ),
        },
        {
          value: `${a.first_name} `,

          component: (
            <div className={classes.personaCellWrapper}>
              <span className={classes.persona}>
                {a.first_name} <b>{a.last_name}</b>
              </span>
              {a?.email && (
                <span className={classes.role}>
                  <i>{a?.email}</i>
                </span>
              )}
            </div>
          ),
        },
        {
          value: a?.persona?.persona ? a.persona.persona : "-",
          component: (
            <div className={classes.personaCellWrapper}>
              <span className={classes.persona}>
                {a?.persona?.persona ? a.persona.persona : "-"}
              </span>
              {a?.persona?.role && (
                <span className={classes.role}>
                  <i>{a?.persona?.role}</i>
                </span>
              )}
            </div>
          ),
        },
        {
          component: (
            <div className={classes.companyCell}>
              <span className={classes.companyText}>
                {a?.userextra_set[0]?.company
                  ? a?.userextra_set[0]?.company
                  : "-"}
              </span>
            </div>
          ),
        },
        {
          component: <StatusIndicator data={a} />,
          justifyContent: "flex-end",
        },
        {
          component: <RecentActivity data={a.checkins} />,
        },
      ],
    };
  });

  const labels = [
    { label: "", sortable: true, key: "image", width: "50px !important" },
    { label: "Name", sortable: true, key: "name" },
    { label: "Group", sortable: true, key: "group" },
    { label: "Company", sortable: true, key: "company" },
    { label: "Status", sortable: true, key: "status" },
    { label: "Recent Activity", width: "220px !important" },
  ];

  return {
    columns: data,
    labels: labels,
  };
};
