import React, { useState } from "react";
import classes from "./existingHazard.module.scss";
import { existingHazards } from "../../lib";
import IconComponent from "components/UI/IconComponent";
import cn from "classnames";
import { isNil } from "lodash";

const ExistingHazardsSelector = ({
  title,
  onSelect,
  isEditBoxOpen,
  isAnimationActive,
  isExistingAnimationActive,
}) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  return (
    <div
      className={cn(
        classes.existingHazardsContainer,
        isEditBoxOpen && !isAnimationActive && classes.hidden,
        isExistingAnimationActive && classes.slideInEx,
        isEditBoxOpen && isAnimationActive && classes.slideOutEx
      )}
    >
      <div className={classes.existHazardTitleWrapper}>
        <span>Add existing hazard</span>
      </div>
      <div>
        {existingHazards?.map((item) => (
          <div
            className={classes.existHazardBox}
            key={item.name}
            onClick={() => onSelect(item)}
          >
            <div className={classes.iconWrapper}>
              <IconComponent size="70%" icon={item.icon} color={"#6FCF97"} />
            </div>
            <span>{item?.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
export default ExistingHazardsSelector;
