// @flow
import { put, call, takeLatest, delay } from "redux-saga/effects";
import {
  DELETE_SITE,
  DELETE_SITE_SUCCESS,
  DELETE_SITE_ERROR,
} from "../../constants";
import axios from "../../../../services/api";
import { push } from "connected-react-router";
import { success } from "../../../../main";

const deleteReq = async (data) => {
  return await axios.delete("/sites/", { data: { id: data.id } });
};

function* deleteSite(data) {
  try {
    const response = yield call(deleteReq, data);
    yield put({
      type: DELETE_SITE_SUCCESS,
      data: response,
    });

    yield call(success, `Site Deleted Succesfully`);
    yield call(data.callback);

    if (data.end != null) {
      data.end.callback();
    }
  } catch (error) {
    console.log({ error });
    yield put({
      type: DELETE_SITE_ERROR,
      data: error,
    });
  }
}

export default function* deleteSiteSaga() {
  yield takeLatest(DELETE_SITE, deleteSite);
}
