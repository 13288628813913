// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_REGION_REQUEST,
  GET_REGION_REQUEST_ERROR,
  GET_REGION_REQUEST_SUCCESS,
} from "../../constants";
import axios from "../../../../services/api";
import { isNil } from "lodash";

function* getRegionsRequest(data) {
  try {
    const params = new URLSearchParams();
    if (!isNil(data?.search)) {
      params.append("search", data.search);
    }
    if (!isNil(data?.page)) {
      params.append("page", data.page);
    }

    if (!isNil(data?.id)) {
      params.append("id", data.id);
    }

    if (!isNil(data?.induction)) {
      params.append("induction", data.induction);
    }

    const response = yield call(axios.get, "/regions/", { params });

    if (response?.data?.error) {
      yield put({
        type: GET_REGION_REQUEST_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: GET_REGION_REQUEST_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_REGION_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* getRegionsRequestSaga() {
  yield takeLatest(GET_REGION_REQUEST, getRegionsRequest);
}
