import React from "react";
import classes from "./multi.module.scss";
import Tick from "./tick.svg";
import cn from "classnames";
const MultiCheckbox = ({ items, onChange, value }) => {
  return (
    <div className={classes.MultiCheckbox}>
      {items.map((item) => (
        <button
          className={classes.checkbox}
          onClick={() => onChange(item.value)}
          type="button"
          key={item.label}
        >
          <div
            className={cn(
              classes.circle,
              value === item.value && classes.checked
            )}
          >
            <img alt={"checked"} src={Tick} />
          </div>
          <span>{item.label}</span>
        </button>
      ))}
    </div>
  );
};

export default MultiCheckbox;
