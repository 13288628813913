import React from "react";
import classes from "./button.module.scss";

const Button = ({ text, icon, color, onClick, width }) => {
  return (
    <button
      onClick={onClick}
      style={{
        color: color && color,
        border: color && `1px solid ${color}`,
        width: width && width,
      }}
      className={color ? classes.deleteContainer : classes.buttonContainer}
    >
      <div className={classes.wrapper}>
        <div style={{ display: "flex" }}>{icon()}</div>
        <span>{text}</span>
      </div>
    </button>
  );
};

export default Button;
