import React, { useState } from "react";
import classes from "./iconSelector.module.scss";
import { ClickOutside } from "utils/helper";
import Icon from "components/UI/IconComponent";
import cn from "classnames";
import { Tooltip } from "react-tooltip";

const iconList = [
  { icon: "ImageAndText", name: "Fall protection" },
  { icon: "FlipCards", name: "harmful substances" },
  { icon: "Carousel-Block", name: "Scaffold protection" },
  { icon: "CardSort", name: "Speed limit" },
];

const IconSelector = ({ value, onChange, tooltipText }) => {
  const [selectedIcon, setSelectedIcon] = useState(false);
  const [isSelectorOpen, setSelectorOpen] = useState(false);
  return (
    <ClickOutside onClick={() => setSelectorOpen(false)}>
      <div className={cn(classes.selector)} data-tooltip-id="iconSelector">
        <label className={classes.label}>Icon</label>
        <div
          className={cn(
            classes.selectContainer,
            isSelectorOpen && classes.borderGreen
          )}
          onClick={() => setSelectorOpen((val) => !val)}
        >
          <div className={classes.iconWrapper}>
            {value ? (
              <>
                <div className={classes.selectedIcon}>
                  <Icon icon={value} size={"80%"} color={"#6FCF97"} />
                </div>
                <span>
                  {iconList.find((item) => item.icon === value)?.name}
                </span>
              </>
            ) : (
              <>
                <Icon icon={"select-icon"} size={30} color={"#BB6BD9"} />
                <span>Select Icon</span>
              </>
            )}
          </div>
          <div
            className={cn(
              classes.selectButton,
              isSelectorOpen && classes.darkGreen
            )}
          >
            <div
              className={cn(
                isSelectorOpen && classes.rotate180,
                classes.selectIconWrapper
              )}
            >
              <Icon
                onClick={() => {
                  setSelectorOpen((val) => !val);
                }}
                icon={"arrow-up"}
                size={"100%"}
                color={isSelectorOpen ? "#ffffff" : "#6FCF97"}
              />
            </div>
          </div>
        </div>
        {tooltipText && (
          <Tooltip
            className="react-tooltip-keepsafe"
            id="iconSelector"
            place="top"
            type="dark"
            effect="solid"
          >
            <span>{tooltipText}</span>
          </Tooltip>
        )}
        {isSelectorOpen && (
          <div className={classes.selectorBox}>
            {iconList.map((item) => (
              <div
                className={cn(
                  classes.selectableIconWrapper,
                  value === item.icon && classes.selected
                )}
                onClick={() => {
                  setSelectorOpen(false);
                  onChange(item.icon);
                }}
              >
                <div className={classes.selectableIcon}>
                  <Icon icon={item.icon} size={"80%"} color={"#6FCF97"} />
                </div>
                <span>{item.name}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </ClickOutside>
  );
};

export default IconSelector;
