export const SET_SECTION_TIME_REQUEST = "TIME/SET_SECTION_TIME_REQUEST";
export const SET_SECTION_TIME_REQUEST_SUCCESS =
  "TIME/SET_SECTION_TIME_REQUEST_SUCCESS";
export const SET_SECTION_TIME_REQUEST_ERROR =
  "TIME/SET_SECTION_TIME_REQUEST_ERROR";

export const GET_SECTION_TIME_REQUEST = "TIME/GET_SECTION_TIME_REQUEST";
export const GET_SECTION_TIME_REQUEST_SUCCESS =
  "TIME/GET_SECTION_TIME_REQUEST_SUCCESS";
export const GET_SECTION_TIME_REQUEST_ERROR =
  "TIME/GET_SECTION_TIME_REQUEST_ERROR";

export const SET_COUNT_TIME_REQUEST = "TIME/SET_COUNT_TIME_REQUEST";
export const SET_COUNT_TIME_REQUEST_SUCCESS =
  "TIME/SET_COUNT_TIME_REQUEST_SUCCESS";
export const SET_COUNT_TIME_REQUEST_ERROR = "TIME/SET_COUNT_TIME_REQUEST_ERROR";

export const SET_CURRENT_SECTION_REQUEST = "TIME/SET_CURRENT_SECTION_REQUEST";
export const SET_CURRENT_SECTION_REQUEST_SUCCESS =
  "TIME/SET_CURRENT_SECTION_REQUEST_SUCCESS";
export const SET_CURRENT_SECTION_REQUEST_ERROR =
  "TIME/SET_CURRENT_SECTION_REQUEST_ERROR";
