import React from "react";
import Input from "../../../../../components/Supervisor/input";
import classes from "../editor.module.scss";

const AllChaptersCompleteForm = ({page,updatePageData}) => {


  return <div>
        
        <br />
        <h4 className={classes.formHeading}>Chapter Completion Informative Text</h4>
        <Input
            hasTooltip={true}
            order="column"
            value={page?.data?.content}
            name={"Content"}
            characterLimit={120}
            onChange={(e) => {
                updatePageData({key:'content',value:e.target.value});
            }}
            label={"Content"}
            placeholder={"Content here"}
            error={""}
            tooltipText={
            "This content appears after the heading."
            }
        />

        <Input
            hasTooltip={true}
            order="column"
            value={page?.data?.subcontent}
            name={"Sub content"}
            characterLimit={50}
            onChange={(e) => {
                updatePageData({key:'subcontent',value:e.target.value});
            }}
            label={"Sub content"}
            placeholder={"Sub content here"}
            error={""}
            tooltipText={
            "This text appears after the content. Usually used as a punchline."
            }
        />


  </div>;
};

export default AllChaptersCompleteForm;