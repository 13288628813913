import React, { useState, useEffect } from "react";
import cn from "classnames";
import { ClickOutside } from "utils/helper";

import classes from "./dateRange.module.scss";
import { isNil } from "lodash";
import ArrowIcon from "./arrow.svg";
import { DateRangePicker } from "react-date-range";
import { format, addDays } from "date-fns";

const formatStartDate = (val) => (!isNil(val) ? format(val, `LLL dd`) : "-");

const formatEndDate = (val) =>
  !isNil(val) ? format(val, `E dd LLL yyyy`) : "-";

const DateRangeFilter = ({ onChange, disabled, value }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: !isNil(value?.startdate)
        ? new Date(value.startdate * 1000)
        : new Date(),
      endDate: !isNil(value?.enddate)
        ? new Date(value.enddate * 1000)
        : addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  useEffect(() => {
    if (!isNil(dateRange)) {
      onChange(dateRange);
    }
  }, [dateRange]);

  return (
    <ClickOutside onClick={() => setIsDropdownOpen(false)}>
      <div className={cn(classes.selectbox)}>
        <button
          className={cn(classes.selectbox_head)}
          onClick={() => !disabled && setIsDropdownOpen(!isDropdownOpen)}
        >
          <div className={classes.wrapper}>
            <span className={classes.title}>Date Range</span>
            <div className={classes.thisMonth}>
              <span>
                {formatStartDate(dateRange[0].startDate)} -{" "}
                {formatEndDate(dateRange[0].endDate)}
              </span>
            </div>
            <img
              alt={"Arrow Icon"}
              src={ArrowIcon}
              className={cn(classes.icon, isDropdownOpen && classes.rotate)}
            />
          </div>
        </button>
        <div className={cn(classes.dropdown, isDropdownOpen && classes.open)}>
          <DateRangePicker
            value={value}
            className={classes.DateRangePicker}
            onChange={(item) => setDateRange([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={dateRange}
            direction="horizontal"
          />
        </div>
      </div>
    </ClickOutside>
  );
};

export default DateRangeFilter;
