import { isNil } from "lodash";
import { formatDate, formatTime, secondToHours } from "utils/helper";

const labels = [
  { label: "Branch", sortable: true, key: "branch" },
  { label: "Site", sortable: true, key: "site" },
  { label: "Date", sortable: true, key: "date" },
  { label: "Check in" },
  { label: "Check out" },
  { label: "Total hours per day" },
];

export const reportingTableData = (item, branches) => {
  const data = item?.map((a) => {
    return {
      id: a.id,
      email: a.email,

      values: [
        {
          value: a.title
            ? !isNil(
                branches?.results?.find((item) => item.id === a?.site?.region)
                  ?.code
              )
              ? branches?.results?.find((item) => item.id === a?.site?.region)
                  ?.code
              : "-"
            : "",
        },
        {
          value: a.title ? (!isNil(a?.site?.name) ? a.site.name : "-") : " ",
        },
        {
          value: !isNil(a?.checkin) ? formatDate(a.checkin) : "-",
        },
        {
          value: !isNil(a?.checkin) ? formatTime(a.checkin) : "-",
        },
        {
          value: !isNil(a?.checkout) ? formatTime(a.checkout) : "-",
        },
        {
          value: !isNil(a?.total_seconds)
            ? secondToHours(a.total_seconds)
            : "-",
        },
      ],
    };
  });

  return {
    columns: data,
    labels: labels,
  };
};
