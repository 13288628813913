import React, { useState } from "react";

import classes from "./weekly.module.scss";
import { isNaN } from "lodash";
import ReactLoading from "react-loading";

const WeeklyActivity = ({ dashboardData, loading }) => {
  const topStats = [
    {
      title: "Check-in",
      count: parseInt(dashboardData?.weeklystats?.total?.checkin),
      color: "#007BFF",
    },
    {
      title: "Completed",
      count: parseInt(dashboardData?.weeklystats?.total?.completed),
      color: "#03CE91",
    },
    {
      title: "Registered",
      count: parseInt(dashboardData?.weeklystats?.total?.registered),
      color: "#BB6BD9",
    },
  ];

  const totalCheckIn = dashboardData?.weeklystats?.total?.checkin;
  const totalCompleted = dashboardData?.weeklystats?.total?.completed;
  const totalRegistered = dashboardData?.weeklystats?.total?.registered;

  const [hoverIndex, setHoverIndex] = useState(null);

  const percentage = (val, total) => {
    return (val / total) * 100;
  };

  const numberShow = (number) => {
    return number < 10 ? `00${number}` : number < 100 ? `0${number}` : number;
  };

  const weekly = [
    {
      day: "Monday",
      progress: [
        percentage(dashboardData?.weeklystats?.monday?.checkin, totalCheckIn),
        percentage(
          dashboardData?.weeklystats?.monday?.completed,
          totalCompleted
        ),
        percentage(
          dashboardData?.weeklystats?.monday?.registered,
          totalRegistered
        ),
      ],
      raw: [
        dashboardData?.weeklystats?.monday?.checkin,
        dashboardData?.weeklystats?.monday?.completed,
        dashboardData?.weeklystats?.monday?.registered,
      ],
    },
    {
      day: "Tuesday",
      progress: [
        percentage(dashboardData?.weeklystats?.tuesday?.checkin, totalCheckIn),
        percentage(
          dashboardData?.weeklystats?.tuesday?.completed,
          totalCompleted
        ),
        percentage(
          dashboardData?.weeklystats?.tuesday?.registered,
          totalRegistered
        ),
      ],
      raw: [
        dashboardData?.weeklystats?.tuesday?.checkin,
        dashboardData?.weeklystats?.tuesday?.completed,
        dashboardData?.weeklystats?.tuesday?.registered,
      ],
    },
    {
      day: "Wednesday",
      progress: [
        percentage(
          dashboardData?.weeklystats?.wednesday?.checkin,
          totalCheckIn
        ),
        percentage(
          dashboardData?.weeklystats?.wednesday?.completed,
          totalCompleted
        ),
        percentage(
          dashboardData?.weeklystats?.wednesday?.registered,
          totalRegistered
        ),
      ],
      raw: [
        dashboardData?.weeklystats?.wednesday?.checkin,
        dashboardData?.weeklystats?.wednesday?.completed,
        dashboardData?.weeklystats?.wednesday?.registered,
      ],
    },
    {
      day: "Thursday",
      progress: [
        percentage(dashboardData?.weeklystats?.thursday?.checkin, totalCheckIn),
        percentage(
          dashboardData?.weeklystats?.thursday?.completed,
          totalCompleted
        ),
        percentage(
          dashboardData?.weeklystats?.thursday?.registered,
          totalRegistered
        ),
      ],
      raw: [
        dashboardData?.weeklystats?.thursday?.checkin,
        dashboardData?.weeklystats?.thursday?.completed,
        dashboardData?.weeklystats?.thursday?.registered,
      ],
    },
    {
      day: "Friday",
      progress: [
        percentage(dashboardData?.weeklystats?.friday?.checkin, totalCheckIn),
        percentage(
          dashboardData?.weeklystats?.friday?.completed,
          totalCompleted
        ),
        percentage(
          dashboardData?.weeklystats?.friday?.registered,
          totalRegistered
        ),
      ],
      raw: [
        dashboardData?.weeklystats?.friday?.checkin,
        dashboardData?.weeklystats?.friday?.completed,
        dashboardData?.weeklystats?.friday?.registered,
      ],
    },
    {
      day: "Saturday",
      progress: [
        percentage(dashboardData?.weeklystats?.saturday?.checkin, totalCheckIn),
        percentage(
          dashboardData?.weeklystats?.saturday?.completed,
          totalCompleted
        ),
        percentage(
          dashboardData?.weeklystats?.saturday?.registered,
          totalRegistered
        ),
      ],
      raw: [
        dashboardData?.weeklystats?.saturday?.checkin,
        dashboardData?.weeklystats?.saturday?.completed,
        dashboardData?.weeklystats?.saturday?.registered,
      ],
    },
    {
      day: "Sunday",
      progress: [
        percentage(dashboardData?.weeklystats?.sunday?.checkin, totalCheckIn),
        percentage(
          dashboardData?.weeklystats?.sunday?.completed,
          totalCompleted
        ),
        percentage(
          dashboardData?.weeklystats?.sunday?.registered,
          totalRegistered
        ),
      ],
      raw: [
        dashboardData?.weeklystats?.sunday?.checkin,
        dashboardData?.weeklystats?.sunday?.completed,
        dashboardData?.weeklystats?.sunday?.registered,
      ],
    },
  ];
  return (
    <div className={classes.dashboardSection2}>
      {loading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "400px",
            }}
          >
            <ReactLoading
              type={"spin"}
              color={"#20a8d8"}
              height={100}
              width={100}
            />
          </div>
        </>
      ) : (
        <>
          <div className={classes.topStats}>
            {topStats.map((item, index) => (
              <div className={classes.section}>
                <div
                  className={
                    index === 0 ? classes.firstDivider : classes.divider
                  }
                  style={{ background: item.color }}
                />
                <div className={classes.countSection}>
                  <span className={classes.count}>{item.count}</span>
                  <span className={classes.title}>{item.title}</span>
                </div>
              </div>
            ))}
          </div>
          <div className={classes.weeklyStats}>
            {weekly.map((item, i) => (
              <div className={classes.dayWrapper}>
                <span className={classes.day}>{item.day}</span>

                <div
                  className={classes.progressContainer}
                  onMouseEnter={() => setHoverIndex({ i: i })}
                  onMouseLeave={() => setHoverIndex(null)}
                >
                  {hoverIndex?.i === i && (
                    <div className={classes.hover}>
                      <div className={classes.checkinText}>
                        <span>Checkin</span>
                        <span>
                          {numberShow(
                            isNaN(item.progress[0]) ? 0 : parseInt(item.raw[0])
                          )}
                        </span>
                      </div>
                      <div className={classes.completedText}>
                        <span>Completed</span>
                        <span>
                          {numberShow(
                            isNaN(item.progress[1]) ? 0 : parseInt(item.raw[1])
                          )}
                        </span>
                      </div>
                      <div className={classes.registeredText}>
                        <span>Registered</span>
                        <span>
                          {numberShow(
                            isNaN(item.progress[2]) ? 0 : parseInt(item.raw[2])
                          )}
                        </span>
                      </div>
                      <div className={classes.arrowDown} />
                    </div>
                  )}
                  {item.progress.map((percentage, index) => (
                    <div className={classes.progressWrapper}>
                      <div
                        className={classes.progress}
                        style={{
                          width: `${percentage}%`,
                          background:
                            index === 0
                              ? topStats[0].color
                              : index === 1
                              ? topStats[1].color
                              : topStats[2].color,
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default WeeklyActivity;
