// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_SITE_REQUEST_SUCCESS,
  GET_SITE_REQUEST_ERROR,
  GET_SITE_REQUEST,
} from "../../constants";
import axios from "../../../../services/api";
import { isNil } from "lodash";

function* getSitesRequest(data) {
  try {
    const params = new URLSearchParams();

    if (data.ordering) {
      params.append("ordering", data.ordering);
    }
    if (data.search) {
      params.append("search", data.search);
    }
    if (data.page) {
      params.append("page", data.page);
    }
    if (data.id) {
      params.append("id", data.id);
    }

    const response = yield call(axios.get, `/sites/`, { params });
    if (response?.data?.error) {
      yield put({
        type: GET_SITE_REQUEST_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: GET_SITE_REQUEST_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_SITE_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* getSitesRequestSaga() {
  yield takeLatest(GET_SITE_REQUEST, getSitesRequest);
}
