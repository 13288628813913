import produce, { enableES5 } from "immer";
import {
  CREATE_LANGUAGE_REQUEST,
  CREATE_LANGUAGE_REQUEST_ERROR,
  CREATE_LANGUAGE_REQUEST_SUCCESS,
  GET_LANGUAGE_REQUEST,
  GET_LANGUAGE_REQUEST_ERROR,
  GET_LANGUAGE_REQUEST_SUCCESS,
  DELETE_LANGUAGE_REQUEST,
  DELETE_LANGUAGE_REQUEST_SUCCESS,
  DELETE_LANGUAGE_REQUEST_ERROR,
  SET_SELECTED_LANGUAGE_SUCCESS,
} from "./constants";

enableES5();

export const initialState = {
  contractorLanguages: null,
  languages: null,
  selectedLanguage: false,
  loading: false,
  error: null,
  uploading: false,
};

const LanguageReducer = (state = initialState, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case CREATE_LANGUAGE_REQUEST:
        draftState.uploading = true;
        draftState.error = null;
        break;
      case CREATE_LANGUAGE_REQUEST_SUCCESS:
        draftState.uploading = false;
        draftState.error = null;
        break;
      case CREATE_LANGUAGE_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case DELETE_LANGUAGE_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case DELETE_LANGUAGE_REQUEST_SUCCESS:
        draftState.loading = false;
        break;
      case DELETE_LANGUAGE_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case GET_LANGUAGE_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case GET_LANGUAGE_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.error = null;
        draftState.languages = action.data;
        break;
      case GET_LANGUAGE_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case SET_SELECTED_LANGUAGE_SUCCESS:
        draftState.selectedLanguage = action.data;
        break;
      default:
        break;
    }
  });
};

export default LanguageReducer;
