import React from "react";

const DownloadIcon = () => {
  return (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66634 4H6.99967V0H2.99967V4H0.333008L4.99967 8.66667L9.66634 4ZM0.333008 10V11.3333H9.66634V10H0.333008Z"
        fill="#7185BE"
      />
    </svg>
  );
};

export default DownloadIcon;
