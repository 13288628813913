import React from "react";

const ArrowIcon = ({ onClick, style }) => {
  return (
    <svg
      style={style}
      onClick={onClick}
      width="6"
      height="8"
      viewBox="0 0 6 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66656 0L0.726562 0.94L3.7799 4L0.726562 7.06L1.66656 8L5.66656 4L1.66656 0Z"
        fill="#323232"
      />
    </svg>
  );
};

export default ArrowIcon;
