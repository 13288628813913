// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  SITE_INDUCTION_REQUEST,
  SITE_INDUCTION_REQUEST_SUCCESS,
  SITE_INDUCTION_REQUEST_ERROR,
} from "../constants";
import axios from "../../../services/api";
import { push } from "connected-react-router";
import { isNil } from "lodash";

function* checkIn(data) {
  try {
    const values = data.payload;
    const params = new URLSearchParams();
    params.append("site", values.site);
    params.append("sitemanager", values.siteManager);

    const access_token = window.localStorage.getItem("access_token");

    delete axios.defaults.headers.common["X-Token"];
    delete axios.defaults.headers.common["X-Access-Token"];

    if (!isNil(values.token) && isNil(access_token)) {
      axios.defaults.headers.common["X-Token"] = values.token;
    } else if (!isNil(access_token)) {
      axios.defaults.headers.common["X-Token"] = access_token;
    } else if (isNil(values.token) && isNil(access_token)) {
      yield put(
        push(
          `/language-selection?site=${values.site}&sitemanager=${values.siteManager}&redirect=site-induction`
        )
      );
    }

    if (values.token) {
      delete axios.defaults.headers.common["X-Token"];
      axios.defaults.headers.common["X-Token"] = values.token;
    } else {
      delete axios.defaults.headers.common["X-Token"];
      axios.defaults.headers.common["X-Token"] = window.localStorage.getItem(
        "access_token"
      );
    }

    const response = yield call(axios.post, "/site-induction/", params);

    if (response.data.success === 1) {
      yield put({
        type: SITE_INDUCTION_REQUEST_SUCCESS,
        data: {
          siteID: values.site,
          siteManager: values.siteManager,
          ...response.data,
        },
      });
    } else if (response.data.success === 0) {
      yield put({
        type: SITE_INDUCTION_REQUEST_ERROR,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: SITE_INDUCTION_REQUEST_ERROR,
      data: null,
    });
  }
}

export default function* checkInSaga() {
  yield takeLatest(SITE_INDUCTION_REQUEST, checkIn);
}
