// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  POST_LOGIN_REQUEST,
  POST_LOGIN_REQUEST_SUCCESS,
  POST_LOGIN_REQUEST_ERROR,
  POST_LOGIN_REQUEST_FAIL,
} from "../constants";
import { push } from "connected-react-router";
import { isNil } from "lodash";

function* postLoginRequest(data) {
  try {
    const values = data.payload;

    if (values.items.length === 0) {
      const response = { data: { hascards: true } };

      if (response.data.error) {
        yield put({
          type: POST_LOGIN_REQUEST_ERROR,
          data: response.error,
        });
      } else {
        yield put({
          type: POST_LOGIN_REQUEST_SUCCESS,
          data: response.data,
        });

        values.callBack();
      }
    } else {
      yield put({
        type: POST_LOGIN_REQUEST_FAIL,
        data: values.items,
      });
      if (!isNil(values.site)) {
        yield put(push(`/post-login-failure?site=${values.site}`));
      } else {
        yield put(push("/post-login-failure"));
      }
    }
  } catch (error) {
    yield put({
      type: POST_LOGIN_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* postLoginRequestSaga() {
  yield takeLatest(POST_LOGIN_REQUEST, postLoginRequest);
}
