import React from "react";
import classes from "./section.module.scss";
import { FormattedMessage } from "react-intl";

import miniCheckIcon from "../../../assets/icons/miniCheck.svg";
import IconComponent from "components/UI/IconComponent";
import { isNil } from "lodash";

const ChapterCompletion = ({ data, ruleSet, index }) => {
  return (
    <div>
      <div className={classes.chapterCompletionWrapper}>
        <span className={classes.chapterCompletionTitle}>
          <FormattedMessage
            id={"chapter.completion.title"}
            defaultMessage={"Chapter {number}:"}
            values={{
              number: index + 1,
            }}
          />
          <br /> <b>{data?.title?.split(":")[1]}</b>
        </span>
        {data.iconType && (
          <div className={classes.chapterAnimationWrapper}>
            <div className={classes.chapterCompletionTickWrapper}>
              <img src={miniCheckIcon} alt={"mini check icon"} />
            </div>
            <div
              className={classes.chapterCompletionIconBackground}
              style={{
                background: data?.iconBackground
                  ? data?.iconBackground
                  : "green",
              }}
            >
              {!isNil(data.iconType) ? (
                <IconComponent
                  icon={data?.iconType}
                  size={"100%"}
                  color={data?.iconColor ? data?.iconColor : "white"}
                />
              ) : (
                <img src={data?.icon} alt={`${data?.iconType}`} />
              )}
            </div>
          </div>
        )}
        <span className={classes.chapterCompletionComplete}>
          <FormattedMessage
            id={"chapter.completion.complete"}
            defaultMessage={"Complete"}
          />
        </span>
        <span className={classes.chapterCompletionProgressText}>
          <FormattedMessage
            id={"chapter.completion.progress.count"}
            defaultMessage={"Your Progress {currentProgress} / {totalProgress}"}
            values={{
              currentProgress: index + 1,
              totalProgress: ruleSet?.length,
            }}
          />
        </span>
        <div className={classes.chapterIconProgressWrapper}>
          {ruleSet?.map((item, i) => (
            <div className={classes.chapterIconsOuterWrapper}>
              <div
                className={classes.chapterIcons}
                style={{
                  background:
                    index >= i
                      ? item?.iconBackground
                        ? item?.iconBackground
                        : "green"
                      : "#ccc",
                }}
              >
                <IconComponent
                  icon={item.iconType}
                  size={"80%"}
                  color={
                    index >= i
                      ? item.iconColor
                        ? item.iconColor
                        : "white"
                      : "#28353C"
                  }
                />
                {/* <img src={item.icon} className={classes.blackChapterIcon} /> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChapterCompletion;
