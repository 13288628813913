import React from "react";
import classes from "./button.module.scss";
import ReactLoading from "react-loading";
import { FormattedMessage } from "react-intl";
import RightIcon from "./right-icon.svg";
import LeftIcon from "./left-icon.svg";

const Button = (props) => {
  return (
    <button
      disabled={props.disabled}
      onClick={props.onClick}
      id={props.id}
      type={props.type}
      style={{
        height: props.height && props.height,
        borderRadius: props.borderRadius && props.borderRadius,
      }}
      className={
        props.theme === "yellow"
          ? classes.YellowButton
          : props.theme === "blue"
          ? classes.BlueButton
          : props.theme === "light"
          ? classes.LightButton
          : classes.Button
      }
    >
      {props.loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactLoading
            type={"spin"}
            color={props.textColor ? props.textColor : "white"}
            height={30}
            width={30}
          />
        </div>
      ) : (
        <>
          {props.type === "back" || props.type === "next" ? (
            <>
              <div
                className={classes.backButton}
                style={{
                  flexDirection: props.type === "back" && "row-reverse",
                }}
              >
                <div
                  className={classes.textContainer}
                  style={{
                    alignItems: props.type === "back" && "flex-end",
                  }}
                >
                  <span
                    style={{ color: props.textColor ? props.textColor : "" }}
                    className={classes.Text}
                  >
                    <FormattedMessage
                      id={props.formatID ? props.formatID : "default"}
                      defaultMessage={props.text}
                    />
                  </span>
                  {/* <span className={classes.SubText}>
                    {props?.subText?.length > 15
                      ? `${props?.subText?.slice(0, 15)}...`
                      : props?.subText}
                  </span> */}
                </div>
                <img
                  alt={"direction icon"}
                  src={props.type === "back" ? RightIcon : LeftIcon}
                />
              </div>
            </>
          ) : (
            <div className={classes.TextWrapper}>
              <span
                style={{ color: props.textColor ? props.textColor : "" }}
                className={classes.Text}
              >
                <FormattedMessage
                  id={props.formatID ? props.formatID : "default"}
                  defaultMessage={props.text}
                />
              </span>
              {(props.icon || props.type) && (
                <>
                  {props.icon ? (
                    <img
                      className={classes.ButtonIcon}
                      src={props.icon}
                      alt="Restart Icon"
                    />
                  ) : (
                    <img
                      className={classes.ButtonIcon}
                      alt={"direction icon"}
                      src={props.type === "back" ? RightIcon : LeftIcon}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}
    </button>
  );
};

export default Button;
