import React from "react";
import { CFooter } from "@coreui/react";

const TheFooter = () => {
  const disabled =
    window.location.pathname === "/supervisor-settings/users" ||
    "/supervisor-settings/roles";

  return (
    <>
      {!disabled && (
        <CFooter style={{ zIndex: "99" }} fixed={true}>
          <div>
            <a
              style={{ paddingLeft: "10px" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              &copy; 2021 Immersive Solutions Limited | All Rights Reserved.
            </a>
            <span className="ml-1"></span>
          </div>
          <div className="mfs-auto"></div>
        </CFooter>
      )}
    </>
  );
};

export default React.memo(TheFooter);
