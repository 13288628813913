import { useEffect, useState, useRef } from "react";
import {
  setProgressRequest,
  getProgressRequest,
} from "../store/progress/actions";

import {
  makeSelectProgress,
  makeSelectLoading,
} from "../store/progress/selector";

import { makeSelectSelectedInduction } from "../store/induction/selector";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { isNil } from "lodash";
import { withRouter } from "react-router-dom";

const ProgressCalculator = ({
  setProgress,
  getProgress,
  selectedInduction,
  progressData,
  history,
  type,
}) => {
  useEffect(() => {
    if (isNil(selectedInduction) && type !== "supervisor") {
      history.push("/site-selector");
    }

    if (selectedInduction) {
      const id = selectedInduction?.id;
      getProgress({
        induction_id: id,
      });
    }
  }, [selectedInduction]);

  return null;
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  progressData: makeSelectProgress(),
  selectedInduction: makeSelectSelectedInduction(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getProgress: (val) => dispatch(getProgressRequest(val)),
    setProgress: (val) => dispatch(setProgressRequest(val)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProgressCalculator)
);
