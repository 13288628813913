import Example from "./icons/example.png";

export const pages = [
  {
    title: "Hazards",
    active: true,
    pathName: "/supervisor-hazards-update",
    path: "update",
  },
  {
    title: "Settings",
    active: true,
    pathName: "/supervisor-hazards-settings",
    path: "settings",
  },
];

export const existingHazards = [
  {
    dragId: "f56",
    name: "Fall Protection",
    icon: "Carousel-Block",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur laoreet turpis, ut semper risus varius a.",
    created: "2023-06-16T07:36:23.343040Z",
    color: "#E26C82",
    site_list: [],
  },
  {
    dragId: "s4",
    name: "Speed Limit",
    icon: "CardSort",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur laoreet turpis, ut semper risus varius a.",
    created: "2023-06-16T07:36:23.343040Z",
    color: "#E26C82",
    site_list: [],
  },
  {
    dragId: "s6",
    name: "Scaffold",
    icon: "Hotspots",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur laoreet turpis, ut semper risus varius a.",
    created: "2023-06-16T07:36:23.343040Z",
    color: "#E26C82",
    site_list: [],
  },
  {
    dragId: "s7",
    name: "Harmful Substances",
    icon: "FlipCards",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur laoreet turpis, ut semper risus varius a.",
    created: "2023-06-16T07:36:23.343040Z",
    color: "#E26C82",
    site_list: [],
  },
];

export const hazardsListMock = [
  {
    dragId: 1,
    name: "Test Hazard",
    message: "There is a chemical leak on site",
    created: "2023-06-16T07:36:23.343040Z",
    icon: "FlipCards",
    is_active: true,
    is_for_all_sites: true,
    start_date: "2023-06-16T07:36:20Z",
    end_date: "2023-06-16T07:36:22Z",
    site_list: [],
    color: "#E26C82",
  },
  {
    dragId: 2,
    name: "Test Hazard",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur laoreet turpis, ut semper risus varius a.",
    created: "2023-06-16T07:36:23.343040Z",
    icon: "",
    is_active: true,
    is_for_all_sites: true,
    start_date: "2023-06-16T07:36:20Z",
    end_date: "2023-06-16T07:36:22Z",
    site_list: [],
    color: "#FFC200",
  },
  {
    dragId: 3,
    name: "Test Hazard",
    message:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi efficitur laoreet turpis, ut semper risus varius a.",
    created: "2023-06-16T07:36:23.343040Z",
    icon: "Hotspots",
    is_active: true,
    is_for_all_sites: true,
    start_date: "2023-06-16T07:36:20Z",
    end_date: "2023-06-16T07:36:22Z",
    site_list: [],
    image: Example,
    color: "#BB6BD9",
  },
];
