// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  SET_CHAPTER_COMPLETION,
  SET_CHAPTER_COMPLETION_SUCCESS,
} from "../constants";

function* setChapterCompletion(data) {
  yield put({
    type: SET_CHAPTER_COMPLETION_SUCCESS,
    data: data.value,
  });
}

export default function* setChapterCompletionSaga() {
  yield takeLatest(SET_CHAPTER_COMPLETION, setChapterCompletion);
}
