import React, { useState, useEffect } from "react";
import Checkbox from "../checkbox";
import { FormattedMessage } from "react-intl";
import {
  CardContainer,
  TitleWrapper,
  Title,
  ProgressBar,
  Progress,
  CorrectBox,
  Button,
  IconWrapper,
  IconContainer,
  ProgressWrapper,
  ProgressDescriptionWrapper,
  ProgressText,
  LockIcon,
  IconImage,
} from "./styles";

import Lottie from "react-lottie";
import { InView } from "react-intersection-observer";
import IconComponent from "components/UI/IconComponent";

import CardSort from "assets/animations/cardSort.json";
import Carousel from "assets/animations/carousel.json";
import FlipCard from "assets/animations/Flip_cards.json";
import Hotspots from "assets/animations/hotspots.json";
import ImageText from "assets/animations/image_and_text.json";

import { isNil } from "lodash";

const Card = ({
  onClick,
  color,
  image,
  completed,
  progress,
  title,
  titleId,
  iconType,
  iconColor,
  isLocked,
  background,
  progressColor,
  progressEmptyColor,
  textColor,
  ctaColor,
  ctaBorder,
  icon,
  iconBackground,
  border,
  ctaTextColor,
  ...props
}) => {
  console.log("iconType", iconType);
  return (
    <CardContainer
      onClick={onClick}
      color={!isNil(background) ? background : color}
      image={image}
      isLocked={isLocked}
      borderColor={border}
    >
      <LockIcon>
        <IconComponent
          icon={!isLocked ? "lock" : "unlock"}
          color={textColor ? textColor : "white"}
        />
      </LockIcon>

      <IconWrapper color={!isNil(iconBackground) ? iconBackground : color}>
        {!isNil(iconType) ? (
          <IconComponent
            icon={iconType}
            color={iconColor ? iconColor : "white"}
            size={"80%"}
          />
        ) : (
          <IconImage src={icon} />
        )}
      </IconWrapper>

      <TitleWrapper>
        <Title textColor={textColor}>
          <FormattedMessage id={titleId} defaultMessage={title} />
        </Title>
      </TitleWrapper>
      {isLocked && (
        <>
          <Button
            borderColor={ctaBorder?.color ? ctaBorder.color : "#ffffff"}
            background={ctaColor}
            textColor={ctaTextColor ? ctaTextColor : textColor}
          >
            {progress === "100%" ? (
              <FormattedMessage
                id={"start-again.button"}
                defaultMessage={"Start Again"}
              />
            ) : progress !== "0%" ? (
              <FormattedMessage
                id={"continue.button"}
                defaultMessage={"Continue"}
              />
            ) : (
              <FormattedMessage id={"start.button"} defaultMessage={"Start"} />
            )}

            <IconContainer
              width={progress === "100%" ? "11px" : "5px"}
              height={progress === "100%" ? "11px" : "8px"}
            >
              <IconComponent
                icon={
                  progress === "100%"
                    ? "start-again"
                    : progress !== "0%"
                    ? "left-arrow"
                    : "left-arrow"
                }
                color={ctaTextColor ? ctaTextColor : "white"}
                size={"100%"}
              />
            </IconContainer>
          </Button>
        </>
      )}
      {isLocked && (
        <ProgressWrapper>
          <ProgressDescriptionWrapper>
            <ProgressText textColor={textColor}>
              <FormattedMessage
                id={"progress.chapter.completion"}
                defaultMessage={"Chapter Completion"}
              />
            </ProgressText>
            <ProgressText textColor={textColor}>{progress}</ProgressText>
          </ProgressDescriptionWrapper>
          <ProgressBar color={progressEmptyColor}>
            <Progress
              color={progressColor ? progressColor : "#ff4b00"}
              progress={progress}
            />
          </ProgressBar>
        </ProgressWrapper>
      )}
      <CorrectBox>{completed && <Checkbox checked={true} />}</CorrectBox>
    </CardContainer>
  );
};

export default Card;
