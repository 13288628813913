import React from "react";
import Form from "./Form";
import QrCode from "./QrCode";
import Contractors from "./Contractors";

export const pages = [
  {
    title: "Site Settings",
    active: true,
    component: <Form />,
    pathName: "/supervisor-sites-detail",
    path: "detail",
  },
  {
    title: "QR Code",
    active: false,
    component: <QrCode />,
    pathName: "/supervisor-sites-qr",
    path: "qr",
  },
  {
    title: "Contractors",
    active: false,
    component: <Contractors />,
    pathName: "/supervisor-sites-contractors",
    path: "contractors",
  },
];

export const sites = {
  count: 20,
  items_per_page: 10,
  results: [
    { siteId: 1, locationId: 2, contractors: "00", valid: "00", renewal: "00" },
    { siteId: 3, locationId: 2, contractors: "00", valid: "00", renewal: "00" },
    { siteId: 5, locationId: 2, contractors: "00", valid: "00", renewal: "00" },
    { siteId: 4, locationId: 2, contractors: "00", valid: "00", renewal: "00" },
    { siteId: 1, locationId: 2, contractors: "00", valid: "00", renewal: "00" },
    { siteId: 3, locationId: 2, contractors: "00", valid: "00", renewal: "00" },
    { siteId: 1, locationId: 2, contractors: "00", valid: "00", renewal: "00" },
    { siteId: 3, locationId: 2, contractors: "00", valid: "00", renewal: "00" },
    { siteId: 5, locationId: 2, contractors: "00", valid: "00", renewal: "00" },
    { siteId: 4, locationId: 2, contractors: "00", valid: "00", renewal: "00" },
    { siteId: 1, locationId: 2, contractors: "00", valid: "00", renewal: "00" },
    { siteId: 3, locationId: 2, contractors: "00", valid: "00", renewal: "00" },
  ],
};

export const contractorList = {
  count: 20,
  items_per_page: 10,
  results: [
    {
      id: 0,
      firstName: "Can",
      lastName: "Goktas",
      company: "Immersive Solutions",
      status: "00",
      renewal: "00 Days, 00/00/0000",
      onSite: "00",
    },
  ],
};
