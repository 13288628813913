// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  SUPERVISOR_LOGIN_REQUEST_ERROR,
  SUPERVISOR_LOGIN_REQUEST_SUCCESS,
  SUPERVISOR_LOGIN_REQUEST,
} from "../constants";
import { push } from "connected-react-router";
import { isNil } from "lodash";

function* supervisorloginRequest(data) {
  try {
    yield put({
      type: SUPERVISOR_LOGIN_REQUEST_SUCCESS,
      data: "logged_in",
    });

    if (!isNil(data?.site)) {
      yield call([localStorage, localStorage.removeItem], "redirect");
      yield call([localStorage, localStorage.removeItem], "site");

      yield put(push(`/check-in?site=${data.site}`));
    } else {
      yield put(push("/supervisor-dashboard"));
    }
  } catch (error) {
    yield put({
      type: SUPERVISOR_LOGIN_REQUEST_ERROR,
      data: error.message,
    });
  }
}

export default function* supervisorloginRequestSaga() {
  yield takeLatest(SUPERVISOR_LOGIN_REQUEST, supervisorloginRequest);
}
