import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectLanguage = (state) => state.language || initialState;

const makeSelectError = () =>
  createSelector(selectLanguage, (state) => state.error);

const makeSelectLoading = () =>
  createSelector(selectLanguage, (state) => state.loading);

const makeSelectLanguages = () =>
  createSelector(selectLanguage, (state) => state.languages);

const makeSelectContractorLanguages = () =>
  createSelector(selectLanguage, (state) => state.contractorLanguages);

const makeSelectSelectedLanguage = () =>
  createSelector(selectLanguage, (state) => state.selectedLanguage);

export {
  selectLanguage,
  makeSelectError,
  makeSelectLoading,
  makeSelectLanguages,
  makeSelectContractorLanguages,
  makeSelectSelectedLanguage,
};
