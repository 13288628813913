import React, { useEffect, useState } from "react";
import classes from "./notifications.module.scss";
import { getEmergencyNotificationsReq } from "store/supervisor/actions";
import {
  makeSelectEmergencyNotifications,
  makeSelectLoading,
} from "store/supervisor/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Table from "components/Supervisor/table";
import { isNil } from "lodash";

import Pagination from "components/Supervisor/pagination";
import { formatDateTime, notificationTypeHandler } from "utils/helper";
import { useSortableData } from "utils/hooks";

import PlusIcon from "assets/supervisorIcons/plusIcon.js";

const Notifications = ({
  notifications,
  getNotification,
  loading,
  history,
}) => {
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchState, setSearchState] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  const [PAGE_COUNT, setPageCount] = useState(null);
  const [rawList, setRawList] = useState([]);

  useEffect(() => {
    getNotification({ page: 1, search: "", ordering: "-id" });
  }, []);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      if (event.keyCode === 13) {
        setSelectedPage(1);
        getNotification({
          page: 1,
          search: event.target.value,
        });
      }
    }
  };

  const { requestSort, sortConfig } = useSortableData(rawList);
  const sortBy = (key) => {
    requestSort(key, (direction) =>
      getNotification({
        page: selectedPage,
        search: searchState,
        ordering: direction === "descending" ? `-${key}` : key,
      })
    );
  };

  const orderingConfig =
    sortConfig?.direction?.toString() === "descending" &&
    !isNil(sortConfig?.key)
      ? `-${sortConfig.key}`
      : !isNil(sortConfig?.key)
      ? sortConfig?.key
      : "-id";

  const rowActions = [];

  const notifTableData = (item) => {
    const data = item.map((a) => {
      return {
        id: a.id,
        values: [
          {
            value: a.user_info,
            id: a.id,
            raw: item,
          },
          {
            value: notificationTypeHandler(a.type),
          },
          {
            value: a.site_list,
          },
          {
            value: formatDateTime(a.created),
          },
          {
            value: a.message,
            hoverText: a.message,
          },
        ],
      };
    });

    const labels = [
      { label: "Sender", sortable: true, key: "user_info" },
      { label: "Type", sortable: true, key: "type" },
      { label: "Site(s)", sortable: true, key: "site_list" },
      { label: "Date", sortable: true, key: "created" },
      { label: "Message", sortable: true, key: "message" },
    ];

    return {
      columns: data,
      labels: labels,
    };
  };

  useEffect(() => {
    if (notifications) {
      setNotificationList(notifTableData(notifications.results));
      setPageCount(Number(notifications.items_per_page));
      setRawList(notifications?.results);
      const newRows = notifications.results.map((item, i) => {
        return {
          id: item.id,
          checked: false,
        };
      });
      setSelectedRows(newRows);
    }
  }, [notifications]);

  const onSearch = (e) => {
    setSearchState(e.target.value);
    if (e.target.value.length === 0) {
      getNotification({
        page: 1,
        search: "",
        ordering: orderingConfig,
      });
      setSelectedPage(1);
    }
  };

  return (
    <>
      <div className={classes.notificationContainer}>
        <div className={classes.headingWrapper}>
          <h1 className={classes.titleHead}>Notifications</h1>
        </div>

        <div className={classes.notificationContainer}>
          <Table
            width={"100%"}
            handleKeyDown={handleKeyDown}
            isSearchable={true}
            searchState={searchState}
            loading={loading}
            onSearch={(e) => onSearch(e)}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            data={notificationList}
            rowActions={rowActions}
            clickable={false}
            sortByDesc={(val) => sortBy(val)}
            sortConfig={sortConfig}
          />
        </div>
        <>
          {PAGE_COUNT && notifications && notifications.count > PAGE_COUNT && (
            <Pagination
              totalCount={notifications?.count}
              pageCount={PAGE_COUNT}
              currentPage={selectedPage}
              onChange={(val) => {
                getNotification({
                  page: val + 1,
                  search: searchState,
                  ordering: orderingConfig,
                });
                setSelectedPage(val + 1);
              }}
            />
          )}
        </>
        <div className={classes.buttonContainer}>
          <div
            style={{
              width: "75%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{ justifyContent: "flex-end" }}
              className={classes.flex}
            >
              <button
                disabled={loading}
                className={classes.darkButton}
                onClick={() => {
                  history.push("/supervisor-notifications-create");
                }}
              >
                <div className={classes.buttonWrapper}>
                  New Notification
                  <div className={classes.icon}>
                    <PlusIcon />
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  notifications: makeSelectEmergencyNotifications(),
  loading: makeSelectLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getNotification: (val) => dispatch(getEmergencyNotificationsReq(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
