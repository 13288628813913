import React from "react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

import { removeToken } from "store/auth/actions";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

const Avatar = process.env.PUBLIC_URL + "/user.svg";

const TheHeaderDropdown = ({ logout }) => {
  return (
    <CDropdown
      style={{ zIndex: "2002 !important" }}
      inNav
      className="c-header-nav-items mx-2 supervisor-logout"
      direction="down"
    >
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          <CImg
            src={Avatar}
            className="c-avatar-img supervisor-logout"
            alt="admin@bootstrapmaster.com"
          />
        </div>

        <style>{`@media print {.c-avatar{display: none;}}`}</style>
      </CDropdownToggle>
      <CDropdownMenu
        style={{ zIndex: "99999 !important" }}
        className="pt-0"
        placement="bottom-end"
      >
        <CDropdownItem
          onClick={() => {
            logout();
            window.localStorage.removeItem("AUTH_TOKEN");
            window.localStorage.removeItem("access_token");
            window.localStorage.removeItem("refresh_token");
            window.localStorage.removeItem("expiry_date");
            window.localStorage.removeItem("CODEVERIFIER");
          }}
        >
          {/* <CIcon name="cil-lock-locked" className="mfe-2" /> */}
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

const mapStateToProps = createStructuredSelector({});
const mapDispatchToProps = (dispatch) => {
  return {
    logout: (val) => dispatch(removeToken(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TheHeaderDropdown);
