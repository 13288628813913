import React from "react";
import classes from "./switch.module.scss";
import { Tooltip } from "react-tooltip";

const Switch = ({
  checked,
  label,
  onChange,
  onMouseEnter,
  onMouseLeave,
  activeTitle,
  inActiveTitle,
  width,
  tooltipText,
}) => {
  return (
    <div
      className={classes.switchContainer}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-tooltip-id={tooltipText && "switch"}
      data-tooltip-delay-show={500}
    >
      <div
        onClick={onChange}
        className={classes.switch}
        style={{
          justifyContent: !checked ? "flex-end" : "flex-start",
          background: !checked && "#e26c82",
          width: width && width,
        }}
      >
        {checked ? (
          <span className={classes.label}>
            {activeTitle ? activeTitle : "Enabled"}
          </span>
        ) : (
          <span className={classes.label1}>
            {inActiveTitle ? inActiveTitle : "Disabled"}
          </span>
        )}

        <div className={checked ? classes.active : classes.disabled} />
      </div>
      {tooltipText && (
        <Tooltip
          className="react-tooltip-keepsafe"
          id="switch"
          place="left"
          type="dark"
          effect="solid"
        >
          <span>{tooltipText}</span>
        </Tooltip>
      )}
    </div>
  );
};

export default Switch;
