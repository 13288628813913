import React from "react";
import classes from "./people.module.scss";
import { isEmpty } from "lodash";
import { formatDateTimeHourMinute } from "utils/helper";

const RecentActivity = ({ data }) => {
  return (
    <div className={classes.recentActivityWrapper}>
      {isEmpty(data) ? (
        ""
      ) : (
        <>
          <div className={classes.recentActivityTitle}>
            <span>
              <b>{data[0].checkout ? `Checked-Out: ` : "Checked-In: "}</b>
            </span>
            <span>{data[0].name}</span>
          </div>
          <span className={classes.recentActivityInfo}>
            {formatDateTimeHourMinute(
              data[0].checkout ? data[0].checkout : data[0].checkin
            )}
          </span>
        </>
      )}
    </div>
  );
};

export default RecentActivity;
