import React from "react";
import classes from "./textArea.module.scss";
import IconComponent from "components/UI/IconComponent";
import { Tooltip } from "react-tooltip";
import cn from "classnames";

const Input = (props) => {
  return (
    <>
      <div
        className={classes.Container}
        style={{
          flexDirection: props.order === "column" && "column",
          alignItems: props.order === "column" && "flex-start",
        }}
      >
        <div
          className={cn(
            classes.labelWrapper,
            props.order === "column" && "w-full"
          )}
        >
          {props.label && (
            <label
              style={{
                marginLeft: props.order === "column" && "10px",
                width: props.order === "column" && "100%",
              }}
              className={classes.BlockLabel}
            >
              {props.label}
            </label>
          )}
          {props.hasTooltip && (
            <a data-tooltip-id={props.name}>
              <IconComponent
                icon="help_outline"
                size="25px"
                color="#6FCF97"
                className={classes.tooltipIcon}
              />
            </a>
          )}
        </div>
        <div className={classes.inputContainer}>
          <textarea
            style={{
              width: props.order === "column" && "100%",
            }}
            className={`${classes.BlockInput} ${props.error && classes.error}`}
            type={props.type}
            placeholder={props.placeholder}
            onChange={props.onChange}
            value={props.value}
            maxLength={props.characterLimit}
            spellCheck="false"
            autoComplete="off"
          />
          {props.characterLimit && props.value && (
            <span className={classes.characterLimitMessage}>
              {props.characterLimit &&
                `Character limit ${props?.value?.length} of ${props?.characterLimit}`}
            </span>
          )}
          {props.error && (
            <span className={classes.errorMessage}>
              {props.error && typeof props.error === "string"
                ? props.error
                : "Please provide valid information."}
            </span>
          )}
          <Tooltip
            className="react-tooltip-keepsafe"
            id={props.name}
            place="left"
            type="dark"
            effect="solid"
          >
            <span>Pinpoint possible hazards staff will encounter on site</span>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default Input;
