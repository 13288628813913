import produce from "immer";
import {
  SET_SECTION_TIME_REQUEST,
  SET_SECTION_TIME_REQUEST_SUCCESS,
  SET_SECTION_TIME_REQUEST_ERROR,
  GET_SECTION_TIME_REQUEST,
  GET_SECTION_TIME_REQUEST_SUCCESS,
  GET_SECTION_TIME_REQUEST_ERROR,
  SET_COUNT_TIME_REQUEST,
  SET_COUNT_TIME_REQUEST_ERROR,
  SET_COUNT_TIME_REQUEST_SUCCESS,
  SET_CURRENT_SECTION_REQUEST,
  SET_CURRENT_SECTION_REQUEST_SUCCESS,
} from "./constants";

export const initialState = {
  error: null,
  loading: false,
  sectionTime: 0,
  counter: 0,
  currentSection: null,
  lastSettedTime: null,
};

const progressReducer = (state = initialState, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case SET_SECTION_TIME_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case SET_SECTION_TIME_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.lastSettedTime = action.data;
        break;
      case SET_SECTION_TIME_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case GET_SECTION_TIME_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        draftState.sectionTime = null;
        break;
      case GET_SECTION_TIME_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.sectionTime = action.data;
        draftState.counter = action.data.totalseconds;
        break;
      case GET_SECTION_TIME_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        draftState.sectionTime = 0;
        draftState.counter = 0;

        break;
      case SET_COUNT_TIME_REQUEST:
        draftState.error = null;
        break;
      case SET_COUNT_TIME_REQUEST_SUCCESS:
        draftState.counter = action.data;
        break;
      case SET_CURRENT_SECTION_REQUEST:
        draftState.loading = false;
        draftState.error = null;
        break;
      case SET_CURRENT_SECTION_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.currentSection = action.data;
        break;
      case "AUTH/REMOVE_TOKEN":
        draftState.loading = false;
        draftState.error = null;
        draftState.sectionTime = null;
        draftState.counter = 0;
        draftState.currentSection = null;
        draftState.lastSettedTime = null;

        break;
      default:
        break;
    }
  });
};

export default progressReducer;
