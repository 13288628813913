import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectSteps = (state) => state.steps || initialState;

const makeSelectStep = () =>
  createSelector(selectSteps, (state) => state.selectedStep);

const makeSelectLoading = () =>
  createSelector(selectSteps, (state) => state.loading);

const makeSelectError = () =>
  createSelector(selectSteps, (state) => state.error);

export { selectSteps, makeSelectLoading, makeSelectError, makeSelectStep };
