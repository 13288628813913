// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_ERROR,
  UPDATE_ROLE,
} from "../../constants";
import axios from "../../../../services/api";
import { push } from "connected-react-router";
import { success } from "../../../../main";

function* updateRoleRequest(data) {
  try {
    const values = data;
    const params = new URLSearchParams();
    params.append("name", values.name);
    params.append("permissions", values.permissions);
    params.append("id", values.id);

    const response = yield call(axios.put, `/roles/`, params);
    if (response.data.error) {
      yield put({
        type: UPDATE_ROLE_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: UPDATE_ROLE_SUCCESS,
        data: response.data,
      });
    }
    yield call(success, `Role Updated Succesfully`);
    yield put(push("/supervisor-settings-roles"));
  } catch (error) {
    yield put({
      type: UPDATE_ROLE_ERROR,
      data: error,
    });
  }
}

export default function* updateRoleRequestSaga() {
  yield takeLatest(UPDATE_ROLE, updateRoleRequest);
}
