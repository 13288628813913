// @flow
import { put, call, take, takeEvery, takeLatest } from "redux-saga/effects";
import { SET_ASSESMENT_STATE, SET_ASSESMENT_STATE_SUCCESS } from "../constants";

function* setAssesmentState(data) {
  yield put({
    type: SET_ASSESMENT_STATE_SUCCESS,
    data: data,
  });
}

export default function* setAssesmentStateSaga() {
  yield takeLatest(SET_ASSESMENT_STATE, setAssesmentState);
}
