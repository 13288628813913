import React from "react";
import classes from "./switch.module.scss";
const Switch = ({ checked, label, onChange }) => {
  return (
    <div className={classes.switchContainer}>
      <span className={classes.label}>{label}</span>
      <div onClick={onChange} className={classes.switch}>
        <div className={checked ? classes.active : classes.disabled} />
      </div>
    </div>
  );
};

export default Switch;
