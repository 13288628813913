import produce, { enableES5 } from "immer";
import {
  GET_INDUCTION_REQUEST,
  GET_INDUCTION_REQUEST_ERROR,
  GET_INDUCTION_REQUEST_SUCCESS,
  GET_INDUCTION_DETAIL_REQUEST,
  GET_INDUCTION_DETAIL_REQUEST_ERROR,
  GET_INDUCTION_DETAIL_REQUEST_SUCCESS,
  SET_SELECTED_SITE,
  SET_SELECTED_SITE_SUCCESS,
  SET_CURRENT_RULE,
  SET_CURRENT_RULE_SUCCESS,
  SET_FOOTER_VISIBILITY,
  SET_FOOTER_VISIBILITY_SUCCESS,
  SET_SECTION,
  SET_SECTION_SUCCESS,
  SET_SECTION_STEP,
  SET_SECTION_STEP_SUCCESS,
  SET_CHAPTER_COMPLETION,
  SET_CHAPTER_COMPLETION_SUCCESS,
  DOCUMENT_CAPTURE,
  DOCUMENT_CAPTURE_ERROR,
  DOCUMENT_CAPTURE_SUCCESS
} from "./constants";

enableES5();

export const initialState = {
  error: null,
  loading: false,
  induction: null,
  selectedInduction: null,
  selectedSites: null,
  isFooterVisible: true,
  currentRule: null,
  section: false,
  sectionStep: 1,
  chapterCompletion: false,
};

const inductionReducer = (state = initialState, action) => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case GET_INDUCTION_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        break;
      case GET_INDUCTION_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.induction = action.data;
        draftState.selectedInduction = null;
        break;
      case GET_INDUCTION_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case GET_INDUCTION_DETAIL_REQUEST:
        draftState.loading = true;
        draftState.error = null;
        draftState.selectedInduction = null;

        break;
      case GET_INDUCTION_DETAIL_REQUEST_SUCCESS:
        draftState.loading = false;
        draftState.selectedInduction = action.data;
        break;
      case GET_INDUCTION_DETAIL_REQUEST_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;
      case SET_SELECTED_SITE:
        draftState.loading = true;
        draftState.selectedSites = action.data;
        break;
      case SET_SELECTED_SITE_SUCCESS:
        draftState.loading = false;
        draftState.selectedSites = action.data;
        break;
      case SET_CURRENT_RULE:
        draftState.loading = true;
        break;
      case SET_CURRENT_RULE_SUCCESS:
        draftState.loading = false;
        draftState.currentRule = action.data;
        break;
      case SET_SECTION:
        draftState.loading = true;
        break;
      case SET_SECTION_SUCCESS:
        draftState.loading = false;
        draftState.section = action.data;
        break;
      case SET_SECTION_STEP:
        draftState.loading = true;
        break;
      case SET_SECTION_STEP_SUCCESS:
        draftState.loading = false;
        draftState.sectionStep = action.data;
        break;
      case SET_CHAPTER_COMPLETION:
        draftState.loading = true;
        break;
      case SET_CHAPTER_COMPLETION_SUCCESS:
        draftState.loading = false;
        draftState.chapterCompletion = action.data;
        break;
      case SET_FOOTER_VISIBILITY:
        // draftState.isFooterVisible = action.data;
        break;
      case SET_FOOTER_VISIBILITY_SUCCESS:
        draftState.isFooterVisible = action.data;
        break;

      case DOCUMENT_CAPTURE:
        draftState.loading = true;
        draftState.error = null;
        break;
      case DOCUMENT_CAPTURE_SUCCESS:
        draftState.loading = false;
        draftState.error = null;
        break; 
      case DOCUMENT_CAPTURE_ERROR:
        draftState.loading = false;
        draftState.error = action.data;
        break;        

      default:
        break;
    }
  });
};

export default inductionReducer;
