import React from "react";
import classes from "./input.module.scss";
import { useIntl } from "react-intl";
import PhoneInput from "react-phone-number-input";

const PhoneNumberInput = (props) => {
  const intl = useIntl();

  const placeholder = intl.formatMessage({
    id: props.placeholder,
    defaultMessage: props.defaultMessage,
  });
  const label = intl.formatMessage({
    id: props.label,
    defaultMessage: props.defaultLabel,
  });
  return (
    <div className={classes.Container}>
      <label className={classes.BlockLabel}>{label}</label>
      <PhoneInput
        international
        style={{
          background: "#f8f8f8",
          heigth: "44px",
          color: "#273d52",
          border: "1px solid #c8ced3",
          borderRadius: "6px",
          paddingLeft: "20px",
        }}
        defaultCountry="NZ"
        className={`${classes.BlockInput} ${props.error && classes.error}`}
        placeholder={placeholder}
        onChange={props.onChange}
        value={props.value}
        withCountryCallingCode={true}
        smartCaret={true}
      />

      {props.error && (
        <span className={classes.errorMessage}>
          Please provide valid information.
        </span>
      )}
    </div>
  );
};

export default PhoneNumberInput;
