// @flow
import { all, fork } from "redux-saga/effects";

import setSectionTimeSaga from "./sagas/setSectionTime";
import getSectionTimeRequestSaga from "./sagas/getSectionTime";
import setCountTimeSaga from "./sagas/setCountTime";
import setCurrentSectionSaga from "./sagas/setCurrentSection";

function* timeSaga() {
  yield all([
    fork(getSectionTimeRequestSaga),
    fork(setSectionTimeSaga),
    fork(setCountTimeSaga),
    fork(setCurrentSectionSaga),
  ]);
}

export default timeSaga;
