import React, { useEffect, useState, useRef } from "react";
//styling
import classes from "../reporting.module.scss";

//components
import Table from "components/Supervisor/table";
import Pagination from "components/Supervisor/pagination";
import DateRangeFilter from "components/Supervisor/dateRangeFilter";
import ReactLoading from "react-loading";
import Filters from "./filters";

//redux
import {
  getReportsRequest,
  getCompaniesRequest,
  getAllReportsRequest,
  getRegionRequest,
  setDateFilterReq,
  getSitesRequest,
} from "../../../../store/supervisor/actions";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  makeSelectReports,
  makeSelectCompanies,
  makeSelectLoading,
  makeSelectAllReports,
  makeSelectAllReportsLoading,
  makeSelectRegions,
  makeSelectDateFilter,
  makeSelectSites,
  makeSelectFilterLoading,
} from "../../../../store/supervisor/selector";

//utils
import { isNil, isEmpty } from "lodash";
import { format, getUnixTime } from "date-fns";
import { CSVLink } from "react-csv";
import { useSortableData } from "../../../../utils/hooks";
import { tableIndicator, reportingTableData } from "./data";
import { getFilterValues } from "../../../../utils/helper";

const InductionReporting = ({
  getReports,
  reports,
  loading,
  getAllReports,
  aloading,
  menu,
  branches,
  getBranches,
  filterDate,
  setDateFilter,
  getCompanies,
  getSites,
  companies,
  sites,
  filterLoading,
}) => {
  const [reportingList, setReportingList] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [csvData, setcsvData] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);

  const csvLink = useRef();

  const [searchState, setSearchState] = useState("");

  const [rawReports, setRawReports] = useState([]);

  const [PAGE_COUNT, setPageCount] = useState(null);

  useEffect(() => {
    if (!isNil(branches?.results)) {
      const opts = branches.results.map((item) => {
        return { label: item.name, value: item.id, checked: false };
      });
      setBranchOptions(opts);
    }
  }, [branches]);

  useEffect(() => {
    if (!isNil(companies?.results)) {
      const opts = companies.results.map((item) => {
        return { label: item.name, value: item.id, checked: false };
      });
      const checkedOptions = companyOptions?.filter((item) => item.checked);

      if (checkedOptions?.length > 0) {
        const filteredNewOptions = opts.filter((item) =>
          isEmpty(checkedOptions.find((a) => item.id !== a.id))
        );

        let newOpts = [...filteredNewOptions, ...checkedOptions];
        setCompanyOptions(newOpts);
      } else {
        setCompanyOptions(opts);
      }
    }
  }, [companies]);

  useEffect(() => {
    if (!isNil(sites?.results)) {
      const opts = sites.results.map((item) => {
        return { label: item.name, value: item.id, checked: false };
      });
      setSiteOptions(opts);
    }
  }, [sites]);

  useEffect(() => {
    if (!isNil(tableIndicator)) {
      const statusOpts = tableIndicator.map((item) => {
        return {
          ...item,
          label: item.name,
          value: item.type,
          checked: false,
        };
      });
      setStatusOptions(statusOpts);
    }
  }, [tableIndicator]);

  const runFilters = (branchOpt, siteOpt, companyOpt, statusOpt) => {
    getReports({
      search: searchState,
      enddate: !isNil(filterDate?.enddate) && filterDate.enddate,
      startdate: !isNil(filterDate?.startdate) && filterDate.startdate,
      statusIds: getFilterValues(statusOpt),
      regionIds: getFilterValues(branchOpt),
      siteIds: getFilterValues(siteOpt),
      companyIds: getFilterValues(companyOpt),
      sort:
        !isNil(sortConfig) && sortConfig?.direction?.toString() === "descending"
          ? `-${sortConfig?.key}`
          : sortConfig?.key,
    });
  };

  const onSearch = (e) => {
    setSearchState(e.target.value);
    if (e.target.value?.length === 0) {
      getReports({
        enddate: !isNil(filterDate?.enddate) && filterDate.enddate,
        startdate: !isNil(filterDate?.startdate) && filterDate.startdate,
        statusIds: getFilterValues(statusOptions),
        regionIds: getFilterValues(branchOptions),
        siteIds: getFilterValues(siteOptions),
        companyIds: getFilterValues(companyOptions),
        sort:
          !isNil(sortConfig) &&
          sortConfig?.direction?.toString() === "descending"
            ? `-${sortConfig?.key}`
            : sortConfig?.key,
      });
      setSearchState("");
      setSelectedPage(1);
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      setSelectedPage(1);
      getReports({
        search: event.target.value,
        enddate: !isNil(filterDate?.enddate) && filterDate.enddate,
        startdate: !isNil(filterDate?.startdate) && filterDate.startdate,
        statusIds: getFilterValues(statusOptions),
        regionIds: getFilterValues(branchOptions),
        siteIds: getFilterValues(siteOptions),
        companyIds: getFilterValues(companyOptions),
        sort:
          !isNil(sortConfig) &&
          sortConfig?.direction?.toString() === "descending"
            ? `-${sortConfig?.key}`
            : sortConfig?.key,
      });
    }
  };

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const printReports = async (data) => {
    const { labels, columns } = reportingTableData(data, branches);

    const labelData = labels?.map((item) => item.label);
    const columnData = columns?.map((item) => item.values);
    const mergedData = [labelData, ...columnData];

    const handledCsvData = mergedData?.map((item, index) => {
      if (index === 0) {
        return item;
      } else return item.map((item) => item.value);
    });
    setcsvData(handledCsvData);

    await delay(1000);
    csvLink.current.link.click();
  };

  useEffect(() => {
    if (!isNil(reports)) {
      setReportingList(reportingTableData(reports.results, branches));
      setRawReports(reports.results);
      setPageCount(reports.items_per_page);
    }
  }, [reports]);

  useEffect(() => {
    //api call
    getBranches();
    getCompanies();
    getSites();
  }, []);

  const { requestSort, sortConfig } = useSortableData(rawReports);
  const sortBy = (key) => {
    requestSort(key);
    if (!isNil(sortConfig)) {
      getReports({
        search: searchState,
        enddate: !isNil(filterDate?.enddate) && filterDate.enddate,
        startdate: !isNil(filterDate?.startdate) && filterDate.startdate,
        statusIds: getFilterValues(statusOptions),
        regionIds: getFilterValues(branchOptions),
        siteIds: getFilterValues(siteOptions),
        companyIds: getFilterValues(companyOptions),
        sort:
          sortConfig.direction.toString() === "descending" ? `-${key}` : key,
      });
    }
  };

  useEffect(() => {
    if (filterDate) {
      getReports({
        enddate: filterDate.enddate,
        startdate: filterDate.startdate,
        search: searchState,
        statusIds: getFilterValues(statusOptions),
        regionIds: getFilterValues(branchOptions),
        siteIds: getFilterValues(siteOptions),
        companyIds: getFilterValues(companyOptions),
        sort:
          !isNil(sortConfig) &&
          sortConfig?.direction?.toString() === "descending"
            ? `-${sortConfig?.key}`
            : sortConfig?.key,
      });
    }
  }, [filterDate]);

  return (
    <div className={classes.container}>
      <div className={classes.headContainer}>
        <DateRangeFilter
          value={filterDate}
          onChange={(val) => {
            setDateFilter({
              enddate: getUnixTime(val[0].endDate),
              startdate: getUnixTime(val[0].startDate),
            });
          }}
        />
      </div>
      <div>{menu}</div>
      <div className={classes.filterContainer}>
        <Filters
          filterLoading={filterLoading}
          runFilters={runFilters}
          branchOptions={branchOptions}
          setBranchOptions={setBranchOptions}
          siteOptions={siteOptions}
          setSiteOptions={setSiteOptions}
          companyOptions={companyOptions}
          setCompanyOptions={setCompanyOptions}
          setStatusOptions={setStatusOptions}
          statusOptions={statusOptions}
        />
        {/* <div className={classes.filterWrapper}>
          <FilterSelector
            title={"Branch"}
            options={branchOptions}
            onChange={(val) => {
              const opts = branchOptions.map((item) => {
                return {
                  ...item,
                  checked: item.value === val ? !item.checked : item.checked,
                };
              });
              setBranchOptions(opts);
              getReports({
                statusIds: !isNil(statusFilter) && statusFilter,
                enddate: !isNil(filterDate?.enddate) && filterDate.enddate,
                regionIds: getFilterValues(opts),
                startdate:
                  !isNil(filterDate?.startdate) && filterDate.startdate,
                search: searchState,
                sort:
                  !isNil(sortConfig) &&
                  sortConfig?.direction?.toString() === "descending"
                    ? `-${sortConfig?.key}`
                    : sortConfig?.key,
              });
            }}
          />
          <StatusSelecotr
            title={"Status"}
            options={statusOptions}
            onChange={(val) => {
              const newOptions = statusOptions.map((item) => {
                return {
                  ...item,
                  checked: item.label === val ? !item.checked : item.checked,
                };
              });

              const selectedIds = newOptions
                .filter((a) => a.checked)
                .map((item) => item.type);

              getReports({
                statusIds: selectedIds.toString(),
                enddate: !isNil(filterDate?.enddate) && filterDate.enddate,
                startdate:
                  !isNil(filterDate?.startdate) && filterDate.startdate,
                search: searchState,
                regionIds: getFilterValues(branchOptions),
                sort:
                  !isNil(sortConfig) &&
                  sortConfig?.direction?.toString() === "descending"
                    ? `-${sortConfig?.key}`
                    : sortConfig?.key,
              });
              setStatusFilter(selectedIds.toString());
              setStatusOptions(newOptions);
            }}
          />
        </div> */}
        <div className={classes.indicatorWrapper}>
          {tableIndicator.map((item) => (
            <div className={classes.indicatorItem}>
              <div style={{ background: item.color }} />
              <span>{item.name}</span>
            </div>
          ))}
        </div>
      </div>
      <div className={classes.tableWrapper}>
        <Table
          handleKeyDown={handleKeyDown}
          isSearchable={true}
          searchState={searchState}
          loading={loading}
          onSearch={(e) => onSearch(e)}
          data={reportingList}
          sortByDesc={(val) => sortBy(val)}
          sortConfig={sortConfig}
        />
      </div>
      {PAGE_COUNT && reports && reports.count > PAGE_COUNT && (
        <Pagination
          totalCount={reports.count}
          pageCount={PAGE_COUNT}
          currentPage={selectedPage}
          onChange={(val) => {
            getReports({
              page: val + 1,
              search: searchState,
              enddate: !isNil(filterDate?.enddate) && filterDate.enddate,
              startdate: !isNil(filterDate?.startdate) && filterDate.startdate,
              statusIds: getFilterValues(statusOptions),
              regionIds: getFilterValues(branchOptions),
              siteIds: getFilterValues(siteOptions),
              companyIds: getFilterValues(companyOptions),
              sort:
                !isNil(sortConfig) &&
                sortConfig?.direction?.toString() === "descending"
                  ? `-${sortConfig?.key}`
                  : sortConfig?.key,
            });
            setSelectedPage(val + 1);
          }}
        />
      )}

      <div className={classes.buttonContainer}>
        <div className={classes.buttonWrapper}>
          <div style={{ justifyContent: "flex-end" }} className={classes.flex}>
            <button
              disabled={aloading}
              className={classes.darkButton}
              onClick={() => {
                getAllReports({
                  callBack: (val) => printReports(val),
                  search: searchState,
                  enddate: !isNil(filterDate?.enddate) && filterDate.enddate,
                  startdate:
                    !isNil(filterDate?.startdate) && filterDate.startdate,
                  statusIds: getFilterValues(statusOptions),
                  regionIds: getFilterValues(branchOptions),
                  siteIds: getFilterValues(siteOptions),
                  companyIds: getFilterValues(companyOptions),
                  sort:
                    !isNil(sortConfig) &&
                    sortConfig?.direction?.toString() === "descending"
                      ? `-${sortConfig?.key}`
                      : sortConfig?.key,
                });
              }}
            >
              {aloading ? (
                <ReactLoading
                  type={"spin"}
                  color={"#40507e"}
                  height={30}
                  width={30}
                />
              ) : (
                "Export"
              )}
            </button>
            <CSVLink
              filename={`${
                !isNil(filterDate) &&
                !isNil(filterDate?.startdate) &&
                !isNil(filterDate?.enddate)
                  ? `${format(
                      new Date(filterDate?.startdate * 1000),
                      "yyyyMMdd"
                    )}-${format(
                      new Date(filterDate?.enddate * 1000),
                      "yyyyMMdd"
                    )}`
                  : "reports"
              }.csv`}
              className="hidden"
              data={csvData}
              ref={csvLink}
              target="_blank"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  reports: makeSelectReports(),
  companies: makeSelectCompanies(),
  allReports: makeSelectAllReports(),
  aloading: makeSelectAllReportsLoading(),
  branches: makeSelectRegions(),
  filterDate: makeSelectDateFilter(),
  sites: makeSelectSites(),
  filterLoading: makeSelectFilterLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getCompanies: (val) => dispatch(getCompaniesRequest(val)),
    getReports: (val) => dispatch(getReportsRequest(val)),
    getAllReports: (val) => dispatch(getAllReportsRequest(val)),
    getBranches: (val) => dispatch(getRegionRequest(val)),
    setDateFilter: (val) => dispatch(setDateFilterReq(val)),
    getSites: (val) => dispatch(getSitesRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InductionReporting);
