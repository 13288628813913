import { useEffect, useRef, useState } from "react";
import {
  setSectionTime,
  getSectionTime,
  setCountTime,
} from "../store/time/actions";
import {
  makeSelectCurrentSection,
  makeSelectCountTime,
  makeSelectSectionTime,
  makeSelectLoading,
} from "../store/time/selector";

import {
  makeSelectSelectedInduction,
  makeSelectCurrentRule,
  makeSelectSection,
} from "../store/induction/selector";

import { makeSelectProgress } from "../store/progress/selector";

import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { usePrevious } from "./hooks/index";
import { isNil, findIndex, isNaN } from "lodash";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const Timer = ({
  setSectionTimeReq,
  loading,
  time,
  setTime,
  progressData,
  selectedInduction,
  currentRule,
  selectedSection,
}) => {
  const pathname = window.location.pathname;
  const induction_id = selectedInduction?.id;

  const location = useLocation();

  //calculated time for current section

  const pages = selectedInduction?.data?.pages;
  const index = findIndex(pages, {
    url: pathname,
  });

  const isRulesPage = window.location.pathname === "/rules";

  const currentSection =
    isRulesPage && !isNil(currentRule?.rule)
      ? selectedSection?.id
      : pages[index].id;

  //previous section

  const prevSection = usePrevious(
    isRulesPage && !isNil(currentRule?.rule)
      ? selectedSection?.id
      : pages[index].id
  );

  //console.log("PREV SECTION", prevSection);

  useEffect(() => {
    if (
      isRulesPage &&
      prevSection !== currentSection &&
      !isNil(prevSection) &&
      !isNaN(time) &&
      !isNil(time)
    ) {
      setSectionTimeReq({
        induction_id,
        totalseconds: time,
        section: prevSection,
      });
      setTime(0);
    }
  }, [currentSection]);

  const setTimeRequest = () => {
    if (
      !loading &&
      currentSection >= Number(progressData?.section) &&
      !isNaN(time) &&
      !isNil(time)
    ) {
      setSectionTimeReq({
        induction_id,
        totalseconds: time,
        section: currentSection,
      });
    }
  };
  useEffect(() => {
    if (time !== 0 && !isNil(prevSection) && !isNaN(time) && !isNil(time)) {
      setSectionTimeReq({
        induction_id,
        totalseconds: time,
        section: prevSection,
      });
    }
    setTime(0);
  }, [location]);

  useInterval(() => {
    if (!loading) {
      setTimeRequest();
    }
  }, 10000);

  useInterval(() => {
    if (!loading) {
      setTime({ time: isNaN(time) && !isNil(time) ? 1 : time + 1 });
    }
  }, 1000);

  return null;
};

const mapStateToProps = createStructuredSelector({
  selectedSection: makeSelectSection(),
  sectionTime: makeSelectSectionTime(),
  time: makeSelectCountTime(),
  loading: makeSelectLoading(),
  progressData: makeSelectProgress(),
  selectedInduction: makeSelectSelectedInduction(),
  currentRule: makeSelectCurrentRule(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    setSectionTimeReq: (val) => dispatch(setSectionTime(val)),
    getSectionTimeReq: (val) => dispatch(getSectionTime(val)),
    setTime: (val) => dispatch(setCountTime(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Timer);
