// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_CONTRACTORS_BY_SITE_SUCCESS,
  GET_CONTRACTORS_BY_SITE_ERROR,
  GET_CONTRACTORS_BY_SITE,
} from "../../constants";
import axios from "../../../../services/api";
import { isNil } from "lodash";

function* getContractorsBySite(data) {
  try {
    const params = new URLSearchParams();
    params.append("site", data.id);
    if (!isNil(data?.search)) {
      params.append("search", data.search);
    }
    if (!isNil([data?.page])) {
      params.append("page", data.page);
    }
    const response = yield call(axios.get, `/site-contractors/`, { params });
    if (response.data.error) {
      yield put({
        type: GET_CONTRACTORS_BY_SITE_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: GET_CONTRACTORS_BY_SITE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_CONTRACTORS_BY_SITE_ERROR,
      data: error,
    });
  }
}

export default function* getContractorsBySiteSaga() {
  yield takeLatest(GET_CONTRACTORS_BY_SITE, getContractorsBySite);
}
