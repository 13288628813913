// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  SET_ME_REQUEST,
  SET_ME_REQUEST_SUCCESS,
  SET_ME_REQUEST_ERROR,
} from "../constants";
import axios from "../../../services/api";
import { isNil } from "lodash";

function* setMe(data) {
  const values = data.payload;
  const params = new URLSearchParams();
  if (values.language) {
    params.append("language", values.language);
  }

  if (values.vrstatus) {
    params.append("vrstatus", values.vrstatus);
  }

  try {
    const response = yield call(axios.post, "/me/", params);

    if (response.data.error) {
      yield put({
        type: SET_ME_REQUEST_ERROR,
        data: response.data.error,
      });
    } else {
      yield put({
        type: SET_ME_REQUEST_SUCCESS,
        data: response.data.languages,
      });

      if (!isNil(values?.callBack)) {
        yield call(() => values.callBack());
      }
    }
  } catch (error) {
    yield put({
      type: SET_ME_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* setMeSaga() {
  yield takeLatest(SET_ME_REQUEST, setMe);
}
