// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_PEOPLE_LIST_SUCCESS,
  GET_PEOPLE_LIST_ERROR,
  GET_PEOPLE_LIST,
} from "../../constants";
import axios from "../../../../services/api";

function* getPeopleList(data) {
  const params = new URLSearchParams();

  if (data.personaId) {
    params.append("personaId", data.personaId);
  }
  if (data.companyIds) {
    params.append("companyIds", data.companyIds);
  }
  if (data.siteIds) {
    params.append("siteIds", data.siteIds);
  }
  if (data.statusIds) {
    params.append("statusIds", data.statusIds);
  }
  if (data.recentActivities) {
    params.append("recent_activities", data.recentActivities);
  }
  if (data.ordering) {
    params.append("ordering", data.ordering);
  }
  if (data.searchItem) {
    params.append("search", data.searchItem);
  }
  if (data.page) {
    params.append("page", data.page);
  }

  try {
    const response = yield call(axios.get, `/people/`, { params });

    if (response.data.error) {
      yield put({
        type: GET_PEOPLE_LIST_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: GET_PEOPLE_LIST_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_PEOPLE_LIST_ERROR,
      data: error,
    });
  }
}

export default function* getPeopleListSaga() {
  yield takeLatest(GET_PEOPLE_LIST, getPeopleList);
}
