import React, { useEffect, useState } from "react";

import classes from "./cardSort.module.scss";
import { calcResults, Column, MovableItem } from "./helpers";
import EndCard from "./endCard";
import { CustomDragLayer } from "./customLayer";
import { Portal } from "react-portal";
import cn from "classnames";
import { delay } from "utils/helper";
import ReplayIcon from "./replay.svg";

import { useDragLayer } from "react-dnd";
import { isNil } from "lodash";
import { FormattedMessage } from "react-intl";

const CardSort = ({ question, interactive, setFooterVisibilityReq }) => {
  const [items, setItems] = useState([]);
  const [isEnd, setIsEnd] = useState(false);
  const [isFinish, setIsFinish] = useState(false);
  const [results, setResults] = useState(null);

  useEffect(() => {
    if (!isNil(question.cards)) {
      setItems(question.cards);
    }
  }, [question.cards]);

  useEffect(() => {
    if (interactive === true) {
      setFooterVisibilityReq({ isFooterVisible: false });
    }
  }, [interactive]);

  useEffect(() => {
    if (isFinish) {
      setFooterVisibilityReq({ isFooterVisible: true });
    }
  }, [isFinish]);

  const moveCardHandler = (dragIndex, hoverIndex) => {
    const dragItem = items[dragIndex];
    if (dragItem) {
      setItems((prevState) => {
        const coppiedStateArray = [...prevState];

        // remove item by "hoverIndex" and put "dragItem" instead
        const prevItem = coppiedStateArray.splice(hoverIndex, 1, dragItem);

        // remove item by "dragIndex" and put "prevItem" instead
        coppiedStateArray.splice(dragIndex, 1, prevItem[0]);

        return coppiedStateArray;
      });
    }
  };

  const { isDragging, dragItem, initialOffset, currentOffset } = useDragLayer(
    (monitor) => ({
      dragItem: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    })
  );

  const returnItemsForColumn = (columnName) => {
    const vals = items
      .filter((item) => item.column === columnName)
      .map((item, index) => (
        <MovableItem
          onDrag={isDragging && item.name === dragItem?.name}
          total={items?.length}
          items={items}
          key={item.id}
          name={item.name}
          currentColumnName={item.column}
          setItems={setItems}
          index={index}
          moveCardHandler={moveCardHandler}
          description={item.content}
          description_id={item.content_id}
          feedback={item.feedback}
        />
      ));
    return vals;
  };

  useEffect(() => {
    if (
      items &&
      items.length > 0 &&
      items.every((item) => item.column !== "START")
    ) {
      const numberOfCorrect = calcResults(items, question.categories);
      const numberOfFalse = items.length - numberOfCorrect;
      setResults({
        correctAnswers: numberOfCorrect,
        inCorrectAnswers: numberOfFalse,
      });
      setIsEnd(true);
    }
  }, [items]);

  const setFinish = async () => {
    await delay(2000);
    setIsFinish(true);
  };

  useEffect(() => {
    if (isEnd) {
      setFinish();
    }
  }, [isEnd]);

  return (
    <Portal>
      <div className={cn(classes.Container, classes.portal)}>
        <CustomDragLayer
          isDragging={isDragging}
          item={dragItem}
          initialOffset={initialOffset}
          currentOffset={currentOffset}
        />
        <div className={classes.Title}>
          {isFinish ? (
            <FormattedMessage
              defaultMessage={"Conclusion"}
              id={"cardsort.conclusion"}
            />
          ) : (
            <FormattedMessage
              defaultMessage={question.title}
              id={question.title_id}
            />
          )}
        </div>
        <div className={classes.Content}>
          {isFinish ? (
            <FormattedMessage
              defaultMessage={
                "Below you can see the results of card sort chapter"
              }
              id={"cardsort.content"}
            />
          ) : (
            <FormattedMessage
              defaultMessage={question.content}
              id={question.content_id}
            />
          )}
        </div>
        {!isFinish && (
          <Column
            title={"START"}
            className="column start"
            contentEditable={false}
            data-text={"That was the last card"}
            dataId="start"
          >
            {isEnd ? (
              <div className={cn(classes.WellDoneWrapper, classes.fadeIn)}>
                <span>
                  <FormattedMessage
                    defaultMessage={"Well Done!"}
                    id={"cardsort.welldone"}
                  />
                </span>
              </div>
            ) : (
              <>{returnItemsForColumn("START")}</>
            )}
          </Column>
        )}
        {isFinish && (
          <>
            <div className={cn(classes.EndCardWrapper, classes.fadeIn)}>
              <EndCard
                correctAnswers={results.correctAnswers}
                inCorrectAnswers={results.inCorrectAnswers}
                type="success"
              />
              <EndCard
                correctAnswers={results.correctAnswers}
                inCorrectAnswers={results.inCorrectAnswers}
                type="error"
              />
            </div>
            <button
              className={classes.replayButton}
              onClick={() => {
                setFooterVisibilityReq({ isFooterVisible: false });
                setItems(question.cards);
                setResults(null);
                setIsEnd(false);
                setIsFinish(false);
              }}
            >
              <span>
                <FormattedMessage
                  id={"replay.button"}
                  defaultMessage={"Replay"}
                />
              </span>
              <img src={ReplayIcon} alt={"Replay Icon"} />
            </button>
          </>
        )}
        {!isFinish && (
          <>
            <div className={classes.Category}>
              {" "}
              <FormattedMessage
                id={"cardsort.category.title"}
                defaultMessage={"Category to choose from"}
              />
            </div>
            <div className={classes.AnswerContainer}>
              {question?.categories.map((category) => {
                const { name, name_id } = category;
                return (
                  <div className={classes.columnWrapper}>
                    <span className={classes.categoryTitle}>
                      <FormattedMessage defaultMessage={name} id={name_id} />
                    </span>
                    <Column
                      title={name}
                      className={`column answer-${name}-column`}
                    >
                      {returnItemsForColumn(name)}
                    </Column>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </Portal>
  );
};
export default CardSort;
