import React from "react";
import Form from "../Form";
import { useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { createSite } from "store/supervisor/actions";
import {
  makeSelectLoading,
  makeSelectUsers,
  makeSelectRegions,
} from "store/supervisor/selector";
import { getUsersRequest, getRegionRequest } from "store/supervisor/actions";
import { getInductionRequest } from "store/induction/actions";
import { makeSelectInduction } from "store/induction/selector";

const CreateForm = ({
  history,
  createSiteRequest,
  loading,
  siteManagers,
  getSiteManagers,
  getRegions,
  regions,
  getInductions,
  inductions,
}) => {
  useEffect(() => {
    getSiteManagers();
    getRegions();
    getInductions();
  }, []);
  return (
    <>
      <Form
        siteManagers={siteManagers}
        loading={loading}
        history={history}
        id={null}
        create={createSiteRequest}
        regions={regions}
        inductions={inductions}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  siteManagers: makeSelectUsers(),
  regions: makeSelectRegions(),
  inductions: makeSelectInduction(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    createSiteRequest: (val) => dispatch(createSite(val)),
    getSiteManagers: (val) => dispatch(getUsersRequest(val)),
    getRegions: (val) => dispatch(getRegionRequest(val)),
    getInductions: (val) => dispatch(getInductionRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateForm);
