// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_ROLE_BY_ID_REQUEST_SUCCESS,
  GET_ROLE_BY_ID_REQUEST_ERROR,
  GET_ROLE_BY_ID_REQUEST,
} from "../../constants";
import axios from "../../../../services/api";

function* getRoleByIdRequest(data) {
  try {
    const response = yield call(axios.get, `/roles/?id=${data.id}`);
    if (response.data.error) {
      yield put({
        type: GET_ROLE_BY_ID_REQUEST_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: GET_ROLE_BY_ID_REQUEST_SUCCESS,
        data: response.data.results[0],
      });
    }
  } catch (error) {
    yield put({
      type: GET_ROLE_BY_ID_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* getRoleByIdRequestSaga() {
  yield takeLatest(GET_ROLE_BY_ID_REQUEST, getRoleByIdRequest);
}
