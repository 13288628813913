// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_ATTENDANCE_DETAIL,
  GET_ATTENDANCE_DETAIL_ERROR,
  GET_ATTENDANCE_DETAIL_SUCCESS,
} from "../../constants";
import axios from "../../../../services/api";

function* getAttendanceDetail(data) {
  const params = new URLSearchParams();
  if (data.id) {
    params.append("user", data.id);
  }
  if (data.startdate) {
    params.append("startdate", data.startdate);
  }
  if (data.enddate) {
    params.append("enddate", data.enddate);
  }
  if (data.search) {
    params.append("search", data.search);
  }
  if (data.sort) {
    params.append("sort", data.sort);
  }
  try {
    const response = yield call(axios.get, `/reports-check-in-details/`, {
      params,
    });
    if (response.data.error) {
      yield put({
        type: GET_ATTENDANCE_DETAIL_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: GET_ATTENDANCE_DETAIL_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_ATTENDANCE_DETAIL_ERROR,
      data: error,
    });
  }
}

export default function* getAttendanceDetailSaga() {
  yield takeLatest(GET_ATTENDANCE_DETAIL, getAttendanceDetail);
}
