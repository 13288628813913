import React from "react";
import classes from "./form.module.scss";
import Input from "components/Supervisor/input";
import Card from "components/Supervisor/card";
import { isNaN } from "lodash";

import {
  deleteCompany,
  updateCompany,
  createCompany,
} from "store/supervisor/actions";
import {
  makeSelectLoading,
  makeSelectCompanies,
} from "store/supervisor/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Formik, Form, Field } from "formik";
import ReactLoading from "react-loading";
import queryString from "query-string";

const BackIcon = window.location.origin + "/backIcon/backIconn.svg";

const CompanyForm = ({
  deleteCompanyRequest,
  companies,
  history,
  createCompanyRequest,
  updateCompanyRequest,
  loading,
}) => {
  const id = Number(queryString.parse(window.location.search).id);

  const initialValues = {
    id: id,
    name: !isNaN(id)
      ? companies?.results?.find((item) => item.id === id)?.name
      : "",
    address1: !isNaN(id)
      ? companies?.results?.find((item) => item.id === id)?.address1
      : "",
    address2: !isNaN(id)
      ? companies?.results?.find((item) => item.id === id)?.address2
      : "",
  };
  return (
    <>
      {loading ? (
        <div className={classes.loadingContainer}>
          <ReactLoading
            type={"spin"}
            color={"#20a8d8"}
            height={300}
            width={300}
          />
        </div>
      ) : (
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <img
            alt={"back icon"}
            onClick={() => history.push("/supervisor-company")}
            src={BackIcon}
            style={{
              width: "50px",
              height: "50px",
              cursor: "pointer",
              margin: "10px 10px 10px 0px",
            }}
          />
          <div className={classes.form}>
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                //Only run on edit page
                if (!isNaN(id)) {
                  updateCompanyRequest({
                    values: values,
                  });
                }
                //Only runs on crate page
                if (isNaN(id)) {
                  createCompanyRequest({
                    values: values,
                  });
                }
              }}
            >
              {({ values, setFieldValue, handleSubmit }) => (
                <Form>
                  <Card title={"Company Info"}>
                    <Field
                      name={"name"}
                      value={values.name}
                      onChange={(e) => {
                        setFieldValue("name", e.target.value);
                      }}
                      label={"Name"}
                      placeholder={"Name"}
                      component={Input}
                    />
                  </Card>
                  <Card title={"Company Address"}>
                    <Field
                      name={"address1"}
                      value={values.address1}
                      onChange={(e) => {
                        setFieldValue("address1", e.target.value);
                      }}
                      label={"Address 1"}
                      placeholder={"Address 1"}
                      component={Input}
                    />
                    <Field
                      name={"address2"}
                      value={values.address2}
                      onChange={(e) => {
                        setFieldValue("address2", e.target.value);
                      }}
                      label={"Address 2"}
                      placeholder={"Address 2"}
                      component={Input}
                    />
                  </Card>

                  <div className={classes.buttonContainer}>
                    <div
                      style={{
                        width: "75%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{ justifyContent: "flex-start" }}
                        className={classes.flex}
                      >
                        {!isNaN(id) && (
                          <button
                            className={classes.lightButton}
                            onClick={() => deleteCompanyRequest({ id: id })}
                          >
                            Delete
                          </button>
                        )}
                      </div>
                      <div
                        style={{ justifyContent: "flex-end" }}
                        className={classes.flex}
                      >
                        <button
                          onClick={() => history.push("/supervisor-company")}
                          className={classes.lightButton}
                        >
                          Cancel
                        </button>
                        <button
                          disabled={loading}
                          className={classes.darkButton}
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  companies: makeSelectCompanies(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    deleteCompanyRequest: (val) => dispatch(deleteCompany(val)),
    updateCompanyRequest: (val) => dispatch(updateCompany(val)),
    createCompanyRequest: (val) => dispatch(createCompany(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyForm);
