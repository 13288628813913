import React, { useEffect, useState } from "react";
import classes from "./company.module.scss";
import {
  getCompaniesRequest,
  deleteCompany,
  mergeCompany,
} from "store/supervisor/actions";
import {
  makeSelectCompanies,
  makeSelectLoading,
} from "store/supervisor/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Table from "components/Supervisor/table";
import Button from "components/Supervisor/button";
import DeleteModal from "components/Supervisor/modal";
import MergeModal from "components/Supervisor/modal/merge";
import Pagination from "components/Supervisor/pagination";

import DeleteIcon from "assets/supervisorIcons/deleteIcon.js";
import PlusIcon from "assets/supervisorIcons/plusIcon.js";

import { delay } from "utils/helper";
import { useSortableData } from "utils/hooks";
import { isNil } from "lodash";

const Company = ({
  companies,
  getCompanies,
  loading,
  deleteCompanyReq,
  history,
  mergeCompanyReq,
}) => {
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchState, setSearchState] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [PAGE_COUNT, setPageCount] = useState(null);

  const [deleteModal, setDeleteModal] = useState({
    open: false,
    type: null,
    action: null,
    cancel: null,
  });
  const [mergeModal, setMergeModal] = useState({
    open: false,
    type: null,
    action: null,
    cancel: null,
  });

  const [companyOptions, setCompanyOptions] = useState([]);

  const { requestSort, sortConfig } = useSortableData([]);
  const sortBy = (key) => {
    requestSort(key, (direction) =>
      getCompanies({
        page: selectedPage,
        search: searchState,
        ordering: direction === "descending" ? `-${key}` : key,
      })
    );
  };

  const orderingConfig =
    sortConfig?.direction?.toString() === "descending" &&
    !isNil(sortConfig?.key)
      ? `-${sortConfig.key}`
      : !isNil(sortConfig?.key)
      ? sortConfig?.key
      : "-id";

  useEffect(() => {
    getCompanies({ page: 1, search: "", ordering: "-id" });
  }, []);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      setSelectedPage(1);
      getCompanies({
        search: event.target.value,
        page: 1,
        ordering: orderingConfig,
      });
    }
  };

  const rowActions = [
    {
      name: "Edit",
      action: (val) => history.push(`/supervisor-company-edit?id=${val.id}`),
    },
    {
      name: "Delete",
      action: (val) => {
        setDeleteModal({
          open: true,
          type: "delete",
          action: () => {
            deleteCompanyReq({
              id: val.id,
              callback: async () => {
                await delay(1000);
                getCompanies();
              },
            });
            setDeleteModal({
              open: false,
              type: null,
              action: null,
              cancel: null,
            });
          },
          cancel: () =>
            setDeleteModal({
              open: false,
              type: null,
              action: null,
              cancel: null,
            }),
        });
      },
    },
  ];

  const companyTableData = (item) => {
    const data = item.map((a) => {
      return {
        id: a.id,
        values: [
          {
            value: a.name,
            id: a.id,
            raw: item,
          },
          {
            value: a.address1,
          },
          {
            value: a.address2,
          },
        ],
      };
    });

    const labels = [
      { label: "Name", sortable: true, key: "name" },
      { label: "Address 1", sortable: true, key: "address1" },
      { label: "Address 2", sortable: true, key: "address2" },
    ];

    return {
      columns: data,
      labels: labels,
    };
  };

  useEffect(() => {
    if (companies) {
      setCompanyList(companyTableData(companies.results));
      setPageCount(Number(companies.items_per_page));

      const newRows = companies.results.map((item, i) => {
        return {
          id: item.id,
          checked: false,
        };
      });
      setSelectedRows(newRows);
      const options = companies.results.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setCompanyOptions(options);
    }
  }, [companies]);

  const onSearch = (e) => {
    setSearchState(e.target.value);
    setSelectedPage(1);
    if (e.target.value.length === 0) {
      getCompanies({ search: e.target.value, ordering: orderingConfig });
    }
  };

  return (
    <>
      <div className={classes.companyContainer}>
        <h1 className={classes.titleHead}>Company</h1>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingRight: "20px",
          }}
        >
          {selectedRows &&
            selectedRows.filter((item) => item.checked).length > 0 && (
              <Button
                onClick={() => {
                  setDeleteModal({
                    open: true,
                    type: "delete",
                    action: () => {
                      selectedRows
                        .filter((item) => item.checked)
                        .forEach((item, index) => {
                          deleteCompanyReq({
                            id: item.id,
                            end:
                              index + 1 === selectedRows.length
                                ? null
                                : {
                                    callback: () => getCompanies(),
                                  },
                          });
                        });
                      setDeleteModal({
                        open: false,
                        type: null,
                        action: null,
                        cancel: null,
                      });
                    },
                    cancel: () =>
                      setDeleteModal({
                        open: false,
                        type: null,
                        action: null,
                        cancel: null,
                      }),
                  });
                }}
                icon={() => {
                  return <DeleteIcon />;
                }}
                text={"Delete"}
                color={"#E26C82"}
              />
            )}
          {selectedRows.filter((item) => item.checked).length === 2 && (
            <Button
              onClick={() => {
                setMergeModal({
                  open: true,
                  action: (val) => mergeCompanyReq(val),
                  cancel: () =>
                    setMergeModal({
                      open: false,
                      type: null,
                      action: null,
                      cancel: null,
                    }),
                });
              }}
              icon={() => {
                return <PlusIcon />;
              }}
              text={"Merge"}
            />
          )}
          <Button
            width={"200px"}
            onClick={() => history.push("/supervisor-company-create")}
            icon={() => {
              return <PlusIcon />;
            }}
            text={"New Company"}
          />
        </div>
        <div className={classes.companyContainer}>
          <Table
            width={"100%"}
            handleKeyDown={handleKeyDown}
            isSearchable={true}
            searchState={searchState}
            loading={loading}
            onSearch={(e) => onSearch(e)}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            data={companyList}
            rowActions={rowActions}
            clickable={true}
            sortByDesc={(val) => sortBy(val)}
            sortConfig={sortConfig}
          />
        </div>
        <>
          {PAGE_COUNT && companies && companies.count > PAGE_COUNT && (
            <Pagination
              totalCount={companies?.count}
              pageCount={PAGE_COUNT}
              currentPage={selectedPage}
              onChange={(val) => {
                getCompanies({
                  page: val + 1,
                  ordering: orderingConfig,
                  search: searchState,
                });
                setSelectedPage(val + 1);
              }}
            />
          )}
        </>
        <MergeModal
          open={mergeModal.open}
          cancel={mergeModal.cancel}
          action={mergeModal.action}
          type={mergeModal.type}
          companyOptions={companyOptions}
          selectedRows={selectedRows?.filter((item) => item.checked)}
          getCompanies={() => getCompanies()}
        />
        <DeleteModal
          open={deleteModal.open}
          cancel={deleteModal.cancel}
          action={deleteModal.action}
          type={deleteModal.type}
          loading={loading}
          deleteTitle={"You are about to delete a company."}
        />
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  companies: makeSelectCompanies(),
  loading: makeSelectLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getCompanies: (val) => dispatch(getCompaniesRequest(val)),
    deleteCompanyReq: (val) => dispatch(deleteCompany(val)),
    mergeCompanyReq: (val) => dispatch(mergeCompany(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);
