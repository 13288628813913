import React, { useEffect, useState } from "react";
import Menu from "components/Supervisor/menu";
import Button from "components/Supervisor/button";
import { pages } from "../utils";
import QRCode from "react-qr-code";
import classes from "./qr.module.scss";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { getSiteById } from "store/supervisor/actions";
import {
  makeSelectLoading,
  makeSelectCurrentSite,
} from "store/supervisor/selector";
import ReactLoading from "react-loading";
import { isNil } from "lodash";

import DownloadIcon from "assets/supervisorIcons/downloadIcon";
import cn from "classnames";
//import BottomShadow from "assets/supervisorIcons/bottomShadow.svg";
import queryString from "query-string";
import CheckIn from "./check-in.svg";
import CheckOut from "./check-out.svg";

const BackIcon = window.location.origin + "/backIcon/backIconn.svg";

const QRCodeGenerator = ({
  getSiteByIdRequest,
  loading,
  currentSite,
  history,
}) => {
  const id = queryString.parse(window.location.search).id;

  const LogoLink = "https://keepsafe.work/images/Keepsafe-logo.svg";

  const onImageDownload = () => {
    window.document.title = "KeepSafe HSE QR Codes";

    window.print();
    /*
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    */
  };

  const [qrData, setQrData] = useState(false);

  useEffect(() => {
    getSiteByIdRequest({ id });
  }, []);

  useEffect(() => {
    if (!isNil(currentSite) && !isNil(id)) {
      setQrData(currentSite.find((item) => item.id.toString() === id));
    }
  }, [currentSite]);

  const qrCodes = [
    {
      title: "Check-in",
      value: `${window.location.origin}/check-in?site=${qrData?.code}`,
    },
    {
      title: "Check-out",
      value: `${window.location.origin}/check-out?site=${qrData?.code}`,
    },
    {
      title: "Site Induction",
      value: `${window.location.origin}/site-induction?site=${qrData?.code}&sitemanager=${process.env.REACT_APP_SITEMANAGER_TOKEN}`,
    },
  ];

  const isNullOrZero = (val) => {
    return val !== 0 && val !== null;
  };
  return (
    <>
      <Menu pages={pages} id={id} />
      <div className={classes.container}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          className={classes.wrapper}
        >
          <img
            alt={"back icon"}
            onClick={() => history.push("/supervisor-sites")}
            src={BackIcon}
            className={classes.backIcon}
          />
          <span className={classes.title}>QR Code</span>
          <Button
            width={"167px"}
            onClick={() => onImageDownload()}
            icon={() => {
              return <DownloadIcon />;
            }}
            text={"Download"}
          />
        </div>
        {loading ? (
          <div className={classes.loadingContainer}>
            <ReactLoading
              type={"spin"}
              color={"#20a8d8"}
              height={300}
              width={300}
            />
          </div>
        ) : (
          <>
            <div className={classes.displayQRContainer}>
              {qrData &&
                qrCodes?.map((item, index) => (
                  <div
                    className={cn(
                      classes.QRItem,
                      !isNullOrZero(qrData?.induction_id) &&
                        index === 2 &&
                        classes.none
                    )}
                  >
                    <span>{item.title}</span>
                    <QRCode id="QRCode" value={item.value} size={250} />
                  </div>
                ))}
            </div>
            <div className={classes.qrWrapper}>
              <div className={classes.qrCodeCheckIn}>
                <div className={classes.qrCodeLogoBar}>
                  <img src={LogoLink} alt={"qr logo"} />
                </div>
                <div className={classes.qrCol}>
                  <img
                    className={classes.checkInIcon}
                    src={CheckIn}
                    alt={"check in icon"}
                  />
                  <span className={classes.checkInText}>Check-in</span>
                </div>
                <div className={classes.qrRow}>
                  <div className={classes.qrCodeWrapper}>
                    <div className={classes.leftTop} />
                    <div className={classes.rightTop} />
                    <div className={classes.leftBottom} />
                    <div className={classes.rightBottom} />
                    <QRCode
                      id="QRCode"
                      value={`${window.location.origin}/check-in?site=${qrData?.code}`}
                      size={300}
                    />
                  </div>
                  <span>
                    Use your mobile to scan the QR code above.
                    <br /> You'll be prompted to open the QR code in your
                    default browser.
                    <br />
                    <br /> Keep Safe!
                  </span>
                </div>
                <div className={classes.keepsafeBar}>
                  <span>
                    www.keep<span className={classes.green}>safe</span>.work
                  </span>
                </div>
              </div>
            </div>

            <div className={classes.qrWrapper}>
              <div className={classes.qrCodeCheckIn}>
                <div className={classes.qrCodeLogoBar}>
                  <img src={LogoLink} alt={"qr logo"} />
                </div>
                <div className={classes.qrCol}>
                  <img
                    className={classes.checkInIcon}
                    src={CheckOut}
                    alt={"check out icon"}
                  />
                  <span className={classes.checkInText}>Check-out</span>
                </div>
                <div className={classes.qrRow}>
                  <div className={classes.qrCodeWrapper}>
                    <div className={classes.leftTop} />
                    <div className={classes.rightTop} />
                    <div className={classes.leftBottom} />
                    <div className={classes.rightBottom} />

                    <QRCode
                      id="QRCode"
                      value={`${window.location.origin}/check-out?site=${qrData?.code}`}
                      size={300}
                    />
                  </div>
                  <span>
                    Use your mobile to scan the QR code above.
                    <br /> You'll be prompted to open the QR code in your
                    default browser.
                    <br />
                    <br /> Keep Safe!
                  </span>
                </div>
                <div className={classes.keepsafeBar}>
                  <span>
                    www.keep<span className={classes.green}>safe</span>.work
                  </span>
                </div>
              </div>
            </div>

            {qrData?.induction_id !== 0 && qrData?.induction_id !== null && (
              <div className={classes.qrWrapper}>
                <div className={classes.qrCodeCheckIn}>
                  <div className={classes.qrCodeLogoBar}>
                    <img src={LogoLink} alt={"qr logo"} />
                  </div>
                  <div className={classes.siteInductionHeaderWrapper}>
                    <span className={classes.siteInductionTitle}>
                      Your online HSE induction
                    </span>
                    <span className={classes.siteInductionText}>
                      Start Your Induction
                    </span>
                  </div>
                  <div className={classes.siteInductionHeaderWrapper}>
                    <div className={classes.qrCodeWrapper}>
                      <div className={classes.leftTop} />
                      <div className={classes.rightTop} />
                      <div className={classes.leftBottom} />
                      <div className={classes.rightBottom} />
                      <QRCode
                        id="QRCode"
                        value={`${window.location.origin}/site-induction?site=${qrData?.code}&sitemanager=${process.env.REACT_APP_SITEMANAGER_TOKEN}`}
                        size={300}
                      />
                    </div>
                  </div>
                  <div className={classes.subTextWrapper}>
                    <span className={classes.siteInducionSubtext}>
                      Use your mobile to scan the QR code above.
                      <br /> You'll be prompted to open the QR code in your
                      default browser.
                      <br /> <br /> Enjoy the experience!
                    </span>
                  </div>
                  <div className={classes.keepsafeBar}>
                    <span>
                      www.keep<span className={classes.green}>safe</span>.work
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  currentSite: makeSelectCurrentSite(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getSiteByIdRequest: (val) => dispatch(getSiteById(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeGenerator);
