import React from "react";
import { isNil, isEmpty } from "lodash";
import classes from "../reporting.module.scss";
import { format, parseISO } from "date-fns";

const formatDate = (val) =>
  !isNil(val) ? format(parseISO(val), `MM/yyyy`) : "-";

const labels = [
  { label: "Branch", width: "100px" },
  { label: "Site", sortable: true, key: "site" },
  { label: "Name", width: "150px", sortable: true, key: "name" },
 /* { label: "Company", width: "200px", sortable: true, key: "company" },*/
  { label: "Online", sortable: true, key: "status" },
  { label: "Renewal" },
  { label: "Site" },
  { label: "Renewal" },
];

export const reportingTableData = (item, branches) => {
  const data = item?.map((a) => {
    return {
      id: a.id,
      email: a.email,
      values: [
        {
          value: !isNil(
            branches?.results?.find((item) => item.id === a.site.region)?.code
          )
            ? branches?.results?.find((item) => item.id === a.site.region)?.code
            : "-",
        },
        {
          value: !isNil(a?.site?.name) ? a.site.name : "-",
        },
        {
          value: `${a.first_name} ${a.last_name}`,
        },
        /*
        {
          value:
            !isNil(a.company_data) &&
            !isNil(a.company_data.company) &&
            !isEmpty(a.company_data.company)
              ? a?.company_data?.company
              : "-",
        },
        */
        {
          value: !isNil(a?.online_status?.status)
            ? a?.online_status?.status
            : "-",
          component: (
            <div className={classes.statusWrapper}>
              <div
                className={classes.status}
                style={{
                  background:
                    !isEmpty(a?.online_status?.status) &&
                    !isNil(a.online_status.status)
                      ? tableIndicator.find(
                          (item) => item.type === a.online_status.status
                        )?.color
                      : "gray",
                }}
              />
            </div>
          ),
        },
        {
          value:
            !isNil(a?.online_status?.renewal) &&
            !isEmpty(a?.online_status?.renewal)
              ? formatDate(a.online_status.renewal)
              : "-",
        },
        {
          value: !isNil(a?.site_status?.status) ? a.site_status.status : "-",
          component: (
            <div className={classes.statusWrapper}>
              <div
                className={classes.status}
                style={{
                  background:
                    !isEmpty(a?.site_status?.status) &&
                    !isNil(a.site_status.status)
                      ? tableIndicator.find(
                          (item) => item.type === a.site_status.status
                        )?.color
                      : "gray",
                }}
              />
            </div>
          ),
        },
        {
          value:
            !isNil(a?.site_status?.renewal) && !isEmpty(a?.site_status?.renewal)
              ? formatDate(a.site_status.renewal)
              : "-",
        },
      ],
    };
  });

  return {
    columns: data,
    labels: labels,
  };
};

export const tableIndicator = [
  {
    color: "#BB6BD9",
    name: "Pending",
    type: "pending_induction",
  },
  {
    color: "#03CE91",
    name: "Successful",
    type: "permitted",
  },
  {
    color: "#F2C75E",
    name: "Renewal required",
    type: "renewal_required",
  },

  {
    color: "#E26C82",
    name: "Diqualified",
    type: "not_permitted",
  },
];
