// @flow
import { all, fork } from "redux-saga/effects";
import getInductionRequestSaga from "./sagas/getInductionRequest";
import getInductionDetailRequestSaga from "./sagas/getInductionDetailRequest";
import setSelectedSiteSaga from "./sagas/setSelectedSites";
import setCurrentRule from "./sagas/setCurrentRule";
import setFooterVisibilitySaga from "./sagas/setFooterVisibility";
import setSectionSaga from "./sagas/setSection";
import setSectionStepSaga from "./sagas/setSectionStep";
import setChapterCompletionSaga from "./sagas/setChapterCompletion";
import setDocumentCaptureSaga from "./sagas/setDocumentCapture";

function* assesmentSaga() {
  yield all([
    fork(getInductionRequestSaga),
    fork(getInductionDetailRequestSaga),
    fork(setSelectedSiteSaga),
    fork(setFooterVisibilitySaga),
    fork(setCurrentRule),
    fork(setSectionSaga),
    fork(setSectionStepSaga),
    fork(setChapterCompletionSaga),
    fork(setDocumentCaptureSaga),
  ]);
}

export default assesmentSaga;
