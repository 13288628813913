// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  CREATE_REGION_REQUEST,
  CREATE_REGION_REQUEST_SUCCESS,
  CREATE_REGION_REQUEST_ERROR,
} from "../../constants";
import axios from "../../../../services/api";
import { push } from "connected-react-router";
import { error as err, success } from "../../../../main";
import { isEmpty, isNil } from "lodash";

function* createRegionRequest(data) {
  try {
    const values = data;
    const params = new URLSearchParams();
    params.append("name", values?.values?.name);
    params.append("code", values?.values?.code);
    if (!isNil(values?.values?.branch_managers)) {
      params.append(
        "branch_managers",
        values.values.branch_managers?.map((item) => item.value).toString()
      );
    }
    if (!isNil(values?.values?.contracts_managers)) {
      params.append(
        "contracts_managers",
        values.values.contracts_managers.map((item) => item.value).toString()
      );
    }
    if (!isNil(values?.values?.hse_managers)) {
      params.append(
        "ehs_managers",
        values.values.hse_managers.map((item) => item.value).toString()
      );
    }

    const response = yield call(axios.post, `/regions/`, params);
    if (response?.data?.error) {
      yield put({
        type: CREATE_REGION_REQUEST_ERROR,
        data: response.error,
      });
      yield call(err, `${response.data.error}`);
    } else if (response.data.success === 1) {
      yield put({
        type: CREATE_REGION_REQUEST_SUCCESS,
        data: response.data,
      });
      yield call(success, `New Region Created Succesfully`);
      yield put(push("/supervisor-settings-branch"));
    }
  } catch (error) {
    console.log("error", error);
    yield call(err, error?.response?.status?.message?.detail);
    yield put({
      type: CREATE_REGION_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* createRegionRequestSaga() {
  yield takeLatest(CREATE_REGION_REQUEST, createRegionRequest);
}
