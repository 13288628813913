//utils
import "react-datepicker/dist/react-datepicker.css";
import React, { forwardRef, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import cn from "classnames";
//components
import Icon from "components/UI/IconComponent";
//locale
import classes from "./datePicker.module.scss";
import { Tooltip } from "react-tooltip";

const DateRangePicker = ({
  label,
  name,
  //   setValues,
  values,
  id,
  disabled,
  placeholder,
  format,
  //minDate ve maxDate bir date degeri almali
  minDate,
  maxDate,
  minTime,
  maxTime,
  isClearable = true,
  error,
  startDateValue,
  endDateValue,
  onChange,
  tooltipText,
}) => {
  const [startDate, setStartDate] = useState(
    startDateValue ? startDateValue : new Date()
  );
  const [endDate, setEndDate] = useState(endDateValue ? endDateValue : null);

  const onChangeVal = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    onChange({ startDate: start, endDate: end });
  };

  /*
  useEffect(() => {
    if (startDateValue) {
      setStartDate(startDateValue);
    }
  }, [startDateValue]);

  useEffect(() => {
    if (endDateValue) {
      setEndDate(endDateValue);
    }
  }, [endDateValue]);
*/
  const CustomInputComponent = ({ value, onClick, error }, ref) => (
    <button
      data-tooltip-id="date-picker"
      type="button"
      id="date-picker-button"
      className={cn(classes.customInput, "date-picker-button")}
      onClick={onClick}
      ref={ref}
    >
      <span
        className={cn(
          value ? "text-gray-700 font-medium" : "text-gray-400 font-normal"
        )}
      >
        {value ? value : placeholder}
      </span>
      <div className={classes.iconWrapper}>
        <Icon icon={"date"} size={18} color={"#666666"} />
      </div>
    </button>
  );

  const ForwardCustomInput = forwardRef(CustomInputComponent);

  return (
    <div>
      <label
        htmlFor={id}
        className={cn(classes.datePickerWrapper, error && "validation-error")}
        data-tooltip-delay-show={500}
      >
        {label ? label : null}
        <ReactDatePicker
          name={name}
          id={id}
          scrollableYearDropdown
          yearDropdownItemNumber={30}
          disabled={disabled}
          //   isClearable={isClearable}
          showTimeSelect={false}
          startDate={startDate}
          endDate={endDate}
          selected={startDate}
          customInput={<ForwardCustomInput error={error} />}
          showYearDropdown
          dateFormat={format}
          onChange={onChangeVal}
          minDate={minDate}
          maxDate={maxDate}
          minTime={minTime}
          maxTime={maxTime}
          selectsRange={true}
          popperModifiers={[
            {
              name: "arrow",
              options: { padding: 24 },
            },
          ]}
          popperPlacement="top-end"
          showPopperArrow={true}
          //router locale undefined geliyor tanimlanmasi lazim
          className={cn("custom-datepicker")}
          error={error}
        />
      </label>
      {error && <span className="validation-error">{error}</span>}
      {tooltipText && (
        <Tooltip
          className="react-tooltip-keepsafe"
          id="date-picker"
          place="left"
          type="dark"
          effect="solid"
        >
          <span>{tooltipText}</span>
        </Tooltip>
      )}
    </div>
  );
};
export default DateRangePicker;
