// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  SET_SELECTED_STEP_REQUEST,
  SET_SELECTED_STEP_REQUEST_SUCCESS,
  SET_SELECTED_STEP_REQUEST_ERROR,
} from "../constants";
import axios from "../../../services/api";

function* setSelectedStep(data) {
  const value = data;
  try {
    yield put({
      type: SET_SELECTED_STEP_REQUEST_SUCCESS,
      data: value,
    });
  } catch (error) {
    console.log(error);
  }
}

export default function* setSelectedStepSaga() {
  yield takeLatest(SET_SELECTED_STEP_REQUEST, setSelectedStep);
}
