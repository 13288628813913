// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_ERROR,
  CREATE_COMPANY,
} from "../../constants";
import axios from "../../../../services/api";
import { push } from "connected-react-router";
import { error as err, success } from "../../../../main";

function* createCompany(data) {
  try {
    const params = new URLSearchParams();
    params.append("name", data.values.name);
    params.append("address1", data.values.address1);
    params.append("address2", data.values.address2);

    const response = yield call(axios.post, `/companies/`, params);

    yield put({
      type: CREATE_COMPANY_SUCCESS,
      data: response.data,
    });
    yield call(success, `New Company Created Succesfully`);
    yield put(push("/supervisor-company"));
  } catch (error) {
    yield call(err, error.response.status.message.detail);
    yield put({
      type: CREATE_COMPANY_ERROR,
      data: error,
    });
  }
}

export default function* createCompanySaga() {
  yield takeLatest(CREATE_COMPANY, createCompany);
}
