import React from "react";

const CloseIcon = ({ onClick, style }) => {
  return (
    <svg
      onClick={onClick}
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66683 1.27337L8.72683 0.333374L5.00016 4.06004L1.2735 0.333374L0.333496 1.27337L4.06016 5.00004L0.333496 8.72671L1.2735 9.66671L5.00016 5.94004L8.72683 9.66671L9.66683 8.72671L5.94016 5.00004L9.66683 1.27337Z"
        fill="#7185BE"
      />
    </svg>
  );
};

export default CloseIcon;
