import {
  CREATE_LANGUAGE_REQUEST,
  GET_LANGUAGE_REQUEST,
  DELETE_LANGUAGE_REQUEST,
  SET_SELECTED_LANGUAGE,
} from "./constants";

export const createLanguageRequest = (payload) => {
  return {
    type: CREATE_LANGUAGE_REQUEST,
    ...payload,
  };
};

export const getLanguagesRequest = (payload) => {
  return {
    type: GET_LANGUAGE_REQUEST,
    ...payload,
  };
};

export const deleteLanguageRequest = (payload) => {
  return {
    type: DELETE_LANGUAGE_REQUEST,
    ...payload,
  };
};

export const selectLanguage = (payload) => {
  return {
    type: SET_SELECTED_LANGUAGE,
    ...payload,
  };
};
