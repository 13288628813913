import React from "react";
import classes from "./tags.module.scss";
import { capitalize } from "lodash";
import { CPopover } from "@coreui/react";

const Tags = ({ tags }) => {
  const contentHandler = (index) => {
    const result = Object.keys(tags).map((key) => tags[key]);
    const variable = result[index].map((item) =>
      capitalize(item.codename.split("_")[0])
    );
    const filtered = variable.filter((item) => {
      return (
        (item === "Add") |
        (item === "Delete") |
        (item === "View") |
        (item === "Change")
      );
    });

    const array = filtered.map((item) => ` ${item}`);
    const data = array.toString();
    const replaced = data.replace(/,(?=[^,]*$)/, " &");

    return replaced;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "90%",
        justifyContent: "flex-start",
        background: "white",
      }}
    >
      {tags.map((item, index) => (
        <div className={classes.container} key={index}>
          <CPopover
            header={false}
            content={contentHandler(index)}
            placement={"top"}
            interactive={true}
            style={{ backgroundColor: "#7185BE" }}
          >
            <div>
              <div className={classes.tags}>
                <span>{capitalize(item[0].content_type)}</span>
              </div>
            </div>
          </CPopover>
        </div>
      ))}
    </div>
  );
};

export default Tags;
