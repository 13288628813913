import React, { useState, useEffect } from "react";
import classes from "./splash.module.scss";
import Loading from "components/UI/loading";

const SplashScreen = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 100);
  }, []);

  return (
    <div className={visible ? classes.Container : classes.ContainerHidden}>
      <Loading />
    </div>
  );
};

export default SplashScreen;
