import { isNil } from "lodash";
import {
  GET_INDUCTION_REQUEST,
  GET_INDUCTION_DETAIL_REQUEST,
  SET_SELECTED_SITE,
  SET_FOOTER_VISIBILITY,
  SET_CURRENT_RULE,
  SET_SECTION,
  SET_SECTION_STEP,
  SET_CHAPTER_COMPLETION,
  DOCUMENT_CAPTURE,
} from "./constants";

export const getInductionRequest = (payload) => {
  return {
    type: GET_INDUCTION_REQUEST,
    ...payload,
  };
};

export const getInductionDetailRequest = (payload) => {
  return {
    type: GET_INDUCTION_DETAIL_REQUEST,
    ...payload,
  };
};

export const setSelectedSite = (payload) => {
  return {
    type: SET_SELECTED_SITE,
    ...payload,
  };
};

export const setCurrentRule = (payload) => {
  return {
    type: SET_CURRENT_RULE,
    ...payload,
  };
};

export const setFooterVisibility = (payload) => {
  return {
    type: SET_FOOTER_VISIBILITY,
    ...payload,
  };
};

export const setSectionReq = (payload) => {
  if (isNil(payload?.value))
    return {
      type: SET_SECTION,
      value: false,
      ...payload,
    };
  return {
    type: SET_SECTION,
    ...payload,
  };
};

export const setSectionStepReq = (payload) => {
  if (isNil(payload?.value))
    return {
      type: SET_SECTION_STEP,
      value: 1,
      ...payload,
    };
  else
    return {
      type: SET_SECTION_STEP,
      ...payload,
    };
};

export const setChapterCompletionReq = (payload) => {
  if (isNil(payload?.value))
    return {
      type: SET_CHAPTER_COMPLETION,
      value: false,
      ...payload,
    };
  else
    return {
      type: SET_CHAPTER_COMPLETION,
      ...payload,
    };
};


export const documentCaptureReq = (payload) => {
  return {
    type: DOCUMENT_CAPTURE,
    ...payload,
  };
};
