import Intro1 from "../../assets/images/Introduction1.jpg";
import Intro2 from "../../assets/images/Introduction2.jpg";
import WhatCouldHappen from "../../assets/images/whatwedo.png";

const Rule1 = process.env.PUBLIC_URL + "/rulesbg/BG_Card-Rule-1.jpg";
const Rule3 = process.env.PUBLIC_URL + "/rulesbg/BG_Card-Rule-2.jpg";
const Rule2 = process.env.PUBLIC_URL + "/rulesbg/BG_Card-Rule-3.jpg";
const Rule4 = process.env.PUBLIC_URL + "/rulesbg/BG_Card-Rule-4.jpg";
const Rule5 = process.env.PUBLIC_URL + "/rulesbg/BG_Card-Rule-5.jpg";
const Rule6 = process.env.PUBLIC_URL + "/rulesbg/BG_Card-Rule-6.jpg";

const IntroSpeed = process.env.PUBLIC_URL + "/speed/intro.png";
const ExclusionIntro = process.env.PUBLIC_URL + "/exclusion/intro.png";
const DustIntro = process.env.PUBLIC_URL + "/dust/intro.png";
const FallsIntro = process.env.PUBLIC_URL + "/falls/intro.png";
const ScaffoldIntro = process.env.PUBLIC_URL + "/scaffold/intro.png";

const FallsCould = process.env.PUBLIC_URL + "/falls/could.png";
const SpeedCould = process.env.PUBLIC_URL + "/speed/could1.png";
const SpeedCould1 = process.env.PUBLIC_URL + "/speed/could2.png";
const SpeedCould2 = process.env.PUBLIC_URL + "/speed/could3.png";

const ScaffoldCould1 = process.env.PUBLIC_URL + "/scaffold/assesment.png";

//signs
const Icon1 = process.env.PUBLIC_URL + "/signs/drugs.jpg";
const Icon2 = process.env.PUBLIC_URL + "/signs/exclusion.jpg";
const Icon3 = process.env.PUBLIC_URL + "/signs/speed.jpg";
const Icon4 = process.env.PUBLIC_URL + "/signs/fall.jpg";
const Icon5 = process.env.PUBLIC_URL + "/signs/scaffold.jpg";
const Icon6 = process.env.PUBLIC_URL + "/signs/dust.jpg";

const Rules = [
  {
    id: 3,
    aid: 3,
    slug: "Drugs & Alcohol",
    slugID: "induction.3.slug",
    title: "We always work free from drugs and alcohol",
    titleID: "induction.3.title",
    color: "#158FCE",
    progress: "10%",
    image: Rule1,
    icon: Icon1,
    completed: false,
    questions: [
      {
        type: "introduction",
        id: 1,
        title: "Introduction",
        content: [
          "It’s not just about what you do ‘at work’. Drugs and alcohol can take a long time to wear off.",
          "Some legal drugs and medicines can make you more likely to have an accident. Make sure you talk to your doctor.",
        ],
        images: [Intro1, Intro2],
      },
      {
        type: "What-could-happen?",
        id: 2,
        title: "What could happen?",
        content: [
          {
            title: "Alcohol:",
            text:
              "<p><b>ANY</b> amount of alcohol will affect you and your judgment. Whether you’re operating machinery, on the tools, or just simply on-site.</p>",
          },
          {
            title: "Drugs:",
            text:
              "<p>Like alcohol, you’re much more likely to hurt others, or be hurt yourself if you are on<b> ANY</b> drugs.</p>",
          },
        ],
        images: [WhatCouldHappen],
      },
      {
        type: "drag-drop",
        id: 3,
        content: [
          {
            id: 0,
            do: "info",
            text:
              "<p><b>Great stuff!</b> <br/> You’re all done in this section - now it’s time for a quick knowledge check before moving on to the next Life Saving Rule</p>",
          },
          {
            id: 1,
            do: "info",
            text:
              "<p><b>Swipe here to learn about the Do’s & Don’ts of this Life Saving Rule.</b></p>",
          },
          {
            id: 2,
            text:
              "<p><b>LET US KNOW</b> if you have taken any medication that may affect your work. You may be given other duties.</p>",
            do: true,
          },
          {
            id: 3,
            text:
              "<p><b>HELP YOUR MATES.</b>  Talk to your supervisor if you know that someone drinks or uses drugs.</p>",
            do: true,
          },
          {
            id: 4,
            text:
              "<p><b>NEVER</b> come to work under the influence of alcohol or drugs.</p>",
            do: false,
          },
          {
            id: 5,
            text: "<p><b>NEVER</b> bring drugs or alcohol to work.</p>",
            do: false,
          },
          {
            id: 6,
            text:
              "<p><b>NEVER</b> cover for your mates. You’re not helping them.</p>",
            do: false,
          },
          {
            id: 7,
            text:
              "<p><b>NEVER</b> pretend that it’s ‘not a big deal’. Accidents can kill. We all want to get home safely.</p>",

            do: false,
          },
        ],
      },
    ],
  },
  {
    id: 4,
    aid: 4,
    slug: "Exclusion Zones",
    title: "We always set and obey exclusion zones",
    titleID: "induction.4.title",
    color: "#F86C6B",
    progress: "0%",
    image: Rule2,
    icon: Icon2,
    completed: false,
    questions: [
      {
        type: "introduction",
        id: 1,
        title: "Introduction",
        content: [
          "Exclusion zones stop people walking into dangerous areas.",
          "Inside exclusion zones, you must follow the safety rules and wear the right protective equipment.",
        ],
        images: [ExclusionIntro],
      },
      {
        type: "What-could-happen?",
        id: 2,
        title: "What could happen?",
        content: [
          {
            text: "Exclusion zones are there for a reason.",
          },
          {
            text:
              "If an exclusion zone has been set up, then it is a ‘danger area’ where accidents could happen.",
          },
          {
            text:
              "They might be set up around vehicles, excavators, concrete pumps, areas where trucks load and unload, or where the work being done could harm you - either by flying or falling items or breathing in harmful substances.",
          },
          {
            text:
              "These areas are where accidents are more likely to happen and be serious and life-changing.",
          },
        ],
        images: [],
      },
      {
        type: "drag-drop",
        id: 3,
        content: [
          {
            id: 0,
            do: "info",
            text:
              "<p><b>Great stuff!</b> <br/> You’re all done in this section - now it’s time for a quick knowledge check before moving on to the next Life Saving Rule</p>",
          },
          {
            id: 1,
            do: "info",
            text:
              "<p><b>Swipe here to learn about the Do’s & Don’ts of this Life Saving Rule.</b></p>",
          },
          {
            id: 2,
            text: "<p>Know and follow safety rules.</p>",
            do: true,
          },
          {
            id: 3,
            text: "<p>Be aware of pedestrians and other workers.</p>",
            do: true,
          },
          {
            id: 4,
            text: "<p>Know the control measures for hazardous substances.</p>",
            do: true,
          },

          {
            id: 5,
            text:
              "<p><b>NEVER</b> enter unless authorised and using proper protective equipment.</p>",
            do: false,
          },
          {
            id: 6,
            text:
              "<p><b>NEVER</b> allow pedestrians into an exclusion zone, unless authorised.</p>",
            do: false,
          },
        ],
      },
    ],
  },

  {
    id: 5,
    aid: 5,
    slug: "Speed Limit",
    title: "We always obey the site speed limit",
    titleID: "induction.5.title",
    color: "#63C2DE",
    progress: "0%",
    image: Rule3,
    icon: Icon3,
    completed: false,
    questions: [
      {
        type: "introduction",
        id: 1,
        title: "Introduction",
        content: [
          " It doesn’t matter if you are a driver or a pedestrian; slower speeds are safer for everybody.",

          " We share our busy sites with the community. Deliveries, excavators, kids, trucks, cars and the public are all nearby.",
        ],
        images: [IntroSpeed],
      },
      {
        type: "What-could-happen?",
        id: 2,
        title: "What could happen?",
        content: [
          {
            text: "Accidents are made worse by speed.",
          },
          {
            text:
              " If you go too fast, you are more likely to have an accident.",
          },
          {
            text:
              " If you have an accident, you are more likely to hurt someone or be hurt yourself, if you are going too fast.",
          },
        ],
        images: [SpeedCould, SpeedCould1, SpeedCould2],
      },
      {
        type: "drag-drop",
        id: 3,
        content: [
          {
            id: 0,
            do: "info",
            text:
              "<p><b>Great stuff!</b> <br/> You’re all done in this section - now it’s time for a quick knowledge check before moving on to the next Life Saving Rule</p>",
          },

          {
            id: 1,
            do: "info",
            text:
              "<p><b>Swipe here to learn about the Do’s & Don’ts of this Life Saving Rule.</b></p>",
          },
          {
            id: 2,
            text:
              "<p>Know your speed limits.<br>Always know the speed limits in the area you work in.</p>",
            do: true,
          },
          { id: 3, text: "<p>Stick to the speed limit.</p>", do: true },
          {
            id: 4,
            text: "<p>Look out for pedestrians as well as other vehicles.</p>",
            do: true,
          },
          {
            id: 5,
            text:
              "<p><b>NEVER</b> get impatient and go faster than the speed limit.</p>",
            do: false,
          },
        ],
      },
    ],
  },
  {
    id: 6,
    aid: 6,
    slug: "Working with Scaffold",
    titleID: "induction.6.title",
    title:
      "We never alter scaffold and fall protection unless approved by installer",
    color: "#FFC107",
    progress: "0%",
    image: Rule5,
    icon: Icon5,

    completed: false,
    questions: [
      {
        type: "introduction",
        id: 1,
        title: "Introduction",
        content: [
          "Scaffold, safety decks, nets, fall bags, etc. are provided to keep you and other workers safe.",
          "If you need to remove any part of the scaffold, safety deck and/ or a net to do your work, talk to a Supervisor. They will get this done.",
          "You should not remove or move parts of safety equipment yourself.",
        ],
        images: [ScaffoldIntro],
      },
      {
        type: "What-could-happen?",
        id: 2,
        title: "What could happen?",
        content: [
          {
            text:
              "Falling from height has the potential to cause serious harm and even death.",
          },
          {
            text:
              "Falling from height can have long-lasting/ permanent injuries that can affect the way you work or live moving forward.",
          },
          {
            text:
              "Life-altering injuries can have negative effects on family life.",
          },
        ],
        images: [ScaffoldCould1],
      },
      {
        type: "drag-drop",
        id: 3,
        content: [
          {
            id: 0,
            do: "info",
            text:
              "<p><b>Great stuff!</b> <br/> You’re all done in this section - now it’s time for a quick knowledge check before moving on to the next Life Saving Rule</p>",
          },
          {
            id: 1,
            do: "info",
            text:
              "<p><b>Swipe here to learn about the Do’s & Don’ts of this Life Saving Rule.</b></p>",
          },
          {
            id: 2,
            text:
              "<p><b>LET US KNOW</b>. of any scaffold changes that need to be made by the scaffolder 24hrs in advance.</p>",
            do: true,
          },
          {
            id: 3,
            text: "<p>Report any issues promptly to the Supervisor.</p>",
            do: true,
          },
          {
            id: 4,
            text:
              "<p>Always check that scaffold has been checked once a week.</p>",
            do: true,
          },
          {
            id: 5,
            text:
              "<p>We <b>NEVER</b> alter scaffold, safety nets, safety decks, handrails or <b>ANY</b> fall protection unless it is approved by the installer.</p>",
            do: false,
          },
          {
            id: 6,
            text: "<p>We <b>NEVER</b> stand on hand rails.</p>",
            do: false,
          },
        ],
      },
    ],
  },
  {
    id: 7,
    aid: 8,
    title: "We always protect ourselves against falls",
    titleID: "induction.7.title",
    slug: "Fall Protection",
    color: "#4DBD74",
    progress: "0%",
    image: Rule4,
    icon: Icon4,
    completed: false,
    questions: [
      {
        type: "introduction",
        id: 1,
        title: "Introduction",
        content: [
          "If we work at height, we always use the correct fall protection, such as industrial strength ladders & trestles, mobile scaffold or similar.",
          "Anywhere we could fall from, we must ensure the edges are protected and gaps covered.",
        ],
        images: [FallsIntro],
      },
      {
        type: "What-could-happen?",
        id: 2,
        title: "What could happen?",
        content: [
          {
            text:
              "Any fall from a height could cause serious injury and even death. It doesn’t need to be very high. A fall, even from less than 2m, can have long-lasting or permanent injuries that can affect the way you live.",
          },
          {
            text:
              "If you die or become are disabled, who is going to provide for your family?",
          },
        ],
        images: [FallsCould],
      },
      {
        type: "drag-drop",
        id: 3,
        content: [
          {
            id: 0,
            do: "info",
            text:
              "<p><b>Great stuff!</b> <br/> You’re all done in this section - now it’s time for a quick knowledge check before moving on to the next Life Saving Rule</p>",
          },

          {
            id: 1,
            do: "info",
            text:
              "<p><b>Swipe here to learn about the Do’s & Don’ts of this Life Saving Rule.</b></p>",
          },
          {
            id: 2,
            text:
              "<p><b>ALWAYS</b> use appropriate equipment, industrial strength ladders & trestles, mobile scaffold, etc.</p>",
            do: true,
          },
          {
            id: 3,
            text:
              "<p><b> LET US KNOW</b> if anything looks or feels unsafe.</p>",
            do: true,
          },
          {
            id: 4,
            text:
              "<p><b>NEVER</b> access work at height without the correct fall protection, such as safety nets, fall bags, edge protection, etc.</p>",
            do: false,
          },
          {
            id: 5,
            text: "<p><b>NEVER</b> use three step ladders.</p>",
            do: false,
          },
        ],
      },
    ],
  },

  {
    id: 8,
    aid: 7,
    slug: "Dust & Hazardous...",
    title: "We protect everyone from dust and harmful substances",
    titleID: "induction.8.title",
    color: "#2F353A",
    progress: "0%",
    image: Rule6,
    icon: Icon6,

    completed: false,
    questions: [
      {
        type: "introduction",
        id: 1,
        title: "Introduction",
        content: [
          "Workers in construction are 20 times more likely to die from breathing harmful substances than from a work accident.",

          "Many work activities can create dust such as  cutting, sanding, grinding and sweeping.  The dust you can’t see is the dust that can cause the most harm. ",
        ],
        images: [DustIntro],
      },
      {
        type: "What-could-happen?",
        id: 2,
        title: "What could happen?",
        content: [
          {
            text:
              "Inhaling harmful substances causes serious illnesses over time that affect  your lungs and overall health.",
          },
          {
            text:
              "<p>Some of these illnesses can not be cured or treated. They cause lasting lung damage that in the long run causes death.</p>",
          },
        ],
        images: [],
      },
      {
        type: "drag-drop",
        id: 3,
        content: [
          {
            id: 0,
            do: "info",
            text:
              "<p><b>Great stuff!</b> <br/> You’re all done in this section - now it’s time for a quick knowledge check before moving on to the next Life Saving Rule</p>",
          },
          {
            id: 1,
            do: "info",
            text:
              "<p><b>Swipe here to learn about the Do’s & Don’ts of this Life Saving Rule.</b></p>",
          },
          {
            id: 2,
            text:
              "<p><b>ALWAYS</b> wear correct breathing protection when cutting and handling materials.</p>",
            do: true,
          },
          {
            id: 3,
            text:
              "<p><b>ALWAYS</b> use correct dust collection equipment and exclusion zones when cutting materials.</p>",

            do: true,
          },
          {
            id: 4,
            text:
              "<p><b>ALWAYS</b> wear protective equipment when working with hazardous materials – Concrete, Cement and Chemicals.</p>",

            do: true,
          },
          {
            id: 5,
            text:
              "<p><b>NEVER</b>  work without the protection required or given to you by your employer.</p>",
            do: false,
          },
        ],
      },
    ],
  },
];

export default Rules;
