import {
  SET_SELECTED_STEP_REQUEST,
  RESET_SELECTED_STEP_REQUEST,
} from "./constants";

export const setSelectedStep = (payload) => {
  return {
    type: SET_SELECTED_STEP_REQUEST,
    ...payload,
  };
};
export const resetSelectedStep = () => {
  return {
    type: RESET_SELECTED_STEP_REQUEST,
  };
};
