import React, { useEffect, useRef } from "react";
import { format, parseISO, intervalToDuration } from "date-fns";
import { isNil } from "lodash";

export function createMarkup(val) {
  return { __html: val };
}

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const incrementString = function (str, rawList) {
  var regexp = /\d+$/g;
  if (str.match(regexp)) {
    var trailingNumbers = str.match(regexp)[0];
    var number = parseInt(trailingNumbers);
    number += 1;

    // Replace the trailing numbers and put back incremented
    str = str.replace(/\d+$/g, "");
    str += number;
  } else {
    str += " Copy 1";
  }
  return rawList.filter((item) => item.name === str).length > 0
    ? `${str} Copy 1`
    : str;
};

export const msToTime = (ms) => {
  const seconds = ms / 1000;
  let hours = Math.floor(seconds / (60 * 60));

  let divisor_for_minutes = seconds % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);

  let divisor_for_seconds = divisor_for_minutes % 60;
  let sec = Math.ceil(divisor_for_seconds);

  let obj = {
    h: hours < 10 ? `0${hours}` : hours,
    m: minutes < 10 ? `0${minutes}` : minutes,
    s: sec < 10 ? `0${sec}` : sec,
  };

  return `${obj.h}:${obj.m}:${obj.s}`;
};

const isInDom = (obj) => {
  return Boolean(obj.closest("body"));
};

const hasParent = (element, root) => {
  return root && root.contains(element) && isInDom(element);
};

export const ClickOutside = ({ active = true, onClick, children }) => {
  const innerRef = useRef();

  const handleClick = (event) => {
    if (!hasParent(event.target, innerRef?.current)) {
      if (typeof onClick === "function") {
        onClick(event);
      }
    }
    if (event.keyCode === 27) {
      onClick(event);
    }
  };

  useEffect(() => {
    if (active) {
      document.addEventListener("mousedown", handleClick);
      document.addEventListener("touchstart", handleClick);
      document.addEventListener("keydown", handleClick);
    }

    return () => {
      if (active) {
        document.removeEventListener("mousedown", handleClick);
        document.removeEventListener("touchstart", handleClick);
        document.removeEventListener("keydown", handleClick);
      }
    };
  });

  return React.cloneElement(children, { ref: innerRef });
};

export const formatDate = (val) =>
  !isNil(val) ? format(parseISO(val), `dd/MM/yyyy`) : "-";

export const formatTime = (val) =>
  !isNil(val) ? format(parseISO(val), `hh:mm:ss`) : "-";

export const formatDateTime = (val) =>
  !isNil(val) ? format(parseISO(val), `dd/MM/yyyy HH:mm:ss`) : "-";

export const formatDateTimeHourMinute = (val) =>
  !isNil(val) ? format(parseISO(val), `dd/MM/yyyy, HH:mm`) : "-";

export const getFilterValues = (options) => {
  const values = options?.filter((a) => a.checked)?.map((item) => item.value);
  
  if(values == undefined)
     return null;

  return values.length ? values : null;
};

export const secondToHours = (seconds) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });

  const hours = duration.hours < 10 ? `0${duration.hours}` : duration.hours;
  const minutes =
    duration.minutes < 10 ? `0${duration.minutes}` : duration.minutes;
  const sec = duration.seconds < 10 ? `0${duration.seconds}` : duration.seconds;

  return `${hours}:${minutes}:${sec}`;
};

export const isMobile = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const notificationTypeHandler = (val) => {
  switch (val) {
    case 0:
      return "Everyone";
    case 1:
      return "Everyone currently checked in";
    case 2:
      return "Everyone on site";
    default:
      return "-";
  }
};

export function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
