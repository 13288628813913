// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  EDIT_REGION_REQUEST,
  EDIT_REGION_REQUEST_ERROR,
  EDIT_REGION_REQUEST_SUCCESS,
} from "../../constants";
import axios from "../../../../services/api";
import { push } from "connected-react-router";
import { success } from "../../../../main";
import { isNil, isEmpty } from "lodash";

function* editRegionRequest(data) {
  try {
    const values = data;
    const params = new URLSearchParams();

    params.append("id", values.values.id);
    params.append("name", values.values.name);
    params.append("code", values.values.code);
    if (!isNil(values?.values?.branch_managers)) {
      params.append(
        "branch_managers",
        values.values.branch_managers?.map((item) => item.value).toString()
      );
    }
    if (!isNil(values?.values?.contracts_managers)) {
      params.append(
        "contracts_managers",
        values.values.contracts_managers.map((item) => item.value).toString()
      );
    }
    if (!isNil(values?.values?.hse_managers)) {
      params.append(
        "ehs_managers",
        values.values.hse_managers.map((item) => item.value).toString()
      );
    }

    const response = yield call(axios.put, `/regions/`, params);
    if (response.data.error) {
      yield put({
        type: EDIT_REGION_REQUEST_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: EDIT_REGION_REQUEST_SUCCESS,
        data: response.data,
      });
    }
    yield call(success, `Region Updated Succesfully`);
    yield put(push("/supervisor-settings-branch"));
  } catch (error) {
    yield put({
      type: EDIT_REGION_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* editRegionRequestSaga() {
  yield takeLatest(EDIT_REGION_REQUEST, editRegionRequest);
}
