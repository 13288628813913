// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  MERGE_COMPANY_SUCCESS,
  MERGE_COMPANY_ERROR,
  MERGE_COMPANY,
} from "../../constants";
import axios from "../../../../services/api";
import { isNil } from "lodash";
import { error as err, success } from "../../../../main";

function* mergeCompany(data) {
  try {
    const params = new URLSearchParams();
    params.append("ids", data.ids);
    if (!isNil(data.combine_with)) {
      params.append("combine_with", data.combine_with);
    }
    if (!isNil(data.name)) {
      params.append("name", data.name);
    }

    const response = yield call(axios.post, `/company-merge/`, params);
    if (response.data.error) {
      yield put({
        type: MERGE_COMPANY_ERROR,
        data: response.error,
      });
    } else {
      if (response.data.success === 1) {
        data.callBack();
        yield call(success, response.data.message);
      }
      if (response.data.success === 0) {
        data.callBack();
        yield call(err, response.data.message);
      }
      yield put({
        type: MERGE_COMPANY_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    data.callBack();
    yield call(err, "Something went wrong.");
    yield put({
      type: MERGE_COMPANY_ERROR,
      data: error,
    });
  }
}

export default function* mergeCompanySaga() {
  yield takeLatest(MERGE_COMPANY, mergeCompany);
}
