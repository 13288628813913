import classes from "./lookAround.module.scss";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Button from "components/UI/button";
import Footer from "components/UI/footer";
import Heading from "components/UI/heading";
import { Link } from "react-router-dom";
import Loading from "components/UI/loading";

import { getMeRequest, setMeRequest } from "../../store/auth/actions";
import { getProgressRequest, setProgressRequest } from "store/progress/actions";
import { setCurrentSection, getSectionTime } from "store/time/actions";

import { makeSelectSelectedLanguage } from "store/language/selector";
import { makeSelectProgress } from "store/progress/selector";
import {
  makeSelectMe,
  makeSelectLoading as makeSelectMeLoading,
} from "../../store/auth/selector";

import { saveProgress, useLocate } from "utils/hooks";
import { makeSelectSelectedInduction } from "store/induction/selector";
import { isEmpty, isNil } from "lodash";
import TickIcon from "./tick.svg";

const LookAround = ({
  history,
  selectedInduction,
  selectedLanguage,
  loading,
  getMe,
  setMe,
  me,
  isPreview = false,
  pageData
}) => {
  
  var { previous, current, next } = useLocate(
    selectedInduction,
    history?.location.pathname
  );

  if(isPreview)
     current = pageData;

  const [vrStatus, setVrStatus] = useState();
  const [vrData, setVrData] = useState([]);

  useEffect(() => {

    if(!isPreview)
    {
       saveProgress(selectedInduction, history.location.pathname, 0);
    }  
  
    getMe();

  }, []);

  useEffect(() => {

    if (!isNil(me)) {
      setVrStatus(me?.vrstatus);

      if (isNil(me?.vrstatus)) {
        const statusData = current?.data?.vr?.map((item, index) => {
          return {
            id: index,
            completed: false,
          };
        });
        setVrData(statusData);
      } else {
        setVrData(JSON.parse(me?.vrstatus));
      }
    }
  }, [me,current]);

  if (loading && isEmpty(vrData)) {
    return (
      <div className={classes.loadingWrapper}>
        {" "}
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className={classes.Container}>
        <Heading
          title={current?.title}
          titleId={isPreview ? current?.title : current?.title_id}
          description={current?.data?.subtitle}
          descriptionId={isPreview? current?.data?.subtitle : current?.data?.subtitle_id}
        />
        <div className={classes.vrWrapper}>
          {current?.data?.vr.map((item, index) => (
            <>
              <div className={classes.VRContainer}>
                {vrData[index]?.completed && (
                  <div className={classes.completedWrapper}>
                    <img src={TickIcon} />
                  </div>
                )}
                <img
                  alt="VR Experience 1"
                  className={classes.Image}
                  src={item.image}
                />
              </div>
              <div className={classes.ButtonContainer}>
                <Button
                  borderRadius={"6px"}
                  height={"44px"}
                  text={"Take a look"}
                  theme="blue"
                  formatID={"take-a-look-button.text"}
                  onClick={() => {
                    const newVRData = vrData.map((a, i) => {
                      return {
                        ...a,
                        completed: i === index ? true : a.completed,
                      };
                    });
                    setMe({
                      vrstatus: JSON.stringify(newVRData),
                      language: selectedLanguage?.code,
                      callBack: () => {
                        return (window.location = item.url);
                      },
                    });
                  }}
                  disabled={false}
                />
              </div>
            </>
          ))}
        </div>
      </div>
      {!isPreview && (

        <Footer
        isHidden={
          vrData.filter((item) => item.completed).length !== vrData.length
        }
        >
          <Button
            onClick={() => {
              saveProgress(selectedInduction, history?.location.pathname, 100);
              history.push(next?.url);
            }}
            subText={next?.show_in_menu ? next?.menu_name : next?.title}
            type={"submit"}
            theme="blue"
            id="apply-button"
            text="Continue"
            formatID="continue.button"
          />
        </Footer>

      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  progressData: makeSelectProgress(),
  selectedLanguage: makeSelectSelectedLanguage(),
  selectedInduction: makeSelectSelectedInduction(),
  me: makeSelectMe(),
  loading: makeSelectMeLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getProgress: (val) => dispatch(getProgressRequest(val)),
    setProgress: (val) => dispatch(setProgressRequest(val)),
    setSection: (val) => dispatch(setCurrentSection(val)),
    getSectionTimeReq: (val) => dispatch(getSectionTime(val)),
    getMe: (val) => dispatch(getMeRequest(val)),
    setMe: (val) => dispatch(setMeRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LookAround);
