// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  SET_COUNT_TIME_REQUEST,
  SET_COUNT_TIME_REQUEST_SUCCESS,
  SET_COUNT_TIME_REQUEST_ERROR,
} from "../constants";

function* setCountTime(data) {
  try {
    yield put({
      type: SET_COUNT_TIME_REQUEST_SUCCESS,
      data: data.time,
    });
  } catch (error) {
    yield put({
      type: SET_COUNT_TIME_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* setCountTimeSaga() {
  yield takeLatest(SET_COUNT_TIME_REQUEST, setCountTime);
}
