import React, { useState } from "react";
import classes from "./camera.module.scss";
import Button from "../../components/UI/button";
import Footer from "../../components/UI/footer";
import { documentCaptureReq } from "../../store/induction/actions";
import { makeSelectLoading } from "../../store/auth/selector";
import { makeSelectSelectedInduction } from "store/induction/selector";

import { makeSelectProgress } from "../../store/progress/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import CameraIcon from "./camera.png";
import Camera, { IMAGE_TYPES,FACING_MODES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import RetakePhotoIcon from "./retake.png";
import CameraDisabledIcon from "./selfie_failed.png";
import queryString from "query-string";
import { isNil } from "lodash";
import { useLocate, saveProgress } from "utils/hooks";

const CameraView = ({ documentCaptureRequest, history, loading, selectedInduction}) => {
  const [cameraView, setCameraView] = useState(false);
  const [dataUri, setDataUri] = useState("");

  const [cameraAccess, setCameraAccess] = useState(true);

  const last_induction = queryString.parse(window.location.search).section_id;

  const { previous, current, next } = useLocate(
    selectedInduction,
    history?.location.pathname
  );


  //console.log("last induction", selectedInduction);

  //console.log("data",history?.location.data);

  const handleTakePhotoAnimationDone = (dataUri) => {
    setDataUri(dataUri);
    setCameraView(false);
  };

  const isFullscreen = true;

  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return documentCaptureRequest({
      document: blob,
      last_induction: last_induction,
      title: history?.location?.data?.item_name,
      induction_id: selectedInduction?.id
    });
  }
  return (
    <div className={classes.container}>
      <>
        <>
          {dataUri && (
            <>
              <div className={classes.takenPhotoContainer}>
                <img
                  src={dataUri}
                  className={classes.takenPhoto}
                  alt={"takenPhoto"}
                />
                <img
                  alt={"retakePhotoIcon"}
                  src={RetakePhotoIcon}
                  className={classes.retakeIcon}
                  onClick={() => {
                    setCameraView(true);
                  }}
                />
              </div>
              <Footer>
                <Button
                  loading={loading}
                  onClick={() => {
                    dataURItoBlob(dataUri);
                    history.push({pathname: next?.url,completed_id: history?.location?.data?.item_id});
                  }}
                  height={"60px"}
                  id="apply-button"
                  text="Submit & Continue"
                  type="submit"
                />
              </Footer>
            </>
          )}
        </>
        {cameraView && (
          <>
            {!cameraAccess ? (
              <>
                <img
                  className={classes.icon}
                  src={CameraDisabledIcon}
                  alt={"disabled camera icon"}
                />
                <span className={classes.title}>{history?.location?.data?.capture_failed_title}</span>
                <span className={classes.description}>
                {history?.location?.data?.capture_failed_description}
                </span>
                <span className={classes.smile}>
                {history?.location?.data?.capture_failed_subtitle}
                </span>

                <Footer>
                  <Button
                    onClick={() => {
                      history.push({pathname: next?.url});
                    }}
                    height={"60px"}
                    formatID="camera.skip"
                    id="apply-button"
                    text="Skip step"
                    type="submit"
                  />
                </Footer>
              </>
            ) : (
              <div className={classes.fullCamera}>
                <Camera
                  onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
                  isFullscreen={isFullscreen}
                  onCameraError={(err) => setCameraAccess(false)}
                  isMaxResolution={true}
                  idealResolution={{ width: 1080, height: 1920 }}
                  imageType={IMAGE_TYPES.JPG}
                  idealFacingMode={FACING_MODES.ENVIRONMENT}
                />
              </div>
            )}
          </>
        )}
      </>
      {!cameraView && !dataUri && (
        <>
          <img className={classes.icon} src={CameraIcon} alt={"cameraIcon"} />
          <span className={classes.title}>{history?.location?.data?.capture_title}</span>
          <span className={classes.description}>
              {history?.location?.data?.capture_description}
          </span>
          <span className={classes.smile}>{history?.location?.data?.capture_punchline}</span>
          <span className={classes.information}>{history?.location?.data?.capture_subtitle}</span>
          <Footer>
            <Button
              onClick={() => setCameraView(true)}
              height={"60px"}
              formatID="camera.button"
              id="apply-button"
              text="Camera"
              type="submit"
              theme="blue"
            />
          </Footer>
        </>
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  progressData: makeSelectProgress(),
  loading: makeSelectLoading(),
  selectedInduction: makeSelectSelectedInduction(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    documentCaptureRequest: (val) => dispatch(documentCaptureReq(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CameraView);
