// @flow
import { all, fork } from "redux-saga/effects";
import createLanguageRequest from "./sagas/createLanguageRequest";
import getLanguagesSaga from "./sagas/getLanguages";
import deleteLanguageSaga from "./sagas/deleteLanguage";
import setSelectedLanguageSaga from "./sagas/setSelectedLanguage";

function* languageSaga() {
  yield all([
    fork(createLanguageRequest),
    fork(getLanguagesSaga),
    fork(deleteLanguageSaga),
    fork(setSelectedLanguageSaga),
  ]);
}

export default languageSaga;
