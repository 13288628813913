import React from "react";

const CheckIcon = ({ checked, bigger }) => {
  return (
    <svg
      width={bigger ? "16" : "12"}
      height={bigger ? "16" : "12"}
      viewBox="0 0 12 12"
      fill={checked ? "none" : "#fff"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0H0V12H12V0ZM4.66667 9.33333L1.33333 6L2.27333 5.06L4.66667 7.44667L9.72667 2.38667L10.6667 3.33333L4.66667 9.33333Z"
        fill={checked ? "#6FCF97" : "#fff"}
      />
    </svg>
  );
};

export default CheckIcon;
