// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  UPDATE_CONTRACTOR_CARD,
  UPDATE_CONTRACTOR_CARD_ERROR,
  UPDATE_CONTRACTOR_CARD_SUCCESS,
} from "../constants";
import axios from "../../../services/api";
import { push } from "connected-react-router";
import { error as err, success } from "../../../main.js";

function* updateContractorCard(data) {
  try {
    const params = new URLSearchParams();
    params.append("id", data.id);
    params.append("user", data.user_id);
    params.append("site", data.site_id);
    params.append("type", data.cardType);
    params.append("action", data.action);
    params.append("summary", data.summary);

    const response = yield call(axios.put, `/cards/`, params);

    yield put({
      type: UPDATE_CONTRACTOR_CARD_SUCCESS,
      data: response.data,
    });
    yield call(() => data.callBack());
    yield call(success, `Card Updated Succesfully`);
    // yield put(push("/supervisor-sites"));
  } catch (error) {
    yield call(err, error.response.status.message.detail);
    yield put({
      type: UPDATE_CONTRACTOR_CARD_ERROR,
      data: error,
    });
  }
}

export default function* updateContractorCardSaga() {
  yield takeLatest(UPDATE_CONTRACTOR_CARD, updateContractorCard);
}
