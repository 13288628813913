export const mockConfig = [
  {
    title: "Introduction",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus molestie sagittis nisi, ut ullamcorper neque cursus ac.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus molestie sagittis nisi, ut ullamcorper neque cursus ac. ",
  },
  {
    title: "Image & text",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus molestie sagittis nisi, ut ullamcorper neque cursus ac. ",
    image: require("./1.png"),
  },
  {
    title: "Text",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus molestie sagittis nisi, ut ullamcorper neque cursus ac.  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus molestie sagittis nisi, ut ullamcorper neque cursus ac. ",
  },
  {
    title: "Conclusion",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus molestie sagittis nisi, ut ullamcorper neque cursus ac. ",
  },
];