// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  STAFF_VERIFY_REQUEST,
  STAFF_VERIFY_REQUEST_SUCCESS,
  STAFF_VERIFY_REQUEST_ERROR,
} from "../constants";
import axios from "../../../services/public";
import { error as err } from "../../../main.js";

function* staffVerify(data) {
  try {
    const values = data.payload;
    const params = new URLSearchParams();
    params.append("email", values.data.email);
    params.append("token", values.data.token);
    params.append("password", values.data.password);

    const response = yield call(axios.post, "/staff-verify/", params);

    if (response.data.success === 0) {
      yield put({
        type: STAFF_VERIFY_REQUEST_ERROR,
        data: response.data.message,
      });
      yield call(err, response.data.message);
    } else if (response.data.error) {
      yield put({
        type: STAFF_VERIFY_REQUEST_ERROR,
        data: response.data.error,
      });
    } else {
      yield put({
        type: STAFF_VERIFY_REQUEST_SUCCESS,
        data: response.data.languages,
      });
      data.payload.callback();
    }
  } catch (error) {
    yield put({
      type: STAFF_VERIFY_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* staffVerifySaga() {
  yield takeLatest(STAFF_VERIFY_REQUEST, staffVerify);
}
