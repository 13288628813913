import React from "react";
import classes from "./footer.module.scss";
import cn from "classnames";
import { Portal } from "react-portal";
const Footer = ({ children, transparent, isHidden, rulesPage }) => {
  return (
    <Portal>
      <div
        className={cn(
          isHidden ? classes.FooterContainerHidden : classes.FooterWrapper,
          rulesPage && classes.RulesFooter
        )}
      >
        <div className={classes.FooterContainer}>{children}</div>
      </div>
    </Portal>
  );
};

export default React.memo(Footer);
