// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  DELETE_LANGUAGE_REQUEST,
  DELETE_LANGUAGE_REQUEST_SUCCESS,
  DELETE_LANGUAGE_REQUEST_ERROR,
} from "../constants";
import axios from "../../../services/public";
function* deleteLanguage(data) {
  try {
    const values = data;
    const response = yield call(
      axios.delete("/language/", { data: { code: values.code } })
    );
    data.callback({ success: true, code: values.code });

    yield put({
      type: DELETE_LANGUAGE_REQUEST_SUCCESS,
      data: response,
    });
  } catch (error) {
    data.callback({ success: true, code: data.code });

    yield put({
      type: DELETE_LANGUAGE_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* deleteLanguageSaga() {
  yield takeLatest(DELETE_LANGUAGE_REQUEST, deleteLanguage);
}
