// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_USERS_REQUEST_SUCCESS,
  GET_USERS_REQUEST_ERROR,
  GET_USERS_REQUEST,
} from "../../constants";
import axios from "../../../../services/api";

function* getUsersRequest(data) {
  try {
    const response = yield call(
      axios.get,
      data.search !== undefined ? `/staff/?search=${data.search}` : "/staff/"
    );
    if (response.data.error) {
      yield put({
        type: GET_USERS_REQUEST_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: GET_USERS_REQUEST_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_USERS_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* getUsersRequestSaga() {
  yield takeLatest(GET_USERS_REQUEST, getUsersRequest);
}
