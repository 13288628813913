// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_CONTRACTOR_LIST_REQUEST_SUCCESS,
  GET_CONTRACTOR_LIST_REQUEST_ERROR,
  GET_CONTRACTOR_LIST_REQUEST,
} from "../../constants";
import axios from "../../../../services/api";

function* getContractorListRequest(data) {
  const params = new URLSearchParams();

  if (data.ordering) {
    params.append("ordering", data.ordering);
  }
  if (data.searchItem) {
    params.append("search", data.searchItem);
  }
  if (data.page) {
    params.append("page", data.page);
  }

  try {
    const response = yield call(axios.get, `/contractors/`, { params });

    if (response.data.error) {
      yield put({
        type: GET_CONTRACTOR_LIST_REQUEST_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: GET_CONTRACTOR_LIST_REQUEST_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_CONTRACTOR_LIST_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* getContractorListRequestSaga() {
  yield takeLatest(GET_CONTRACTOR_LIST_REQUEST, getContractorListRequest);
}
