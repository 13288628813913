// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_WEEKLY_ACTIVITY_SUCCESS,
  GET_WEEKLY_ACTIVITY_ERROR,
  GET_WEEKLY_ACTIVITY,
} from "../constants";
import axios from "../../../services/api";
import { isNil } from "lodash";

function* getWeeklyActivity(data) {
  const params = new URLSearchParams();
  if (!isNil(data?.siteIds)) {
    params.append("siteIds", data.siteIds);
  }

  if (!isNil(data?.regionIds)) {
    params.append("regionIds", data.regionIds);
  }

  if (!isNil(data?.induction)) {
    params.append("induction_id", data.induction);
  }

  try {
    const response = yield call(axios.get, `/dashboard/`, { params });
    if (response.data.error) {
      yield put({
        type: GET_WEEKLY_ACTIVITY_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: GET_WEEKLY_ACTIVITY_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_WEEKLY_ACTIVITY_ERROR,
      data: error,
    });
  }
}

export default function* getWeeklyActivitySaga() {
  yield takeLatest(GET_WEEKLY_ACTIVITY, getWeeklyActivity);
}
