import React, { useEffect, useState } from "react";
import classes from "./siteSelector.module.scss";
import {
  getInductionDetailRequest,
  getInductionRequest,
  setSelectedSite,
} from "store/induction/actions";
import {
  makeSelectInduction,
  makeSelectSelectedSites,
  makeSelectLoading,
} from "store/induction/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { isNil } from "lodash";

import Heading from "components/UI/heading";
import SiteCard from "components/UI/siteCard";
import Button from "components/UI/button";
import Footer from "components/UI/footer";
import Loading from "components/UI/loading";

const CardTypesEnums = {
  not_permitted: {
    color: "#E26C82",
    disabled: true,
    checked: false,
    info: {
      time: false,
      text: "Not Permitted",
      formatId: "site.selector.not.permitted",
    },
  },
  pending_induction: {
    color: "#DDDDDD",
    disabled: false,
    info: {
      time: true,
      text: false,
    },
  },
  not_completed: {
    color: "rgba(242, 199, 94, 0.5)",
    disabled: false,
    checked: false,
    info: {
      time: true,
      text: "Not Completed",
      formatId: "site.selector.not.completed",
    },
  },
  renewal_required: {
    color: "#F2C75E",
    disabled: false,
    checked: false,
    info: {
      time: true,
      text: "Renewal required",
      formatId: "site.selector.renewal.required",
    },
  },
  renewal_required_soon: {
    color: "#F2C75E",
    disabled: false,
    checked: false,
    info: {
      time: true,
      text: "Renewal required",
      formatId: "site.selector.renewal.required",
    },
  },

  permitted: {
    renewal: true,
    disabled: true,
    color: "#44C032",
    checked: true,
    info: {
      time: false,
      text: "Completed",
      formatId: "site.selector.completed",
    },
  },
};

const SiteSelector = ({
  getInduction,
  getInductionDetail,
  inductions,
  history,
  selectSites,
  selectedSites,
  loading,
}) => {
  const [inductionList, setInductionList] = useState([]);
  useEffect(() => {
    getInduction();
  }, [getInduction]);

  useEffect(() => {
    if (!isNil(inductions)) {
      const list = inductions?.results?.map((item) => {
        return {
          selected: false,
          ...item,
        };
      });
      setInductionList(list);
    }
  }, [inductions, setInductionList]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={classes.Container}>
      <Heading title={"Select Induction"} titleId={"site-selector.title"} />

      <div className={classes.inductionWrapper}>
        {inductionList.map((item, index) => (
          <SiteCard
            onClick={() => {
              if (item.id !== 1) {
                const newList = inductionList.map((a, i) => {
                  return {
                    ...a,
                    selected: index === i ? !a.selected : false,
                  };
                });
                setInductionList(newList);
              }
            }}
            key={index}
            status={item.contractor_status}
            title={item.name}
            titleId={`induction.${index + 1}.name`}
            description={item.description}
            descriptionId={`induction.${index + 1}.description`}
            selected={item.selected}
            image={item.image}
            type={CardTypesEnums[item.contractor_status.status]}
          />
        ))}
      </div>
      <Footer
        transparent
        isHidden={
          !isNil(inductionList?.find((item) => item.selected)) ? false : true
        }
      >
        <Button
          onClick={() => {
            selectSites({
              sites: inductionList.filter((item) => item?.selected),
            });
            const selectedInductionId = inductionList.filter(
              (item) => item.selected
            )[0].id;

            getInductionDetail({ id: selectedInductionId });
          }}
          height={"60px"}
          id="apply-button"
          text="Continue"
          type="submit"
          theme="blue"
          formatID="continue.button"
        />
      </Footer>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  inductions: makeSelectInduction(),
  selectedSites: makeSelectSelectedSites(),
  loading: makeSelectLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getInduction: (val) => dispatch(getInductionRequest(val)),
    getInductionDetail: (val) => dispatch(getInductionDetailRequest(val)),
    selectSites: (val) => dispatch(setSelectedSite(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteSelector);
