// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  IMPORT_STAFF_SUCCESS,
  IMPORT_STAFF_ERROR,
  IMPORT_STAFF,
} from "../../constants";
import axios from "../../../../services/api";
import { push } from "connected-react-router";
import { error as err, success } from "../../../../main";
import { isNil, capitalize } from "lodash";
const errorHandler = (data) => {
  var result = [];
  for (var i in data.details) {
    result.push([i, data.details[i]]);
  }
  result.forEach((item) =>
    err(
      `${data.for_user.first_name} ${data.for_user.last_name}'s ${capitalize(
        item[0]
      )} : ${item[1]}`
    )
  );
};

function* importStaff(data) {
  try {
    const params = new URLSearchParams();

    const handledData = data.data.map((item) => {
      return {
        first_name: item.first_name,
        last_name: item.last_name,
        email: item.email,
        company: item.company,
        group: !isNil(data.rolesList.find((a) => a.name === item.role))
          ? data.rolesList.find((a) => a.name === item.role).id
          : null,
      };
    });

    params.append("data", JSON.stringify(handledData));

    const response = yield call(axios.post, `/staff-import/`, params);
    if (response.data.error) {
      yield put({
        type: IMPORT_STAFF_ERROR,
        data: response.error,
      });
      yield call(errorHandler, response.data);
    } else {
      yield put({
        type: IMPORT_STAFF_SUCCESS,
        data: response.data,
      });
      yield call(success, `Users imported succesfully`);
      yield put(push("/supervisor-settings-users"));
    }
  } catch (error) {
    yield put({
      type: IMPORT_STAFF_ERROR,
      data: error,
    });
  }
}

export default function* importStaffSaga() {
  yield takeLatest(IMPORT_STAFF, importStaff);
}
