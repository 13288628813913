// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  DOCUMENT_CAPTURE,
  DOCUMENT_CAPTURE_ERROR,
  DOCUMENT_CAPTURE_SUCCESS,
} from "../constants";
import axios from "../../../services/api";
import { error as err } from "../../../main.js";
import { push } from "connected-react-router";
import { isNil } from "lodash";

function* setDocumentCapture(data) {
  const formData = new FormData();
  //console.log('data',data);
  formData.append("qualification_file", data.document, "document.jpeg");
  formData.append("qualification", data.title);
  formData.append("induction_id", data?.induction_id);

  try {
    const response = yield call(axios.post, "/me/", formData);

    if (response.data.error) {
      yield put({
        type: DOCUMENT_CAPTURE_ERROR,
        data: response.data.error,
      });
    } else {
      yield put({
        type: DOCUMENT_CAPTURE_SUCCESS,
        data: response.data.languages,
      });

      if (!isNil(data.payload.last_induction)) {
        yield put(push(`/induction?section_id=${data.payload.last_induction}`));
      } else {
        yield put(push(`/site-selector`));
      }
    }
  } catch (error) {
    yield put({
      type: DOCUMENT_CAPTURE_ERROR,
      data: error,
    });
  }
}

export default function* setDocumentCaptureSaga() {
  yield takeLatest(DOCUMENT_CAPTURE, setDocumentCapture);
}
