import React from "react";
import Inductions from "./Inductions";
import Attendance from "./Attendance";
import AttendanceDetail from "./Attendance/Detail/index";

import Cards from "./Cards";

import Menu from "components/Supervisor/menu";
import classes from "./reporting.module.scss";

import { Route, Switch, Redirect } from "react-router-dom";

const Settings = () => {
  const pages = [
    {
      title: "Inductions",
      active: true,
      component: <Inductions />,
      pathName: "/supervisor-reporting-inductions",
      path: "inductions",
    },
    {
      title: "Attendance",
      active: false,
      component: <Attendance />,
      pathName: "/supervisor-reporting-attendance",
      path: "attendance",
    },
    {
      title: "Cards",
      active: false,
      component: <Cards />,
      pathName: "/supervisor-reporting-cards",
      path: "cards",
    },
  ];

  const menu = <Menu pages={pages} />;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        {/* <Menu pages={pages} /> */}
        <div className={classes.container}>
          <Switch>
            {
              /* Redirect from supervisor root URL to /supervisor translations */
              <Redirect
                exact={true}
                from="/supervisor-reporting"
                to="/supervisor-reporting-inductions"
              />
            }
            <Route path="/supervisor-reporting-inductions" exact={true}>
              {({ history, match }) => (
                <Inductions menu={menu} history={history} />
              )}
            </Route>
            <Route path="/supervisor-reporting-cards" exact={true}>
              {({ history, match }) => <Cards menu={menu} history={history} />}
            </Route>
            <Route path="/supervisor-reporting-attendance-detail" exact={true}>
              {({ history, match }) => (
                <AttendanceDetail menu={menu} history={history} />
              )}
            </Route>
            <Route path="/supervisor-reporting-attendance" exact={true}>
              {({ history, match }) => (
                <Attendance menu={menu} history={history} />
              )}
            </Route>
          </Switch>
        </div>
      </div>
    </>
  );
};

export default Settings;
