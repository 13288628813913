import React from "react";
import classes from "./heading.module.scss";
import cn from "classnames";
import { isNil } from "lodash";
import { useIntl } from "react-intl";

const Heading = ({
  title,
  titleId,
  description,
  descriptionId,
  className,
  contentColor,
  titleValues,
}) => {
  const intl = useIntl();
  const titleText = () => {
    return intl.formatMessage(
      {
        id: titleId,
        defaultMessage: title,
      },
      titleValues
    );
  };
  const descriptionText = () =>
    intl.formatMessage({
      id: descriptionId,
      defaultMessage: description,
    });

  return (
    <div
      className={cn(
        classes.headingContainer,
        isNil(description) && classes.mn100
      )}
    >
      <div className={cn(classes.headingWrapper, className)}>
        <div className={cn(classes.headingTextWrapper)}>
          <span className={classes.title} style={{ color: contentColor }}>
            {titleId
              ? titleText()
                  .toLowerCase()
                  .split(" ")
                  .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                  .join(" ")
              : title}
          </span>
          <span className={classes.description} style={{ color: contentColor }}>
            {descriptionId ? descriptionText() : description}
          </span>
        </div>{" "}
      </div>
    </div>
  );
};

export default Heading;
