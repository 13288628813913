import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectAssesment = (state) => state.assesment || initialState;

const makeSelectAssesment = () =>
  createSelector(selectAssesment, (state) => state.assesment);

const makeSelectAssesmentResult = () =>
  createSelector(selectAssesment, (state) => state.assesmentResult);

const makeSelectAssesmentTryId = () =>
  createSelector(selectAssesment, (state) => state.assesmentTryId);

const makeSelectLoading = () =>
  createSelector(selectAssesment, (state) => state.aloading);

const makeSelectError = () =>
  createSelector(selectAssesment, (state) => state.error);
const makeSelectAssesmentState = () =>
  createSelector(selectAssesment, (state) => state.assesmentState);

export {
  selectAssesment,
  makeSelectAssesment,
  makeSelectAssesmentResult,
  makeSelectAssesmentTryId,
  makeSelectLoading,
  makeSelectError,
  makeSelectAssesmentState,
};
