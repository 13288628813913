/* LANGUAGE */
export const GET_LANGUAGE_REQUEST = "LANGUAGE/GET_LANGUAGE_REQUEST";
export const GET_LANGUAGE_REQUEST_SUCCESS =
  "LANGUAGE/GET_LANGUAGE_REQUEST_SUCCESS";
export const GET_LANGUAGE_REQUEST_ERROR = "LANGUAGE/GET_LANGUAGE_REQUEST_ERROR";

export const CREATE_LANGUAGE_REQUEST = "LANGUAGE/CREATE_LANGUAGE_REQUEST";
export const CREATE_LANGUAGE_REQUEST_SUCCESS =
  "LANGUAGE/CREATE_LANGUAGE_REQUEST_SUCCESS";
export const CREATE_LANGUAGE_REQUEST_ERROR =
  "LANGUAGE/CREATE_LANGUAGE_REQUEST_ERROR";

export const DELETE_LANGUAGE_REQUEST = "LANGUAGE/DELETE_LANGUAGE_REQUEST";
export const DELETE_LANGUAGE_REQUEST_SUCCESS =
  "LANGUAGE/DELETE_LANGUAGE_REQUEST_SUCCESS";
export const DELETE_LANGUAGE_REQUEST_ERROR =
  "LANGUAGE/DELETE_LANGUAGE_REQUEST_ERROR";

export const SET_SELECTED_LANGUAGE = "LANGUAGE/SET_SELECTED_LANGUAGE";
export const SET_SELECTED_LANGUAGE_SUCCESS =
  "LANGUAGE/SET_SELECTED_LANGUAGE_SUCCESS";
