import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Header } from "components";
import DrawerNavigation from "components/UI/header/drawerNavigation";
import { motion } from "framer-motion";
//action start
import { loginRequest, checkInRequest } from "../../store/auth/actions";
import { getCompaniesRequest } from "../../store/supervisor/actions";
import cn from "classnames";

//action end

//selectors start
import { createStructuredSelector } from "reselect";
import { makeSelectSelectedInduction } from "store/induction/selector";
//utils
import { useLocate, usePrevious } from "utils/hooks";

import classes from "./contractor.module.scss";
import { isNil } from "lodash";
const Layout = ({ children, menu, selectedInduction, header = true }) => {
  const [open, setDrawerOpen] = useState(false);
  const node = useRef();
  const ref = useRef(null);

  const setOpen = (val) => {
    setDrawerOpen(val);
  };

  const prevIndex = window.localStorage.getItem("INDEX");

  const { index } = useLocate(selectedInduction, window?.location?.pathname);

  useEffect(() => {
    if (!isNil(index)) {
      window.localStorage.setItem("INDEX", index);
    }
  }, [index]);

  const noMenu = ["/camera"]; //Urls that should not have a menu TODO: Make it dynamic!
  const isLogin = window.location.pathname === "/login";
  const isSiteSelector = window.location.pathname === "/site-selector" || noMenu.includes(window.location.pathname);

  return (
    <React.Fragment>
      <div
        id={"layout"}
        className={cn(classes.contractorLayout, open && classes.drawerLayout)}
      >
        <DrawerNavigation
          open={open}
          setOpen={(val) => setOpen(val)}
          index={index}
        />

        <div id={"wrapper"} ref={node} className={classes.wrapper}>
          {!isLogin && header && (
            <Header
              menu={isSiteSelector ? false : menu}
              open={open}
              setOpen={setOpen}
            />
          )}
          <motion.div
            initial={{ x: prevIndex > index ? "-50%" : "50%" }}
            animate={{
              x: "0",
            }}
            transition={{ type: "spring" }}
            className={`c-body ${classes.contractorBody}`}
          >
            {children}
          </motion.div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  selectedInduction: makeSelectSelectedInduction(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitForm: (val) => dispatch(loginRequest(val)),
    checkIn: (val) => dispatch(checkInRequest(val)),
    getCompanies: (val) => dispatch(getCompaniesRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
