// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_ERROR,
  GET_COMPANIES,
} from "../../constants";
import axios from "../../../../services/public";

function* getCompaniesRequest(data) {
  const params = new URLSearchParams();

  if (data.ordering) {
    params.append("ordering", data.ordering);
  }
  if (data.search) {
    params.append("search", data.search);
  }
  if (data.page) {
    params.append("page", data.page);
  }
  try {
    const response = yield call(axios.get, `/companies/`, { params });
    if (response.data.error) {
      yield put({
        type: GET_COMPANIES_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: GET_COMPANIES_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_COMPANIES_ERROR,
      data: error,
    });
  }
}

export default function* getCompaniesRequestSaga() {
  yield takeLatest(GET_COMPANIES, getCompaniesRequest);
}
