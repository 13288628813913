import React from "react";
import Input from "../../../../../components/Supervisor/input";
import classes from "../editor.module.scss";

const Video = ({page,updatePageData}) => {


  return <div>
        
        <br />
        <h4 className={classes.formHeading}>Video content</h4>
        <Input
            hasTooltip={true}
            order="column"
            value={page?.data?.video[0]?.url}
            name={"Vimeo URL"}
            characterLimit={50}
            onChange={(e) => {
                updatePageData({key:'video',value:[{video: e.target.value,
                                                              language: 'eng', //TODO
                                                              transcript: page?.data.video[0]?.transcript
                                                              }]});
            }}
            label={"Vimeo URL"}
            placeholder={"https://vimeo.com/...."}
            error={""}
            tooltipText={
            "Vimeo url. It has to be in the form of: https://vimeo.com/757431224"
            }
        />

  </div>;
};

export default Video;