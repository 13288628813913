// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  UPDATE_INDUCTION_SUCCESS,
  UPDATE_INDUCTION_ERROR,
  UPDATE_INDUCTION,
} from "../../constants";
import axios from "../../../../services/api";
import { push } from "connected-react-router";
import { success } from "../../../../main";
import { isEmpty, isNil } from "lodash";

function* updateInductionRequest(data) {
  try {
    const values = data;
    const params = new URLSearchParams();
    
    console.log('all data',values);

    params.append("id", values.id);

    //if (values.name != '')
    //    params.append("name", values.name);
    
    params.append("data", values.data);

    const response = yield call(axios.put, `/inductions/`, params);
    if (response.data.error) {
      yield put({
        type: UPDATE_INDUCTION_ERROR,
        data: response.error,
      });
    } else {
      console.log("response", response);
      yield put({
        type: UPDATE_INDUCTION_SUCCESS,
        data: response.data,
      });
    }
    yield call(success, `Induction Updated Succesfully`);
  } catch (error) {
    yield put({
      type: UPDATE_INDUCTION_ERROR,
      data: error,
    });
  }
}

export default function* updateInductionRequestSaga() {
  yield takeLatest(UPDATE_INDUCTION, updateInductionRequest);
}
