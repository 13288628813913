// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  UPLOAD_INDUCTION_IMAGE,
  UPLOAD_INDUCTION_IMAGE_SUCCESS,
  UPLOAD_INDUCTION_IMAGE_ERROR,
} from "../../constants";
import axios from "../../../../services/api";
function* uploadInductionImage(data) {
  const formData = new FormData();

  formData.append("image", data?.image, data?.image?.name);
  try {
    const response = yield call(axios.post, "/induction-image/", formData);
    if (response.data.error) {
      yield put({
        type: UPLOAD_INDUCTION_IMAGE_ERROR,
        data: response.data.error,
      });
    } else {
      yield put({
        type: UPLOAD_INDUCTION_IMAGE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: UPLOAD_INDUCTION_IMAGE_ERROR,
      data: error,
    });
  }
}

export default function* uploadInductionImageSaga() {
  yield takeLatest(UPLOAD_INDUCTION_IMAGE, uploadInductionImage);
}
