import React, { useEffect, useState } from "react";
import classes from "./user.module.scss";

import { getUsersRequest, deleteUser } from "store/supervisor/actions";
import {
  makeSelectRoles,
  makeSelectLoading,
  makeSelectPermissions,
  makeSelectUsers,
} from "store/supervisor/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Table from "components/Supervisor/table";
import Button from "components/Supervisor/button";
import { components } from "react-select";
import Select from "components/Supervisor/select";
import Modal from "components/Supervisor/modal";

import DeleteIcon from "assets/supervisorIcons/deleteIcon.js";
import PlusIcon from "assets/supervisorIcons/plusIcon.js";

import { isNil, isEmpty } from "lodash";
import { useSortableData } from "utils/hooks";

const { Placeholder } = components;
const IconOption = (props) => {
  return (
    <Placeholder {...props}>
      <PlusIcon />
      <span
        style={{
          marginLeft: "10px",
          fontFamily: "Open Sans",
          fontSize: "14px",
          color: "#40507E",
        }}
      >
        {props.children}
      </span>
    </Placeholder>
  );
};

const Users = ({ loading, deleteUserRequest, users, history, getUsers }) => {
  const [usersList, setUsersList] = useState([]);

  const [rawUserList, setRawUserList] = useState([]);

  const { items, requestSort, sortConfig } = useSortableData(rawUserList);

  const [selectedRows, setSelectedRows] = useState([]);

  const [searchState, setSearchState] = useState("");

  const [selectedPage, setSelectedPage] = useState(1);

  const [PAGE_COUNT, setPageCount] = useState(null);
  const [modal, setModal] = useState({
    open: false,
    type: null,
    action: null,
    cancel: null,
  });
  const onSearch = (e) => {
    setSearchState(e.target.value);
    if (e.target.value.length === 0) {
      getUsers();
      setSelectedPage(1);
    }
  };
  const rolesTableData = (item) => {
    const data = item.map((a) => {
      return {
        id: a.id,
        values: [
          {
            value: `${a.first_name} ${a.last_name}`,
            id: a.id,
            raw: item,
          },
          /*
          {
            value: a.company,
          },
          */
          {
            value: a.role,
          },
        ],
      };
    });

    const labels = [
      { label: "Name", width: "25%", sortable: true, key: "first_name" },
      /*{ label: "Company", width: "30%", sortable: true, key: "company" },*/
      { label: "Role", width: "30%", sortable: true, key: "role" },
    ];

    return {
      columns: data,
      labels: labels,
    };
  };

  const sortBy = (key) => {
    requestSort(key);
    setUsersList(rolesTableData(items));
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      setSelectedPage(1);
      getUsers({ search: event.target.value });
    }
  };

  const rowActions = [
    {
      name: "Edit",
      action: (val) =>
        history.push(`/supervisor-settings-users-edit?id=${val.id}`),
    },
    {
      name: "Delete",
      action: (val) => {
        setModal({
          open: true,
          type: "delete",
          action: () => {
            deleteUserRequest({ id: val.id, callback: () => getUsers() });
            setModal({
              open: false,
              type: null,
              action: null,
              cancel: null,
            });
          },
          cancel: () =>
            setModal({
              open: false,
              type: null,
              action: null,
              cancel: null,
            }),
        });
      },
    },
  ];

  useEffect(() => {
    if (!isNil(users)) {
      const newRows = users.results.map((item, i) => {
        return {
          id: item.id,
          checked: false,
        };
      });

      const handledUsers = users.results.map((item) => {
        return {
          ...item,
          company: !isEmpty(item.userextra_set)
            ? item.userextra_set[0].company
            : "-",
          role: !isEmpty(item.groups) ? item.groups[0].name : "-",
        };
      });
      setSelectedRows(newRows);
      setRawUserList(handledUsers);
      setUsersList(rolesTableData(handledUsers));
      setPageCount(users.items_per_page);
    }
  }, [users]);

  return (
    <>
      <>
        <>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                paddingRight: "20px",
              }}
            >
              {selectedRows &&
                selectedRows.filter((item) => item.checked).length > 0 && (
                  <Button
                    onClick={() => {
                      setModal({
                        open: true,
                        type: "delete",
                        action: () => {
                          selectedRows
                            .filter((item) => item.checked)
                            .forEach((item, index) => {
                              deleteUserRequest({
                                id: item.id,
                                end:
                                  index + 1 === selectedRows.length
                                    ? null
                                    : {
                                        callback: () => getUsers(),
                                      },
                              });
                            });
                          setModal({
                            open: false,
                            type: null,
                            action: null,
                            cancel: null,
                          });
                        },
                        cancel: () =>
                          setModal({
                            open: false,
                            type: null,
                            action: null,
                            cancel: null,
                          }),
                      });
                    }}
                    icon={() => {
                      return <DeleteIcon />;
                    }}
                    text={"Delete"}
                    color={"#E26C82"}
                  />
                )}
              <Select
                isSearchable={false}
                placeholder={"New User"}
                components={{ Placeholder: IconOption }}
                onChange={(val) =>
                  val.value === 1
                    ? history.push("/supervisor-settings-users-create")
                    : history.push("/supervisor-settings-users-import-csv")
                }
                options={[
                  {
                    label: "Single User",
                    value: 1,
                  },
                  {
                    label: "Bulk Import",
                    value: 2,
                  },
                ]}
              />
            </div>
            <Table
              handleKeyDown={handleKeyDown}
              isSearchable={true}
              searchState={searchState}
              onSearch={(e) => onSearch(e)}
              selectedRows={selectedRows}
              loading={loading}
              setSelectedRows={setSelectedRows}
              data={usersList}
              rowActions={rowActions}
              sortByDesc={(val) => sortBy(val)}
              sortConfig={sortConfig}
              clickable={true}
            />
          </div>
        </>
        {PAGE_COUNT && users && users.count > PAGE_COUNT && (
          <div className={classes.paginationContainer}>
            {Array.from(
              Array.from(
                {
                  length:
                    Math.abs(users.count / PAGE_COUNT) -
                      Math.floor(Math.abs(users.count / PAGE_COUNT)) !==
                    0
                      ? (users.count < PAGE_COUNT || users.count > PAGE_COUNT
                          ? users.count / PAGE_COUNT + 1
                          : users.count / PAGE_COUNT
                        ).toFixed(0)
                      : users.count / PAGE_COUNT,
                },
                (_, i) => i + 1
              )
            ).map((item, i) => (
              <span
                onClick={() => {
                  getUsers({ page: i + 1 });
                  setSelectedPage(i + 1);
                }}
                style={{
                  background:
                    i + 1 === selectedPage && " rgba(165, 180, 201, 0.2)",
                }}
              >
                {i + 1}
              </span>
            ))}
          </div>
        )}
      </>
      <Modal
        open={modal.open}
        cancel={modal.cancel}
        action={modal.action}
        type={modal.type}
        totalUsers={selectedRows.length}
        loading={loading}
        deleteTitle={"You are about to delete user"}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  roles: makeSelectRoles(),
  users: makeSelectUsers(),
  loading: makeSelectLoading(),
  permissions: makeSelectPermissions(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (val) => dispatch(getUsersRequest(val)),
    deleteUserRequest: (val) => dispatch(deleteUser(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
