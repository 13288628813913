import React, { useState } from "react";
import classes from "./people.module.scss";
import Icon from "components/UI/IconComponent";
import { isEmpty, isNil } from "lodash";
import cn from "classnames";
import Block from "./block.png";
import { formatDate } from "utils/helper";
import { CProgress } from "@coreui/react";

const StatusEnums = {
  Completed: { color: "#6fcf97", icon: "tick", title: "Completed" },
  Disqualified: {
    color: "#E26C82",
    icon: "user",
    title: "Disqualified",
  },
  Pending: { color: "#9B51E0", icon: "flag", title: "Pending" },
  renewal_required: {
    color: "#F4BB44",
    icon: "autorenew",
    iconColor: "",
    title: "Renewal Required",
  },

  "In progress": {
    color: "#9B51E0",
    icon: "flag",
    iconColor: "",
    title: "In Progress",
  },
  "General ind.": {
    color: "#6fcf97",
    icon: "tick",
    title: "General ind.",
  },
  renewal_required_soon: {
    color: "#F4BB44",
    icon: "time",
    iconColor: "",
    title: "Renewal Soon",
  },
  Safe: { color: "#28A745", icon: "tick", title: "Safe" },
};

const StatusIndicator = ({ data }) => {
  const [hover, setHover] = useState(false);
  if (isNil(data)) {
    return <span>-</span>;
  }

  const isCustomFlag =
    data?.status?.status === "Safe" &&
    (data?.status?.type === "tick" || data?.status?.type === "flag");

  const isCustomSafe =
    data?.status?.status === "Safe" &&
    (data?.status?.type === "All" || !isNaN(data?.status?.type)) &&
    data.status.type !== 0;

  const isFlag = data?.status?.type === "flag";

  const isRenewal =
    data.status.status === "renewal_required_soon" ||
    data.status.status === "renewal_required";

  const isRenewalSoon = data.status.status === "renewal_required_soon";

  const isRenewalRequired = data.status.status === "renewal_required";
  return (
    <div
      className={cn(classes.statusWrapper, hover && classes.hover)}
      style={{
        border:
          hover && `2px solid ${StatusEnums[data?.status?.status]?.color}`,
        backgroundColor: hover && "#ffffff",
        minWidth: isRenewalRequired && "140px",
      }}
      onMouseOver={(e) => {
        const rect = e.target.getBoundingClientRect();
        setHover({
          id: data.id,
          left: rect.x + rect.width / 2,
          top: rect.y + window.scrollY,
          data: data,
        });
      }}
      onMouseLeave={() => setHover(null)}
    >
      <span
        style={{
          whiteSpace: "nowrap",
          color: StatusEnums[data?.status?.status]?.color,
        }}
      >
        {StatusEnums[data?.status?.status]?.title}
      </span>
      <div
        className={cn(classes.iconWrapper, hover && classes.iconWrapperHover)}
        style={{
          backgroundColor:
            isCustomSafe && !hover
              ? "rgba(94, 202, 139, 0.10)"
              : isCustomFlag && hover && isFlag
              ? "#9B51E0"
              : hover
              ? StatusEnums[data?.status?.status]?.color
              : "",
        }}
      >
        {isCustomSafe ? (
          <div
            className={cn(
              hover ? classes.statusTypeCountHover : classes.statusTypeCount
            )}
          >
            <span>{data.status.type}</span>
          </div>
        ) : (
          <Icon
            icon={
              isCustomFlag
                ? data?.status?.type
                : StatusEnums[data?.status?.status]?.icon
            }
            color={
              hover
                ? "#ffffff"
                : isCustomFlag && isFlag
                ? "#9B51E0"
                : StatusEnums[data?.status?.status]?.color
            }
            size="10"
          />
        )}
      </div>

      {data === hover?.data && (
        <div className={classes.statusHoverWrapper}>
          {StatusEnums[data?.status?.status]?.title === "Disqualified" ? (
            <>
              <div>
                <div className={classes.disqualifiedWarningWrapper}>
                  <img src={Block} alt="blocked" />
                  <span>Blocked to enter all our sites</span>
                </div>
              </div>

              <div className={classes.statusInfoWrapper}>
                <span className={classes.statusInfoTitle}>Joined:</span>{" "}
                <span className={classes.statusInfoJoinedText}>
                  {formatDate(data?.date_joined)}
                </span>
              </div>
              <div className={classes.statusInfoWrapper}>
                <span className={classes.statusInfoTitle}>Disqualified:</span>{" "}
                <span className={classes.statusInfoText}>
                  {data?.status.disqualifieddate
                    ? formatDate(data?.status.disqualifieddate)
                    : "N/A"}
                </span>
              </div>
            </>
          ) : (
            <>
              <div className={classes.statusInfoWrapper}>
                <span className={classes.statusInfoTitle}>Joined:</span>{" "}
                <span className={classes.statusInfoText}>
                  {formatDate(data?.date_joined)}
                </span>
              </div>
              <div className={classes.statusGeneralInductionWrapper}>
                <span className={classes.statusInfoTitle}>
                  General Induction:
                </span>
                {isRenewal ? (
                  <div className={classes.flexCol}>
                    <div
                      style={{
                        background: StatusEnums[data?.status.status]?.color,
                      }}
                      className={cn(
                        classes.progressPercentage,
                        isRenewalSoon ? classes.wFull : classes.w120
                      )}
                    >
                      <span>
                        {isRenewalSoon
                          ? `Renewal required by ${formatDate(
                              data?.status?.renewal
                            )}`
                          : StatusEnums[data?.status.status]?.title}
                      </span>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className={classes.progressWrapper}>
                      <div className={classes.progressBar}>
                        <CProgress
                          style={{ width: "100%" }}
                          className={cn("progress-xs")}
                          value={data.status.percentage}
                        />
                      </div>
                      {data.status.percentage === 100 && (
                        <span className={classes.progressStatusText}>
                          <i>
                            Completed: {formatDate(data?.status?.completed)}
                          </i>
                        </span>
                      )}
                    </div>
                    <div
                      style={{
                        alignSelf:
                          data.status.percentage !== 100
                            ? "center"
                            : "flex-start",
                        background:
                          data.status.percentage !== 100 &&
                          "linear-gradient(180deg, #3C4D56 0%, #28353C 100%)",
                      }}
                      className={classes.progressPercentage}
                    >
                      <span>{data?.status?.percentage}%</span>
                    </div>
                  </>
                )}
              </div>
              {!isEmpty(data.status.failed) && (
                <div>
                  <div className={classes.divider} />

                  {data.status.failed.map((item) => (
                    <div className={classes.statusGeneralInductionWrapper}>
                      <span className={classes.statusInfoTitle}>
                        {item?.section?.title}
                        <Icon
                          className={classes.failedFlag}
                          icon={"flag"}
                          color={item?.section?.iconBackground}
                          size="20"
                        />
                      </span>
                      <div className={classes.flexRow}>
                        <div
                          className={classes.failedIconWrapper}
                          style={{ background: item?.section?.iconBackground }}
                        >
                          <Icon
                            icon={item?.section?.iconType}
                            color={item?.section?.iconColor}
                            size="80%"
                          />
                        </div>
                        <div className={classes.failedAttemptText}>
                          <span className={classes.name}>
                            {item?.assesment__name}
                          </span>
                          <span className={classes.attempts}>
                            {item.total} failed Attempts
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {!isEmpty(data.status.sites) && (
                <div>
                  <div className={classes.divider} />

                  {data.status.sites.map((item) => (
                    <div className={classes.statusGeneralInductionWrapper}>
                      <span className={classes.statusInfoTitle}>
                        {item.site}
                      </span>
                      <div className={classes.flexCol}>
                        <div
                          style={{
                            background: isRenewal
                              ? StatusEnums[data?.status?.status]?.color
                              : StatusEnums[item?.status]?.color,
                          }}
                          className={cn(
                            classes.statusShow,
                            isRenewalSoon ? classes.wFull : classes.w120
                          )}
                        >
                          <span>
                            {isRenewalSoon
                              ? `Renewal required by ${formatDate(
                                  data?.status?.renewal
                                )}`
                              : isRenewal
                              ? StatusEnums[data?.status.status]?.title
                              : StatusEnums[item?.status]?.title}
                          </span>
                        </div>
                        {item.completed && (
                          <span className={classes.progressStatusText}>
                            <i>Completed: {formatDate(item.completed)}</i>
                          </span>
                        )}
                        {isNil(item?.checkin?.checkout) &&
                        !isNil(item?.checkin?.checkin) ? (
                          <span className={classes.progressStatusText}>
                            <i className={classes.green}>Checked-in</i>
                          </span>
                        ) : !isNil(item?.checkin?.checkout) ? (
                          <span className={classes.progressStatusText}>
                            <i>
                              Checked-out: {formatDate(item?.checkin?.checkout)}
                            </i>
                          </span>
                        ) : null}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default StatusIndicator;
