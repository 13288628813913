import React, { useEffect } from "react";
import classes from "./siterules.module.scss";

import { Link } from "react-router-dom";
import Button from "components/UI/button";
import Footer from "components/UI/footer";
import FormattedHTMLMessage from "components/UI/FormattedHTMLMessage";
import Heading from "components/UI/heading";

import { setCurrentSection, getSectionTime } from "store/time/actions";

import { makeSelectProgress } from "store/progress/selector";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useLocate, saveProgress } from "utils/hooks";
import { makeSelectSelectedInduction } from "store/induction/selector";
import { isNil } from "lodash";

const SiteRules = ({ selectedInduction, history, isPreview=false, pageData }) => {
  var { current, previous, next } = useLocate(
    selectedInduction,
    history?.location.pathname
  );

  if(isPreview)
  {
     current = pageData;
  }

  useEffect(() => {
    if(!isPreview)
        saveProgress(selectedInduction, history.location.pathname);
  }, []);

  return (
    <>
      <div className={classes.SiteRuleContainer}>
        <Heading
          backArrow={true}
          onBackPress={() => {
            history.push(previous?.url);
          }}
          title={current?.title}
          titleId={isPreview ? current?.title : current?.title_id}
          description={current?.data?.subtitle}
          descriptionId={isPreview ? current?.data?.subtitle : current?.data?.subtitle_id}
        />

        {current?.data?.image && (
          <div className={classes.ImageContainer}>
            <div
              className={classes.Image}
              style={{
                backgroundImage: !isNil(current?.data?.image)
                  ? `url(${current.data.image})`
                  : "url(https://hs.immersivetechnology.nz/siterules.png)",
              }}
            />
          </div>
        )}

        <div
          className={classes.RuleContainer}
          style={{
            marginTop: current?.data?.image ? `158px` : "0px",
          }}
        >
          {current.data.rules.map((rule) => (
            <span className={classes.Item} key={rule.content}>
              <div className={classes.dot} />
              <FormattedHTMLMessage
                defaultMessage={rule.content}
                id={isPreview ? rule.content : rule.content_id}
              />
            </span>
          ))}
        </div>
      </div>
      {!isPreview && (
        <Footer>
          <Link className={classes.Link} to={next?.url}>
            <Button
              formatID={"siterules.button"}
              id="apply-button"
              text="Next"
              theme="blue"
              type="submit"
            />
          </Link>
        </Footer>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  progressData: makeSelectProgress(),
  selectedInduction: makeSelectSelectedInduction(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    setSection: (val) => dispatch(setCurrentSection(val)),
    getSectionTimeReq: (val) => dispatch(getSectionTime(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteRules);
