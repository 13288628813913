import React from "react";
import classes from "./input.module.scss";
import { useIntl } from "react-intl";
const Input = (props) => {
  const intl = useIntl();

  const placeholder = intl.formatMessage({
    id: props.placeholder,
    defaultMessage: props.defaultMessage,
  });
  const label = intl.formatMessage({
    id: props.label,
    defaultMessage: props.defaultLabel,
  });
  return (
    <div className={classes.Container}>
      <label className={classes.BlockLabel}>{label}</label>
      <input
        className={`${classes.BlockInput} ${props.error && classes.error}`}
        type={props.type}
        placeholder={placeholder}
        onChange={props.onChange}
        value={props.value}
        spellCheck="false"
        autoComplete="off"
        onBlur={props.onBlur}
        name={props.name}
      />
      {props.error && (
        <span className={classes.errorMessage}>
          Please provide valid information.
        </span>
      )}
    </div>
  );
};

export default Input;
