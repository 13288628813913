import React, { useEffect, useState } from "react";
import classes from "./people.module.scss";
import FilterSelector from "components/Supervisor/filterSelector";
import StatusSelector from "components/Supervisor/statusSelector";
//import { tableIndicator } from "utils/enums";

//redux
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  getCompaniesRequest,
  getAllAttendaceReportsRequest,
  getAttendanceReportsRequest,
  getRegionRequest,
  getSitesRequest,
  getPeopleListReq,
} from "store/supervisor/actions";
import {
  makeSelectCompanies,
  makeSelectLoading,
  makeSelectAllReportsLoading,
  makeSelectAttendanceReports,
  makeSelectAllAttendancesReports,
  makeSelectRegions,
  makeSelectSites,
  makeSelectFilterLoading,
} from "store/supervisor/selector";
import { isNil, isEmpty } from "lodash";
import { getFilterValues } from "utils/helper";

const personaOpts = [
  { label: "Contractor", value: 1 },
  { label: "Staff", value: 3 },
  { label: "Visitor", value: 2 },
];

const recentOpts = [
  { label: "Checked-in", value: "checked_in" },
  { label: "Checked-out", value: "checked_out" },
  { label: "Auto-checked-out", value: "auto_checked_out" },
  { label: "Inactive", value: "4" },
  { label: "Office based", value: "5" },
  { label: "Pending: currently at the gate", value: "pending" },
];

const tableIndicator = [
  {
    color: "#BB6BD9",
    name: "Pending",
    type: "pending_induction",
    iconColor: "#BB6BD9",
    icon: "flag",
  },
  {
    color: "#03CE91",
    name: "Safe",
    type: "permitted",
    icon: "tick",
    iconColor: "#28A745",
  },
  {
    color: "#03CE91",
    name: "Safe !",
    type: "permitted_flag",
    icon: "flag",
    iconColor: "#E93489",
  },
  {
    color: "#03CE91",
    name: "General Ind.",
    type: "general_ind",
  },
  {
    color: "#F2C75E",
    name: "Renewal required",
    type: "renewal_required",
    icon: "autorenew",
    iconColor: "#F2C75E",
    iconSize: "14px",
  },
  {
    color: "#F2C75E",
    name: "Renewal soon",
    type: "renewal_required_soon",
    icon: "time",
    iconColor: "#F2C75E",
  },
  {
    color: "#E26C82",
    name: "Diqualified",
    type: "not_permitted",
    icon: "user",
    iconColor: "#E26C82",
  },
];

const Filters = ({
  sites,
  companies,
  loading,
  getCompanies,
  getSites,
  filterLoading,
  getPeople,
  page,
  searchState,
  orderingConfig,
  setSelectedPage,
  saveFilters,
}) => {
  const [statusOptions, setStatusOptions] = useState([]);
  const [personaOptions, setPersonaOptions] = useState([]);
  const [recentOptions, setRecentOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);

  const runFilters = (
    personaValue,
    companyValue,
    siteValue,
    statusValue,
    activityValue
  ) => {
    getPeople({
      page: page,
      searchItem: searchState,
      ordering: orderingConfig,
      personaId: personaValue,
      companyIds: companyValue,
      siteIds: siteValue,
      statusIds: statusValue,
      recentActivities: activityValue,
    });
    setSelectedPage(1);
    saveFilters({
      personaId: personaValue,
      companyIds: companyValue,
      siteIds: siteValue,
      statusIds: statusValue,
      recentActivities: activityValue,
    });
  };

  useEffect(() => {
    getCompanies();
    getSites();
  }, []);

  useEffect(() => {
    if (!isNil(companies?.results)) {
      const opts = companies.results.map((item) => {
        return { label: item.name, value: item.id, checked: false };
      });
      const checkedOptions = companyOptions?.filter((item) => item.checked);

      if (checkedOptions !== undefined) {
        const filteredNewOptions = opts.filter((item) =>
          isEmpty(checkedOptions.find((a) => item.id !== a.id))
        );

        let newOpts = [...filteredNewOptions, ...checkedOptions];
        setCompanyOptions(newOpts);
      } else {
        setCompanyOptions(opts);
      }
    }
  }, [companies]);

  useEffect(() => {
    if (!isNil(sites?.results)) {
      const opts = sites.results.map((item) => {
        return { label: item.name, value: item.id, checked: false };
      });
      setSiteOptions(opts);
    }
  }, [sites]);

  useEffect(() => {
    if (!isNil(personaOpts)) {
      const opts = personaOpts.map((item) => {
        return { label: item.label, value: item.value, checked: false };
      });
      setPersonaOptions(opts);
    }
  }, [personaOpts]);

  useEffect(() => {
    if (!isNil(recentOpts)) {
      const opts = recentOpts.map((item) => {
        return { label: item.label, value: item.value, checked: false };
      });
      setRecentOptions(opts);
    }
  }, [recentOpts]);

  useEffect(() => {
    if (!isNil(tableIndicator)) {
      const statusOpts = tableIndicator.map((item) => {
        return {
          ...item,
          label: item.name,
          value: item.type,
          checked: false,
        };
      });
      setStatusOptions(statusOpts);
    }
  }, [tableIndicator]);

  return (
    <div className={classes.filterWrapper}>
      <FilterSelector
        title={"Group"}
        options={personaOptions}
        searchable={false}
        onChange={(val) => {
          const opts = personaOptions.map((item) => {
            return {
              ...item,
              checked: item.value === val ? !item.checked : item.checked,
            };
          });
          setPersonaOptions(opts);
          runFilters(
            val,
            getFilterValues(companyOptions),
            getFilterValues(siteOptions),
            getFilterValues(statusOptions)
          );
        }}
      />
      <FilterSelector
        loading={filterLoading}
        title={"Company"}
        options={companyOptions}
        searchable={true}
        onSearch={(val) => getCompanies({ search: val, filter: true })}
        onChange={(val) => {
          const opts = companyOptions.map((item) => {
            return {
              ...item,
              checked: item.value === val ? !item.checked : item.checked,
            };
          });
          setCompanyOptions(opts);
          runFilters(
            personaOptions?.find((item) => item.checked)?.value,
            getFilterValues(opts),
            getFilterValues(siteOptions),
            getFilterValues(statusOptions),
            getFilterValues(recentOptions)
          );
        }}
      />
      <FilterSelector
        loading={filterLoading}
        title={"Site"}
        options={siteOptions}
        searchable={true}
        onSearch={(val) => getSites({ search: val, filter: true })}
        onChange={(val) => {
          const opts = siteOptions.map((item) => {
            return {
              ...item,
              checked: item.value === val ? !item.checked : item.checked,
            };
          });
          setSiteOptions(opts);
          runFilters(
            personaOptions?.find((item) => item.checked)?.value,
            getFilterValues(companyOptions),
            getFilterValues(opts),
            getFilterValues(statusOptions),
            getFilterValues(recentOptions)
          );
        }}
      />

      <FilterSelector
        loading={filterLoading}
        title={"Recent Activity"}
        options={recentOptions}
        searchable={false}
        onChange={(val) => {
          const opts = recentOptions.map((item) => {
            return {
              ...item,
              checked: item.value === val ? !item.checked : item.checked,
            };
          });
          setRecentOptions(opts);
          runFilters(
            personaOptions?.find((item) => item.checked)?.value,
            getFilterValues(companyOptions),
            getFilterValues(siteOptions),
            getFilterValues(statusOptions),
            getFilterValues(opts)
          );
        }}
      />

      <StatusSelector
        title={"Status"}
        options={statusOptions}
        onChange={(val) => {
          const opts = statusOptions.map((item) => {
            return {
              ...item,
              checked: item.label === val ? !item.checked : item.checked,
            };
          });

          setStatusOptions(opts);
          runFilters(
            personaOptions?.find((item) => item.checked)?.value,
            getFilterValues(companyOptions),
            getFilterValues(siteOptions),
            getFilterValues(opts),
            getFilterValues(recentOptions)
          );
        }}
      />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  reports: makeSelectAttendanceReports(),
  companies: makeSelectCompanies(),
  branches: makeSelectRegions(),
  allReports: makeSelectAllAttendancesReports(),
  aloading: makeSelectAllReportsLoading(),
  sites: makeSelectSites(),
  filterLoading: makeSelectFilterLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getCompanies: (val) => dispatch(getCompaniesRequest(val)),
    getBranches: (val) => dispatch(getRegionRequest(val)),
    getPeople: (val) => dispatch(getPeopleListReq(val)),
    getSites: (val) => dispatch(getSitesRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
