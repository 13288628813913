import React from "react";

import classes from "./languageStats.module.scss";
import { sum } from "lodash";

const LanguageStats = ({ dashboardData, languages }) => {
  const totalLanguage = sum(
    dashboardData?.languagestats?.map((item) => item.total)
  );

  return (
    <div className={classes.dashboardSection2}>
      {dashboardData?.languagestats?.map((item) => (
        <div className={classes.language}>
          {languages && (
            <p>{languages?.find((a) => a.code === item.language)?.name}</p>
          )}
          <div className={classes.progressWrapper}>
            <span className={classes.progressCount}>
              {((item.total / totalLanguage) * 100).toFixed(0)}%
            </span>
            <div
              className={classes.progress}
              style={{
                width: `${((item.total / totalLanguage) * 100).toFixed(0)}%`,
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default LanguageStats;
