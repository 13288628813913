import React from "react";
import { Sidebar } from "components";
import Header from "components/UI/supervisorHeader";
import Footer from "components/UI/supervisorFooter";
import { CContainer } from "@coreui/react";
import PermissionModal from "components/UI/PermissionModal";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { makeSelectPermissionError } from "store/supervisor/selector";

const Layout = ({ children, menu, no_permission }) => {
  return (
    <div className="c-app c-default-layout">
      {!no_permission && menu && <Sidebar />}
      <div className="c-wrapper">
        {menu && <Header no_permission={no_permission} />}
        {!no_permission && (
          <div className="c-body" style={{ background: "#F6F7FA" }}>
            <main className="c-main">
              <CContainer fluid>{children}</CContainer>
            </main>
          </div>
        )}
        {!no_permission && <Footer style={{ zIndex: "99" }} />}
      </div>
      {no_permission && <PermissionModal />}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  no_permission: makeSelectPermissionError(),
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
