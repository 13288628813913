import React from "react";
import { BoxDragPreview } from "./BoxDragPreview";
import { snapToGrid } from "./snapToGrid.js";
const layerStyles = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 100,
  left: 0,
  top: 0,
  width: "80vw",
  height: "100%",
};
function getItemStyles(initialOffset, currentOffset, isSnapToGrid) {
  if (!initialOffset || !currentOffset) {
    return {
      display: "none",
    };
  }
  let { x, y } = currentOffset;
  if (isSnapToGrid) {
    x -= initialOffset.x;
    y -= initialOffset.y;
    [x, y] = snapToGrid(x, y);
    x += initialOffset.x;
    y += initialOffset.y;
  }
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}
export const CustomDragLayer = ({
  item,
  isDragging,
  initialOffset,
  currentOffset,
  snapToGrid,
}) => {
  function renderItem() {
    return <BoxDragPreview item={item} />;
  }
  if (!isDragging) {
    return null;
  }
  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset, snapToGrid)}>
        {renderItem()}
      </div>
    </div>
  );
};
