// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  UPLOAD_HAZARD_IMAGE,
  UPLOAD_HAZARD_IMAGE_SUCCESS,
  UPLOAD_HAZARD_IMAGE_ERROR,
} from "../../constants";
import axios from "../../../../services/api";
function* uploadHazardImage(data) {
  const formData = new FormData();

  formData.append("image", data?.image, data?.image?.name);
  try {
    const response = yield call(axios.post, "/hazard-image/", formData);
    if (response.data.error) {
      yield put({
        type: UPLOAD_HAZARD_IMAGE_ERROR,
        data: response.data.error,
      });
    } else {
      yield put({
        type: UPLOAD_HAZARD_IMAGE_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: UPLOAD_HAZARD_IMAGE_ERROR,
      data: error,
    });
  }
}

export default function* uploadHazardImageSaga() {
  yield takeLatest(UPLOAD_HAZARD_IMAGE, uploadHazardImage);
}
