// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  CREATE_EMERGENCY_NOTIFICATIONS,
  CREATE_EMERGENCY_NOTIFICATIONS_ERROR,
  CREATE_EMERGENCY_NOTIFICATIONS_SUCCESS,
} from "../../constants";
import axios from "../../../../services/api";
import { push } from "connected-react-router";
import { error as err, success } from "../../../../main";
function* createEmergencyNotification(data) {
  console.log("data", data);
  const params = new URLSearchParams();

  params.append("type", data?.values?.type);

  if (data.values.template_id) {
    params.append("template_id", data.values.template_id);
  }
  if (data.values.site_list) {
    params.append(
      "site_list",
      data.values.site_list.map((item) => item.value).toString()
    );
  }
  if (data.values.message) {
    params.append("message", data.values.message);
  }
  try {
    const response = yield call(
      axios.post,
      `/emergency-notifications/`,
      params
    );
    if (response.data.error) {
      yield put({
        type: CREATE_EMERGENCY_NOTIFICATIONS_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: CREATE_EMERGENCY_NOTIFICATIONS_SUCCESS,
        data: response.data,
      });
      yield call(success, `The notification has been successfully sent. `);
      yield put(push("/supervisor-notifications"));
    }
  } catch (error) {
    yield put({
      type: CREATE_EMERGENCY_NOTIFICATIONS,
      data: error,
    });
  }
}

export default function* createEmergencyNotificationSaga() {
  yield takeLatest(CREATE_EMERGENCY_NOTIFICATIONS, createEmergencyNotification);
}
