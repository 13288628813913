import React, { useEffect, useState } from "react";
import classes from "./branch.module.scss";
import {
  getRegionRequest,
  deleteRegionRequest,
} from "store/supervisor/actions";
import {
  makeSelectRegions,
  makeSelectLoading,
} from "store/supervisor/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Table from "components/Supervisor/table";
import Button from "components/Supervisor/button";
import DeleteModal from "components/Supervisor/modal";

import DeleteIcon from "assets/supervisorIcons/deleteIcon.js";
import PlusIcon from "assets/supervisorIcons/plusIcon.js";
import { delay } from "utils/helper";
import { isEmpty } from "lodash";

const Regions = ({
  getRegions,
  loading,
  deleteRegionReq,
  history,
  regions,
}) => {
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    type: null,
    action: null,
    cancel: null,
  });

  const [companyOptions, setCompanyOptions] = useState([]);

  useEffect(() => {
    getRegions();
  }, []);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      setSelectedPage(1);
      getRegions({ search: event.target.value });
    }
  };

  const rowActions = [
    {
      name: "Edit",
      action: (val) =>
        history.push(`/supervisor-settings-branch-edit?id=${val.id}`),
    },
    {
      name: "Delete",
      action: (val) => {
        setDeleteModal({
          open: true,
          type: "delete",
          action: () => {
            deleteRegionReq({
              id: val.id,
              callback: async () => {
                await delay(1000);
                getRegions();
              },
            });
            setDeleteModal({
              open: false,
              type: null,
              action: null,
              cancel: null,
            });
          },
          cancel: () =>
            setDeleteModal({
              open: false,
              type: null,
              action: null,
              cancel: null,
            }),
        });
      },
    },
  ];

  const companyTableData = (item) => {
    const data = item.map((a) => {
      return {
        id: a.id,
        values: [
          {
            value: a.code,
            id: a.id,
            raw: item,
          },
          {
            value: a.name,
          },
          {
            value: isEmpty(a.branch_managers)
              ? "-"
              : `${a.branch_managers[0]?.user?.first_name} ${a?.branch_managers[0]?.user?.last_name}`,
          },
          {
            value: isEmpty(a.contracts_managers)
              ? "-"
              : `${a?.contracts_managers[0]?.user?.first_name} ${a?.contracts_managers[0]?.user?.last_name}`,
          },
          {
            value: isEmpty(a.hse_managers)
              ? "-"
              : `${a?.hse_managers[0]?.user?.first_name} ${a?.hse_managers[0]?.user?.last_name}`,
          },
        ],
      };
    });

    const labels = [
      { label: "Code" },
      { label: "Full name" },
      { label: "Branch Manager" },
      { label: "Contracts Manager" },
      { label: "EHS Manager" },
    ];

    return {
      columns: data,
      labels: labels,
    };
  };
  const [PAGE_COUNT, setPageCount] = useState(null);

  useEffect(() => {
    if (regions) {
      setRegionList(companyTableData(regions.results));
      setPageCount(regions.items_per_page);

      const newRows = regions.results.map((item, i) => {
        return {
          id: item.id,
          checked: false,
        };
      });
      setSelectedRows(newRows);
      const options = regions.results.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setCompanyOptions(options);
    }
  }, [regions]);

  const [selectedPage, setSelectedPage] = useState(1);

  const [searchState, setSearchState] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [regionList, setRegionList] = useState([]);

  const onSearch = (e) => {
    setSearchState(e.target.value);
    if (e.target.value.length === 0) {
      getRegionRequest({ search: e.target.value });
    }
  };

  return (
    <>
      <div className={classes.regionsContainer}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingRight: "20px",
          }}
        >
          {selectedRows &&
            selectedRows.filter((item) => item.checked).length > 0 && (
              <Button
                onClick={() => {
                  setDeleteModal({
                    open: true,
                    type: "delete",
                    action: () => {
                      selectedRows
                        .filter((item) => item.checked)
                        .forEach((item, index) => {
                          deleteRegionReq({
                            id: item.id,
                            end:
                              index + 1 === selectedRows.length
                                ? null
                                : {
                                    callback: () => getRegions(),
                                  },
                          });
                        });
                      setDeleteModal({
                        open: false,
                        type: null,
                        action: null,
                        cancel: null,
                      });
                    },
                    cancel: () =>
                      setDeleteModal({
                        open: false,
                        type: null,
                        action: null,
                        cancel: null,
                      }),
                  });
                }}
                icon={() => {
                  return <DeleteIcon />;
                }}
                text={"Delete"}
                color={"#E26C82"}
              />
            )}

          <Button
            width={"200px"}
            onClick={() => history.push("/supervisor-settings-branch-create")}
            icon={() => {
              return <PlusIcon />;
            }}
            text={"New Branch"}
          />
        </div>
        <Table
          width={"100%"}
          handleKeyDown={handleKeyDown}
          isSearchable={true}
          searchState={searchState}
          loading={loading}
          onSearch={(e) => onSearch(e)}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          data={regionList}
          rowActions={rowActions}
          clickable={true}
        />
        <>
          {PAGE_COUNT && regions && regions.count > PAGE_COUNT && (
            <div className={classes.paginationContainer}>
              {Array.from(
                Array.from(
                  {
                    length:
                      Math.abs(regions.count / PAGE_COUNT) -
                        Math.floor(Math.abs(regions.count / PAGE_COUNT)) !==
                      0
                        ? (regions.count < PAGE_COUNT ||
                          regions.count > PAGE_COUNT
                            ? regions.count / PAGE_COUNT + 1
                            : regions.count / PAGE_COUNT
                          ).toFixed(0)
                        : regions.count / PAGE_COUNT,
                  },
                  (_, i) => i + 1
                )
              ).map((item, i) => (
                <span
                  onClick={() => {
                    getRegionRequest({ page: i + 1 });
                    setSelectedPage(i + 1);
                  }}
                  style={{
                    background:
                      i + 1 === selectedPage && " rgba(165, 180, 201, 0.2)",
                  }}
                >
                  {i + 1}
                </span>
              ))}
            </div>
          )}
        </>

        <DeleteModal
          open={deleteModal.open}
          cancel={deleteModal.cancel}
          action={deleteModal.action}
          type={deleteModal.type}
          loading={loading}
          deleteTitle={"You are about to delete a region."}
        />
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  regions: makeSelectRegions(),
  loading: makeSelectLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getRegions: (val) => dispatch(getRegionRequest(val)),
    deleteRegionReq: (val) => dispatch(deleteRegionRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Regions);
