// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  CHECK_OUT_REQUEST,
  CHECK_OUT_REQUEST_SUCCESS,
  CHECK_OUT_REQUEST_ERROR,
} from "../constants";
import axios from "../../../services/api";
import { push } from "connected-react-router";
import { isNil } from "lodash";

function* checkOut(data) {
  try {
    const values = data.payload;
    const params = new URLSearchParams();
    params.append("site", values.site);
    const token = values.token;
    const access_token = window.localStorage.getItem("access_token");

    delete axios.defaults.headers.common["X-Access-Token"];
    delete axios.defaults.headers.common["X-Token"];

    if (!isNil(token) && isNil(access_token)) {
      axios.defaults.headers.common["X-Token"] = token;
    } else if (!isNil(access_token)) {
      axios.defaults.headers.common["X-Token"] = access_token;
    }

    const response = yield call(axios.post, "/check-out/", params);

    yield put({
      type: CHECK_OUT_REQUEST_SUCCESS,
      data: response.data,
    });

    values.callBack();
  } catch (error) {
    yield put(
      push(`/language-selection?site=${data.payload.site}&redirect=check-out`)
    );
    yield put({
      type: CHECK_OUT_REQUEST_ERROR,
      data: error,
    });
  }
}

export default function* checkOutSaga() {
  yield takeLatest(CHECK_OUT_REQUEST, checkOut);
}
