import React from "react";
import classes from "./modal.module.scss";
import WarningIcon from "assets/supervisorIcons/warning.svg";
import ReactLoading from "react-loading";
const Modal = ({
  open,
  cancel,
  action,
  type,
  data,
  totalUsers,
  rolesList,
  loading,
  deleteTitle,
  deleteDescription,
  buttonTitle,
}) => {
  return (
    <>
      {open && (
        <>
          <div className={classes.modalContainer}>
            <div
              className={classes.modal}
              style={{
                width: type === "info" ? "466px" : "597px",
                height: type === "info" ? "482px" : "219px",
              }}
            >
              {type === "info" ? (
                <>
                  <div className={classes.headerContainer}>
                    <span>
                      You are about to send an invitation to the group of users
                    </span>
                  </div>
                  <div className={classes.bodyContainer}>
                    <div className={classes.wrapper}>
                      <span className={classes.title}>Users:</span>
                      <span className={classes.description}>{totalUsers}</span>
                    </div>
                    <div className={classes.wrapper}>
                      <span className={classes.title}>Roles:</span>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {rolesList.map((item) => (
                          <span className={classes.description}>
                            {
                              data.columns
                                .map((a) => a.values[3].value)
                                .filter((b) => b === item.name).length
                            }{" "}
                            {item.name}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className={classes.footerContainer}>
                    <div
                      className={classes.cancelButton}
                      onClick={() => cancel()}
                    >
                      <span>Cancel</span>
                    </div>
                    <div
                      onClick={() => !loading && action()}
                      className={classes.sendButton}
                    >
                      {!loading ? (
                        <span>Send</span>
                      ) : (
                        <ReactLoading
                          width={"20px"}
                          height={"20px"}
                          color={"#ffffff"}
                          type={"spin"}
                        />
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className={classes.deleteHeader}>
                    <img
                      alt={"warning icon"}
                      src={WarningIcon}
                      style={{
                        width: "19px",
                        height: "16px",
                        marginLeft: "25px",
                        marginRight: "25px",
                        display: "flex",
                      }}
                    />
                    <span>{deleteTitle}</span>
                  </div>
                  <div className={classes.deleteBody}>
                    <span>
                      {deleteDescription
                        ? deleteDescription
                        : "Note that this action is irreversible"}
                    </span>
                  </div>
                  <div className={classes.deleteFooter}>
                    <div
                      onClick={() => cancel()}
                      className={classes.deleteCancelButton}
                    >
                      <span>Cancel</span>
                    </div>
                    <div
                      onClick={() => action()}
                      className={classes.deleteButton}
                    >
                      <span>{buttonTitle ? buttonTitle : "Delete"}</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={classes.modalBackground} onClick={() => cancel()} />
        </>
      )}
    </>
  );
};

export default Modal;
