import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import classes from "./modal.module.scss";
import ReactLoading from "react-loading";
import Input from "../input";
import TextArea from "../textArea";
import greenCardIcon from "assets/supervisorIcons/greenCard.svg";
import greenCardDisabledIcon from "assets/supervisorIcons/greenCardDisabled.svg";
import redCardIcon from "assets/supervisorIcons/redCard.svg";
import redCardDisabledIcon from "assets/supervisorIcons/redCardDisabled.svg";
import threeDots from "assets/supervisorIcons/threedots.svg";
import { isNil } from "lodash";
import cn from "classnames";

import { getSitesRequest } from "store/supervisor/actions";
import { makeSelectSites } from "store/supervisor/selector";

const Modal = ({ open, cancel, save, loading, sites, data }) => {
  const [option, setOption] = useState(false);
  const [site, setSite] = useState("");
  const [summary, setSummary] = useState("");
  const [action, setAction] = useState("");
  const [sitesOptions, setSiteOptions] = useState([]);
  const [headerType, setHeaderType] = useState(classes.chooseHeaderContainer);
  const [headerTextType, setHeaderTextType] = useState("Choose");

  useEffect(() => {
    if (open) {
      window.scrollTo(0, 0);
    }
  }, [open]);

  useEffect(() => {
    if (data) {
      setSite({
        label: data?.values[2]?.value,
        value: data?.values[0]?.site_id,
      });
      setSummary(data?.values[4]?.value);
      setAction(data?.values[5]?.value);
      setOption(data?.values[0]?.type);

      if (data?.values[0]?.type == 1) {
        setHeaderType(classes.greenHeaderContainer);
        setHeaderTextType("Green");
      } else {
        setHeaderType(classes.redHeaderContainer);
        setHeaderTextType("Red");
      }
    } else {
      setSite("");
      setSummary("");
      setAction("");
      setOption(false);
      setHeaderType(classes.chooseHeaderContainer);
      setHeaderTextType("Choose");
    }
  }, [data]);

  useEffect(() => {
    if (!isNil(sites)) {
      const options = sites.results.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      setSiteOptions(options);
    }
  }, [sites]);

  return (
    <>
      {open && (
        <>
          <div
            className={
              classes.modalContainer + " " + classes.cardModalContainer
            }
          >
            <div
              className={classes.modal}
              style={{
                width: "608px",
                height: "640px",
                alignItems: "center",
              }}
            >
              <>
                <div className={classes.headerContainer + " " + headerType}>
                  <span>{headerTextType} Card</span>

                  <span className={classes.threedots}>
                    <img src={threeDots} alt={"dots"} />
                  </span>
                </div>

                <div
                  style={{ width: "100%", margin: "0 auto" }}
                  className={classes.bodyContainer}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "center",
                      //height: "60px",
                    }}
                  >
                    {data ? (
                      <div className={classes.cardTypeContainer}>
                        <div className={classes.cardType}>
                          <span>
                            <img
                              alt={"card"}
                              src={
                                data?.values[0]?.type == 1
                                  ? greenCardIcon
                                  : redCardIcon
                              }
                            />
                          </span>
                        </div>

                        <div className={classes.issuer}>
                          <span className={classes.issuerPretext}>
                            Issued:{" "}
                          </span>{" "}
                          <span>{data?.values[3]?.value}</span>
                          <br />
                          <span className={classes.issuerPretext}>
                            By{" "}
                          </span>{" "}
                          <span>{data?.values[1]?.value}</span>
                        </div>
                      </div>
                    ) : (
                      <div className={cn(classes.tabContainer, classes.mn120)}>
                        <div
                          onClick={() => setOption(1)}
                          className={option ? classes.tab1 : classes.activetab1}
                        >
                          <span>
                            <img
                              alt={"Green Card"}
                              src={
                                option == false || option == 1
                                  ? greenCardIcon
                                  : greenCardDisabledIcon
                              }
                            />
                          </span>
                        </div>
                        <div
                          onClick={() => setOption(2)}
                          style={{ borderRadius: " 0px 22px 22px 0px" }}
                          className={
                            !option ? classes.tab1 : classes.activetab1
                          }
                        >
                          <span>
                            <img
                              alt={"Red Card"}
                              src={
                                option == false || option == 2
                                  ? redCardIcon
                                  : redCardDisabledIcon
                              }
                            />
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className={classes.inputCont}>
                    <div
                      style={{
                        marginTop: "32px",
                        marginBottom: "32px",
                        width: "100%",
                      }}
                    >
                      <Input
                        tabSelectsValue={site}
                        value={site}
                        type={"select"}
                        onChange={(val) => {
                          setSite(val);
                        }}
                        label={"Site ID"}
                        placeholder={"Site ID"}
                        options={sitesOptions}
                        component={Input}
                      />
                    </div>
                  </div>

                  <div className={classes.inputCont}>
                    <div
                      style={{
                        marginTop: "32px",
                        marginBottom: "32px",
                        width: "100%",
                      }}
                    >
                      <TextArea
                        label={"Summary"}
                        onChange={(e) => {
                          setSummary(e.target.value);
                        }}
                        value={summary}
                      />
                    </div>
                  </div>

                  <div className={classes.inputCont}>
                    <div
                      style={{
                        marginTop: "32px",
                        marginBottom: "32px",
                        width: "100%",
                      }}
                    >
                      <TextArea
                        label={"Action"}
                        onChange={(e) => {
                          setAction(e.target.value);
                        }}
                        value={action}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.footerContainer}>
                  <div
                    className={classes.cancelButton}
                    onClick={() => cancel()}
                  >
                    <span>Cancel</span>
                  </div>
                  <div
                    onClick={() => {
                      if (option || data) {
                        save({
                          cardType: option,
                          action: action,
                          summary: summary,
                          site_id: site.value,
                          id: data ? data?.values[0]?.id : null,
                        });
                      }
                    }}
                    className={
                      option == false || data == null
                        ? classes.sendButton + " " + classes.disabledButton
                        : classes.sendButton
                    }
                  >
                    {!loading ? (
                      <span>Save</span>
                    ) : (
                      <ReactLoading
                        width={"20px"}
                        height={"20px"}
                        color={"#ffffff"}
                        type={"spin"}
                      />
                    )}
                  </div>
                </div>
              </>
            </div>
          </div>
          <div className={classes.modalBackground} onClick={() => cancel()} />
        </>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  sites: makeSelectSites(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getSites: (val) => dispatch(getSitesRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
