// @flow
import { put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  SET_SELECTED_LANGUAGE,
  SET_SELECTED_LANGUAGE_SUCCESS,
} from "../constants";

function* setSelectedLanguage(data) {
  const value = { data };
  try {
    yield put({
      type: SET_SELECTED_LANGUAGE_SUCCESS,
      data: value.data,
    });
  } catch (error) {
    console.log(error);
  }
}

export default function* setSelectedLanguageSaga() {
  yield takeLatest(SET_SELECTED_LANGUAGE, setSelectedLanguage);
}
