import React, { useState, useEffect } from "react";
import classes from "./loading.module.scss";
import KeepSafeLogo from "./logo1.gif";

const Loading = () => {
  return (
    <div className={classes.Container}>
      <div className={classes.logoWrapper}>
        <img src={KeepSafeLogo} alt="logo" />
      </div>
    </div>
  );
};

export default Loading;
