import React from "react";
import classes from "./PermissionModal.module.scss";
import Logo from "assets/logo/logo.png";
const PermissionModal = () => {
  return (
    <div className={classes.PermissionModal}>
      <div className={classes.box}>
        <img className={classes.logo} src={Logo} alt={"main logo"} />
        <p className={classes.title}>
          It seems you do not have sufficient permissions to access the KeepSafe
          EHS dashboard.
        </p>
        <p className={classes.description}>
          If you feel this is in error, please contact your branch EHS Manager.
        </p>
      </div>
    </div>
  );
};

export default PermissionModal;
