// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_ERROR,
  CREATE_ROLE,
} from "../../constants";
import axios from "../../../../services/api";
import { push } from "connected-react-router";
import { error as err, success } from "../../../../main";

function* createRoleRequest(data) {
  try {
    const values = data;
    const params = new URLSearchParams();
    params.append("name", values.name);
    params.append("permissions", values.permissions);

    const response = yield call(axios.post, `/roles/`, params);
    if (response.data.error) {
      yield put({
        type: CREATE_ROLE_ERROR,
        data: response.error,
      });
      yield call(err, `${response.data.error}`);
    } else if (response.data.success === 1) {
      yield put({
        type: CREATE_ROLE_SUCCESS,
        data: response.data,
      });
      yield call(success, `New Role Created Succesfully`);
      yield put(push("/supervisor-settings-roles"));

      if (values.key && values.key === "duplicate") {
        values.callback();
      }
    }
  } catch (error) {
    yield call(err, error.response.status.message.detail);
    yield put({
      type: CREATE_ROLE_ERROR,
      data: error,
    });
  }
}

export default function* createRoleRequestSaga() {
  yield takeLatest(CREATE_ROLE, createRoleRequest);
}
