// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_SECTION_TIME_REQUEST,
  GET_SECTION_TIME_REQUEST_SUCCESS,
  GET_SECTION_TIME_REQUEST_ERROR,
} from "../constants";
import axios from "../../../services/api";
import { isNil } from "lodash";

function* getSectionTimeRequest(data) {
  const params = new URLSearchParams();
  if (!isNil(data?.induction_id) && !isNil(data?.section)) {
    params.append("induction_id", data.induction_id);
    params.append("section", data.section);
    try {
      const response = yield call(axios.get, "/induction-timer/", { params });
      if (response.data.error) {
        yield put({
          type: GET_SECTION_TIME_REQUEST_ERROR,
          data: response.error,
        });
      } else {
        yield put({
          type: GET_SECTION_TIME_REQUEST_SUCCESS,
          data: response.data,
        });
      }
    } catch (error) {
      yield put({
        type: GET_SECTION_TIME_REQUEST_ERROR,
        data: error,
      });
    }
  }
}

export default function* getSectionTimeRequestSaga() {
  yield takeLatest(GET_SECTION_TIME_REQUEST, getSectionTimeRequest);
}
