import React,{useState,useEffect} from "react";
import Input from "../../../../../components/Supervisor/input";
import ImageUpload from "../../../Hazards/components/imageUpload";
import classes from "../editor.module.scss";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import {
    uploadInductionImageReq
  } from "store/supervisor/actions";
  
import {
    makeSelectInductionImage,
    makeSelectLoading,
    makeSelectInductionImageLoading,
    makeSelectInductionImageError,
    makeSelectInductionImageSuccess,
} from "store/supervisor/selector";


const ChaptersForm = ({
page,
updatePageData, 
chapter, 
section,
inductionImage,
inductionImageLoading,
inductionImageSuccess,
inductionImageError,
uploadInductionImage,
setSelectedSection

}) => {


const [rules, setRules] = useState(page?.data?.rules);
const [initialState, setInitialState] = useState(true);
const [selectedComponent, setSelectedComponent] = useState(0);

const updateRule = (item, value) => {
    var updatedRules = [...rules];
    updatedRules[item] = {...rules[item], content:value};
    setRules(updatedRules);
    updatePageData({key:'rules',value:updatedRules});
}

useEffect(() => 
{
    if(initialState)
       return;

    if (inductionImage?.url) 
    {      
       var newComponentUpdate = [ ...section.data.components ]
                            
       for(var j = 0; j < newComponentUpdate.length; j++)
       {
           if(j == selectedComponent)
              newComponentUpdate[j] = {...newComponentUpdate[j],image_id: inductionImage?.id, image: inductionImage?.url};
       }
       
       var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
       setSelectedSection(updatedData);

    }
  }, [inductionImage]);


  return <div>
        
        <br />
        <h4 className={classes.formHeading}>Blocks List</h4>
        
        {section?.data?.components.map((item,index) =>
                          
            <>           
            <Input
                hasTooltip={true}
                order="column"
                value={item?.title}
                name={"component_"+index}
                characterLimit={120}
                onChange={(e) => {
                    //updateRule(index,e.target.value);
                }}
                label={"Title"}
                placeholder={"Title here"}
                error={""}
                tooltipText={
                "Title text"
                }
            />
            <br />
            
            {item?.type == 'image_text_full' && (
                    <ImageUpload
                        isSuccess={inductionImageSuccess}
                        isUploading={inductionImageLoading}
                        isError={inductionImageError}
                        onDelete={() => {
                        //setFieldValue("image_url", null);
                        //setChangeCount((val) => val + 1);
                        }}
                        imageUrl={item?.image}
                        description={"Upload a portrait sized photo from your device that represents the introduction image. Don't worry, we've got the web-friendly optimization covered."}
                        onLoad={(event) => {
                        if (event.target.files[0]) {
                            
                            setInitialState(false);
                            setSelectedComponent(index);
                            uploadInductionImage({
                              image: event.target.files[0],
                            });
                            
                        }
                        }}
                    />
            )}

            {item?.type == 'image_text_regular' && (
                <div>
                    <ImageUpload
                        isSuccess={inductionImageSuccess}
                        isUploading={inductionImageLoading}
                        isError={inductionImageError}
                        onDelete={() => {
                        //setFieldValue("image_url", null);
                        //setChangeCount((val) => val + 1);
                        }}
                        imageUrl={item?.image}
                        description={"Upload a portrait sized photo from your device that represents the introduction image. Don't worry, we've got the web-friendly optimization covered."}
                        onLoad={(event) => {
                        if (event.target.files[0]) {

                            setInitialState(false);
                            setSelectedComponent(index);
                            uploadInductionImage({
                              image: event.target.files[0],
                            });
                        }
                        }}
                    />

                    <Input
                        hasTooltip={true}
                        order="column"
                        value={item?.header}
                        name={"component_header_"+index}
                        characterLimit={120}
                        onChange={(e) => {
                            var newComponentUpdate = [ ...section.data.components ]
                            
                            for(var j = 0; j < newComponentUpdate.length; j++)
                            {
                                if(j == index)
                                  newComponentUpdate[j] = {...newComponentUpdate[j],header:e.target.value};

                            }
                            
                            var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                           setSelectedSection(updatedData);
                        }}
                        label={"Header"}
                        placeholder={"header here"}
                        error={""}
                        tooltipText={
                        "Header text"
                        }
                    />


                    <Input
                        hasTooltip={true}
                        order="column"
                        value={item?.content}
                        name={"component_content_"+index}
                        characterLimit={250}
                        onChange={(e) => {
                            var newComponentUpdate = [ ...section.data.components ]
                            
                            for(var j = 0; j < newComponentUpdate.length; j++)
                            {
                                if(j == index)
                                  newComponentUpdate[j] = {...newComponentUpdate[j],content:e.target.value};

                            }
                            
                            var updatedData = {...section,data:{...section.data, components: newComponentUpdate}}
                           setSelectedSection(updatedData);
                        }}
                        label={"Content"}
                        placeholder={"Content here"}
                        error={""}
                        tooltipText={
                        "Content text"
                        }
                    />

                </div>
            )}




            </>
        )}


  </div>;
};


const mapStateToProps = createStructuredSelector({
    loading: makeSelectLoading(),
    inductionImage: makeSelectInductionImage(),
    inductionImageLoading: makeSelectInductionImageLoading(),
    inductionImageSuccess: makeSelectInductionImageSuccess(),
    inductionImageError: makeSelectInductionImageError(),
  });

  const mapDispatchToProps = (dispatch) => {
    return {
      uploadInductionImage: (val) => dispatch(uploadInductionImageReq(val)),
    };
  };
  

  export default connect(mapStateToProps, mapDispatchToProps)(ChaptersForm);