import React, { useEffect, useState, useRef } from "react";
import classes from "./user.module.scss";
import { importStaff, getRolesRequest } from "store/supervisor/actions";
import { makeSelectRoles, makeSelectLoading } from "store/supervisor/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import ReactLoading from "react-loading";
import { CSVLink } from "react-csv";
import Table from "components/Supervisor/table";
import { isNil, isEmpty } from "lodash";
import Modal from "components/Supervisor/modal";
import Counter from "components/Supervisor/counter";
import { RoleCell } from "components/Supervisor/cells";
import Papa from "papaparse";

const BackIcon = window.location.origin + "/backIcon/backIconn.svg";

const CsvImport = ({
  loading,
  history,
  importStaffRequest,
  roles,
  getRoles,
}) => {
  const csvData = [
    ["First Name", "Last Name", "Email", "Company", "Role Name"],
    ["John", "Doe", "john@doe.com", "John Ltd", "Admin hh"],
    ["Chris", "Tomi", "chris@tomi.com", "Tomi Ltd", "test"],
  ];

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    if (!isNil(roles)) {
      setRolesList(roles.results);
    }
  }, [roles]);

  const csvLink = useRef();

  const [isUploaded, setUploaded] = useState(null);
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [searchState, setSearchState] = useState("");

  const onSearch = (e) => {
    setSearchState(e.target.value);

    let filteredData = rawData.filter((value) => {
      return (
        value.first_name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        value.last_name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        value.email
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        value.company
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        value.role
          .toString()
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      );
    });

    setData(rolesTableData(filteredData));
  };

  const [modal, setModal] = useState({
    open: false,
    type: null,
    action: null,
    cancel: null,
  });

  const csvUpload = useRef();

  const onChangeFile = (e) => {
    e.stopPropagation();
    e.preventDefault();
    // setNumber(0);
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const data = results?.data;
          data.shift();
          const aa = data.map((item, index) => {
            return {
              id: index,
              first_name: item["First Name"],
              last_name: item["Last Name"],
              email: item["Email"],
              company: item["Company"],
              role: !isNil(rolesList.find((a) => a.name === item["Role Name"]))
                ? rolesList.find((a) => a.name === item["Role Name"]).name
                : item["Role Name"],
              valid: !isNil(rolesList.find((a) => a.name === item["Role Name"]))
                ? true
                : false,
            };
          });
          setRawData(aa);
          setData(rolesTableData(aa));
          const newRows = data.map((item, i) => {
            return {
              id: i,
              index: i,
              checked: false,
            };
          });
          setSelectedRows(newRows);
          setUploaded(true);
        },
      });
      // csv.parse(reader.result, (err, data) => {});
    };

    reader.onerror = function (e) {
      console.log("error", e);
    };

    reader.readAsText(file);
  };

  const rolesTableData = (item) => {
    const data = item.map((a, index) => {
      return {
        id: a.id,
        values: [
          {
            id: a.id,
            index: index,
            value: `${a.first_name} ${a.last_name}`,
          },
          {
            value: a.company,
          },
          {
            value: a.email,
          },
          {
            value: a.role,
            component: <RoleCell data={a} />,
          },
        ],
      };
    });

    const labels = [
      { label: "Name", width: "15%" },
      { label: "Company", width: "20%" },
      { label: "Email", width: "20%" },
      { label: "Role", width: "25%" },
    ];

    return {
      columns: data,
      labels: labels,
    };
  };
  const rowActions = [
    {
      name: "Delete",
      action: (val) =>
        setModal({
          open: true,
          type: "delete",
          action: () => {
            const newData = rawData.filter((item) => item.id !== val.id);
            setRawData(newData);
            setData(rolesTableData(newData));
            const newRows = newData.map((item, i) => {
              return {
                id: i,
                index: i,
                checked: false,
              };
            });
            setSelectedRows(newRows);
            setModal({
              open: false,
              type: null,
              action: null,
              cancel: null,
            });
          },
          cancel: () =>
            setModal({
              open: false,
              type: null,
              action: null,
              cancel: null,
            }),
        }),
    },
  ];

  return (
    <>
      {!isNil(isUploaded) ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Counter count={selectedRows.length} title={"Total Users"} />
            {rolesList.map((item) => (
              <Counter
                title={item.name}
                count={rawData.filter((b) => b.role === item.name).length}
              />
            ))}
          </div>
          <Table
            isSearchable={true}
            searchState={searchState}
            onSearch={(e) => onSearch(e)}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            data={data}
            rowActions={rowActions}
          />
          <div className={classes.buttonContainer}>
            <div
              style={{
                width: "75%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{ justifyContent: "flex-start" }}
                className={classes.flex}
              >
                {!isEmpty(selectedRows) &&
                  !isEmpty(selectedRows.filter((item) => item.checked)) && (
                    <button
                      className={classes.deleteButton}
                      onClick={() =>
                        setModal({
                          open: true,
                          type: "delete",
                          action: () => {
                            const selectedIndex = selectedRows
                              .filter((item) => item.checked)
                              .map((a) => a.index);
                            const newData = rawData.filter(
                              (item, index) => !selectedIndex.includes(index)
                            );
                            setRawData(newData);
                            setData(rolesTableData(newData));
                            const newRows = newData.map((item, i) => {
                              return {
                                id: i,
                                index: i,
                                checked: false,
                              };
                            });
                            setSelectedRows(newRows);
                            setModal({
                              open: false,
                              type: null,
                              action: null,
                              cancel: null,
                            });
                          },
                          cancel: () =>
                            setModal({
                              open: false,
                              type: null,
                              action: null,
                              cancel: null,
                            }),
                        })
                      }
                    >
                      Delete group of users
                    </button>
                  )}
              </div>
              <div
                style={{ justifyContent: "flex-end" }}
                className={classes.flex}
              >
                <button
                  onClick={() => {
                    setUploaded(null);
                    setData([]);
                    setSelectedRows([]);
                  }}
                  className={classes.lightButton}
                >
                  Cancel
                </button>
                <button
                  className={classes.darkButton}
                  onClick={() => {
                    setModal({
                      open: true,
                      type: "info",
                      action: () =>
                        importStaffRequest({
                          data: rawData,
                          rolesList: rolesList,
                        }),
                      cancel: () =>
                        setModal({
                          open: false,
                          type: null,
                          action: null,
                          cancel: null,
                        }),
                    });
                  }}
                >
                  Review & Send
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <img
            alt={"backIcon"}
            onClick={() => history.push("/supervisor-settings-users")}
            src={BackIcon}
            style={{
              width: "50px",
              height: "50px",
              cursor: "pointer",
              margin: "10px 10px 10px 0px",
            }}
          />
          {loading ? (
            <div>
              <div className={classes.loadingContainer}>
                <ReactLoading
                  type={"spin"}
                  color={"#20a8d8"}
                  height={300}
                  width={300}
                />
              </div>
            </div>
          ) : (
            <div>
              <div className={classes.container}>
                <h3 className={classes.header}>Export CSV file template </h3>
                <span className={classes.text}>
                  Export system friendly template where can add as many users as
                  necessary and assign them with desired roles.
                </span>
                <button
                  onClick={() => csvLink.current.link.click()}
                  className={classes.button}
                >
                  Export CSV file
                </button>
                <CSVLink
                  filename="example.csv"
                  className="hidden"
                  data={csvData}
                  ref={csvLink}
                  target="_blank"
                />

                <div className={classes.divider} />
                <h3 className={classes.header}>Import user group</h3>
                <span className={classes.text}>
                  Chose a filled out CSV file and import it.
                </span>
                <button
                  onClick={() => csvUpload.current.click()}
                  className={classes.button}
                >
                  Import CSV file
                </button>
                <input
                  className={"hidden"}
                  onChange={(e) => onChangeFile(e)}
                  type="file"
                  accept=".csv"
                  ref={csvUpload}
                  style={{ display: "none" }}
                />
              </div>
            </div>
          )}
        </div>
      )}
      <Modal
        open={modal.open}
        cancel={modal.cancel}
        action={modal.action}
        type={modal.type}
        totalUsers={selectedRows.length}
        rolesList={rolesList}
        data={data}
        loading={loading}
        deleteTitle={
          "You are about to delete the recently imported group of users"
        }
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  roles: makeSelectRoles(),
  loading: makeSelectLoading(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    importStaffRequest: (val) => dispatch(importStaff(val)),
    getRoles: (val) => dispatch(getRolesRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CsvImport);
