import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

//action start
import { loginRequest, checkInRequest } from "../../store/auth/actions";
import { getCompaniesRequest } from "../../store/supervisor/actions";

//action end

//selectors start
import { createStructuredSelector } from "reselect";
import {
  makeSelectUser,
  makeSelectLoading,
  makeSelectError,
} from "store/auth/selector";

import {
  makeSelectSelectedLanguage,
  makeSelectLanguages,
} from "store/language/selector";
import { makeSelectCompanies } from "store/supervisor/selector";
//selectors end

import { selectLanguage } from "store/language/actions";

//components start
import PrivacyPolicy from "../PrivacyPolicy";
import { Formik, Field, Form } from "formik";
import Button from "components/UI/button";
import Footer from "components/UI/footer";
import Input from "components/UI/input";
import Heading from "components/UI/heading";

//components end

import classes from "./register.module.scss";
import LoginSchema from "./validationSchema";
import queryString from "query-string";
import BackArrow from "./back.svg";
import { FormattedMessage } from "react-intl";
import { isNil } from "lodash";
import cn from "classnames";

const Register = (props) => {
  const [site, setSite] = useState(undefined);
  const [siteManager, setSiteManager] = useState(undefined);
  const [languageOptions, setLanguageOptions] = useState(false);
  const [userType, setUserType] = useState(false);
  const [selectedOption, setSelectedOption] = useState("en");
  const [isPrivacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const [isPrivacyPolicyAgreed, setPrivacyPolicyAgreed] = useState(false);

  useEffect(() => {
    if (props?.languages?.length) {
      const dfList = props.languages.map((item) => {
        return {
          value: item.code,
          label: item.name,
        };
      });

      setLanguageOptions(dfList);
    }
  }, [props.languages]);

  useEffect(() => {
    if (props?.selectedLanguage) {
      setSelectedOption(props?.selectedLanguage?.code);
    }
  }, [props.selectedLanguage]);

  const initialValues = {
    email: "",
    lastName: "",
    firstName: "",
    company: {
      id: null,
      value: "",
    },
    language: props?.selectedLanguage?.code,
    site: site,
    phoneNumber: "",
    siteManager: siteManager,
    userType: userType ? userType : "1",
    checkbox: false,
  };

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    setSite({ site: parsed.site });
    setSiteManager(parsed.sitemanager);
    setUserType(parsed.userType);
  }, []);

  return (
    <>
      <div className={classes.wrapper}>
        {!isPrivacyPolicyOpen && (
          <Heading
            title={"Welcome to your online HSE induction"}
            description={"Register with your company email address"}
          />
        )}
        {!isNil(userType) && (
          <div
            className={classes.backArrowWrapper}
            onClick={() => {
              props.history.push(`/persona-selection?site=${site?.site}`);
            }}
          >
            <img
              alt={"Back Arrow"}
              className={classes.backArrow}
              src={BackArrow}
            />
          </div>
        )}
        <Formik
          validationSchema={LoginSchema}
          validateOnChange={true}
          validateOnBlur={true}
          val
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values) => {
            props.onSubmitForm(values);
          }}
        >
          {({ values, errors, isValid, setFieldValue, touched }) => {
            return (
              <Form>
                <div className={classes.FormWrapper}>
                  <Field
                    error={errors && errors.firstName}
                    placeholder={"login.firstName.placeholder"}
                    label={"login.firstName"}
                    name="firstName"
                    value={values.firstName}
                    onChange={(e) => setFieldValue("firstName", e.target.value)}
                    component={Input}
                  />
                  <Field
                    error={errors && errors.lastName && errors.lastName.length}
                    placeholder={"login.lastName.placeholder"}
                    label={"login.lastName"}
                    name="lastName"
                    value={values.lastName}
                    onChange={(e) => setFieldValue("lastName", e.target.value)}
                    component={Input}
                  />

                  <Field
                    error={errors && errors.email && errors.email.length}
                    placeholder={"login.email.placeholder"}
                    label={"login.email"}
                    name="email"
                    value={values.email}
                    onChange={(e) => setFieldValue("email", e.target.value)}
                    component={Input}
                  />
                  <Field
                    name="checkbox"
                    // @ts-ignore
                    component={({ field }) => (
                      <div className={classes.termsWrapper}>
                        <label className={cn(classes.custom_checkbox)}>
                          {
                            <span>
                              Contact information is collected to generate
                              work-related notifications and messaging
                            </span>
                          }
                          <input
                            name={"checkbox"}
                            type="checkbox"
                            checked={isPrivacyPolicyAgreed}
                            onChange={(e) => {
                              setFieldValue("checkbox", e.target.checked);
                              setPrivacyPolicyAgreed(e.target.checked);
                            }}
                          />
                          <span className={classes.checkmark}></span>
                        </label>
                        <span
                          className={classes.readMore}
                          onClick={() => {
                            setPrivacyPolicyOpen(true);
                            window.scrollTo({ top: 0, behavior: "smooth" });
                          }}
                        >
                          Read More
                        </span>
                      </div>
                    )}
                  />
                </div>

                {!isPrivacyPolicyOpen && isValid && isPrivacyPolicyAgreed && (
                  <Footer>
                    <Button
                      disabled={!isPrivacyPolicyAgreed}
                      formatID="login.button"
                      loading={props.loading}
                      id="apply-button"
                      text="Get Started"
                      type="submit"
                    />
                  </Footer>
                )}
                <PrivacyPolicy
                  no_header
                  setFieldValue={setFieldValue}
                  setPrivacyPolicyOpen={setPrivacyPolicyOpen}
                  isPrivacyPolicyOpen={isPrivacyPolicyOpen}
                  setPrivacyPolicyAgreed={setPrivacyPolicyAgreed}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  languages: makeSelectLanguages(),
  user: makeSelectUser(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
  selectedLanguage: makeSelectSelectedLanguage(),
  companies: makeSelectCompanies(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitForm: (val) => dispatch(loginRequest(val)),
    checkIn: (val) => dispatch(checkInRequest(val)),
    setSelectLanguage: (val) => dispatch(selectLanguage(val)),
    getCompanies: (val) => dispatch(getCompaniesRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
