import React from "react";
import { Portal } from "react-portal";
import classes from "./modal.module.scss";
import { ReactComponent as CloseIcon } from "./close.svg";
import cn from "classnames";
import { FormattedMessage } from "react-intl";
const Modal = ({ visible, onCancel, onConfirm }) => {
  return (
    <Portal>
      <div className={cn(classes.modalContainer, !visible && classes.hidden)}>
        <div className={classes.modalWrapper}>
          <div className={classes.closeIconWrapper} onClick={() => onCancel()}>
            <CloseIcon />
          </div>
          <span className={classes.title}>
            <FormattedMessage
              id={"assesment.popup.title"}
              defaultMessage={"Knowledge check"}
            />
          </span>
          <span className={classes.description}>
            <FormattedMessage
              id={"assesment.popup.description.1"}
              defaultMessage={"Don't leave now, you're doing so well!"}
            />
            <br />
            <br />
            <FormattedMessage
              id={"assesment.popup.description.2"}
              defaultMessage={
                "If you exit the knowledge check, your answers will not be recorded."
              }
            />
            <br />
            <br />
            <FormattedMessage
              id={"assesment.popup.description.3"}
              defaultMessage={"Up to you, but you've been warned..."}
            />
          </span>
          <div className={classes.buttonWrapper}>
            <button className={classes.ligthButton} onClick={() => onCancel()}>
              <FormattedMessage
                id={"assesment.popup.button.cancel"}
                defaultMessage={"CANCEL"}
              />
            </button>
            <button className={classes.redButton} onClick={() => onConfirm()}>
              <FormattedMessage
                id={"assesment.popup.button.confirm"}
                defaultMessage={"Yes"}
              />
            </button>
          </div>
        </div>
      </div>
      <div
        className={cn(classes.modalBackground, !visible && classes.hidden)}
      />
    </Portal>
  );
};

export default Modal;
