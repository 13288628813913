import React, { useEffect, useMemo, useState } from "react";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import classes from "./hotspotsCard.module.scss";
import EyeIcon from "./eyeIcon.svg";
import rotateIcon from "../../../assets/icons/rotateScreen.svg";
import miniCheckIcon from "../../../assets/icons/miniCheck.svg";
import notificationClose from "../../../assets/icons/miniClose.svg";
import panIcon from "../../../assets/icons/Pan.svg";

import chevronLeftIcon from "../../../assets/icons/chevron_left.svg";
import chevronRightIcon from "../../../assets/icons/chevron_right.svg";

import HotSpotModal from "./hotspotModal";
import { useDoubleTap } from "utils/hooks";
import { cilAssistiveListeningSystem } from "@coreui/icons";
import { FormattedMessage } from "react-intl";
import FormattedHTMLMessage from "components/UI/FormattedHTMLMessage";

const HotspotsCard = ({
  config,
  onEnd,
  interactive,
  setFooterVisibilityReq,
}) => {
  const { background, hotspots } = config;
  const [completedSteps, setCompletedSteps] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [showText, setShowText] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const [currentModalContent, setCurrentModalContent] = useState(null);
  const [panVisible, setPanVisible] = useState(true);

  const [chevronRightVisible, setChevronRightVisible] = useState(true);
  const [chevronLeftVisible, setChevronLeftVisible] = useState(true);

  const [onLeftCount, setOnLeftCount] = useState(0);
  const [onRightCount, setOnRightCount] = useState(0);

  const isCompleted = useMemo(
    () => hotspots?.every((h) => completedSteps?.includes(h.name)),
    [completedSteps, hotspots]
  );
  const { title, content, title_id, content_id } = useMemo(
    () => (isCompleted ? config.end : config.intro),
    [config.end, config.intro, isCompleted]
  );

  useEffect(() => {
    if (interactive === true) {
      setFooterVisibilityReq({ isFooterVisible: false });
    }
  }, [interactive]);

  // Callbacks

  const toggleShowText = () => setShowText(!showText);

  const handleCompleteStep = (name) => {
    if (!completedSteps?.includes(name)) {
      setIsModalVisible(false);
      setCurrentModalContent(null);
      setCompletedSteps([name, ...completedSteps]);
    } else {
      setIsModalVisible(false);
      setCurrentModalContent(null);
    }
  };

  const handleDialogOpen = (idx) => {
    var buttonLabel = "Continue";

    if (isCompleted) buttonLabel = "";

    setCurrentModalContent({
      name: hotspots[idx].name,
      name_id: hotspots[idx].name_id,
      content: hotspots[idx].content,
      content_id: hotspots[idx].content_id,
      animationName: "",
      idx,
      buttonLabel: "", //set like this to avoid showing the button
      closeIconVisible: true,
    });
    setIsModalVisible(true);
  };

  const handleIntroDialog = () => {
    setCurrentModalContent({
      name: "Hotspots instructions",
      name_id: "hotspot.intro.name",
      content:
        "<div style='width:100%;text-align:center'><img src='https://keepsafe.work/images/ks_pan_icon.svg'></div><p style='text-align:center;margin-top:10px;'>You can <b>zoom</b> and <b>pan</b> the image the same way as you would do with your image gallery.</p><p style='text-align:center'><b>Have fun!</b></b>",
      content_id: "hotspot.intro.content",
      animationName: "",
      idx: -1,
      buttonLabel: "Start",
      button_id: "hotspot.intro.button",
      closeIconVisible: false,
    });

    setIsModalVisible(true);
  };

  const handleEndDialog = () => {
    setCurrentModalContent({
      name: "Well Done!",
      name_id: "hotspot.end.name",
      content:
        "<p style='text-align:center;margin-top:10px;'>You can now continue with your induction journey</p>",
      content_id: "hotspot.end.dialog",
      idx: -2,
      buttonLabel: "",
      closeIconVisible: false,
      animationName: "check",
    });

    setIsModalVisible(true);
  };

  // Effects

  useEffect(() => {
    if (isCompleted) {
      //setShowNotification(true);

      setTimeout(() => {
        handleEndDialog();
      }, 500);

      setFooterVisibilityReq({ isFooterVisible: true });
    }
  }, [isCompleted]);

  useEffect(() => {
    setTimeout(() => {
      handleIntroDialog();
    }, 2000);
  }, []);

  const windowHeight = window.innerHeight;
  const windowWidth = window.innerWidth;
  useEffect(() => {
    hotspots.map((spot, i) => {
      const pos = document.getElementsByClassName(classes.IconContainer)[i];
      pos.style.left = (spot.coordinate.x * 100) / windowWidth + "%";
      pos.style.top = (spot.coordinate.y * 100) / windowHeight + "%";
    });
  }, [hotspots]);

  const bindDoubleClick = useDoubleTap((event) => {
    //toggleShowText();
    setPanVisible(false);
  });

  useDoubleTap(toggleShowText);

  const hidePan = () => {
    setPanVisible(false);
  };

  const bubbleUpdate = (event) => {
    var currentX = event.state.positionX;

    //TODO: Calculate how many are out of bounds

    var count_on_left = 0;
    var count_on_right = 0;
    var width = window.innerWidth;

    hotspots.map((spot, i) => {
      const pos = document.getElementsByClassName(classes.IconContainer)[i];

      var posrect = pos.getBoundingClientRect();

      if (posrect.x > 0 && posrect.x > width) count_on_right += 1;

      if (posrect.x < 0) count_on_left += 1;
    });

    setOnLeftCount(count_on_left);
    setOnRightCount(count_on_right);
  };

  const panUpdate = (event) => {
    if (panVisible) hidePan();

    var minPosX = event.instance.bounds.minPositionX;
    var maxPosX = event.instance.bounds.maxPositionX;
    var currentX = event.state.positionX;

    const bias = 20;

    if (currentX - bias <= minPosX) setChevronRightVisible(false);
    else setChevronRightVisible(true);

    if (currentX + bias >= maxPosX) setChevronLeftVisible(false);
    else setChevronLeftVisible(true);

    setTimeout(() => {
      bubbleUpdate(event);
    }, 200);

    //TODO: Calculate how many are out of bounds

    /*    
    var count_on_left = 0;
    var count_on_right = 0;
    var width = window.innerWidth;

    hotspots.map((spot, i) => {
      const pos = document.getElementsByClassName(classes.IconContainer)[i];
      //console.log(pos.getBoundingClientRect());
      
      var posrect = pos.getBoundingClientRect();
      
      //console.log('currentX: ' + currentX + ' max: '+ (-1* currentX + width) +' pos.x: ' +posrect.x);

      if(posrect.x > 0 && posrect.x > width)
         count_on_right +=1;
      
      if(posrect.x < 0)
         count_on_left +=1;

    });
    
    setOnLeftCount(count_on_left);
    setOnRightCount(count_on_right);
    
    //console.log('on the right: ' + count_on_right + ' on the left: ' + count_on_left);
    */
  };

  return (
    <div style={{ zIndex: 10 }}>
      <HotSpotModal
        isVisible={isModalVisible}
        name={currentModalContent?.name}
        name_id={currentModalContent?.name_id}
        content={currentModalContent?.content}
        content_id={currentModalContent?.content_id}
        animationName={currentModalContent?.animationName}
        type={currentModalContent?.idx == -1 ? "Intro" : currentModalContent?.idx == -2 ? "Outro" :  "Normal"}
        buttonLabel={currentModalContent?.buttonLabel}
        button_id={currentModalContent?.button_id}
        closeIconVisible={currentModalContent?.closeIconVisible}
        onClose={(name) => handleCompleteStep(name)}
      />

      {/* For now it's de-scoped
      <div className={classes.rotateIconWrapper}><img src={rotateIcon} /></div>
      */}

      <div
        className={
          isCompleted && showNotification
            ? classes.NotificationWrapper
            : classes.NotificationWrapperHidden
        }
        onClick={(e) => {
          setShowNotification(false);
        }}
      >
        <div className={classes.NotificationClose}>
          <img src={notificationClose} />
        </div>
        <span className={classes.NotificationHeader}>
          <FormattedMessage
            defaultMessage={"Well Done!"}
            id={"hotspot.notification.header"}
          />
        </span>
        <span className={classes.Notification}>
          <FormattedMessage
            defaultMessage={"You can now continue with your induction journey."}
            id={"hotspot.notification.content"}
          />
        </span>
      </div>

      <div
        className={panVisible ? classes.PanWrapper : classes.PanWrapperHidden}
      >
        <div className={classes.PanIconWrapper}>
          <img alt={"pan icon"} src={panIcon} />
        </div>
        <div className={classes.Halo}></div>
      </div>

      {chevronLeftVisible && (
        <div className={classes.ChevronLeft}>
          <img alt={"chevron left"} src={chevronLeftIcon} />

          {onLeftCount > 0 && (
            <div className={classes.ChevronCountLeft}>{onLeftCount}</div>
          )}
        </div>
      )}

      {chevronRightVisible && (
        <div className={classes.ChevronRight}>
          <img alt={"chevron right"} src={chevronRightIcon} />

          {onRightCount > 0 && (
            <div className={classes.ChevronCountRight}>{onRightCount}</div>
          )}
        </div>
      )}

      <div
        className={
          classes.Container + ` ${isCompleted ? classes.completed : ""}`
        }
        {...bindDoubleClick}
      >
        <div
          className={
            showText ? classes.ImageWrapper : classes.ImageWrapperWithoutText
          }
        >
          <TransformWrapper
            doubleClick={{ disabled: true }}
            onPanningStop={panUpdate}
          >
            <TransformComponent>
              <img
                className={classes.image}
                id="yunus"
                src={background}
                alt="alt"
              />

              {hotspots.map((spot, i) => (
                <div
                  key={`${spot.name}-idx`}
                  className={classes.IconContainer}
                  style={{
                    position: "fixed",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDialogOpen(i);
                  }}
                >
                  {spot.type == "image" ? (
                    <div>
                      <div className={classes.IconWrapper}>
                        {completedSteps?.includes(spot.name) && (
                          <div className={classes.miniCheckIconWrapper}>
                            <img
                              className={classes.miniCheckIcon}
                              src={miniCheckIcon}
                              alt="check-icon"
                            />
                          </div>
                        )}
                        <img
                          className={classes.Icon}
                          src={spot.image}
                          alt={spot.name}
                        />
                      </div>
                      <div
                        className={
                          completedSteps?.includes(spot.name)
                            ? classes.HaloChecked
                            : classes.Halo
                        }
                      ></div>
                    </div>
                  ) : (
                    <div>
                      <div className={classes.textIconWrapper}>
                        {completedSteps?.includes(spot.name) && (
                          <div className={classes.miniCheckIconWrapper}>
                            <img
                              className={classes.miniCheckIcon}
                              src={miniCheckIcon}
                              alt="check-icon"
                            />
                          </div>
                        )}
                        <FormattedMessage
                          defaultMessage={spot.name}
                          id={spot.name_id ? spot.name_id : spot.name}
                        />
                      </div>
                      <div
                        className={
                          completedSteps?.includes(spot.name)
                            ? classes.rectangleHaloChecked
                            : classes.rectangleHalo
                        }
                      ></div>
                    </div>
                  )}
                </div>
              ))}
            </TransformComponent>
          </TransformWrapper>{" "}
        </div>
        {showText && (
          <div className={classes.TextContent}>
            <div className={classes.TitleWrapper}>
              <FormattedMessage defaultMessage={title} id={title_id} />
            </div>
            <div
              className={classes.ContentWrapper}
              // dangerouslySetInnerHTML={{ __html: content }}
            >
              <FormattedHTMLMessage id={content_id} value={content} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HotspotsCard;
