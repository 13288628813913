import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation } from "react-router-dom";

//routes
import {
  inductionRoutes,
  authRoutes,
  supervisorRoutes,
} from "routes/allRoutes";

//middleware start
import Authmiddleware from "middleware/auth";
import NonAuthmiddleware from "middleware/nonAuth";
import SupervisorAuthMiddleware from "middleware/supervisorAuth";

//midleware end

import { getLanguagesRequest } from "store/language/actions";
import {
  makeSelectLanguages,
  makeSelectLoading,
  makeSelectSelectedLanguage,
} from "./store/language/selector";
import { makeSelectUserType } from "store/auth/selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

//layouts
import Layout from "layouts/contractor";
import SupervisorLayout from "layouts/supervisor";

//pages
import CheckIn from "pages/CheckIn";
import SiteInduction from "pages/SiteInduction";
import CheckOut from "pages/CheckOut";
import SiteHazards from "pages/SiteHazards";
import Splash from "pages/Splash";

//components
import Loading from "components/UI/loading";
import ErrorBoundary from "components/UI/errorBoundary";
import { ToastContainer, toast } from "react-toastify";

//styles
import "assets/scss/style.scss";
import "assets/css/toastify.css";

//utils
import ReactGA from "react-ga";
import { enableES5 } from "immer";
import { icons } from "assets/icons";
import { AnimatePresence } from "framer-motion";
import { IntlProvider } from "react-intl";
import { isMobile } from "utils/helper";
import enTranslations from "translations/en.json";

enableES5();

React.icons = icons;

export const success = (val) => toast.success(`${val}`);
export const error = (val) => toast.error(`${val}`);

const App = ({ selectedLanguage, getLanguages, languages, userType }) => {
  const [langs, setLangs] = useState(null);
  const isMain = window.location.pathname === "/";

  const [landscapeWarningVisible, setLandscapeWarningVisible] = useState(false);
  const [isSplashVisible, setSplashVisible] = useState(isMain ? true : false);
  const location = useLocation();

  const trackingId = "G-240XE9FT93";

  useEffect(() => {
    ReactGA.initialize(trackingId);
  }, []);

  useEffect(() => {
    getLanguages();
    window.process = {
      ...window.process,
    };
  }, []);

  useEffect(() => {
    if (languages !== null) {
      const parsedlangs = languages?.map((item) => {
        return {
          ...item,
          data: JSON.parse(item.data),
        };
      });
      setLangs(parsedlangs);
    }
  }, [languages]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSplashVisible(false);
    }, 3500);
    return () => clearTimeout(timer);
  }, []);

  //onLocationChange

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    setInterval(function () {
      handleLandscapeWarningDialog();
    }, 2000);

    setTimeout(() => {
      if (isMobile) {
        handleLandscapeWarningDialog();
      }
    }, 2000);
  }, []);

  const handleLandscapeWarningDialog = () => {
    if (window.innerHeight > window.innerWidth) {
      return setLandscapeWarningVisible(false);
    } else if (window.innerWidth < 768) {
      return setLandscapeWarningVisible(true);
    }
  };

  return (
    <>
      <ErrorBoundary>
        <AnimatePresence mode="wait">
          <IntlProvider
            messages={
              selectedLanguage
                ? langs &&
                  langs?.find((item) => item.code === selectedLanguage?.code)
                    ?.data
                : enTranslations
            }
            locale={selectedLanguage ? selectedLanguage?.code : "en"}
            defaultLocale="en"
          >
            <React.Suspense fallback={Loading}>
              {isSplashVisible ? (
                <Splash />
              ) : (
                <Switch key={location.pathname} location={location}>
                  <Route path={"/check-in"} component={CheckIn} />
                  <Route path={"/check-out"} component={CheckOut} />
                  <Route path={"/site-induction"} component={SiteInduction} />
                  <Route path={"/site-hazards"} component={SiteHazards} />

                  {/*  Auth routes */}
                  {authRoutes.map((route, idx) => (
                    <NonAuthmiddleware
                      type={route.type}
                      path={route.path}
                      layout={
                        route.type === "supervisor" ? SupervisorLayout : Layout
                      }
                      component={route.component}
                      key={idx}
                      landscapeWarningVisible={landscapeWarningVisible}
                    />
                  ))}

                  {/* Induction Routes */}
                  {userType !== "supervisor" &&
                    inductionRoutes.map((route, idx) => (
                      <Authmiddleware
                        type={route.type}
                        path={route.path}
                        layout={Layout}
                        component={route.component}
                        key={idx}
                        landscapeWarningVisible={landscapeWarningVisible}
                      />
                    ))}

                  {/*Supervisor  Routes */}
                  {userType === "supervisor" &&
                    supervisorRoutes.map((route, idx) => (
                      <SupervisorAuthMiddleware
                        type={route.type}
                        path={route.path}
                        layout={SupervisorLayout}
                        component={route.component}
                        key={idx}
                        landscapeWarningVisible={landscapeWarningVisible}
                      />
                    ))}
                </Switch>
              )}
              <ToastContainer />
            </React.Suspense>
          </IntlProvider>
        </AnimatePresence>
      </ErrorBoundary>
    </>
  );
};
const mapStateToProps = createStructuredSelector({
  languages: makeSelectLanguages(),
  loading: makeSelectLoading(),
  selectedLanguage: makeSelectSelectedLanguage(),
  userType: makeSelectUserType(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getLanguages: (val) => dispatch(getLanguagesRequest(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
