// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_ERROR,
} from "../../constants";
import axios from "../../../../services/api";
import { push } from "connected-react-router";
import { success } from "../../../../main.js";

function deleteReq(data) {
  return axios.delete("/companies/", { data: { id: data.id } });
}

function* deleteCompany(data) {
  try {
    const response = yield call(deleteReq, data);
    yield put({
      type: DELETE_COMPANY_SUCCESS,
      data: response,
    });

    yield put(push("/supervisor-company"));
    yield call(success, `Company Deleted Succesfully`);
    if (data.callback) {
      data.callback();
    }
    if (data.end != null) {
      data.end.callback();
    }
  } catch (error) {
    console.log({ error });
    yield put({
      type: DELETE_COMPANY_ERROR,
      data: error,
    });
  }
}

export default function* deleteCompanySaga() {
  yield takeLatest(DELETE_COMPANY, deleteCompany);
}
