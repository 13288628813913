// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  CREATE_CONTRACTOR_CARD,
  CREATE_CONTRACTOR_CARD_ERROR,
  CREATE_CONTRACTOR_CARD_SUCCESS,
} from "../constants";
import axios from "../../../services/api";
import { push } from "connected-react-router";
import { error as err, success } from "../../../main.js";

function* createContractorCard(data) {
  try {
    const params = new URLSearchParams();
    params.append("user", data.user_id);
    params.append("site", data.site_id);
    params.append("type", data.cardType);
    params.append("action", data.action);
    params.append("summary", data.summary);

    const response = yield call(axios.post, `/cards/`, params);

    yield put({
      type: CREATE_CONTRACTOR_CARD_SUCCESS,
      data: response.data,
    });
    yield call(() => data.callBack());
    yield call(success, `New card Created Succesfully`);
    // yield put(push("/supervisor-sites"));
  } catch (error) {
    yield call(err, error.response.status.message.detail);
    yield put({
      type: CREATE_CONTRACTOR_CARD_ERROR,
      data: error,
    });
  }
}

export default function* createContractorCardSaga() {
  yield takeLatest(CREATE_CONTRACTOR_CARD, createContractorCard);
}
