import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

//styling
import classes from "./siteHazards.module.scss";
import cn from "classnames";

//components start
import Footer from "components/UI/footer";
import Button from "components/UI/button";
import IconComponent from "components/UI/IconComponent";
import Loading from "components/UI/loading";
//components end

//action start
import { getSiteHazardsRequest } from "store/auth/actions";
//action end

//selectors start
import {
  makeSelectLoading,
  makeSelectError,
  makeSelectSiteHazards,
} from "store/auth/selector";
//selectors end

//utils
import { withRouter } from "react-router";
import { isNil } from "lodash";

const SiteHazards = ({
  hazards,
  loading,
  setHasSiteHazards,
  hasSiteHazards,
  isHazardCollapsed,
  setHazardCollapsed,
  isCollapsedIndicator,
}) => {
  const [hazardList, setHazardList] = useState([]);

  useEffect(() => {
    if (hazards) {
      setHazardList(hazards?.results);
    }
  }, [hazards]);

  const hasRed = hazardList?.filter((item) => item.level === 1)?.length > 0;

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      className={cn(
        isHazardCollapsed
          ? classes.siteHazardCollapsed
          : hasSiteHazards
          ? classes.siteHazardContainerOpen
          : classes.none,
        isHazardCollapsed && !isCollapsedIndicator && classes.animate
      )}
    >
      <div
        className={classes.heading}
        onClick={() => {
          if (!isCollapsedIndicator) {
            setHazardCollapsed((val) => !val);
          }
        }}
      >
        {!isCollapsedIndicator && (
          <IconComponent
            className={cn(
              isHazardCollapsed ? classes.rotate180 : classes.rotate90
            )}
            size="12px"
            icon={"left-arrow"}
            color={"#3C4D56"}
          />
        )}
        <div className={classes.headingWrapper}>
          <div
            className={cn(
              classes.headingIconWrapper,
              hasRed ? classes.red : classes.orange
            )}
          >
            <IconComponent size="50%" icon={"warning"} color={"#ffffff"} />
          </div>
          <span className={classes.title}>Site Hazards</span>
          <div className={classes.count}>{hazardList?.length}</div>
        </div>
      </div>
      <div>
        <div className={classes.descriptionWrapper}>
          <span>
            Before you check in, let's have a look at the current site hazards.{" "}
          </span>
        </div>
        <div className={classes.cardContainer}>
          {hazardList.map((item) => (
            <div
              className={cn(
                classes.cardBox,
                item.image_url && classes.withImage
              )}
            >
              <div className={classes.wrapper}>
                <div className={classes.mainBox}>
                  {item.image_url && (
                    <img src={item.image_url} className={classes.mainImage} />
                  )}
                  <div className={classes.cardDescContainer}>
                    {item.icon && (
                      <div className={classes.iconBox}>
                        <div
                          className={classes.cardIconWrapper}
                          style={{
                            backgroundColor:
                              item.level === 1 ? "#e26c82" : "#f2c75e",
                          }}
                        >
                          <IconComponent
                            size="70%"
                            icon={item.icon}
                            color={"#ffffff"}
                          />
                        </div>
                      </div>
                    )}
                    <div className={classes.descWrapper}>
                      <span
                        style={{
                          color: item.level === 1 ? "#e26c82" : "#f2c75e",
                        }}
                        className={cn(
                          item.image_url
                            ? classes.cardTitleWithImage
                            : classes.cardTitle
                        )}
                      >
                        {item.name}
                      </span>
                      <span
                        className={cn(
                          item.image_url
                            ? classes.cardMessageWithImage
                            : classes.cardMessage
                        )}
                      >
                        {item.message}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div style={{ marginTop: "60px" }}>&nbsp;</div>
        </div>
        {isCollapsedIndicator && !isHazardCollapsed && (
          <Footer>
            <Button
              subText={""}
              text="Understood"
              theme="yellow"
              formatID=""
              onClick={() => {
                if (!isNil(setHasSiteHazards)) {
                  setHasSiteHazards(false);
                  setHazardCollapsed(false);
                }
              }}
            />
          </Footer>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  error: makeSelectError(),
  hazards: makeSelectSiteHazards(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getSiteHazards: (val) => dispatch(getSiteHazardsRequest(val)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SiteHazards)
);
