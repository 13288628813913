// @flow
import { put, takeLatest } from "redux-saga/effects";
import { SET_SECTION, SET_SECTION_SUCCESS } from "../constants";

function* setSection(data) {
  yield put({
    type: SET_SECTION_SUCCESS,
    data: data.value,
  });
}

export default function* setSectionSaga() {
  yield takeLatest(SET_SECTION, setSection);
}
