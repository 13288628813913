/* ASSESMENT GET */
export const ASSESMENT_REQUEST = "ASSESMENT/ASSESMENT_REQUEST";
export const ASSESMENT_REQUEST_SUCCESS = "ASSESMENT/ASSESMENT_REQUEST_SUCCESS";
export const ASSESMENT_REQUEST_ERROR = "ASSESMENT/ASSESMENT_REQUEST_ERROR";

/*  SET ASSESMENT RESULT */
export const SET_ASSESMENT_REQUEST = "ASSESMENT/SET_ASSESMENT_REQUEST";
export const SET_ASSESMENT_REQUEST_SUCCESS =
  "ASSESMENT/SET_ASSESMENT_REQUEST_SUCCESS";
export const SET_ASSESMENT_REQUEST_ERROR = "ASSESMENT/ASSESMENT_REQUEST_ERROR";
export const SET_ASSESMENT_REQUEST_CANCELLED =
  "ASSESMENT/ASSESMENT_REQUEST_CANCELLED";

/*  CREATE NEW  ASSESMENT */
export const CREATE_ASSESMENT_REQUEST = "ASSESMENT/CREATE_ASSESMENT_REQUEST";
export const CREATE_ASSESMENT_REQUEST_SUCCESS =
  "ASSESMENT/CREATE_ASSESMENT_REQUEST_SUCCESS";
export const CREATE_ASSESMENT_REQUEST_ERROR =
  "ASSESMENT/CREATE_ASSESMENT_REQUEST_ERROR";

//RESET ASSESMENT DATA

export const RESET_ASSESMENT_REQUEST = "ASSESMENT/RESET_ASSESMENT_REQUEST";

//SET ASSESMENT STATE
export const SET_ASSESMENT_STATE = "ASSESMENT/SET_ASSESMENT_STATE";
export const SET_ASSESMENT_STATE_SUCCESS =
  "ASSESMENT/SET_ASSESMENT_STATE_SUCCESS";
