import { findIndex, isNil } from "lodash";
import React, {
  useMemo,
  useEffect,
  useRef,
  useCallback,
  useState,
} from "react";
import { store } from "store";
import { isNaN } from "lodash";

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
};

export const usePrevious = (value) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = useMemo(() => {
    let sortableItems = [];
    if (!isNil(items)) {
      sortableItems.push(items);
    }
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key, action) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });

    if (!isNil(action)) {
      action(direction);
    }
  };

  return { items: sortedItems, requestSort, sortConfig };
};

export const useLocate = (selectedInduction, pathname) => {
  const pages = selectedInduction?.data?.pages;

  const index = findIndex(pages, {
    url: pathname,
  });

  return {
    previous: pages ? pages[index - 1] : null,
    current: pages ? pages[index] : null,
    next: pages ? pages[index + 1] : null,
    index: pages ? index : null,
  };
};

export const saveProgress = (selectedInduction, pathname, count) => {
  const pages = selectedInduction?.data?.pages;
  const index = findIndex(pages, {
    url: pathname,
  });

  const selectedPage = pages[index];

  if (!isNil(selectedPage?.id)) {
    store.dispatch({
      type: "PROGRESS/SET_PROGRESS_REQUEST",
      section: selectedPage?.id,
      induction_id: selectedInduction?.id,
      progress: !isNaN(count) && !isNil(count) ? count : 100,
    });
  }
};

export function useDoubleTap(callback, threshold = 300, options = {}) {
  const timer = useRef(null);

  const handler = useCallback(
    (event) => {
      if (!timer.current) {
        timer.current = setTimeout(() => {
          if (options.onSingleTap) {
            options.onSingleTap(event);
          }
          timer.current = null;
        }, threshold);
      } else {
        clearTimeout(timer.current);
        timer.current = null;
        callback && callback(event);
      }
    },
    [callback, threshold, options.onSingleTap]
  );

  return callback
    ? {
        onClick: handler,
      }
    : {};
}

const getOrientation = () => window.screen.orientation.type;

export const useScreenOrientation = () => {
  const [orientation, setOrientation] = useState(getOrientation());

  const updateOrientation = (event) => {
    setOrientation(getOrientation());
  };

  useEffect(() => {
    window.addEventListener("orientationchange", updateOrientation);
    return () => {
      window.removeEventListener("orientationchange", updateOrientation);
    };
  }, []);

  return orientation;
};
