import React from "react";

const SendIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 0.5H2C1.175 0.5 0.5 1.175 0.5 2V15.5L3.5 12.5H14C14.825 12.5 15.5 11.825 15.5 11V2C15.5 1.175 14.825 0.5 14 0.5ZM5.75 7.25H4.25V5.75H5.75V7.25ZM8.75 7.25H7.25V5.75H8.75V7.25ZM11.75 7.25H10.25V5.75H11.75V7.25Z"
        fill="white"
      />
    </svg>
  );
};

export default SendIcon;
