import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectProgress = (state) => state.progress || initialState;

const makeSelectProgress = () =>
  createSelector(selectProgress, (state) => state.result);

const makeSelectLoading = () =>
  createSelector(selectProgress, (state) => state.loading);

const makeSelectError = () =>
  createSelector(selectProgress, (state) => state.error);

export {
  selectProgress,
  makeSelectLoading,
  makeSelectError,
  makeSelectProgress,
};
