const CloseIcon = process.env.PUBLIC_URL + "/checkin/close.svg";
const RenewalIcon = process.env.PUBLIC_URL + "/checkin/renewal.svg";
const TickIcon = process.env.PUBLIC_URL + "/checkin/tick.svg";
const WarningIcon = process.env.PUBLIC_URL + "/checkin/warning.svg";
const PopUpCLoseIcon = process.env.PUBLIC_URL + "/checkin/closeBlack.svg";
const ArrowUpIcon = process.env.PUBLIC_URL + "/checkin/arrowUp.svg";
const EngineeringIcon = process.env.PUBLIC_URL + "/checkin/engineering.svg";
const LocationIcon = process.env.PUBLIC_URL + "/checkin/locationPin.svg";
const companyIcon = process.env.PUBLIC_URL + "/checkin/business.svg";
const completedIcon = process.env.PUBLIC_URL + "/checkin/check.svg";
const timelapseIconYellow =
  process.env.PUBLIC_URL + "/checkin/timelapse_yellow.svg";
const timelapseIconGreen =
  process.env.PUBLIC_URL + "/checkin/timelapse_green.svg";
const checkedInIcon = process.env.PUBLIC_URL + "/checkin/checkedin.svg";
const checkedOutIcon = process.env.PUBLIC_URL + "/checkin/checkedOut.svg";
const siteInductionCompleteIcon =
  process.env.PUBLIC_URL + "/checkin/siteInductionComplete.svg";
const pendingInductionIcon =
  process.env.PUBLIC_URL + "/checkin/pendingInduction.svg";
const blockedIcon = process.env.PUBLIC_URL + "/checkin/blocked.svg";
const siteSpecificRequired =
  process.env.PUBLIC_URL + "/checkin/siteSpecificRequired.svg";

const RenewalIconSet = {
  icon: RenewalIcon,
  iconWidth: "110px",
  iconHeight: "94px",
};

const BlockedIconSet = {
  icon: blockedIcon,
  iconWidth: "110px",
  iconHeight: "94px",
};

const SiteRequiredIconSet = {
  icon: siteSpecificRequired,
  iconWidth: "110px",
  iconHeight: "94px",
};

const WarningIconSet = {
  icon: WarningIcon,
  iconWidth: "100px",
  iconHeight: "89px",
};

const CloseIconSet = {
  icon: CloseIcon,
  iconWidth: "73px",
  iconHeight: "73px",
};

const TickIconSet = {
  icon: checkedInIcon,
  iconWidth: "88px",
  iconHeight: "66px",
};

const SiteInductionIconSet = {
  icon: siteInductionCompleteIcon,
  iconWidth: "88px",
  iconHeight: "66px",
};

const CheckOutIconSet = {
  icon: checkedOutIcon,
  iconWidth: "88px",
  iconHeight: "66px",
};

const PopUpCloseSet = {
  icon: PopUpCLoseIcon,
  iconWidth: "13px",
  iconHeight: "13px",
};

const RenewalIconSetNew = {
  icon: EngineeringIcon,
  iconWidth: "110px",
  iconHeight: "94px",
};

const TimelapseIconSet = {
  green: timelapseIconGreen,
  yellow: timelapseIconYellow,
};

const PendingInductionIconSet = {
  icon: pendingInductionIcon,
  green: timelapseIconGreen,
  yellow: timelapseIconYellow,
};

const colors = {
  green: "#6FCF97",
  gradient: "linear-gradient(180deg, #6FCF97 0%, #F2C75E 100%)",
  yellow: "#F2C75E",
  red: "#E26C82",
  blue: "#7185BE",
  darkBlue: "#28353C",
  success: "linear-gradient(180deg, #44C032 0%, #339426 100%)",
  imcompleteInduction: "#F2C75E",
  renewal: "linear-gradient(180deg, #6FCF97 0%, #F2C75E 100%)",
  gray: "#F8F8F8",
};

export const types = [
  {
    id: 1,
    title: "No site found with given code",
    titleId: "checkin.message.1",
    key: "error",
    icon: CloseIconSet,
    color: colors.red,
  },
  {
    id: 2,
    title: "User Not Found, Pending Induction",
    titleId: "checkin.message.2",
    key: "pending_induction",
    icon: RenewalIconSet,
    color: colors.blue,
  },
  {
    id: 3,
    htmlDescription: "You have been blocked",
    htmlDescriptionId: "checkin.htmlDescription.3",
    description:
      "Due to previously identified events, you're not allowed to enter our sites.",
    descriptionId: "checkin.description.3",
    key: "error",
    icon: BlockedIconSet,
    color: colors.red,
    companyIcon: companyIcon,
    showLocation: 1,
    locationPin: LocationIcon,
  },
  {
    id: 4,
    title: "Pending Induction",
    titleId: "checkin.message.4",
    description:
      "To enter our site, you are required to complete an online Induction.",
    descriptionId: "checkin.description.4",
    key: "pending_induction",
    icon: PendingInductionIconSet,
    color: colors.yellow,
    companyIcon: companyIcon,
    showLocation: 1,
    locationPin: LocationIcon,

    footer: {
      text: "Start induction",
      textID: "checkin.pendingInduction.footer.text",
    },
    pushPath: "/site-selector",
  },
  {
    id: 5,
    title: "Incomplete Induction",
    titleId: "checkin.message.5",
    key: "incomplete_induction",
    icon: PendingInductionIconSet,
    color: colors.yellow,
    companyIcon: companyIcon,
    showLocation: 1,
    locationPin: LocationIcon,
    footer: {
      text: "Continue induction",
      textID: "checkin.incompleteInduction.footer.text",
    },
    pushPath: "/site-selector",
  },
  {
    id: 6,
    title:
      "User does not have a company record. Some of the user data is missing or corrupted.",
    titleId: "checkin.message.6",
    key: "error",
    icon: CloseIconSet,
    color: colors.red,
  },
  {
    id: 7,
    title: "Renewal Required",
    titleId: "checkin.message.7",
    key: "renewal_required",
    icon: RenewalIconSet,
    color: colors.yellow,
    companyIcon: companyIcon,
    showLocation: 1,
    locationPin: LocationIcon,
    footer: {
      text: "Start renewal process",
      textID: "checkin.renewal.footer.text",
    },
  },
  {
    id: 8,
    key: "site_specific_induction_required",
    title: "Site-specific induction required.",
    titleId: "checkin.siteSpecific.popUp.title",
    icon: SiteRequiredIconSet,
    color: colors.imcompleteInduction,
    locationPin: LocationIcon,
    showLocationPin: 1,
    companyIcon: companyIcon,
    showOnlineCompleted: 1,
    onlineCompletedIcon: completedIcon,
    /*
    popUp: {
      title: "Site-specific induction required.",
      titleId: "checkin.siteSpecific.popUp.title",
      description:
        "Open-up the bottom panel to access the site-specific induction",
      descriptionId: "checkin.siteSpecific.popUp.desc",
      icon: PopUpCloseSet,
    },
    */
    /*
    bottomPopUp: {
      title: "Complete site-specific induction",
      titleId: "checkin.sitespecific.footer.title",
      description:
        "To be able to enter the site safely you're required to complete this site-specific induction.",
      descriptionId: "checkin.sitespecific.footer.desc",
      subText: "checkin.sitepecific.footer.subdesc",
      hasButton: true,
      buttonLabel: "checkin.sitespecific.footer.button.text",
      popUpIcon: ArrowUpIcon,
    },
    */
  },
  {
    id: 9,
    title: "Renewal Required for Site Specific Induction",
    titleId: "checkin.message.9",
    key: "site_specific_induction_renewal_required",
    icon: RenewalIconSet,
    color: colors.yellow,
    companyIcon: companyIcon,
    showLocation: 1,
    locationPin: LocationIcon,
    footer: {
      text: "Continue  ",
      textID: "checkin.incompleteInduction.footer.text",
    },
  },
  {
    id: 10,
    title: "User has already checked in",
    titleId: "checkin.message.10",
    key: "already_checked_in",
    timeCount: true,
    icon: TickIconSet,
    color: colors.green,
    dateText: "Days left",
    dateTextId: "checkin.dateText.10",
    showTimeRemaining: 1,
    TimelapseIconSet: TimelapseIconSet,
    companyIcon: companyIcon,

    // popUp: {
    //   title: "Site-specific induction required.",
    //   titleId: "checkin.siteSpecific.popUp.title",
    //   description:
    //     "Open-up the bottom panel to access the site-specific induction",
    //   descriptionId: "checkin.siteSpecific.popUp.desc",
    //   icon: PopUpCloseSet,
    // },

    // bottomPopUp: {
    //   title: "Complete site-specific induction",
    //   titleId: "checkin.sitespecific.footer.title",
    //   description:
    //     "To be able to enter the site safely you're required to complete this site-specific induction.",
    //   descriptionId: "checkin.sitespecific.footer.desc",
    //   subText: "checkin.sitepecific.footer.subdesc",
    //   hasButton: true,
    //   buttonLabel: "checkin.sitespecific.footer.button.text",
    //   popUpIcon: ArrowUpIcon,
    // },
  },
  {
    id: 11,
    key: "renewal_required_soon",
    description: "Renewal required soon",
    descriptionId: "checkin.message.11",
    timeCount: true,
    icon: RenewalIconSet,
    color: colors.green,
    dateText: "Days left",
    dateTextId: "checkin.dateText.10",
    showTimeRemaining: 1,
    TimelapseIconSet: TimelapseIconSet,
    companyIcon: companyIcon,
    footer: {
      text: "Start renewal induction",
      textID: "checkin.renewal.footer.text",
    },
  },
  {
    id: 12,
    title: "Checked-in",
    titleId: "checkin.message.12",
    key: "checked_in",
    timeCount: true,
    icon: TickIconSet,
    color: colors.green,
    dateText: "Days left",
    dateTextId: "checkin.dateText.10",
    showTimeRemaining: 1,
    TimelapseIconSet: TimelapseIconSet,
    companyIcon: companyIcon,
    showLocationPin: 1,
    locationPin: LocationIcon,
  },
  {
    id: 13,
    title: "No site manager found with given code",
    titleId: "checkin.message.13",
    key: "site_specific",
    icon: CloseIconSet,
    color: colors.red,
  },
  {
    id: 14,
    title: "Site manager has incorrect permissions",
    titleId: "checkin.message.14",
    key: "site_specific",
    icon: CloseIconSet,
    color: colors.red,
  },
  {
    id: 15,
    title: "Site manager does not have permission for the site",
    titleId: "checkin.message.15",
    key: "site_specific",
    icon: CloseIconSet,
    color: colors.red,
  },
  {
    id: 16,
    title: "Site specific induction is completed",
    titleId: "checkin.message.16",
    key: "site_specific",
    icon: SiteInductionIconSet,
    color: colors.green,
    showLocation: 1,
    locationPin: LocationIcon,
    showTimeRemaining: 1,
    TimelapseIconSet: TimelapseIconSet,
    dateTextId: "checkin.dateText.10",
    dateText: "Days left",
    footer: {
      text: "Check-in",
      textID: "checkin.button.site.specific",
    },
  },
  {
    id: 18,
    title: "There is no check in found, no checkout is needed",
    titleId: "checkin.message.18",
    key: "error",
    icon: CloseIconSet,
    color: colors.red,
    companyIcon: companyIcon,
    showLocation: 1,
    locationPin: LocationIcon,
    TimelapseIconSet: TimelapseIconSet,
    dateTextId: "checkin.dateText.10",
    dateText: "Days left",
  },
  {
    id: 19,
    title: "Check out",
    titleId: "checkin.message.19",
    description: "You just checked out of our {site} site.",
    descriptionId: "checkin.description.19",
    key: "checked_in",
    icon: CheckOutIconSet,
    color: colors.gray,
    popUp: true,
    bottomDescription: "",
    bottomDescriptionId: "checkin.description.20",
    showLocationPin: 1,
    companyIcon: companyIcon,
    showLocation: 1,
    locationPin: LocationIcon,
    dateTextId: "checkin.dateText.10",
    dateText: "Days left",
  },
];
