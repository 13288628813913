import React, { useEffect, useState } from "react";
import classes from "./siteInduction.module.scss";
import queryString from "query-string";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
//action start
import { siteInductionRequest } from "store/auth/actions";
//action end

//selectors start
import {
  makeSelectLoading,
  makeSelectError,
  makeSelectSiteInduction,
  makeSelectUser,
} from "store/auth/selector";
//selectors end
import { isNil } from "lodash";
import { withRouter } from "react-router";
import { FormattedMessage } from "react-intl";
import FormattedHTMLMessage from "components/UI/FormattedHTMLMessage";
import Loading from "components/Supervisor/loading";
import { types } from "../CheckIn/types";
import Layout from "layouts/contractor";

const SiteInduction = ({
  siteInductionReq,
  loading,
  user,
  error,
  siteInduction,
  history,
}) => {
  const [params, setParams] = useState(null);

  const [variable, setVariable] = useState(false);
  const [data, setData] = useState(false);

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);

    siteInductionReq({
      site: parsed?.site,
      siteManager: parsed?.sitemanager,
      token: user?.token,
    });
    setParams(parsed);
  }, []);

  useEffect(() => {
    if (!isNil(siteInduction)) {
      const selectedVariable = types.find(
        (item) => item.id === siteInduction?.messageId
      );
      setVariable(selectedVariable);
      setData(siteInduction);
    }
  }, [siteInduction]);

  useEffect(() => {
    if (!isNil(error)) {
      const selectedVariable = types.find(
        (item) => item.id === error?.messageId
      );

      setVariable(selectedVariable);
      setData(error);
    }
  }, [error]);

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <>
      <Layout menu={true}>
        {!isNil(variable) && !isNil(data) && (
          <div
            className={classes.container}
            style={{ background: variable?.color }}
          >
            <img
              alt={"icon"}
              className={classes.icon}
              style={{
                width: variable?.icon?.iconWidth,
                height: variable.icon?.iconHeight,
              }}
              src={variable?.icon?.icon}
            />
            <span className={classes.name}>
              {user && user?.first_name} {user && user?.last_name}
            </span>
            {variable?.title && (
              <span className={classes.title}>
                <FormattedMessage
                  defaultMessage={variable?.title}
                  id={variable?.titleId}
                />
              </span>
            )}
            {variable?.htmlDescription && (
              <span className={classes.htmlDescription}>
                <FormattedHTMLMessage
                  defaultMessage={variable?.htmlDescription}
                  id={variable.htmlDescription}
                />
              </span>
            )}

            {!isNil(variable?.subDescription) && (
              <span className={classes.subDescription}>
                <FormattedMessage
                  defaultMessage={variable.subDescription}
                  id={variable.subDescriptionId}
                />
              </span>
            )}
            <div className={classes.infoContainer}>
              {variable?.showLocationPin == 1 && (
                <div className={classes.locationBox}>
                  <span className={classes.locationTitle}>
                    <img alt={"location"} src={variable?.locationPin} />
                  </span>
                  <span className={classes.locationText}>
                    {data?.site?.address1}
                  </span>
                </div>
              )}

              <div className={classes.boxWrapper}>
                {variable?.companyIcon == 1 && (
                  <div className={classes.box}>
                    <img alt={"company"} src={variable?.companyIcon} />
                    <span className={classes.boxText}>
                      {data?.site?.company?.name}
                    </span>
                  </div>
                )}

                {variable?.showLocation == 1 && (
                  <div className={classes.box}>
                    <img alt={"location pin"} src={variable?.locationPin} />
                    <span className={classes.boxText}>
                      {data?.site?.address1}
                    </span>
                  </div>
                )}

                {variable?.showOnlineCompleted == 1 && (
                  <div className={classes.box}>
                    <img
                      alt={"completed icon"}
                      src={variable?.onlineCompletedIcon}
                    />
                    <span className={classes.boxText}>
                      <FormattedMessage
                        defaultMessage={"Online induction completed"}
                        id={"checkin.inductioncompleted"}
                      />
                    </span>
                  </div>
                )}

                {variable?.showTimeRemaining == 1 && (
                  <div className={classes.box}>
                    <img
                      alt={"time lapse"}
                      src={
                        data.remaining_days > 30
                          ? variable?.TimelapseIconSet.green
                          : variable?.TimelapseIconSet.yellow
                      }
                    />
                    <span className={classes.boxDays}>
                      {data.remaining_days}
                    </span>
                    <span className={classes.boxText}>
                      <FormattedMessage
                        defaultMessage={variable?.dateText}
                        id={variable.dateTextId}
                        values={{ day: data.remaining_days }}
                      />
                    </span>
                  </div>
                )}
              </div>
            </div>
            {variable?.footer && (
              <>
                <div
                  className={classes.footer}
                  onClick={() => {
                    history.push(`/check-in?site=${params.site}`);
                  }}
                >
                  <span>
                    <FormattedMessage
                      defaultMessage={variable?.footer?.text}
                      id={variable?.footer?.textID}
                    />
                  </span>
                </div>
              </>
            )}
          </div>
        )}
      </Layout>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  error: makeSelectError(),
  user: makeSelectUser(),
  siteInduction: makeSelectSiteInduction(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    siteInductionReq: (val) => dispatch(siteInductionRequest(val)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SiteInduction)
);
