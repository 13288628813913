import {
  SET_SECTION_TIME_REQUEST,
  GET_SECTION_TIME_REQUEST,
  SET_CURRENT_SECTION_REQUEST,
  SET_COUNT_TIME_REQUEST,
} from "./constants";

export const setSectionTime = (payload) => {
  return {
    type: SET_SECTION_TIME_REQUEST,
    ...payload,
  };
};

export const getSectionTime = (payload) => {
  return {
    type: GET_SECTION_TIME_REQUEST,
    ...payload,
  };
};

export const setCountTime = (payload) => {
  return {
    type: SET_COUNT_TIME_REQUEST,
    ...payload,
  };
};

export const setCurrentSection = (payload) => {
  return {
    type: SET_CURRENT_SECTION_REQUEST,
    ...payload,
  };
};
