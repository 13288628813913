import React from "react";
import classes from "../reporting.module.scss";
import FilterSelector from "components/Supervisor/filterSelector";

const Filters = ({
  issuerOptions,
  setIssuerOptions,
  setContractorOptions,
  contractorOptions,
  companyOptions,
  setCompanyOptions,
  typeOptions,
  setTypeOptions,
  runFilters,
  getCompanies,
  getContractorList,
  getManagers,
  loading,
}) => {
  return (
    <div className={classes.filterWrapper}>
      {/*
      <FilterSelector
        loading={loading}
        title={"Company"}
        options={companyOptions}
        searchable={true}
        onSearch={(val) => getCompanies({ search: val, filter: true })}
        onChange={(val) => {
          const opts = companyOptions.map((item) => {
            return {
              ...item,
              checked: item.value === val ? !item.checked : item.checked,
            };
          });
          setCompanyOptions(opts);
          runFilters(opts, contractorOptions, issuerOptions, typeOptions);
        }}
      />
      */}
      <FilterSelector
        loading={loading}
        title={"Contractor"}
        options={contractorOptions}
        searchable={true}
        onSearch={(val) =>
          getContractorList({
            page: 1,
            searchItem: val,
            sort: "",
            filter: true,
          })
        }
        onChange={(val) => {
          const opts = contractorOptions.map((item) => {
            return {
              ...item,
              checked: item.value === val ? !item.checked : item.checked,
            };
          });
          setContractorOptions(opts);
          runFilters(companyOptions, opts, issuerOptions, typeOptions);
        }}
      />
      <FilterSelector
        loading={loading}
        title={"Issued By"}
        options={issuerOptions}
        searchable={true}
        onSearch={(val) => getManagers({ search: val, filter: true })}
        onChange={(val) => {
          const opts = issuerOptions.map((item) => {
            return {
              ...item,
              checked: item.value === val ? !item.checked : item.checked,
            };
          });
          setIssuerOptions(opts);
          runFilters(companyOptions, contractorOptions, opts, typeOptions);
        }}
      />
      <FilterSelector
        title={"Card Type"}
        options={typeOptions}
        onChange={(val) => {
          const opts = typeOptions.map((item) => {
            return {
              ...item,
              checked: item.value === val ? !item.checked : false,
            };
          });
          setTypeOptions(opts);
          runFilters(companyOptions, contractorOptions, issuerOptions, opts);
        }}
      />
    </div>
  );
};

export default Filters;
