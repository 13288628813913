import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
//action start
import { getPeopleListReq } from "store/supervisor/actions";
//action end

//selectors start
import { createStructuredSelector } from "reselect";
import {
  makeSelectPeople,
  makeSelectError,
  makeSelectLoading,
} from "store/supervisor/selector";
import { makeSelectSupervisor } from "store/auth/selector";
import classes from "./people.module.scss";
import { isNil } from "lodash";
import Table from "components/Supervisor/table";
import { useSortableData } from "utils/hooks";

import Filters from "./filters";
import DateRangeFilter from "components/Supervisor/dateRangeFilter";
import Icon from "components/UI/IconComponent";
import { peopleListTableData } from "./data";
import { getUnixTime } from "date-fns";

const PeopleListTable = ({ people, getPeopleList, history, loading }) => {
  const [contractorList, setContractorList] = useState([]);

  const [rawList, setRawList] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);

  const [PAGE_COUNT, setPageCount] = useState(null);

  const [searchState, setSearchState] = useState("");

  const [statistics, setStatistics] = useState(false);

  const [filterDate, setDateFilter] = useState(false);
  const [filters, setFilters] = useState();

  const { requestSort, sortConfig } = useSortableData(rawList);
  const sortBy = (key) => {
    requestSort(key, (direction) =>
      getPeopleList({
        page: selectedPage,
        searchItem: searchState,
        ordering: direction === "descending" ? `-${key}` : key,
      })
    );
  };

  const orderingConfig =
    sortConfig?.direction?.toString() === "descending" &&
    !isNil(sortConfig?.key)
      ? `-${sortConfig.key}`
      : !isNil(sortConfig?.key)
      ? sortConfig?.key
      : "-id";

  const onSearch = (e) => {
    setSearchState(e.target.value);
    if (e.target.value.length === 0) {
      getPeopleList({
        page: 1,
        searchItem: "",
        ordering: orderingConfig,
      });
      setSelectedPage(1);
    }
  };

  useEffect(() => {
    getPeopleList({
      page: 1,
      searchItem: "",
      ordering: `-id`,
    });
  }, []);

  useEffect(() => {
    if (!isNil(people)) {
      setContractorList(peopleListTableData(people.results));
      const newRows = people?.results?.map((item, i) => {
        return {
          id: item.id,
          checked: false,
        };
      });

      setSelectedRows(newRows);
      setRawList(people?.results);
      console.log(
        "results",
        people.results.map((item) => item.status)
      );
      setPageCount((people?.count / people?.items_per_page + 1).toFixed(0));
      setStatistics({
        visitors: people.visitor_count,
        staff: people.staff_count,
        contractors: people.contractor_count,
      });
    }
  }, [people]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      setSelectedPage(1);
      getPeopleList({
        page: 1,
        searchItem: event.target.value,
        ordering: orderingConfig,
      });
    }
  };

  const rowActions = [
    {
      name: "View details",
      action: (val) => {
        history.push(
          `/supervisor-sites-contractors-detail?id=${val.id}&returnUrl=supervisor-people-contractors`
        );
      },
      icon: "edit",
    },
    {
      name: "Delete",
      icon: "delete",
      action: (val) => {},
    },
  ];

  const [isActionActive, setActionActive] = useState(false);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headingWrapper}>
          <h1 className={classes.titleHead}>People</h1>
          <div className={classes.peopleStats}>
            <span className={classes.peopleCount}>
              {statistics?.contractors}
            </span>
            <span className={classes.peopleText}>Contractors</span>
          </div>
          <div className={classes.peopleStats}>
            <span className={classes.peopleCount}> {statistics?.staff}</span>
            <span className={classes.peopleText}>Staff</span>
          </div>
          <div className={classes.peopleStats}>
            <span className={classes.peopleCount}> {statistics?.visitors}</span>
            <span className={classes.peopleText}>Visitors</span>
          </div>
          <div className={classes.dateRangeWrapper}>
            <DateRangeFilter
              value={filterDate}
              onChange={(val) => {
                setDateFilter({
                  enddate: getUnixTime(val[0].endDate),
                  startdate: getUnixTime(val[0].startDate),
                });
              }}
            />
          </div>

          <div
            onMouseOver={() => setActionActive(true)}
            onMouseLeave={() => setActionActive(false)}
            className={classes.mainActionButton}
          >
            <Icon icon="action" size={24} color="#6FCF97" />
          </div>
        </div>
        <div className={classes.filterContainer}>
          <Filters
            setSelectedPage={setSelectedPage}
            page={1}
            searchState={searchState}
            orderingConfig={orderingConfig}
            saveFilters={(val) => setFilters(val)}
          />
        </div>
        <div className={classes.tableWrapper}>
          <Table
            handleKeyDown={handleKeyDown}
            isSearchable={true}
            searchState={searchState}
            loading={loading}
            onSearch={(e) => onSearch(e)}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            data={contractorList}
            rowActions={rowActions}
            clickable={true}
            sortByDesc={(val) => sortBy(val)}
            sortConfig={sortConfig}
          />
        </div>
      </div>
      {PAGE_COUNT > 1 && (
        <div className={classes.paginationContainer}>
          {Array.from(
            Array.from(
              {
                length: PAGE_COUNT,
              },
              (_, i) => i + 1
            )
          ).map((item, i) => (
            <span
              onClick={() => {
                getPeopleList({
                  page: i + 1,
                  searchItem: searchState,
                  ordering: orderingConfig,
                  ...filters,
                });
                setSelectedPage(i + 1);
              }}
              style={{
                background:
                  i + 1 === selectedPage && " rgba(165, 180, 201, 0.2)",
              }}
            >
              {i + 1}
            </span>
          ))}
        </div>
      )}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  people: makeSelectPeople(),
  loading: makeSelectLoading(),
  error: makeSelectError(),
  supervisor: makeSelectSupervisor(),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getPeopleList: (val) => dispatch(getPeopleListReq(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PeopleListTable);
