import React from "react";
import { ReactComponent as PeopleIcon } from "./icons/people.svg";
import { ReactComponent as DashboardIcon } from "./icons/dashboard.svg";
import { ReactComponent as ReportIcon } from "./icons/report.svg";
import { ReactComponent as SettingsIcon } from "./icons/settings.svg";
import { ReactComponent as SitesIcon } from "./icons/sites.svg";
import { ReactComponent as NotificationIcon } from "./icons/notifications.svg";
import { ReactComponent as HazardsIcon } from "./icons/hazards.svg";
import { ReactComponent as InductionIcon } from "./icons/induction_badge.svg";

export default [
  {
    _tag: "CSidebarNavItem",
    name: "Dashboard",
    to: "/supervisor-dashboard",
    icon: <DashboardIcon />,
    key: "dashboard",
  },
  {
    _tag: "CSidebarNavItem",
    name: "People",
    to: "/supervisor-people",
    icon: <PeopleIcon />,
    key: "people",
  },
  /*
  {
    _tag: "CSidebarNavItem",
    name: "Company",
    to: "/supervisor-company",
    icon: <CompanyIcon />,
    key: "company",
  },
  */
  {
    _tag: "CSidebarNavItem",
    name: "Sites",
    to: "/supervisor-sites",
    icon: <SitesIcon />,
    key: "sites",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Inductions",
    to: "/supervisor-inductions",
    icon: <InductionIcon />,
    key: "inductions",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Settings",
    to: "/supervisor-settings-translations",
    icon: <SettingsIcon />,
    key: "settings",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Reporting",
    to: "/supervisor-reporting-inductions",
    icon: <ReportIcon />,
    key: "reporting",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Notifications",
    to: "/supervisor-notifications",
    icon: <NotificationIcon />,
    key: "notifications",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Hazards",
    to: "/supervisor-hazards-update",
    icon: <HazardsIcon />,
    key: "hazards",
  },
];
