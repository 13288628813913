export const defaultLanguages = [
  {
    name: "Afar",
    nativeName: "afar",
    isocode: "aa",
    code: "aar",
    code2: null,
  },
  {
    name: "Abkhazian",
    nativeName: "abkhaze",
    isocode: "ab",
    code: "abk",
    code2: null,
  },
  {
    name: "Achinese",
    nativeName: "aceh",
    isocode: null,
    code: "ace",
    code2: null,
  },
  {
    name: "Acoli",
    nativeName: "acoli",
    isocode: null,
    code: "ach",
    code2: null,
  },
  {
    name: "Adangme",
    nativeName: "adangme",
    isocode: null,
    code: "ada",
    code2: null,
  },
  {
    name: "Adyghe; Adygei",
    nativeName: "adyghé",
    isocode: null,
    code: "ady",
    code2: null,
  },
  {
    name: "Afro-Asiatic languages",
    nativeName: "afro-asiatiques, langues",
    isocode: null,
    code: "afa",
    code2: null,
  },
  {
    name: "Afrihili",
    nativeName: "afrihili",
    isocode: null,
    code: "afh",
    code2: null,
  },
  {
    name: "Afrikaans",
    nativeName: "afrikaans",
    isocode: "af",
    code: "afr",
    code2: null,
  },
  {
    name: "Ainu",
    nativeName: "aïnou",
    isocode: null,
    code: "ain",
    code2: null,
  },
  {
    name: "Akan",
    nativeName: "akan",
    isocode: "ak",
    code: "aka",
    code2: null,
  },
  {
    name: "Akkadian",
    nativeName: "akkadien",
    isocode: null,
    code: "akk",
    code2: null,
  },
  {
    name: "Albanian",
    nativeName: "albanais",
    isocode: "sq",
    code: "alb",
    code2: "sqi",
  },
  {
    name: "Aleut",
    nativeName: "aléoute",
    isocode: null,
    code: "ale",
    code2: null,
  },
  {
    name: "Algonquian languages",
    nativeName: "algonquines, langues",
    isocode: null,
    code: "alg",
    code2: null,
  },
  {
    name: "Southern Altai",
    nativeName: "altai du Sud",
    isocode: null,
    code: "alt",
    code2: null,
  },
  {
    name: "Amharic",
    nativeName: "amharique",
    isocode: "am",
    code: "amh",
    code2: null,
  },
  {
    name: "English, Old (ca.450-1100)",
    nativeName: "anglo-saxon (ca.450-1100)",
    isocode: null,
    code: "ang",
    code2: null,
  },
  {
    name: "Angika",
    nativeName: "angika",
    isocode: null,
    code: "anp",
    code2: null,
  },
  {
    name: "Apache languages",
    nativeName: "apaches, langues",
    isocode: null,
    code: "apa",
    code2: null,
  },
  {
    name: "Arabic",
    nativeName: "arabe",
    isocode: "ar",
    code: "ara",
    code2: null,
  },
  {
    name: "Official Aramaic (700-300 BCE); Imperial Aramaic (700-300 BCE)",
    nativeName: "araméen d'empire (700-300 BCE)",
    isocode: null,
    code: "arc",
    code2: null,
  },
  {
    name: "Aragonese",
    nativeName: "aragonais",
    isocode: "an",
    code: "arg",
    code2: null,
  },
  {
    name: "Armenian",
    nativeName: "arménien",
    isocode: "hy",
    code: "arm",
    code2: "hye",
  },
  {
    name: "Mapudungun; Mapuche",
    nativeName: "mapudungun; mapuche; mapuce",
    isocode: null,
    code: "arn",
    code2: null,
  },
  {
    name: "Arapaho",
    nativeName: "arapaho",
    isocode: null,
    code: "arp",
    code2: null,
  },
  {
    name: "Artificial languages",
    nativeName: "artificielles, langues",
    isocode: null,
    code: "art",
    code2: null,
  },
  {
    name: "Arawak",
    nativeName: "arawak",
    isocode: null,
    code: "arw",
    code2: null,
  },
  {
    name: "Assamese",
    nativeName: "assamais",
    isocode: "as",
    code: "asm",
    code2: null,
  },
  {
    name: "Asturian; Bable; Leonese; Asturleonese",
    nativeName: "asturien; bable; léonais; asturoléonais",
    isocode: null,
    code: "ast",
    code2: null,
  },
  {
    name: "Athapascan languages",
    nativeName: "athapascanes, langues",
    isocode: null,
    code: "ath",
    code2: null,
  },
  {
    name: "Australian languages",
    nativeName: "australiennes, langues",
    isocode: null,
    code: "aus",
    code2: null,
  },
  {
    name: "Avaric",
    nativeName: "avar",
    isocode: "av",
    code: "ava",
    code2: null,
  },
  {
    name: "Avestan",
    nativeName: "avestique",
    isocode: "ae",
    code: "ave",
    code2: null,
  },
  {
    name: "Awadhi",
    nativeName: "awadhi",
    isocode: null,
    code: "awa",
    code2: null,
  },
  {
    name: "Aymara",
    nativeName: "aymara",
    isocode: "ay",
    code: "aym",
    code2: null,
  },
  {
    name: "Azerbaijani",
    nativeName: "azéri",
    isocode: "az",
    code: "aze",
    code2: null,
  },
  {
    name: "Banda languages",
    nativeName: "banda, langues",
    isocode: null,
    code: "bad",
    code2: null,
  },
  {
    name: "Bamileke languages",
    nativeName: "bamiléké, langues",
    isocode: null,
    code: "bai",
    code2: null,
  },
  {
    name: "Bashkir",
    nativeName: "bachkir",
    isocode: "ba",
    code: "bak",
    code2: null,
  },
  {
    name: "Baluchi",
    nativeName: "baloutchi",
    isocode: null,
    code: "bal",
    code2: null,
  },
  {
    name: "Bambara",
    nativeName: "bambara",
    isocode: "bm",
    code: "bam",
    code2: null,
  },
  {
    name: "Balinese",
    nativeName: "balinais",
    isocode: null,
    code: "ban",
    code2: null,
  },
  {
    name: "Basque",
    nativeName: "basque",
    isocode: "eu",
    code: "baq",
    code2: "eus",
  },
  {
    name: "Basa",
    nativeName: "basa",
    isocode: null,
    code: "bas",
    code2: null,
  },
  {
    name: "Baltic languages",
    nativeName: "baltes, langues",
    isocode: null,
    code: "bat",
    code2: null,
  },
  {
    name: "Beja; Bedawiyet",
    nativeName: "bedja",
    isocode: null,
    code: "bej",
    code2: null,
  },
  {
    name: "Belarusian",
    nativeName: "biélorusse",
    isocode: "be",
    code: "bel",
    code2: null,
  },
  {
    name: "Bemba",
    nativeName: "bemba",
    isocode: null,
    code: "bem",
    code2: null,
  },
  {
    name: "Bengali",
    nativeName: "bengali",
    isocode: "bn",
    code: "ben",
    code2: null,
  },
  {
    name: "Berber languages",
    nativeName: "berbères, langues",
    isocode: null,
    code: "ber",
    code2: null,
  },
  {
    name: "Bhojpuri",
    nativeName: "bhojpuri",
    isocode: null,
    code: "bho",
    code2: null,
  },
  {
    name: "Bihari languages",
    nativeName: "langues biharis",
    isocode: "bh",
    code: "bih",
    code2: null,
  },
  {
    name: "Bikol",
    nativeName: "bikol",
    isocode: null,
    code: "bik",
    code2: null,
  },
  {
    name: "Bini; Edo",
    nativeName: "bini; edo",
    isocode: null,
    code: "bin",
    code2: null,
  },
  {
    name: "Bislama",
    nativeName: "bichlamar",
    isocode: "bi",
    code: "bis",
    code2: null,
  },
  {
    name: "Siksika",
    nativeName: "blackfoot",
    isocode: null,
    code: "bla",
    code2: null,
  },
  {
    name: "Bantu languages",
    nativeName: "bantou, langues",
    isocode: null,
    code: "bnt",
    code2: null,
  },
  {
    name: "Bosnian",
    nativeName: "bosniaque",
    isocode: "bs",
    code: "bos",
    code2: null,
  },
  {
    name: "Braj",
    nativeName: "braj",
    isocode: null,
    code: "bra",
    code2: null,
  },
  {
    name: "Breton",
    nativeName: "breton",
    isocode: "br",
    code: "bre",
    code2: null,
  },
  {
    name: "Batak languages",
    nativeName: "batak, langues",
    isocode: null,
    code: "btk",
    code2: null,
  },
  {
    name: "Buriat",
    nativeName: "bouriate",
    isocode: null,
    code: "bua",
    code2: null,
  },
  {
    name: "Buginese",
    nativeName: "bugi",
    isocode: null,
    code: "bug",
    code2: null,
  },
  {
    name: "Bulgarian",
    nativeName: "bulgare",
    isocode: "bg",
    code: "bul",
    code2: null,
  },
  {
    name: "Burmese",
    nativeName: "birman",
    isocode: "my",
    code: "bur",
    code2: "mya",
  },
  {
    name: "Blin; Bilin",
    nativeName: "blin; bilen",
    isocode: null,
    code: "byn",
    code2: null,
  },
  {
    name: "Caddo",
    nativeName: "caddo",
    isocode: null,
    code: "cad",
    code2: null,
  },
  {
    name: "Central American Indian languages",
    nativeName: "amérindiennes de L'Amérique centrale, langues",
    isocode: null,
    code: "cai",
    code2: null,
  },
  {
    name: "Galibi Carib",
    nativeName: "karib; galibi; carib",
    isocode: null,
    code: "car",
    code2: null,
  },
  {
    name: "Catalan; Valencian",
    nativeName: "catalan; valencien",
    isocode: "ca",
    code: "cat",
    code2: null,
  },
  {
    name: "Caucasian languages",
    nativeName: "caucasiennes, langues",
    isocode: null,
    code: "cau",
    code2: null,
  },
  {
    name: "Cebuano",
    nativeName: "cebuano",
    isocode: null,
    code: "ceb",
    code2: null,
  },
  {
    name: "Celtic languages",
    nativeName: "celtiques, langues; celtes, langues",
    isocode: null,
    code: "cel",
    code2: null,
  },
  {
    name: "Chamorro",
    nativeName: "chamorro",
    isocode: "ch",
    code: "cha",
    code2: null,
  },
  {
    name: "Chibcha",
    nativeName: "chibcha",
    isocode: null,
    code: "chb",
    code2: null,
  },
  {
    name: "Chechen",
    nativeName: "tchétchène",
    isocode: "ce",
    code: "che",
    code2: null,
  },
  {
    name: "Chagatai",
    nativeName: "djaghataï",
    isocode: null,
    code: "chg",
    code2: null,
  },
  {
    name: "Chinese",
    nativeName: "chinois",
    isocode: "zh",
    code: "chi",
    code2: "zho",
  },
  {
    name: "Chuukese",
    nativeName: "chuuk",
    isocode: null,
    code: "chk",
    code2: null,
  },
  {
    name: "Mari",
    nativeName: "mari",
    isocode: null,
    code: "chm",
    code2: null,
  },
  {
    name: "Chinook jargon",
    nativeName: "chinook, jargon",
    isocode: null,
    code: "chn",
    code2: null,
  },
  {
    name: "Choctaw",
    nativeName: "choctaw",
    isocode: null,
    code: "cho",
    code2: null,
  },
  {
    name: "Chipewyan; Dene Suline",
    nativeName: "chipewyan",
    isocode: null,
    code: "chp",
    code2: null,
  },
  {
    name: "Cherokee",
    nativeName: "cherokee",
    isocode: null,
    code: "chr",
    code2: null,
  },
  {
    name: "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic",
    nativeName:
      "slavon d'église; vieux slave; slavon liturgique; vieux bulgare",
    isocode: "cu",
    code: "chu",
    code2: null,
  },
  {
    name: "Chuvash",
    nativeName: "tchouvache",
    isocode: "cv",
    code: "chv",
    code2: null,
  },
  {
    name: "Cheyenne",
    nativeName: "cheyenne",
    isocode: null,
    code: "chy",
    code2: null,
  },
  {
    name: "Chamic languages",
    nativeName: "chames, langues",
    isocode: null,
    code: "cmc",
    code2: null,
  },
  {
    name: "Montenegrin",
    nativeName: "monténégrin",
    isocode: null,
    code: "cnr",
    code2: null,
  },
  {
    name: "Coptic",
    nativeName: "copte",
    isocode: null,
    code: "cop",
    code2: null,
  },
  {
    name: "Cornish",
    nativeName: "cornique",
    isocode: "kw",
    code: "cor",
    code2: null,
  },
  {
    name: "Corsican",
    nativeName: "corse",
    isocode: "co",
    code: "cos",
    code2: null,
  },
  {
    name: "Creoles and pidgins, English based",
    nativeName: "créoles et pidgins basés sur l'anglais",
    isocode: null,
    code: "cpe",
    code2: null,
  },
  {
    name: "Creoles and pidgins, French-based",
    nativeName: "créoles et pidgins basés sur le français",
    isocode: null,
    code: "cpf",
    code2: null,
  },
  {
    name: "Creoles and pidgins, Portuguese-based",
    nativeName: "créoles et pidgins basés sur le portugais",
    isocode: null,
    code: "cpp",
    code2: null,
  },
  {
    name: "Cree",
    nativeName: "cree",
    isocode: "cr",
    code: "cre",
    code2: null,
  },
  {
    name: "Crimean Tatar; Crimean Turkish",
    nativeName: "tatar de Crimé",
    isocode: null,
    code: "crh",
    code2: null,
  },
  {
    name: "Creoles and pidgins",
    nativeName: "créoles et pidgins",
    isocode: null,
    code: "crp",
    code2: null,
  },
  {
    name: "Kashubian",
    nativeName: "kachoube",
    isocode: null,
    code: "csb",
    code2: null,
  },
  {
    name: "Cushitic languages",
    nativeName: "couchitiques, langues",
    isocode: null,
    code: "cus",
    code2: null,
  },
  {
    name: "Czech",
    nativeName: "tchèque",
    isocode: "cs",
    code: "cze",
    code2: "ces",
  },
  {
    name: "Dakota",
    nativeName: "dakota",
    isocode: null,
    code: "dak",
    code2: null,
  },
  {
    name: "Danish",
    nativeName: "danois",
    isocode: "da",
    code: "dan",
    code2: null,
  },
  {
    name: "Dargwa",
    nativeName: "dargwa",
    isocode: null,
    code: "dar",
    code2: null,
  },
  {
    name: "Land Dayak languages",
    nativeName: "dayak, langues",
    isocode: null,
    code: "day",
    code2: null,
  },
  {
    name: "Delaware",
    nativeName: "delaware",
    isocode: null,
    code: "del",
    code2: null,
  },
  {
    name: "Slave (Athapascan)",
    nativeName: "esclave (athapascan)",
    isocode: null,
    code: "den",
    code2: null,
  },
  {
    name: "Dogrib",
    nativeName: "dogrib",
    isocode: null,
    code: "dgr",
    code2: null,
  },
  {
    name: "Dinka",
    nativeName: "dinka",
    isocode: null,
    code: "din",
    code2: null,
  },
  {
    name: "Divehi; Dhivehi; Maldivian",
    nativeName: "maldivien",
    isocode: "dv",
    code: "div",
    code2: null,
  },
  {
    name: "Dogri",
    nativeName: "dogri",
    isocode: null,
    code: "doi",
    code2: null,
  },
  {
    name: "Dravidian languages",
    nativeName: "dravidiennes, langues",
    isocode: null,
    code: "dra",
    code2: null,
  },
  {
    name: "Lower Sorbian",
    nativeName: "bas-sorabe",
    isocode: null,
    code: "dsb",
    code2: null,
  },
  {
    name: "Duala",
    nativeName: "douala",
    isocode: null,
    code: "dua",
    code2: null,
  },
  {
    name: "Dutch, Middle (ca.1050-1350)",
    nativeName: "néerlandais moyen (ca. 1050-1350)",
    isocode: null,
    code: "dum",
    code2: null,
  },
  {
    name: "Dutch; Flemish",
    nativeName: "néerlandais; flamand",
    isocode: "nl",
    code: "dut",
    code2: "nld",
  },
  {
    name: "Dyula",
    nativeName: "dioula",
    isocode: null,
    code: "dyu",
    code2: null,
  },
  {
    name: "Dzongkha",
    nativeName: "dzongkha",
    isocode: "dz",
    code: "dzo",
    code2: null,
  },
  {
    name: "Efik",
    nativeName: "efik",
    isocode: null,
    code: "efi",
    code2: null,
  },
  {
    name: "Egyptian (Ancient)",
    nativeName: "égyptien",
    isocode: null,
    code: "egy",
    code2: null,
  },
  {
    name: "Ekajuk",
    nativeName: "ekajuk",
    isocode: null,
    code: "eka",
    code2: null,
  },
  {
    name: "Elamite",
    nativeName: "élamite",
    isocode: null,
    code: "elx",
    code2: null,
  },
  {
    name: "English",
    nativeName: "anglais",
    isocode: "en",
    code: "eng",
    code2: null,
  },
  {
    name: "English, Middle (1100-1500)",
    nativeName: "anglais moyen (1100-1500)",
    isocode: null,
    code: "enm",
    code2: null,
  },
  {
    name: "Esperanto",
    nativeName: "espéranto",
    isocode: "eo",
    code: "epo",
    code2: null,
  },
  {
    name: "Estonian",
    nativeName: "estonien",
    isocode: "et",
    code: "est",
    code2: null,
  },
  {
    name: "Ewe",
    nativeName: "éwé",
    isocode: "ee",
    code: "ewe",
    code2: null,
  },
  {
    name: "Ewondo",
    nativeName: "éwondo",
    isocode: null,
    code: "ewo",
    code2: null,
  },
  {
    name: "Fang",
    nativeName: "fang",
    isocode: null,
    code: "fan",
    code2: null,
  },
  {
    name: "Faroese",
    nativeName: "féroïen",
    isocode: "fo",
    code: "fao",
    code2: null,
  },
  {
    name: "Fanti",
    nativeName: "fanti",
    isocode: null,
    code: "fat",
    code2: null,
  },
  {
    name: "Fijian",
    nativeName: "fidjien",
    isocode: "fj",
    code: "fij",
    code2: null,
  },
  {
    name: "Filipino",
    nativeName: "filipino",
    isocode: null,
    code: "fil",
    code2: null,
  },
  {
    name: "Finnish",
    nativeName: "finnois",
    isocode: "fi",
    code: "fin",
    code2: null,
  },
  {
    name: "Finno-Ugrian languages",
    nativeName: "finno-ougriennes, langues",
    isocode: null,
    code: "fiu",
    code2: null,
  },
  {
    name: "Fon",
    nativeName: "fon",
    isocode: null,
    code: "fon",
    code2: null,
  },
  {
    name: "French",
    nativeName: "français",
    isocode: "fr",
    code: "fre",
    code2: "fra",
  },
  {
    name: "French, Middle (ca.1400-1600)",
    nativeName: "français moyen (1400-1600)",
    isocode: null,
    code: "frm",
    code2: null,
  },
  {
    name: "French, Old (842-ca.1400)",
    nativeName: "français ancien (842-ca.1400)",
    isocode: null,
    code: "fro",
    code2: null,
  },
  {
    name: "Northern Frisian",
    nativeName: "frison septentrional",
    isocode: null,
    code: "frr",
    code2: null,
  },
  {
    name: "Eastern Frisian",
    nativeName: "frison oriental",
    isocode: null,
    code: "frs",
    code2: null,
  },
  {
    name: "Western Frisian",
    nativeName: "frison occidental",
    isocode: "fy",
    code: "fry",
    code2: null,
  },
  {
    name: "Fulah",
    nativeName: "peul",
    isocode: "ff",
    code: "ful",
    code2: null,
  },
  {
    name: "Friulian",
    nativeName: "frioulan",
    isocode: null,
    code: "fur",
    code2: null,
  },
  {
    name: "Ga",
    nativeName: "ga",
    isocode: null,
    code: "gaa",
    code2: null,
  },
  {
    name: "Gayo",
    nativeName: "gayo",
    isocode: null,
    code: "gay",
    code2: null,
  },
  {
    name: "Gbaya",
    nativeName: "gbaya",
    isocode: null,
    code: "gba",
    code2: null,
  },
  {
    name: "Germanic languages",
    nativeName: "germaniques, langues",
    isocode: null,
    code: "gem",
    code2: null,
  },
  {
    name: "Georgian",
    nativeName: "géorgien",
    isocode: "ka",
    code: "geo",
    code2: "kat",
  },
  {
    name: "German",
    nativeName: "allemand",
    isocode: "de",
    code: "ger",
    code2: "deu",
  },
  {
    name: "Geez",
    nativeName: "guèze",
    isocode: null,
    code: "gez",
    code2: null,
  },
  {
    name: "Gilbertese",
    nativeName: "kiribati",
    isocode: null,
    code: "gil",
    code2: null,
  },
  {
    name: "Gaelic; Scottish Gaelic",
    nativeName: "gaélique; gaélique écossais",
    isocode: "gd",
    code: "gla",
    code2: null,
  },
  {
    name: "Irish",
    nativeName: "irlandais",
    isocode: "ga",
    code: "gle",
    code2: null,
  },
  {
    name: "Galician",
    nativeName: "galicien",
    isocode: "gl",
    code: "glg",
    code2: null,
  },
  {
    name: "Manx",
    nativeName: "manx; mannois",
    isocode: "gv",
    code: "glv",
    code2: null,
  },
  {
    name: "German, Middle High (ca.1050-1500)",
    nativeName: "allemand, moyen haut (ca. 1050-1500)",
    isocode: null,
    code: "gmh",
    code2: null,
  },
  {
    name: "German, Old High (ca.750-1050)",
    nativeName: "allemand, vieux haut (ca. 750-1050)",
    isocode: null,
    code: "goh",
    code2: null,
  },
  {
    name: "Gondi",
    nativeName: "gond",
    isocode: null,
    code: "gon",
    code2: null,
  },
  {
    name: "Gorontalo",
    nativeName: "gorontalo",
    isocode: null,
    code: "gor",
    code2: null,
  },
  {
    name: "Gothic",
    nativeName: "gothique",
    isocode: null,
    code: "got",
    code2: null,
  },
  {
    name: "Grebo",
    nativeName: "grebo",
    isocode: null,
    code: "grb",
    code2: null,
  },
  {
    name: "Greek, Ancient (to 1453)",
    nativeName: "grec ancien (jusqu'à 1453)",
    isocode: null,
    code: "grc",
    code2: null,
  },
  {
    name: "Greek, Modern (1453-)",
    nativeName: "grec moderne (après 1453)",
    isocode: "el",
    code: "gre",
    code2: "ell",
  },
  {
    name: "Guarani",
    nativeName: "guarani",
    isocode: "gn",
    code: "grn",
    code2: null,
  },
  {
    name: "Swiss German; Alemannic; Alsatian",
    nativeName: "suisse alémanique; alémanique; alsacien",
    isocode: null,
    code: "gsw",
    code2: null,
  },
  {
    name: "Gujarati",
    nativeName: "goudjrati",
    isocode: "gu",
    code: "guj",
    code2: null,
  },
  {
    name: "Gwich'in",
    nativeName: "gwich'in",
    isocode: null,
    code: "gwi",
    code2: null,
  },
  {
    name: "Haida",
    nativeName: "haida",
    isocode: null,
    code: "hai",
    code2: null,
  },
  {
    name: "Haitian; Haitian Creole",
    nativeName: "haïtien; créole haïtien",
    isocode: "ht",
    code: "hat",
    code2: null,
  },
  {
    name: "Hausa",
    nativeName: "haoussa",
    isocode: "ha",
    code: "hau",
    code2: null,
  },
  {
    name: "Hawaiian",
    nativeName: "hawaïen",
    isocode: null,
    code: "haw",
    code2: null,
  },
  {
    name: "Hebrew",
    nativeName: "hébreu",
    isocode: "he",
    code: "heb",
    code2: null,
  },
  {
    name: "Herero",
    nativeName: "herero",
    isocode: "hz",
    code: "her",
    code2: null,
  },
  {
    name: "Hiligaynon",
    nativeName: "hiligaynon",
    isocode: null,
    code: "hil",
    code2: null,
  },
  {
    name: "Himachali languages; Western Pahari languages",
    nativeName: "langues himachalis; langues paharis occidentales",
    isocode: null,
    code: "him",
    code2: null,
  },
  {
    name: "Hindi",
    nativeName: "hindi",
    isocode: "hi",
    code: "hin",
    code2: null,
  },
  {
    name: "Hittite",
    nativeName: "hittite",
    isocode: null,
    code: "hit",
    code2: null,
  },
  {
    name: "Hmong; Mong",
    nativeName: "hmong",
    isocode: null,
    code: "hmn",
    code2: null,
  },
  {
    name: "Hiri Motu",
    nativeName: "hiri motu",
    isocode: "ho",
    code: "hmo",
    code2: null,
  },
  {
    name: "Croatian",
    nativeName: "croate",
    isocode: "hr",
    code: "hrv",
    code2: null,
  },
  {
    name: "Upper Sorbian",
    nativeName: "haut-sorabe",
    isocode: null,
    code: "hsb",
    code2: null,
  },
  {
    name: "Hungarian",
    nativeName: "hongrois",
    isocode: "hu",
    code: "hun",
    code2: null,
  },
  {
    name: "Hupa",
    nativeName: "hupa",
    isocode: null,
    code: "hup",
    code2: null,
  },
  {
    name: "Iban",
    nativeName: "iban",
    isocode: null,
    code: "iba",
    code2: null,
  },
  {
    name: "Igbo",
    nativeName: "igbo",
    isocode: "ig",
    code: "ibo",
    code2: null,
  },
  {
    name: "Icelandic",
    nativeName: "islandais",
    isocode: "is",
    code: "ice",
    code2: "isl",
  },
  {
    name: "Ido",
    nativeName: "ido",
    isocode: "io",
    code: "ido",
    code2: null,
  },
  {
    name: "Sichuan Yi; Nuosu",
    nativeName: "yi de Sichuan",
    isocode: "ii",
    code: "iii",
    code2: null,
  },
  {
    name: "Ijo languages",
    nativeName: "ijo, langues",
    isocode: null,
    code: "ijo",
    code2: null,
  },
  {
    name: "Inuktitut",
    nativeName: "inuktitut",
    isocode: "iu",
    code: "iku",
    code2: null,
  },
  {
    name: "Interlingue; Occidental",
    nativeName: "interlingue",
    isocode: "ie",
    code: "ile",
    code2: null,
  },
  {
    name: "Iloko",
    nativeName: "ilocano",
    isocode: null,
    code: "ilo",
    code2: null,
  },
  {
    name: "Interlingua (International Auxiliary Language Association)",
    nativeName: "interlingua (langue auxiliaire internationale)",
    isocode: "ia",
    code: "ina",
    code2: null,
  },
  {
    name: "Indic languages",
    nativeName: "indo-aryennes, langues",
    isocode: null,
    code: "inc",
    code2: null,
  },
  {
    name: "Indonesian",
    nativeName: "indonésien",
    isocode: "id",
    code: "ind",
    code2: null,
  },
  {
    name: "Indo-European languages",
    nativeName: "indo-européennes, langues",
    isocode: null,
    code: "ine",
    code2: null,
  },
  {
    name: "Ingush",
    nativeName: "ingouche",
    isocode: null,
    code: "inh",
    code2: null,
  },
  {
    name: "Inupiaq",
    nativeName: "inupiaq",
    isocode: "ik",
    code: "ipk",
    code2: null,
  },
  {
    name: "Iranian languages",
    nativeName: "iraniennes, langues",
    isocode: null,
    code: "ira",
    code2: null,
  },
  {
    name: "Iroquoian languages",
    nativeName: "iroquoises, langues",
    isocode: null,
    code: "iro",
    code2: null,
  },
  {
    name: "Italian",
    nativeName: "italien",
    isocode: "it",
    code: "ita",
    code2: null,
  },
  {
    name: "Javanese",
    nativeName: "javanais",
    isocode: "jv",
    code: "jav",
    code2: null,
  },
  {
    name: "Lojban",
    nativeName: "lojban",
    isocode: null,
    code: "jbo",
    code2: null,
  },
  {
    name: "Japanese",
    nativeName: "japonais",
    isocode: "ja",
    code: "jpn",
    code2: null,
  },
  {
    name: "Judeo-Persian",
    nativeName: "judéo-persan",
    isocode: null,
    code: "jpr",
    code2: null,
  },
  {
    name: "Judeo-Arabic",
    nativeName: "judéo-arabe",
    isocode: null,
    code: "jrb",
    code2: null,
  },
  {
    name: "Kara-Kalpak",
    nativeName: "karakalpak",
    isocode: null,
    code: "kaa",
    code2: null,
  },
  {
    name: "Kabyle",
    nativeName: "kabyle",
    isocode: null,
    code: "kab",
    code2: null,
  },
  {
    name: "Kachin; Jingpho",
    nativeName: "kachin; jingpho",
    isocode: null,
    code: "kac",
    code2: null,
  },
  {
    name: "Kalaallisut; Greenlandic",
    nativeName: "groenlandais",
    isocode: "kl",
    code: "kal",
    code2: null,
  },
  {
    name: "Kamba",
    nativeName: "kamba",
    isocode: null,
    code: "kam",
    code2: null,
  },
  {
    name: "Kannada",
    nativeName: "kannada",
    isocode: "kn",
    code: "kan",
    code2: null,
  },
  {
    name: "Karen languages",
    nativeName: "karen, langues",
    isocode: null,
    code: "kar",
    code2: null,
  },
  {
    name: "Kashmiri",
    nativeName: "kashmiri",
    isocode: "ks",
    code: "kas",
    code2: null,
  },
  {
    name: "Kanuri",
    nativeName: "kanouri",
    isocode: "kr",
    code: "kau",
    code2: null,
  },
  {
    name: "Kawi",
    nativeName: "kawi",
    isocode: null,
    code: "kaw",
    code2: null,
  },
  {
    name: "Kazakh",
    nativeName: "kazakh",
    isocode: "kk",
    code: "kaz",
    code2: null,
  },
  {
    name: "Kabardian",
    nativeName: "kabardien",
    isocode: null,
    code: "kbd",
    code2: null,
  },
  {
    name: "Khasi",
    nativeName: "khasi",
    isocode: null,
    code: "kha",
    code2: null,
  },
  {
    name: "Khoisan languages",
    nativeName: "khoïsan, langues",
    isocode: null,
    code: "khi",
    code2: null,
  },
  {
    name: "Central Khmer",
    nativeName: "khmer central",
    isocode: "km",
    code: "khm",
    code2: null,
  },
  {
    name: "Khotanese; Sakan",
    nativeName: "khotanais; sakan",
    isocode: null,
    code: "kho",
    code2: null,
  },
  {
    name: "Kikuyu; Gikuyu",
    nativeName: "kikuyu",
    isocode: "ki",
    code: "kik",
    code2: null,
  },
  {
    name: "Kinyarwanda",
    nativeName: "rwanda",
    isocode: "rw",
    code: "kin",
    code2: null,
  },
  {
    name: "Kirghiz; Kyrgyz",
    nativeName: "kirghiz",
    isocode: "ky",
    code: "kir",
    code2: null,
  },
  {
    name: "Kimbundu",
    nativeName: "kimbundu",
    isocode: null,
    code: "kmb",
    code2: null,
  },
  {
    name: "Konkani",
    nativeName: "konkani",
    isocode: null,
    code: "kok",
    code2: null,
  },
  {
    name: "Komi",
    nativeName: "kom",
    isocode: "kv",
    code: "kom",
    code2: null,
  },
  {
    name: "Kongo",
    nativeName: "kongo",
    isocode: "kg",
    code: "kon",
    code2: null,
  },
  {
    name: "Korean",
    nativeName: "coréen",
    isocode: "ko",
    code: "kor",
    code2: null,
  },
  {
    name: "Kosraean",
    nativeName: "kosrae",
    isocode: null,
    code: "kos",
    code2: null,
  },
  {
    name: "Kpelle",
    nativeName: "kpellé",
    isocode: null,
    code: "kpe",
    code2: null,
  },
  {
    name: "Karachay-Balkar",
    nativeName: "karatchai balkar",
    isocode: null,
    code: "krc",
    code2: null,
  },
  {
    name: "Karelian",
    nativeName: "carélien",
    isocode: null,
    code: "krl",
    code2: null,
  },
  {
    name: "Kru languages",
    nativeName: "krou, langues",
    isocode: null,
    code: "kro",
    code2: null,
  },
  {
    name: "Kurukh",
    nativeName: "kurukh",
    isocode: null,
    code: "kru",
    code2: null,
  },
  {
    name: "Kuanyama; Kwanyama",
    nativeName: "kuanyama; kwanyama",
    isocode: "kj",
    code: "kua",
    code2: null,
  },
  {
    name: "Kumyk",
    nativeName: "koumyk",
    isocode: null,
    code: "kum",
    code2: null,
  },
  {
    name: "Kurdish",
    nativeName: "kurde",
    isocode: "ku",
    code: "kur",
    code2: null,
  },
  {
    name: "Kutenai",
    nativeName: "kutenai",
    isocode: null,
    code: "kut",
    code2: null,
  },
  {
    name: "Ladino",
    nativeName: "judéo-espagnol",
    isocode: null,
    code: "lad",
    code2: null,
  },
  {
    name: "Lahnda",
    nativeName: "lahnda",
    isocode: null,
    code: "lah",
    code2: null,
  },
  {
    name: "Lamba",
    nativeName: "lamba",
    isocode: null,
    code: "lam",
    code2: null,
  },
  {
    name: "Lao",
    nativeName: "lao",
    isocode: "lo",
    code: "lao",
    code2: null,
  },
  {
    name: "Latin",
    nativeName: "latin",
    isocode: "la",
    code: "lat",
    code2: null,
  },
  {
    name: "Latvian",
    nativeName: "letton",
    isocode: "lv",
    code: "lav",
    code2: null,
  },
  {
    name: "Lezghian",
    nativeName: "lezghien",
    isocode: null,
    code: "lez",
    code2: null,
  },
  {
    name: "Limburgan; Limburger; Limburgish",
    nativeName: "limbourgeois",
    isocode: "li",
    code: "lim",
    code2: null,
  },
  {
    name: "Lingala",
    nativeName: "lingala",
    isocode: "ln",
    code: "lin",
    code2: null,
  },
  {
    name: "Lithuanian",
    nativeName: "lituanien",
    isocode: "lt",
    code: "lit",
    code2: null,
  },
  {
    name: "Mongo",
    nativeName: "mongo",
    isocode: null,
    code: "lol",
    code2: null,
  },
  {
    name: "Lozi",
    nativeName: "lozi",
    isocode: null,
    code: "loz",
    code2: null,
  },
  {
    name: "Luxembourgish; Letzeburgesch",
    nativeName: "luxembourgeois",
    isocode: "lb",
    code: "ltz",
    code2: null,
  },
  {
    name: "Luba-Lulua",
    nativeName: "luba-lulua",
    isocode: null,
    code: "lua",
    code2: null,
  },
  {
    name: "Luba-Katanga",
    nativeName: "luba-katanga",
    isocode: "lu",
    code: "lub",
    code2: null,
  },
  {
    name: "Ganda",
    nativeName: "ganda",
    isocode: "lg",
    code: "lug",
    code2: null,
  },
  {
    name: "Luiseno",
    nativeName: "luiseno",
    isocode: null,
    code: "lui",
    code2: null,
  },
  {
    name: "Lunda",
    nativeName: "lunda",
    isocode: null,
    code: "lun",
    code2: null,
  },
  {
    name: "Luo (Kenya and Tanzania)",
    nativeName: "luo (Kenya et Tanzanie)",
    isocode: null,
    code: "luo",
    code2: null,
  },
  {
    name: "Lushai",
    nativeName: "lushai",
    isocode: null,
    code: "lus",
    code2: null,
  },
  {
    name: "Macedonian",
    nativeName: "macédonien",
    isocode: "mk",
    code: "mac",
    code2: "mkd",
  },
  {
    name: "Madurese",
    nativeName: "madourais",
    isocode: null,
    code: "mad",
    code2: null,
  },
  {
    name: "Magahi",
    nativeName: "magahi",
    isocode: null,
    code: "mag",
    code2: null,
  },
  {
    name: "Marshallese",
    nativeName: "marshall",
    isocode: "mh",
    code: "mah",
    code2: null,
  },
  {
    name: "Maithili",
    nativeName: "maithili",
    isocode: null,
    code: "mai",
    code2: null,
  },
  {
    name: "Makasar",
    nativeName: "makassar",
    isocode: null,
    code: "mak",
    code2: null,
  },
  {
    name: "Malayalam",
    nativeName: "malayalam",
    isocode: "ml",
    code: "mal",
    code2: null,
  },
  {
    name: "Mandingo",
    nativeName: "mandingue",
    isocode: null,
    code: "man",
    code2: null,
  },
  {
    name: "Maori",
    nativeName: "maori",
    isocode: "mi",
    code: "mao",
    code2: "mri",
  },
  {
    name: "Austronesian languages",
    nativeName: "austronésiennes, langues",
    isocode: null,
    code: "map",
    code2: null,
  },
  {
    name: "Marathi",
    nativeName: "marathe",
    isocode: "mr",
    code: "mar",
    code2: null,
  },
  {
    name: "Masai",
    nativeName: "massaï",
    isocode: null,
    code: "mas",
    code2: null,
  },
  {
    name: "Malay",
    nativeName: "malais",
    isocode: "ms",
    code: "may",
    code2: "msa",
  },
  {
    name: "Moksha",
    nativeName: "moksa",
    isocode: null,
    code: "mdf",
    code2: null,
  },
  {
    name: "Mandar",
    nativeName: "mandar",
    isocode: null,
    code: "mdr",
    code2: null,
  },
  {
    name: "Mende",
    nativeName: "mendé",
    isocode: null,
    code: "men",
    code2: null,
  },
  {
    name: "Irish, Middle (900-1200)",
    nativeName: "irlandais moyen (900-1200)",
    isocode: null,
    code: "mga",
    code2: null,
  },
  {
    name: "Mi'kmaq; Micmac",
    nativeName: "mi'kmaq; micmac",
    isocode: null,
    code: "mic",
    code2: null,
  },
  {
    name: "Minangkabau",
    nativeName: "minangkabau",
    isocode: null,
    code: "min",
    code2: null,
  },
  {
    name: "Uncoded languages",
    nativeName: "langues non codées",
    isocode: null,
    code: "mis",
    code2: null,
  },
  {
    name: "Mon-Khmer languages",
    nativeName: "môn-khmer, langues",
    isocode: null,
    code: "mkh",
    code2: null,
  },
  {
    name: "Malagasy",
    nativeName: "malgache",
    isocode: "mg",
    code: "mlg",
    code2: null,
  },
  {
    name: "Maltese",
    nativeName: "maltais",
    isocode: "mt",
    code: "mlt",
    code2: null,
  },
  {
    name: "Manchu",
    nativeName: "mandchou",
    isocode: null,
    code: "mnc",
    code2: null,
  },
  {
    name: "Manipuri",
    nativeName: "manipuri",
    isocode: null,
    code: "mni",
    code2: null,
  },
  {
    name: "Manobo languages",
    nativeName: "manobo, langues",
    isocode: null,
    code: "mno",
    code2: null,
  },
  {
    name: "Mohawk",
    nativeName: "mohawk",
    isocode: null,
    code: "moh",
    code2: null,
  },
  {
    name: "Mongolian",
    nativeName: "mongol",
    isocode: "mn",
    code: "mon",
    code2: null,
  },
  {
    name: "Mossi",
    nativeName: "moré",
    isocode: null,
    code: "mos",
    code2: null,
  },
  {
    name: "Multiple languages",
    nativeName: "multilingue",
    isocode: null,
    code: "mul",
    code2: null,
  },
  {
    name: "Munda languages",
    nativeName: "mounda, langues",
    isocode: null,
    code: "mun",
    code2: null,
  },
  {
    name: "Creek",
    nativeName: "muskogee",
    isocode: null,
    code: "mus",
    code2: null,
  },
  {
    name: "Mirandese",
    nativeName: "mirandais",
    isocode: null,
    code: "mwl",
    code2: null,
  },
  {
    name: "Marwari",
    nativeName: "marvari",
    isocode: null,
    code: "mwr",
    code2: null,
  },
  {
    name: "Mayan languages",
    nativeName: "maya, langues",
    isocode: null,
    code: "myn",
    code2: null,
  },
  {
    name: "Erzya",
    nativeName: "erza",
    isocode: null,
    code: "myv",
    code2: null,
  },
  {
    name: "Nahuatl languages",
    nativeName: "nahuatl, langues",
    isocode: null,
    code: "nah",
    code2: null,
  },
  {
    name: "North American Indian languages",
    nativeName: "nord-amérindiennes, langues",
    isocode: null,
    code: "nai",
    code2: null,
  },
  {
    name: "Neapolitan",
    nativeName: "napolitain",
    isocode: null,
    code: "nap",
    code2: null,
  },
  {
    name: "Nauru",
    nativeName: "nauruan",
    isocode: "na",
    code: "nau",
    code2: null,
  },
  {
    name: "Navajo; Navaho",
    nativeName: "navaho",
    isocode: "nv",
    code: "nav",
    code2: null,
  },
  {
    name: "Ndebele, South; South Ndebele",
    nativeName: "ndébélé du Sud",
    isocode: "nr",
    code: "nbl",
    code2: null,
  },
  {
    name: "Ndebele, North; North Ndebele",
    nativeName: "ndébélé du Nord",
    isocode: "nd",
    code: "nde",
    code2: null,
  },
  {
    name: "Ndonga",
    nativeName: "ndonga",
    isocode: "ng",
    code: "ndo",
    code2: null,
  },
  {
    name: "Low German; Low Saxon; German, Low; Saxon, Low",
    nativeName: "bas allemand; bas saxon; allemand, bas; saxon, bas",
    isocode: null,
    code: "nds",
    code2: null,
  },
  {
    name: "Nepali",
    nativeName: "népalais",
    isocode: "ne",
    code: "nep",
    code2: null,
  },
  {
    name: "Nepal Bhasa; Newari",
    nativeName: "nepal bhasa; newari",
    isocode: null,
    code: "new",
    code2: null,
  },
  {
    name: "Nias",
    nativeName: "nias",
    isocode: null,
    code: "nia",
    code2: null,
  },
  {
    name: "Niger-Kordofanian languages",
    nativeName: "nigéro-kordofaniennes, langues",
    isocode: null,
    code: "nic",
    code2: null,
  },
  {
    name: "Niuean",
    nativeName: "niué",
    isocode: null,
    code: "niu",
    code2: null,
  },
  {
    name: "Norwegian Nynorsk; Nynorsk, Norwegian",
    nativeName: "norvégien nynorsk; nynorsk, norvégien",
    isocode: "nn",
    code: "nno",
    code2: null,
  },
  {
    name: "Bokmål, Norwegian; Norwegian Bokmål",
    nativeName: "norvégien bokmål",
    isocode: "nb",
    code: "nob",
    code2: null,
  },
  {
    name: "Nogai",
    nativeName: "nogaï; nogay",
    isocode: null,
    code: "nog",
    code2: null,
  },
  {
    name: "Norse, Old",
    nativeName: "norrois, vieux",
    isocode: null,
    code: "non",
    code2: null,
  },
  {
    name: "Norwegian",
    nativeName: "norvégien",
    isocode: "no",
    code: "nor",
    code2: null,
  },
  {
    name: "N'Ko",
    nativeName: "n'ko",
    isocode: null,
    code: "nqo",
    code2: null,
  },
  {
    name: "Pedi; Sepedi; Northern Sotho",
    nativeName: "pedi; sepedi; sotho du Nord",
    isocode: null,
    code: "nso",
    code2: null,
  },
  {
    name: "Nubian languages",
    nativeName: "nubiennes, langues",
    isocode: null,
    code: "nub",
    code2: null,
  },
  {
    name: "Classical Newari; Old Newari; Classical Nepal Bhasa",
    nativeName: "newari classique",
    isocode: null,
    code: "nwc",
    code2: null,
  },
  {
    name: "Chichewa; Chewa; Nyanja",
    nativeName: "chichewa; chewa; nyanja",
    isocode: "ny",
    code: "nya",
    code2: null,
  },
  {
    name: "Nyamwezi",
    nativeName: "nyamwezi",
    isocode: null,
    code: "nym",
    code2: null,
  },
  {
    name: "Nyankole",
    nativeName: "nyankolé",
    isocode: null,
    code: "nyn",
    code2: null,
  },
  {
    name: "Nyoro",
    nativeName: "nyoro",
    isocode: null,
    code: "nyo",
    code2: null,
  },
  {
    name: "Nzima",
    nativeName: "nzema",
    isocode: null,
    code: "nzi",
    code2: null,
  },
  {
    name: "Occitan (post 1500)",
    nativeName: "occitan (après 1500)",
    isocode: "oc",
    code: "oci",
    code2: null,
  },
  {
    name: "Ojibwa",
    nativeName: "ojibwa",
    isocode: "oj",
    code: "oji",
    code2: null,
  },
  {
    name: "Oriya",
    nativeName: "oriya",
    isocode: "or",
    code: "ori",
    code2: null,
  },
  {
    name: "Oromo",
    nativeName: "galla",
    isocode: "om",
    code: "orm",
    code2: null,
  },
  {
    name: "Osage",
    nativeName: "osage",
    isocode: null,
    code: "osa",
    code2: null,
  },
  {
    name: "Ossetian; Ossetic",
    nativeName: "ossète",
    isocode: "os",
    code: "oss",
    code2: null,
  },
  {
    name: "Turkish, Ottoman (1500-1928)",
    nativeName: "turc ottoman (1500-1928)",
    isocode: null,
    code: "ota",
    code2: null,
  },
  {
    name: "Otomian languages",
    nativeName: "otomi, langues",
    isocode: null,
    code: "oto",
    code2: null,
  },
  {
    name: "Papuan languages",
    nativeName: "papoues, langues",
    isocode: null,
    code: "paa",
    code2: null,
  },
  {
    name: "Pangasinan",
    nativeName: "pangasinan",
    isocode: null,
    code: "pag",
    code2: null,
  },
  {
    name: "Pahlavi",
    nativeName: "pahlavi",
    isocode: null,
    code: "pal",
    code2: null,
  },
  {
    name: "Pampanga; Kapampangan",
    nativeName: "pampangan",
    isocode: null,
    code: "pam",
    code2: null,
  },
  {
    name: "Panjabi; Punjabi",
    nativeName: "pendjabi",
    isocode: "pa",
    code: "pan",
    code2: null,
  },
  {
    name: "Papiamento",
    nativeName: "papiamento",
    isocode: null,
    code: "pap",
    code2: null,
  },
  {
    name: "Palauan",
    nativeName: "palau",
    isocode: null,
    code: "pau",
    code2: null,
  },
  {
    name: "Persian, Old (ca.600-400 B.C.)",
    nativeName: "perse, vieux (ca. 600-400 av. J.-C.)",
    isocode: null,
    code: "peo",
    code2: null,
  },
  {
    name: "Persian",
    nativeName: "persan",
    isocode: "fa",
    code: "per",
    code2: "fas",
  },
  {
    name: "Philippine languages",
    nativeName: "philippines, langues",
    isocode: null,
    code: "phi",
    code2: null,
  },
  {
    name: "Phoenician",
    nativeName: "phénicien",
    isocode: null,
    code: "phn",
    code2: null,
  },
  {
    name: "Pali",
    nativeName: "pali",
    isocode: "pi",
    code: "pli",
    code2: null,
  },
  {
    name: "Polish",
    nativeName: "polonais",
    isocode: "pl",
    code: "pol",
    code2: null,
  },
  {
    name: "Pohnpeian",
    nativeName: "pohnpei",
    isocode: null,
    code: "pon",
    code2: null,
  },
  {
    name: "Portuguese",
    nativeName: "portugais",
    isocode: "pt",
    code: "por",
    code2: null,
  },
  {
    name: "Prakrit languages",
    nativeName: "prâkrit, langues",
    isocode: null,
    code: "pra",
    code2: null,
  },
  {
    name: "Provençal, Old (to 1500); Occitan, Old (to 1500)",
    nativeName:
      "provençal ancien (jusqu'à 1500); occitan ancien (jusqu'à 1500)",
    isocode: null,
    code: "pro",
    code2: null,
  },
  {
    name: "Pushto; Pashto",
    nativeName: "pachto",
    isocode: "ps",
    code: "pus",
    code2: null,
  },
  {
    name: "Reserved for local use",
    nativeName: "réservée à l'usage local",
    isocode: null,
    code: "qaa-qtz",
    code2: null,
  },
  {
    name: "Quechua",
    nativeName: "quechua",
    isocode: "qu",
    code: "que",
    code2: null,
  },
  {
    name: "Rajasthani",
    nativeName: "rajasthani",
    isocode: null,
    code: "raj",
    code2: null,
  },
  {
    name: "Rapanui",
    nativeName: "rapanui",
    isocode: null,
    code: "rap",
    code2: null,
  },
  {
    name: "Rarotongan; Cook Islands Maori",
    nativeName: "rarotonga; maori des îles Cook",
    isocode: null,
    code: "rar",
    code2: null,
  },
  {
    name: "Romance languages",
    nativeName: "romanes, langues",
    isocode: null,
    code: "roa",
    code2: null,
  },
  {
    name: "Romansh",
    nativeName: "romanche",
    isocode: "rm",
    code: "roh",
    code2: null,
  },
  {
    name: "Romany",
    nativeName: "tsigane",
    isocode: null,
    code: "rom",
    code2: null,
  },
  {
    name: "Romanian; Moldavian; Moldovan",
    nativeName: "roumain; moldave",
    isocode: "ro",
    code: "rum",
    code2: "ron",
  },
  {
    name: "Rundi",
    nativeName: "rundi",
    isocode: "rn",
    code: "run",
    code2: null,
  },
  {
    name: "Aromanian; Arumanian; Macedo-Romanian",
    nativeName: "aroumain; macédo-roumain",
    isocode: null,
    code: "rup",
    code2: null,
  },
  {
    name: "Russian",
    nativeName: "russe",
    isocode: "ru",
    code: "rus",
    code2: null,
  },
  {
    name: "Sandawe",
    nativeName: "sandawe",
    isocode: null,
    code: "sad",
    code2: null,
  },
  {
    name: "Sango",
    nativeName: "sango",
    isocode: "sg",
    code: "sag",
    code2: null,
  },
  {
    name: "Yakut",
    nativeName: "iakoute",
    isocode: null,
    code: "sah",
    code2: null,
  },
  {
    name: "South American Indian languages",
    nativeName: "sud-amérindiennes, langues",
    isocode: null,
    code: "sai",
    code2: null,
  },
  {
    name: "Salishan languages",
    nativeName: "salishennes, langues",
    isocode: null,
    code: "sal",
    code2: null,
  },
  {
    name: "Samaritan Aramaic",
    nativeName: "samaritain",
    isocode: null,
    code: "sam",
    code2: null,
  },
  {
    name: "Sanskrit",
    nativeName: "sanskrit",
    isocode: "sa",
    code: "san",
    code2: null,
  },
  {
    name: "Sasak",
    nativeName: "sasak",
    isocode: null,
    code: "sas",
    code2: null,
  },
  {
    name: "Santali",
    nativeName: "santal",
    isocode: null,
    code: "sat",
    code2: null,
  },
  {
    name: "Sicilian",
    nativeName: "sicilien",
    isocode: null,
    code: "scn",
    code2: null,
  },
  {
    name: "Scots",
    nativeName: "écossais",
    isocode: null,
    code: "sco",
    code2: null,
  },
  {
    name: "Selkup",
    nativeName: "selkoupe",
    isocode: null,
    code: "sel",
    code2: null,
  },
  {
    name: "Semitic languages",
    nativeName: "sémitiques, langues",
    isocode: null,
    code: "sem",
    code2: null,
  },
  {
    name: "Irish, Old (to 900)",
    nativeName: "irlandais ancien (jusqu'à 900)",
    isocode: null,
    code: "sga",
    code2: null,
  },
  {
    name: "Sign Languages",
    nativeName: "langues des signes",
    isocode: null,
    code: "sgn",
    code2: null,
  },
  {
    name: "Shan",
    nativeName: "chan",
    isocode: null,
    code: "shn",
    code2: null,
  },
  {
    name: "Sidamo",
    nativeName: "sidamo",
    isocode: null,
    code: "sid",
    code2: null,
  },
  {
    name: "Sinhala; Sinhalese",
    nativeName: "singhalais",
    isocode: "si",
    code: "sin",
    code2: null,
  },
  {
    name: "Siouan languages",
    nativeName: "sioux, langues",
    isocode: null,
    code: "sio",
    code2: null,
  },
  {
    name: "Sino-Tibetan languages",
    nativeName: "sino-tibétaines, langues",
    isocode: null,
    code: "sit",
    code2: null,
  },
  {
    name: "Slavic languages",
    nativeName: "slaves, langues",
    isocode: null,
    code: "sla",
    code2: null,
  },
  {
    name: "Slovak",
    nativeName: "slovaque",
    isocode: "sk",
    code: "slo",
    code2: "slk",
  },
  {
    name: "Slovenian",
    nativeName: "slovène",
    isocode: "sl",
    code: "slv",
    code2: null,
  },
  {
    name: "Southern Sami",
    nativeName: "sami du Sud",
    isocode: null,
    code: "sma",
    code2: null,
  },
  {
    name: "Northern Sami",
    nativeName: "sami du Nord",
    isocode: "se",
    code: "sme",
    code2: null,
  },
  {
    name: "Sami languages",
    nativeName: "sames, langues",
    isocode: null,
    code: "smi",
    code2: null,
  },
  {
    name: "Lule Sami",
    nativeName: "sami de Lule",
    isocode: null,
    code: "smj",
    code2: null,
  },
  {
    name: "Inari Sami",
    nativeName: "sami d'Inari",
    isocode: null,
    code: "smn",
    code2: null,
  },
  {
    name: "Samoan",
    nativeName: "samoan",
    isocode: "sm",
    code: "smo",
    code2: null,
  },
  {
    name: "Skolt Sami",
    nativeName: "sami skolt",
    isocode: null,
    code: "sms",
    code2: null,
  },
  {
    name: "Shona",
    nativeName: "shona",
    isocode: "sn",
    code: "sna",
    code2: null,
  },
  {
    name: "Sindhi",
    nativeName: "sindhi",
    isocode: "sd",
    code: "snd",
    code2: null,
  },
  {
    name: "Soninke",
    nativeName: "soninké",
    isocode: null,
    code: "snk",
    code2: null,
  },
  {
    name: "Sogdian",
    nativeName: "sogdien",
    isocode: null,
    code: "sog",
    code2: null,
  },
  {
    name: "Somali",
    nativeName: "somali",
    isocode: "so",
    code: "som",
    code2: null,
  },
  {
    name: "Songhai languages",
    nativeName: "songhai, langues",
    isocode: null,
    code: "son",
    code2: null,
  },
  {
    name: "Sotho, Southern",
    nativeName: "sotho du Sud",
    isocode: "st",
    code: "sot",
    code2: null,
  },
  {
    name: "Spanish; Castilian",
    nativeName: "espagnol; castillan",
    isocode: "es",
    code: "spa",
    code2: null,
  },
  {
    name: "Sardinian",
    nativeName: "sarde",
    isocode: "sc",
    code: "srd",
    code2: null,
  },
  {
    name: "Sranan Tongo",
    nativeName: "sranan tongo",
    isocode: null,
    code: "srn",
    code2: null,
  },
  {
    name: "Serbian",
    nativeName: "serbe",
    isocode: "sr",
    code: "srp",
    code2: null,
  },
  {
    name: "Serer",
    nativeName: "sérère",
    isocode: null,
    code: "srr",
    code2: null,
  },
  {
    name: "Nilo-Saharan languages",
    nativeName: "nilo-sahariennes, langues",
    isocode: null,
    code: "ssa",
    code2: null,
  },
  {
    name: "Swati",
    nativeName: "swati",
    isocode: "ss",
    code: "ssw",
    code2: null,
  },
  {
    name: "Sukuma",
    nativeName: "sukuma",
    isocode: null,
    code: "suk",
    code2: null,
  },
  {
    name: "Sundanese",
    nativeName: "soundanais",
    isocode: "su",
    code: "sun",
    code2: null,
  },
  {
    name: "Susu",
    nativeName: "soussou",
    isocode: null,
    code: "sus",
    code2: null,
  },
  {
    name: "Sumerian",
    nativeName: "sumérien",
    isocode: null,
    code: "sux",
    code2: null,
  },
  {
    name: "Swahili",
    nativeName: "swahili",
    isocode: "sw",
    code: "swa",
    code2: null,
  },
  {
    name: "Swedish",
    nativeName: "suédois",
    isocode: "sv",
    code: "swe",
    code2: null,
  },
  {
    name: "Classical Syriac",
    nativeName: "syriaque classique",
    isocode: null,
    code: "syc",
    code2: null,
  },
  {
    name: "Syriac",
    nativeName: "syriaque",
    isocode: null,
    code: "syr",
    code2: null,
  },
  {
    name: "Tahitian",
    nativeName: "tahitien",
    isocode: "ty",
    code: "tah",
    code2: null,
  },
  {
    name: "Tai languages",
    nativeName: "tai, langues",
    isocode: null,
    code: "tai",
    code2: null,
  },
  {
    name: "Tamil",
    nativeName: "tamoul",
    isocode: "ta",
    code: "tam",
    code2: null,
  },
  {
    name: "Tatar",
    nativeName: "tatar",
    isocode: "tt",
    code: "tat",
    code2: null,
  },
  {
    name: "Telugu",
    nativeName: "télougou",
    isocode: "te",
    code: "tel",
    code2: null,
  },
  {
    name: "Timne",
    nativeName: "temne",
    isocode: null,
    code: "tem",
    code2: null,
  },
  {
    name: "Tereno",
    nativeName: "tereno",
    isocode: null,
    code: "ter",
    code2: null,
  },
  {
    name: "Tetum",
    nativeName: "tetum",
    isocode: null,
    code: "tet",
    code2: null,
  },
  {
    name: "Tajik",
    nativeName: "tadjik",
    isocode: "tg",
    code: "tgk",
    code2: null,
  },
  {
    name: "Tagalog",
    nativeName: "tagalog",
    isocode: "tl",
    code: "tgl",
    code2: null,
  },
  {
    name: "Thai",
    nativeName: "thaï",
    isocode: "th",
    code: "tha",
    code2: null,
  },
  {
    name: "Tibetan",
    nativeName: "tibétain",
    isocode: "bo",
    code: "tib",
    code2: "bod",
  },
  {
    name: "Tigre",
    nativeName: "tigré",
    isocode: null,
    code: "tig",
    code2: null,
  },
  {
    name: "Tigrinya",
    nativeName: "tigrigna",
    isocode: "ti",
    code: "tir",
    code2: null,
  },
  {
    name: "Tiv",
    nativeName: "tiv",
    isocode: null,
    code: "tiv",
    code2: null,
  },
  {
    name: "Tokelau",
    nativeName: "tokelau",
    isocode: null,
    code: "tkl",
    code2: null,
  },
  {
    name: "Klingon; tlhIngan-Hol",
    nativeName: "klingon",
    isocode: null,
    code: "tlh",
    code2: null,
  },
  {
    name: "Tlingit",
    nativeName: "tlingit",
    isocode: null,
    code: "tli",
    code2: null,
  },
  {
    name: "Tamashek",
    nativeName: "tamacheq",
    isocode: null,
    code: "tmh",
    code2: null,
  },
  {
    name: "Tonga (Nyasa)",
    nativeName: "tonga (Nyasa)",
    isocode: null,
    code: "tog",
    code2: null,
  },
  {
    name: "Tonga (Tonga Islands)",
    nativeName: "tongan (Îles Tonga)",
    isocode: "to",
    code: "ton",
    code2: null,
  },
  {
    name: "Tok Pisin",
    nativeName: "tok pisin",
    isocode: null,
    code: "tpi",
    code2: null,
  },
  {
    name: "Tsimshian",
    nativeName: "tsimshian",
    isocode: null,
    code: "tsi",
    code2: null,
  },
  {
    name: "Tswana",
    nativeName: "tswana",
    isocode: "tn",
    code: "tsn",
    code2: null,
  },
  {
    name: "Tsonga",
    nativeName: "tsonga",
    isocode: "ts",
    code: "tso",
    code2: null,
  },
  {
    name: "Turkmen",
    nativeName: "turkmène",
    isocode: "tk",
    code: "tuk",
    code2: null,
  },
  {
    name: "Tumbuka",
    nativeName: "tumbuka",
    isocode: null,
    code: "tum",
    code2: null,
  },
  {
    name: "Tupi languages",
    nativeName: "tupi, langues",
    isocode: null,
    code: "tup",
    code2: null,
  },
  {
    name: "Turkish",
    nativeName: "turc",
    isocode: "tr",
    code: "tur",
    code2: null,
  },
  {
    name: "Altaic languages",
    nativeName: "altaïques, langues",
    isocode: null,
    code: "tut",
    code2: null,
  },
  {
    name: "Tuvalu",
    nativeName: "tuvalu",
    isocode: null,
    code: "tvl",
    code2: null,
  },
  {
    name: "Twi",
    nativeName: "twi",
    isocode: "tw",
    code: "twi",
    code2: null,
  },
  {
    name: "Tuvinian",
    nativeName: "touva",
    isocode: null,
    code: "tyv",
    code2: null,
  },
  {
    name: "Udmurt",
    nativeName: "oudmourte",
    isocode: null,
    code: "udm",
    code2: null,
  },
  {
    name: "Ugaritic",
    nativeName: "ougaritique",
    isocode: null,
    code: "uga",
    code2: null,
  },
  {
    name: "Uighur; Uyghur",
    nativeName: "ouïgour",
    isocode: "ug",
    code: "uig",
    code2: null,
  },
  {
    name: "Ukrainian",
    nativeName: "ukrainien",
    isocode: "uk",
    code: "ukr",
    code2: null,
  },
  {
    name: "Umbundu",
    nativeName: "umbundu",
    isocode: null,
    code: "umb",
    code2: null,
  },
  {
    name: "Undetermined",
    nativeName: "indéterminée",
    isocode: null,
    code: "und",
    code2: null,
  },
  {
    name: "Urdu",
    nativeName: "ourdou",
    isocode: "ur",
    code: "urd",
    code2: null,
  },
  {
    name: "Uzbek",
    nativeName: "ouszbek",
    isocode: "uz",
    code: "uzb",
    code2: null,
  },
  {
    name: "Vai",
    nativeName: "vaï",
    isocode: null,
    code: "vai",
    code2: null,
  },
  {
    name: "Venda",
    nativeName: "venda",
    isocode: "ve",
    code: "ven",
    code2: null,
  },
  {
    name: "Vietnamese",
    nativeName: "vietnamien",
    isocode: "vi",
    code: "vie",
    code2: null,
  },
  {
    name: "Volapük",
    nativeName: "volapük",
    isocode: "vo",
    code: "vol",
    code2: null,
  },
  {
    name: "Votic",
    nativeName: "vote",
    isocode: null,
    code: "vot",
    code2: null,
  },
  {
    name: "Wakashan languages",
    nativeName: "wakashanes, langues",
    isocode: null,
    code: "wak",
    code2: null,
  },
  {
    name: "Wolaitta; Wolaytta",
    nativeName: "wolaitta; wolaytta",
    isocode: null,
    code: "wal",
    code2: null,
  },
  {
    name: "Waray",
    nativeName: "waray",
    isocode: null,
    code: "war",
    code2: null,
  },
  {
    name: "Washo",
    nativeName: "washo",
    isocode: null,
    code: "was",
    code2: null,
  },
  {
    name: "Welsh",
    nativeName: "gallois",
    isocode: "cy",
    code: "wel",
    code2: "cym",
  },
  {
    name: "Sorbian languages",
    nativeName: "sorabes, langues",
    isocode: null,
    code: "wen",
    code2: null,
  },
  {
    name: "Walloon",
    nativeName: "wallon",
    isocode: "wa",
    code: "wln",
    code2: null,
  },
  {
    name: "Wolof",
    nativeName: "wolof",
    isocode: "wo",
    code: "wol",
    code2: null,
  },
  {
    name: "Kalmyk; Oirat",
    nativeName: "kalmouk; oïrat",
    isocode: null,
    code: "xal",
    code2: null,
  },
  {
    name: "Xhosa",
    nativeName: "xhosa",
    isocode: "xh",
    code: "xho",
    code2: null,
  },
  {
    name: "Yao",
    nativeName: "yao",
    isocode: null,
    code: "yao",
    code2: null,
  },
  {
    name: "Yapese",
    nativeName: "yapois",
    isocode: null,
    code: "yap",
    code2: null,
  },
  {
    name: "Yiddish",
    nativeName: "yiddish",
    isocode: "yi",
    code: "yid",
    code2: null,
  },
  {
    name: "Yoruba",
    nativeName: "yoruba",
    isocode: "yo",
    code: "yor",
    code2: null,
  },
  {
    name: "Yupik languages",
    nativeName: "yupik, langues",
    isocode: null,
    code: "ypk",
    code2: null,
  },
  {
    name: "Zapotec",
    nativeName: "zapotèque",
    isocode: null,
    code: "zap",
    code2: null,
  },
  {
    name: "Blissymbols; Blissymbolics; Bliss",
    nativeName: "symboles Bliss; Bliss",
    isocode: null,
    code: "zbl",
    code2: null,
  },
  {
    name: "Zenaga",
    nativeName: "zenaga",
    isocode: null,
    code: "zen",
    code2: null,
  },
  {
    name: "Standard Moroccan Tamazight",
    nativeName: "amazighe standard marocain",
    isocode: null,
    code: "zgh",
    code2: null,
  },
  {
    name: "Zhuang; Chuang",
    nativeName: "zhuang; chuang",
    isocode: "za",
    code: "zha",
    code2: null,
  },
  {
    name: "Zande languages",
    nativeName: "zandé, langues",
    isocode: null,
    code: "znd",
    code2: null,
  },
  {
    name: "Zulu",
    nativeName: "zoulou",
    isocode: "zu",
    code: "zul",
    code2: null,
  },
  {
    name: "Zuni",
    nativeName: "zuni",
    isocode: null,
    code: "zun",
    code2: null,
  },
  {
    name: "No linguistic content; Not applicable",
    nativeName: "pas de contenu linguistique; non applicable",
    isocode: null,
    code: "zxx",
    code2: null,
  },
  {
    name: "Zaza; Dimili; Dimli; Kirdki; Kirmanjki; Zazaki",
    nativeName: "zaza; dimili; dimli; kirdki; kirmanjki; zazaki",
    isocode: null,
    code: "zza",
    code2: null,
  },
];
