import React from "react";
import classes from "./card.module.scss";
import Button from "../button";
import PlusIcon from "assets/supervisorIcons/plusIcon.js";

const CardView = ({
  children,
  title,
  style,
  bodyStyle,
  action,
  onClick,
  pills,
}) => {
  return (
    <div className={classes.cardContainer} style={style}>
      {title && (
        <div className={classes.titleContainer}>
          <span className={classes.title}>{title}</span>

          {pills && (
            <span className={classes.pillsWrapper}>
              {pills.map((item, index) => (
                <span className={classes.pills} style={item.style} key={index}>
                  {item.name}
                </span>
              ))}
            </span>
          )}

          {action && (
            <div className={classes.buttonWrapper}>
              <Button
                width={"200px"}
                className={classes.headerButton}
                onClick={onClick}
                icon={() => {
                  return <PlusIcon />;
                }}
                text={action}
              />
            </div>
          )}
        </div>
      )}
      <div className={classes.body} style={bodyStyle}>
        {children}
      </div>
    </div>
  );
};

export default CardView;
