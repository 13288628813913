// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  GET_CONTRACTOR_CARDS_SUCCESS,
  GET_CONTRACTOR_CARDS_ERROR,
  GET_CONTRACTOR_CARDS,
} from "../constants";
import axios from "../../../services/api";

function* getContractorCardsRequest(data) {

  try {
    const response = yield call(
      axios.get,
      `/cards/?user_id=${data.id}`
    );
    if (response.data.error) {
      yield put({
        type: GET_CONTRACTOR_CARDS_ERROR,
        data: response.error,
      });
    } else {
      yield put({
        type: GET_CONTRACTOR_CARDS_SUCCESS,
        data: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_CONTRACTOR_CARDS_ERROR,
      data: error,
    });
  }
}

export default function* getContractorCardsSaga() {
  yield takeLatest(GET_CONTRACTOR_CARDS, getContractorCardsRequest);
}
