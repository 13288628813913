import React from "react";
import classes from "./checkbox.module.scss";
import CheckIcon from "./checked";
import cn from "classnames";
const Checkbox = ({ checked, onClick, bigger }) => {
  return (
    <>
      <div
        onClick={onClick}
        className={cn(
          bigger && classes.bigCheck,
          checked ? classes.checked : classes.unchecked
        )}
      >
        <CheckIcon bigger={bigger} checked={checked} />
      </div>
    </>
  );
};

export default Checkbox;
